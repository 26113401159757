/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSource = /* GraphQL */ `
  query GetSource($id: ID!) {
    getSource(id: $id) {
      res
      error
      id
      sort
      organizationID
      groupID
      description
      year
      title
      archived
    }
  }
`;
export const getSourcesInGroup = /* GraphQL */ `
  query GetSourcesInGroup($groupID: ID!, $limit: Int, $nextToken: String) {
    getSourcesInGroup(groupID: $groupID, limit: $limit, nextToken: $nextToken) {
      items {
        res
        error
        id
        sort
        organizationID
        groupID
        description
        year
        title
        archived
      }
      nextToken
    }
  }
`;
export const getAssessment = /* GraphQL */ `
  query GetAssessment($id: ID!) {
    getAssessment(id: $id) {
      res
      error
      id
      sort
      parentID
      templateRoot
      title
      assessmentStartDate
      assessmentEndDate
      assessor
      scope
      hours
      templateTitle
      name
      hsrID
      description
      questionText
      surveyState {
        unanswered
        noGapIdentified
        deferPendingReassignment
        deferPendingResponse
        clarify
        yesPendingRequestedArtifacts
        yesPendingArtifacts
        yesCompliant
        notApplicable
      }
      size
      parent {
        res
        error
        id
        sort
        parentID
        templateRoot
        title
        assessmentStartDate
        assessmentEndDate
        assessor
        scope
        hours
        templateTitle
        name
        hsrID
        description
        questionText
        surveyState {
          unanswered
          noGapIdentified
          deferPendingReassignment
          deferPendingResponse
          clarify
          yesPendingRequestedArtifacts
          yesPendingArtifacts
          yesCompliant
          notApplicable
        }
        size
        parent {
          res
          error
          id
          sort
          parentID
          templateRoot
          title
          assessmentStartDate
          assessmentEndDate
          assessor
          scope
          hours
          templateTitle
          name
          hsrID
          description
          questionText
          surveyState {
            unanswered
            noGapIdentified
            deferPendingReassignment
            deferPendingResponse
            clarify
            yesPendingRequestedArtifacts
            yesPendingArtifacts
            yesCompliant
            notApplicable
          }
          size
          parent {
            res
            error
            id
            sort
            parentID
            templateRoot
            title
            assessmentStartDate
            assessmentEndDate
            assessor
            scope
            hours
            templateTitle
            name
            hsrID
            description
            questionText
            surveyState {
              unanswered
              noGapIdentified
              deferPendingReassignment
              deferPendingResponse
              clarify
              yesPendingRequestedArtifacts
              yesPendingArtifacts
              yesCompliant
              notApplicable
            }
            size
            parent {
              res
              error
              id
              sort
              parentID
              templateRoot
              title
              assessmentStartDate
              assessmentEndDate
              assessor
              scope
              hours
              templateTitle
              name
              hsrID
              description
              questionText
              surveyState {
                unanswered
                noGapIdentified
                deferPendingReassignment
                deferPendingResponse
                clarify
                yesPendingRequestedArtifacts
                yesPendingArtifacts
                yesCompliant
                notApplicable
              }
              size
              parent {
                res
                error
                id
                sort
                parentID
                templateRoot
                title
                assessmentStartDate
                assessmentEndDate
                assessor
                scope
                hours
                templateTitle
                name
                hsrID
                description
                questionText
                surveyState {
                  unanswered
                  noGapIdentified
                  deferPendingReassignment
                  deferPendingResponse
                  clarify
                  yesPendingRequestedArtifacts
                  yesPendingArtifacts
                  yesCompliant
                  notApplicable
                }
                size
                parent {
                  res
                  error
                  id
                  sort
                  parentID
                  templateRoot
                  title
                  assessmentStartDate
                  assessmentEndDate
                  assessor
                  scope
                  hours
                  templateTitle
                  name
                  hsrID
                  description
                  questionText
                  surveyState {
                    unanswered
                    noGapIdentified
                    deferPendingReassignment
                    deferPendingResponse
                    clarify
                    yesPendingRequestedArtifacts
                    yesPendingArtifacts
                    yesCompliant
                    notApplicable
                  }
                  size
                  parent {
                    res
                    error
                    id
                    sort
                    parentID
                    templateRoot
                    title
                    assessmentStartDate
                    assessmentEndDate
                    assessor
                    scope
                    hours
                    templateTitle
                    name
                    hsrID
                    description
                    questionText
                    size
                    groupID
                    archived
                  }
                  groupID
                  archived
                  assessmentAssessor {
                    res
                    error
                    id
                    sort
                    email
                    username
                    firstName
                    lastName
                    inactive
                    lastSeen
                    lastLoggedIn
                  }
                }
                groupID
                archived
                assessmentAssessor {
                  res
                  error
                  id
                  sort
                  email
                  username
                  firstName
                  lastName
                  inactive
                  lastSeen
                  lastLoggedIn
                }
              }
              groupID
              archived
              assessmentAssessor {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
            }
            groupID
            archived
            assessmentAssessor {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
          }
          groupID
          archived
          assessmentAssessor {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
        }
        groupID
        archived
        assessmentAssessor {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
      }
      groupID
      archived
      assessmentAssessor {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
    }
  }
`;
export const getAssessmentsInGroup = /* GraphQL */ `
  query GetAssessmentsInGroup($groupID: ID!, $limit: Int, $nextToken: String) {
    getAssessmentsInGroup(
      groupID: $groupID
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        res
        error
        id
        sort
        parentID
        templateRoot
        title
        assessmentStartDate
        assessmentEndDate
        assessor
        scope
        hours
        templateTitle
        name
        hsrID
        description
        questionText
        surveyState {
          unanswered
          noGapIdentified
          deferPendingReassignment
          deferPendingResponse
          clarify
          yesPendingRequestedArtifacts
          yesPendingArtifacts
          yesCompliant
          notApplicable
        }
        size
        parent {
          res
          error
          id
          sort
          parentID
          templateRoot
          title
          assessmentStartDate
          assessmentEndDate
          assessor
          scope
          hours
          templateTitle
          name
          hsrID
          description
          questionText
          surveyState {
            unanswered
            noGapIdentified
            deferPendingReassignment
            deferPendingResponse
            clarify
            yesPendingRequestedArtifacts
            yesPendingArtifacts
            yesCompliant
            notApplicable
          }
          size
          parent {
            res
            error
            id
            sort
            parentID
            templateRoot
            title
            assessmentStartDate
            assessmentEndDate
            assessor
            scope
            hours
            templateTitle
            name
            hsrID
            description
            questionText
            surveyState {
              unanswered
              noGapIdentified
              deferPendingReassignment
              deferPendingResponse
              clarify
              yesPendingRequestedArtifacts
              yesPendingArtifacts
              yesCompliant
              notApplicable
            }
            size
            parent {
              res
              error
              id
              sort
              parentID
              templateRoot
              title
              assessmentStartDate
              assessmentEndDate
              assessor
              scope
              hours
              templateTitle
              name
              hsrID
              description
              questionText
              surveyState {
                unanswered
                noGapIdentified
                deferPendingReassignment
                deferPendingResponse
                clarify
                yesPendingRequestedArtifacts
                yesPendingArtifacts
                yesCompliant
                notApplicable
              }
              size
              parent {
                res
                error
                id
                sort
                parentID
                templateRoot
                title
                assessmentStartDate
                assessmentEndDate
                assessor
                scope
                hours
                templateTitle
                name
                hsrID
                description
                questionText
                surveyState {
                  unanswered
                  noGapIdentified
                  deferPendingReassignment
                  deferPendingResponse
                  clarify
                  yesPendingRequestedArtifacts
                  yesPendingArtifacts
                  yesCompliant
                  notApplicable
                }
                size
                parent {
                  res
                  error
                  id
                  sort
                  parentID
                  templateRoot
                  title
                  assessmentStartDate
                  assessmentEndDate
                  assessor
                  scope
                  hours
                  templateTitle
                  name
                  hsrID
                  description
                  questionText
                  surveyState {
                    unanswered
                    noGapIdentified
                    deferPendingReassignment
                    deferPendingResponse
                    clarify
                    yesPendingRequestedArtifacts
                    yesPendingArtifacts
                    yesCompliant
                    notApplicable
                  }
                  size
                  parent {
                    res
                    error
                    id
                    sort
                    parentID
                    templateRoot
                    title
                    assessmentStartDate
                    assessmentEndDate
                    assessor
                    scope
                    hours
                    templateTitle
                    name
                    hsrID
                    description
                    questionText
                    size
                    groupID
                    archived
                  }
                  groupID
                  archived
                  assessmentAssessor {
                    res
                    error
                    id
                    sort
                    email
                    username
                    firstName
                    lastName
                    inactive
                    lastSeen
                    lastLoggedIn
                  }
                }
                groupID
                archived
                assessmentAssessor {
                  res
                  error
                  id
                  sort
                  email
                  username
                  firstName
                  lastName
                  inactive
                  lastSeen
                  lastLoggedIn
                }
              }
              groupID
              archived
              assessmentAssessor {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
            }
            groupID
            archived
            assessmentAssessor {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
          }
          groupID
          archived
          assessmentAssessor {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
        }
        groupID
        archived
        assessmentAssessor {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      res
      error
      id
      sort
      email
      username
      firstName
      lastName
      inactive
      lastSeen
      lastLoggedIn
    }
  }
`;
export const getOrganization = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      res
      error
      id
      sort
      name
      organizationRelationships
      demographics
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      res
      error
      id
      sort
      createdAt
      createdBy
      modifiedBy
      lastModified
      itemID
      moduleName
      item {
        id
        sort
        groupID
        owner
        uuid
        title
        dateClosed
        dueDate
        status
        priority
        severity
        responseStatus
        archived
        createdAt
        lastModified
        compositeIDYear
        compositeID
        displayID
        relatedItemsOwner {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        relatedItemsGroup {
          res
          error
          id
          sort
          organizationID
          title
          shortTitle
          riskThreshold {
            financial {
              appetitePercent
              appetiteAbsolute
              capacityPercent
              capacityAbsolute
            }
            privacy {
              appetitePercent
              appetiteAbsolute
              capacityPercent
              capacityAbsolute
            }
          }
          organization {
            res
            error
            id
            sort
            name
            organizationRelationships
            demographics
          }
          groupEmailFooter
        }
        fileName
        address
      }
      notificationType
      data
      isRead
    }
  }
`;
export const getArtifact = /* GraphQL */ `
  query GetArtifact($id: ID!) {
    getArtifact(id: $id) {
      res
      error
      id
      sort
      groupID
      fileName
      displayFileName
      pointOfContact
      artifactID
      archived
    }
  }
`;
export const getControls = /* GraphQL */ `
  query GetControls($id: ID!) {
    getControls(id: $id) {
      res
      error
      id
      sort
      displayID
      uuid
      parentID
      title
      description
      discussion
      relationships
      controlsList {
        items {
          res
          error
          id
          sort
          displayID
          uuid
          parentID
          title
          description
          discussion
          relationships
          controlsList {
            items {
              res
              error
              id
              sort
              displayID
              uuid
              parentID
              title
              description
              discussion
              relationships
              controlsList {
                items {
                  res
                  error
                  id
                  sort
                  displayID
                  uuid
                  parentID
                  title
                  description
                  discussion
                  relationships
                  controlsList {
                    nextToken
                  }
                  controlsRelatedItems {
                    nextToken
                  }
                }
                nextToken
              }
              controlsRelatedItems {
                items {
                  id
                  sort
                  target
                  targetType
                  item {
                    id
                    sort
                    groupID
                    owner
                    uuid
                    title
                    dateClosed
                    dueDate
                    status
                    priority
                    severity
                    responseStatus
                    archived
                    createdAt
                    lastModified
                    compositeIDYear
                    compositeID
                    displayID
                    fileName
                    address
                  }
                }
                nextToken
              }
            }
            nextToken
          }
          controlsRelatedItems {
            items {
              id
              sort
              target
              targetType
              item {
                id
                sort
                groupID
                owner
                uuid
                title
                dateClosed
                dueDate
                status
                priority
                severity
                responseStatus
                archived
                createdAt
                lastModified
                compositeIDYear
                compositeID
                displayID
                relatedItemsOwner {
                  res
                  error
                  id
                  sort
                  email
                  username
                  firstName
                  lastName
                  inactive
                  lastSeen
                  lastLoggedIn
                }
                relatedItemsGroup {
                  res
                  error
                  id
                  sort
                  organizationID
                  title
                  shortTitle
                  organization {
                    res
                    error
                    id
                    sort
                    name
                    organizationRelationships
                    demographics
                  }
                  groupEmailFooter
                }
                fileName
                address
              }
            }
            nextToken
          }
        }
        nextToken
      }
      controlsRelatedItems {
        items {
          id
          sort
          target
          targetType
          item {
            id
            sort
            groupID
            owner
            uuid
            title
            dateClosed
            dueDate
            status
            priority
            severity
            responseStatus
            archived
            createdAt
            lastModified
            compositeIDYear
            compositeID
            displayID
            relatedItemsOwner {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
            relatedItemsGroup {
              res
              error
              id
              sort
              organizationID
              title
              shortTitle
              riskThreshold {
                financial {
                  appetitePercent
                  appetiteAbsolute
                  capacityPercent
                  capacityAbsolute
                }
                privacy {
                  appetitePercent
                  appetiteAbsolute
                  capacityPercent
                  capacityAbsolute
                }
              }
              organization {
                res
                error
                id
                sort
                name
                organizationRelationships
                demographics
              }
              groupEmailFooter
            }
            fileName
            address
          }
        }
        nextToken
      }
    }
  }
`;
export const getRiskPortfolio = /* GraphQL */ `
  query GetRiskPortfolio($id: ID!) {
    getRiskPortfolio(id: $id) {
      res
      error
      id
      sort
      groupID
      title
      description
      notes
      owner
      status
      assumptions
      archived
      modifiedBy
    }
  }
`;
export const getRiskPortfoliosInGroup = /* GraphQL */ `
  query GetRiskPortfoliosInGroup(
    $groupID: ID!
    $limit: Int
    $nextToken: String
  ) {
    getRiskPortfoliosInGroup(
      groupID: $groupID
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        res
        error
        id
        sort
        groupID
        title
        description
        notes
        owner
        status
        assumptions
        archived
        modifiedBy
      }
      nextToken
    }
  }
`;
export const getRiskComparison = /* GraphQL */ `
  query GetRiskComparison($id: ID!) {
    getRiskComparison(id: $id) {
      res
      error
      id
      sort
      groupID
      title
      description
      scenarioOneID
      scenarioTwoID
      scenarioThreeID
      scenarioOne {
        res
        error
        id
        sort
        createdAt
        createdBy
        modifiedBy
        lastModified
        FAIRSummary
        scenarioID
        title
        description
        notes
        assumptions
        creationDate
        lastRevision
        nextRevision
        categories
        owner
        status
        dateClosed
        indicator
        compositeIDYear
        compositeID
        archived
        isTemplate
        groupID
        scenarioRelatedItems {
          items {
            id
            sort
            target
            targetType
            item {
              id
              sort
              groupID
              owner
              uuid
              title
              dateClosed
              dueDate
              status
              priority
              severity
              responseStatus
              archived
              createdAt
              lastModified
              compositeIDYear
              compositeID
              displayID
              relatedItemsOwner {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
              relatedItemsGroup {
                res
                error
                id
                sort
                organizationID
                title
                shortTitle
                riskThreshold {
                  financial {
                    appetitePercent
                    appetiteAbsolute
                    capacityPercent
                    capacityAbsolute
                  }
                  privacy {
                    appetitePercent
                    appetiteAbsolute
                    capacityPercent
                    capacityAbsolute
                  }
                }
                organization {
                  res
                  error
                  id
                  sort
                  name
                  organizationRelationships
                  demographics
                }
                groupEmailFooter
              }
              fileName
              address
            }
          }
          nextToken
        }
        scenarioOwner {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        scenarioCreatedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        scenarioModifiedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        scenarioRASCIRoles {
          items {
            id
            sort
            userID
            userInfo {
              id
              sort
              email
              firstName
              lastName
              inactive
            }
            user {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
            userRASCIRole
          }
          nextToken
        }
      }
      scenarioTwo {
        res
        error
        id
        sort
        createdAt
        createdBy
        modifiedBy
        lastModified
        FAIRSummary
        scenarioID
        title
        description
        notes
        assumptions
        creationDate
        lastRevision
        nextRevision
        categories
        owner
        status
        dateClosed
        indicator
        compositeIDYear
        compositeID
        archived
        isTemplate
        groupID
        scenarioRelatedItems {
          items {
            id
            sort
            target
            targetType
            item {
              id
              sort
              groupID
              owner
              uuid
              title
              dateClosed
              dueDate
              status
              priority
              severity
              responseStatus
              archived
              createdAt
              lastModified
              compositeIDYear
              compositeID
              displayID
              relatedItemsOwner {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
              relatedItemsGroup {
                res
                error
                id
                sort
                organizationID
                title
                shortTitle
                riskThreshold {
                  financial {
                    appetitePercent
                    appetiteAbsolute
                    capacityPercent
                    capacityAbsolute
                  }
                  privacy {
                    appetitePercent
                    appetiteAbsolute
                    capacityPercent
                    capacityAbsolute
                  }
                }
                organization {
                  res
                  error
                  id
                  sort
                  name
                  organizationRelationships
                  demographics
                }
                groupEmailFooter
              }
              fileName
              address
            }
          }
          nextToken
        }
        scenarioOwner {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        scenarioCreatedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        scenarioModifiedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        scenarioRASCIRoles {
          items {
            id
            sort
            userID
            userInfo {
              id
              sort
              email
              firstName
              lastName
              inactive
            }
            user {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
            userRASCIRole
          }
          nextToken
        }
      }
      scenarioThree {
        res
        error
        id
        sort
        createdAt
        createdBy
        modifiedBy
        lastModified
        FAIRSummary
        scenarioID
        title
        description
        notes
        assumptions
        creationDate
        lastRevision
        nextRevision
        categories
        owner
        status
        dateClosed
        indicator
        compositeIDYear
        compositeID
        archived
        isTemplate
        groupID
        scenarioRelatedItems {
          items {
            id
            sort
            target
            targetType
            item {
              id
              sort
              groupID
              owner
              uuid
              title
              dateClosed
              dueDate
              status
              priority
              severity
              responseStatus
              archived
              createdAt
              lastModified
              compositeIDYear
              compositeID
              displayID
              relatedItemsOwner {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
              relatedItemsGroup {
                res
                error
                id
                sort
                organizationID
                title
                shortTitle
                riskThreshold {
                  financial {
                    appetitePercent
                    appetiteAbsolute
                    capacityPercent
                    capacityAbsolute
                  }
                  privacy {
                    appetitePercent
                    appetiteAbsolute
                    capacityPercent
                    capacityAbsolute
                  }
                }
                organization {
                  res
                  error
                  id
                  sort
                  name
                  organizationRelationships
                  demographics
                }
                groupEmailFooter
              }
              fileName
              address
            }
          }
          nextToken
        }
        scenarioOwner {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        scenarioCreatedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        scenarioModifiedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        scenarioRASCIRoles {
          items {
            id
            sort
            userID
            userInfo {
              id
              sort
              email
              firstName
              lastName
              inactive
            }
            user {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
            userRASCIRole
          }
          nextToken
        }
      }
      scenarioOneDescription
      scenarioTwoDescription
      scenarioThreeDescription
      lossThreshold
      archived
      modifiedBy
    }
  }
`;
export const getRiskComparisonsInGroup = /* GraphQL */ `
  query GetRiskComparisonsInGroup(
    $groupID: ID!
    $limit: Int
    $nextToken: String
  ) {
    getRiskComparisonsInGroup(
      groupID: $groupID
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        res
        error
        id
        sort
        groupID
        title
        description
        scenarioOneID
        scenarioTwoID
        scenarioThreeID
        scenarioOne {
          res
          error
          id
          sort
          createdAt
          createdBy
          modifiedBy
          lastModified
          FAIRSummary
          scenarioID
          title
          description
          notes
          assumptions
          creationDate
          lastRevision
          nextRevision
          categories
          owner
          status
          dateClosed
          indicator
          compositeIDYear
          compositeID
          archived
          isTemplate
          groupID
          scenarioRelatedItems {
            items {
              id
              sort
              target
              targetType
              item {
                id
                sort
                groupID
                owner
                uuid
                title
                dateClosed
                dueDate
                status
                priority
                severity
                responseStatus
                archived
                createdAt
                lastModified
                compositeIDYear
                compositeID
                displayID
                relatedItemsOwner {
                  res
                  error
                  id
                  sort
                  email
                  username
                  firstName
                  lastName
                  inactive
                  lastSeen
                  lastLoggedIn
                }
                relatedItemsGroup {
                  res
                  error
                  id
                  sort
                  organizationID
                  title
                  shortTitle
                  organization {
                    res
                    error
                    id
                    sort
                    name
                    organizationRelationships
                    demographics
                  }
                  groupEmailFooter
                }
                fileName
                address
              }
            }
            nextToken
          }
          scenarioOwner {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          scenarioCreatedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          scenarioModifiedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          scenarioRASCIRoles {
            items {
              id
              sort
              userID
              userInfo {
                id
                sort
                email
                firstName
                lastName
                inactive
              }
              user {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
              userRASCIRole
            }
            nextToken
          }
        }
        scenarioTwo {
          res
          error
          id
          sort
          createdAt
          createdBy
          modifiedBy
          lastModified
          FAIRSummary
          scenarioID
          title
          description
          notes
          assumptions
          creationDate
          lastRevision
          nextRevision
          categories
          owner
          status
          dateClosed
          indicator
          compositeIDYear
          compositeID
          archived
          isTemplate
          groupID
          scenarioRelatedItems {
            items {
              id
              sort
              target
              targetType
              item {
                id
                sort
                groupID
                owner
                uuid
                title
                dateClosed
                dueDate
                status
                priority
                severity
                responseStatus
                archived
                createdAt
                lastModified
                compositeIDYear
                compositeID
                displayID
                relatedItemsOwner {
                  res
                  error
                  id
                  sort
                  email
                  username
                  firstName
                  lastName
                  inactive
                  lastSeen
                  lastLoggedIn
                }
                relatedItemsGroup {
                  res
                  error
                  id
                  sort
                  organizationID
                  title
                  shortTitle
                  organization {
                    res
                    error
                    id
                    sort
                    name
                    organizationRelationships
                    demographics
                  }
                  groupEmailFooter
                }
                fileName
                address
              }
            }
            nextToken
          }
          scenarioOwner {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          scenarioCreatedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          scenarioModifiedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          scenarioRASCIRoles {
            items {
              id
              sort
              userID
              userInfo {
                id
                sort
                email
                firstName
                lastName
                inactive
              }
              user {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
              userRASCIRole
            }
            nextToken
          }
        }
        scenarioThree {
          res
          error
          id
          sort
          createdAt
          createdBy
          modifiedBy
          lastModified
          FAIRSummary
          scenarioID
          title
          description
          notes
          assumptions
          creationDate
          lastRevision
          nextRevision
          categories
          owner
          status
          dateClosed
          indicator
          compositeIDYear
          compositeID
          archived
          isTemplate
          groupID
          scenarioRelatedItems {
            items {
              id
              sort
              target
              targetType
              item {
                id
                sort
                groupID
                owner
                uuid
                title
                dateClosed
                dueDate
                status
                priority
                severity
                responseStatus
                archived
                createdAt
                lastModified
                compositeIDYear
                compositeID
                displayID
                relatedItemsOwner {
                  res
                  error
                  id
                  sort
                  email
                  username
                  firstName
                  lastName
                  inactive
                  lastSeen
                  lastLoggedIn
                }
                relatedItemsGroup {
                  res
                  error
                  id
                  sort
                  organizationID
                  title
                  shortTitle
                  organization {
                    res
                    error
                    id
                    sort
                    name
                    organizationRelationships
                    demographics
                  }
                  groupEmailFooter
                }
                fileName
                address
              }
            }
            nextToken
          }
          scenarioOwner {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          scenarioCreatedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          scenarioModifiedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          scenarioRASCIRoles {
            items {
              id
              sort
              userID
              userInfo {
                id
                sort
                email
                firstName
                lastName
                inactive
              }
              user {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
              userRASCIRole
            }
            nextToken
          }
        }
        scenarioOneDescription
        scenarioTwoDescription
        scenarioThreeDescription
        lossThreshold
        archived
        modifiedBy
      }
      nextToken
    }
  }
`;
export const getRiskDomain = /* GraphQL */ `
  query GetRiskDomain($id: ID!) {
    getRiskDomain(id: $id) {
      res
      error
      id
      groupID
      sort
      createdAt
      createdBy
      modifiedBy
      lastModified
      title
      description
      displayID
      parentID
      parent {
        res
        error
        id
        groupID
        sort
        createdAt
        createdBy
        modifiedBy
        lastModified
        title
        description
        displayID
        parentID
        parent {
          res
          error
          id
          groupID
          sort
          createdAt
          createdBy
          modifiedBy
          lastModified
          title
          description
          displayID
          parentID
          parent {
            res
            error
            id
            groupID
            sort
            createdAt
            createdBy
            modifiedBy
            lastModified
            title
            description
            displayID
            parentID
            parent {
              res
              error
              id
              groupID
              sort
              createdAt
              createdBy
              modifiedBy
              lastModified
              title
              description
              displayID
              parentID
              parent {
                res
                error
                id
                groupID
                sort
                createdAt
                createdBy
                modifiedBy
                lastModified
                title
                description
                displayID
                parentID
                parent {
                  res
                  error
                  id
                  groupID
                  sort
                  createdAt
                  createdBy
                  modifiedBy
                  lastModified
                  title
                  description
                  displayID
                  parentID
                  parent {
                    res
                    error
                    id
                    groupID
                    sort
                    createdAt
                    createdBy
                    modifiedBy
                    lastModified
                    title
                    description
                    displayID
                    parentID
                    archived
                  }
                  archived
                  riskDomainCreatedBy {
                    res
                    error
                    id
                    sort
                    email
                    username
                    firstName
                    lastName
                    inactive
                    lastSeen
                    lastLoggedIn
                  }
                  riskDomainModifiedBy {
                    res
                    error
                    id
                    sort
                    email
                    username
                    firstName
                    lastName
                    inactive
                    lastSeen
                    lastLoggedIn
                  }
                }
                archived
                riskDomainCreatedBy {
                  res
                  error
                  id
                  sort
                  email
                  username
                  firstName
                  lastName
                  inactive
                  lastSeen
                  lastLoggedIn
                }
                riskDomainModifiedBy {
                  res
                  error
                  id
                  sort
                  email
                  username
                  firstName
                  lastName
                  inactive
                  lastSeen
                  lastLoggedIn
                }
              }
              archived
              riskDomainCreatedBy {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
              riskDomainModifiedBy {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
            }
            archived
            riskDomainCreatedBy {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
            riskDomainModifiedBy {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
          }
          archived
          riskDomainCreatedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          riskDomainModifiedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
        }
        archived
        riskDomainCreatedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        riskDomainModifiedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
      }
      archived
      riskDomainCreatedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      riskDomainModifiedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
    }
  }
`;
export const getRiskDomainsInGroup = /* GraphQL */ `
  query GetRiskDomainsInGroup($groupID: ID!, $limit: Int, $nextToken: String) {
    getRiskDomainsInGroup(
      groupID: $groupID
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        res
        error
        id
        groupID
        sort
        createdAt
        createdBy
        modifiedBy
        lastModified
        title
        description
        displayID
        parentID
        parent {
          res
          error
          id
          groupID
          sort
          createdAt
          createdBy
          modifiedBy
          lastModified
          title
          description
          displayID
          parentID
          parent {
            res
            error
            id
            groupID
            sort
            createdAt
            createdBy
            modifiedBy
            lastModified
            title
            description
            displayID
            parentID
            parent {
              res
              error
              id
              groupID
              sort
              createdAt
              createdBy
              modifiedBy
              lastModified
              title
              description
              displayID
              parentID
              parent {
                res
                error
                id
                groupID
                sort
                createdAt
                createdBy
                modifiedBy
                lastModified
                title
                description
                displayID
                parentID
                parent {
                  res
                  error
                  id
                  groupID
                  sort
                  createdAt
                  createdBy
                  modifiedBy
                  lastModified
                  title
                  description
                  displayID
                  parentID
                  parent {
                    res
                    error
                    id
                    groupID
                    sort
                    createdAt
                    createdBy
                    modifiedBy
                    lastModified
                    title
                    description
                    displayID
                    parentID
                    archived
                  }
                  archived
                  riskDomainCreatedBy {
                    res
                    error
                    id
                    sort
                    email
                    username
                    firstName
                    lastName
                    inactive
                    lastSeen
                    lastLoggedIn
                  }
                  riskDomainModifiedBy {
                    res
                    error
                    id
                    sort
                    email
                    username
                    firstName
                    lastName
                    inactive
                    lastSeen
                    lastLoggedIn
                  }
                }
                archived
                riskDomainCreatedBy {
                  res
                  error
                  id
                  sort
                  email
                  username
                  firstName
                  lastName
                  inactive
                  lastSeen
                  lastLoggedIn
                }
                riskDomainModifiedBy {
                  res
                  error
                  id
                  sort
                  email
                  username
                  firstName
                  lastName
                  inactive
                  lastSeen
                  lastLoggedIn
                }
              }
              archived
              riskDomainCreatedBy {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
              riskDomainModifiedBy {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
            }
            archived
            riskDomainCreatedBy {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
            riskDomainModifiedBy {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
          }
          archived
          riskDomainCreatedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          riskDomainModifiedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
        }
        archived
        riskDomainCreatedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        riskDomainModifiedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
      }
      nextToken
    }
  }
`;
export const getAuditPlanning = /* GraphQL */ `
  query GetAuditPlanning($id: ID!) {
    getAuditPlanning(id: $id) {
      res
      error
      id
      title
      groupID
      sort
      createdAt
      createdBy
      modifiedBy
      lastModified
      auditPlanID
      riskDomain
      year
      hours
      owner
      days
      status
      background
      objective
      scope
      methodology
      preliminaryAuditWork
      points
      summary
      auditFindings
      recommendations
      auditStartDate
      auditEndDate
      auditor
      totalHours
      archived
      auditPlanningRelatedItems {
        items {
          id
          sort
          target
          targetType
          item {
            id
            sort
            groupID
            owner
            uuid
            title
            dateClosed
            dueDate
            status
            priority
            severity
            responseStatus
            archived
            createdAt
            lastModified
            compositeIDYear
            compositeID
            displayID
            relatedItemsOwner {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
            relatedItemsGroup {
              res
              error
              id
              sort
              organizationID
              title
              shortTitle
              riskThreshold {
                financial {
                  appetitePercent
                  appetiteAbsolute
                  capacityPercent
                  capacityAbsolute
                }
                privacy {
                  appetitePercent
                  appetiteAbsolute
                  capacityPercent
                  capacityAbsolute
                }
              }
              organization {
                res
                error
                id
                sort
                name
                organizationRelationships
                demographics
              }
              groupEmailFooter
            }
            fileName
            address
          }
        }
        nextToken
      }
      isTemplate
      auditPlanningOwner {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      auditPlanningCreatedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      auditPlanningModifiedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      riskDomainDetails {
        res
        error
        id
        groupID
        sort
        createdAt
        createdBy
        modifiedBy
        lastModified
        title
        description
        displayID
        parentID
        parent {
          res
          error
          id
          groupID
          sort
          createdAt
          createdBy
          modifiedBy
          lastModified
          title
          description
          displayID
          parentID
          parent {
            res
            error
            id
            groupID
            sort
            createdAt
            createdBy
            modifiedBy
            lastModified
            title
            description
            displayID
            parentID
            parent {
              res
              error
              id
              groupID
              sort
              createdAt
              createdBy
              modifiedBy
              lastModified
              title
              description
              displayID
              parentID
              parent {
                res
                error
                id
                groupID
                sort
                createdAt
                createdBy
                modifiedBy
                lastModified
                title
                description
                displayID
                parentID
                parent {
                  res
                  error
                  id
                  groupID
                  sort
                  createdAt
                  createdBy
                  modifiedBy
                  lastModified
                  title
                  description
                  displayID
                  parentID
                  parent {
                    res
                    error
                    id
                    groupID
                    sort
                    createdAt
                    createdBy
                    modifiedBy
                    lastModified
                    title
                    description
                    displayID
                    parentID
                    archived
                  }
                  archived
                  riskDomainCreatedBy {
                    res
                    error
                    id
                    sort
                    email
                    username
                    firstName
                    lastName
                    inactive
                    lastSeen
                    lastLoggedIn
                  }
                  riskDomainModifiedBy {
                    res
                    error
                    id
                    sort
                    email
                    username
                    firstName
                    lastName
                    inactive
                    lastSeen
                    lastLoggedIn
                  }
                }
                archived
                riskDomainCreatedBy {
                  res
                  error
                  id
                  sort
                  email
                  username
                  firstName
                  lastName
                  inactive
                  lastSeen
                  lastLoggedIn
                }
                riskDomainModifiedBy {
                  res
                  error
                  id
                  sort
                  email
                  username
                  firstName
                  lastName
                  inactive
                  lastSeen
                  lastLoggedIn
                }
              }
              archived
              riskDomainCreatedBy {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
              riskDomainModifiedBy {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
            }
            archived
            riskDomainCreatedBy {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
            riskDomainModifiedBy {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
          }
          archived
          riskDomainCreatedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          riskDomainModifiedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
        }
        archived
        riskDomainCreatedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        riskDomainModifiedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
      }
      managementResponse
      viewType
    }
  }
`;
export const getUrl = /* GraphQL */ `
  query GetUrl($id: ID!) {
    getUrl(id: $id) {
      res
      error
      id
      sort
      address
      title
      groupID
    }
  }
`;
export const getUrlsInGroup = /* GraphQL */ `
  query GetUrlsInGroup($groupID: ID!, $limit: Int, $nextToken: String) {
    getUrlsInGroup(groupID: $groupID, limit: $limit, nextToken: $nextToken) {
      items {
        res
        error
        id
        sort
        address
        title
        groupID
      }
      nextToken
    }
  }
`;
export const getCategory = /* GraphQL */ `
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      res
      error
      id
      groupID
      sort
      createdAt
      createdBy
      modifiedBy
      lastModified
      title
      archived
      categoryCreatedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      categoryModifiedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
    }
  }
`;
export const getCategorysInGroup = /* GraphQL */ `
  query GetCategorysInGroup($groupID: ID!, $limit: Int, $nextToken: String) {
    getCategorysInGroup(
      groupID: $groupID
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        res
        error
        id
        groupID
        sort
        createdAt
        createdBy
        modifiedBy
        lastModified
        title
        archived
        categoryCreatedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        categoryModifiedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
      }
      nextToken
    }
  }
`;
export const getSupport = /* GraphQL */ `
  query GetSupport($id: ID!) {
    getSupport(id: $id) {
      res
      error
      id
      groupID
      sort
      createdAt
      createdBy
      modifiedBy
      lastModified
      title
      pointOfContact
      pocEmail
      pocPhoneNumber
      notes
      archived
      supportCreatedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      supportModifiedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
    }
  }
`;
export const getSupportsInGroup = /* GraphQL */ `
  query GetSupportsInGroup($groupID: ID!, $limit: Int, $nextToken: String) {
    getSupportsInGroup(
      groupID: $groupID
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        res
        error
        id
        groupID
        sort
        createdAt
        createdBy
        modifiedBy
        lastModified
        title
        pointOfContact
        pocEmail
        pocPhoneNumber
        notes
        archived
        supportCreatedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        supportModifiedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
      }
      nextToken
    }
  }
`;
export const getLocation = /* GraphQL */ `
  query GetLocation($id: ID!) {
    getLocation(id: $id) {
      res
      error
      id
      groupID
      sort
      createdAt
      createdBy
      modifiedBy
      lastModified
      title
      archived
      locationCreatedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      locationModifiedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
    }
  }
`;
export const getLocationsInGroup = /* GraphQL */ `
  query GetLocationsInGroup($groupID: ID!, $limit: Int, $nextToken: String) {
    getLocationsInGroup(
      groupID: $groupID
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        res
        error
        id
        groupID
        sort
        createdAt
        createdBy
        modifiedBy
        lastModified
        title
        archived
        locationCreatedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        locationModifiedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
      }
      nextToken
    }
  }
`;
export const getAssetsGroup = /* GraphQL */ `
  query GetAssetsGroup($id: ID!) {
    getAssetsGroup(id: $id) {
      res
      error
      id
      groupID
      sort
      createdAt
      createdBy
      modifiedBy
      lastModified
      title
      archived
      assetsGroupCreatedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      assetsGroupModifiedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
    }
  }
`;
export const getAssetsGroupsInGroup = /* GraphQL */ `
  query GetAssetsGroupsInGroup($groupID: ID!, $limit: Int, $nextToken: String) {
    getAssetsGroupsInGroup(
      groupID: $groupID
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        res
        error
        id
        groupID
        sort
        createdAt
        createdBy
        modifiedBy
        lastModified
        title
        archived
        assetsGroupCreatedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        assetsGroupModifiedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
      }
      nextToken
    }
  }
`;
export const getThreats = /* GraphQL */ `
  query GetThreats($id: ID!) {
    getThreats(id: $id) {
      res
      error
      id
      itemID
      groupID
      sort
      createdAt
      createdBy
      modifiedBy
      lastModified
      title
      description
      impact
      archived
      threatsCreatedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      threatsModifiedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      threatAssessments {
        items {
          res
          error
          id
          sort
          createdAt
          createdBy
          modifiedBy
          lastModified
          threatID
          assetID
          min
          mostLikely
          max
          vulnerability
          index
          archived
        }
        nextToken
      }
    }
  }
`;
export const getThreatssInGroup = /* GraphQL */ `
  query GetThreatssInGroup($groupID: ID!, $limit: Int, $nextToken: String) {
    getThreatssInGroup(
      groupID: $groupID
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        res
        error
        id
        itemID
        groupID
        sort
        createdAt
        createdBy
        modifiedBy
        lastModified
        title
        description
        impact
        archived
        threatsCreatedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        threatsModifiedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        threatAssessments {
          items {
            res
            error
            id
            sort
            createdAt
            createdBy
            modifiedBy
            lastModified
            threatID
            assetID
            min
            mostLikely
            max
            vulnerability
            index
            archived
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getAssets = /* GraphQL */ `
  query GetAssets($id: ID!) {
    getAssets(id: $id) {
      res
      error
      id
      itemID
      groupID
      sort
      createdAt
      createdBy
      modifiedBy
      lastModified
      title
      qty
      assetsGroupID
      notesAboutControls
      notesAboutThreats
      category
      assetCategory {
        res
        error
        id
        groupID
        sort
        createdAt
        createdBy
        modifiedBy
        lastModified
        title
        archived
        categoryCreatedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        categoryModifiedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
      }
      location
      assetLocation {
        res
        error
        id
        groupID
        sort
        createdAt
        createdBy
        modifiedBy
        lastModified
        title
        archived
        locationCreatedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        locationModifiedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
      }
      support
      assetSupport {
        res
        error
        id
        groupID
        sort
        createdAt
        createdBy
        modifiedBy
        lastModified
        title
        pointOfContact
        pocEmail
        pocPhoneNumber
        notes
        archived
        supportCreatedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        supportModifiedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
      }
      owner
      assetsAnalysisInput
      assetsAnalysisOutput
      assetOwner {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      assetCreatedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      assetModifiedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      notes
      displayID
      assetRelatedItems {
        items {
          id
          sort
          target
          targetType
          item {
            id
            sort
            groupID
            owner
            uuid
            title
            dateClosed
            dueDate
            status
            priority
            severity
            responseStatus
            archived
            createdAt
            lastModified
            compositeIDYear
            compositeID
            displayID
            relatedItemsOwner {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
            relatedItemsGroup {
              res
              error
              id
              sort
              organizationID
              title
              shortTitle
              riskThreshold {
                financial {
                  appetitePercent
                  appetiteAbsolute
                  capacityPercent
                  capacityAbsolute
                }
                privacy {
                  appetitePercent
                  appetiteAbsolute
                  capacityPercent
                  capacityAbsolute
                }
              }
              organization {
                res
                error
                id
                sort
                name
                organizationRelationships
                demographics
              }
              groupEmailFooter
            }
            fileName
            address
          }
        }
        nextToken
      }
      archived
      threatAssessments {
        items {
          res
          error
          id
          sort
          createdAt
          createdBy
          modifiedBy
          lastModified
          threatID
          assetID
          min
          mostLikely
          max
          vulnerability
          index
          archived
        }
        nextToken
      }
      PHIRecords
      assetsGroupDetails {
        res
        error
        id
        groupID
        sort
        createdAt
        createdBy
        modifiedBy
        lastModified
        title
        archived
        assetsGroupCreatedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        assetsGroupModifiedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
      }
      assetID
      assessmentID
    }
  }
`;
export const getRiskAssessment = /* GraphQL */ `
  query GetRiskAssessment($id: ID!) {
    getRiskAssessment(id: $id) {
      res
      error
      id
      title
      groupID
      sort
      createdAt
      createdBy
      modifiedBy
      lastModified
      owner
      startDate
      endDate
      overview
      assessor
      scope
      executiveSummary
      methodology
      archived
      riskAssessmentOwner {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      riskAssessmentCreatedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      riskAssessmentModifiedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
    }
  }
`;
export const getRiskAssessmentsInGroup = /* GraphQL */ `
  query GetRiskAssessmentsInGroup(
    $groupID: ID!
    $limit: Int
    $nextToken: String
  ) {
    getRiskAssessmentsInGroup(
      groupID: $groupID
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        res
        error
        id
        title
        groupID
        sort
        createdAt
        createdBy
        modifiedBy
        lastModified
        owner
        startDate
        endDate
        overview
        assessor
        scope
        executiveSummary
        methodology
        archived
        riskAssessmentOwner {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        riskAssessmentCreatedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        riskAssessmentModifiedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
      }
      nextToken
    }
  }
`;
export const getSurveyTemplate = /* GraphQL */ `
  query GetSurveyTemplate($id: ID!) {
    getSurveyTemplate(id: $id) {
      res
      error
      id
      sort
      parentID
      templateRoot
      title
      locked
    }
  }
`;
export const getTemplateQuestion = /* GraphQL */ `
  query GetTemplateQuestion($id: ID!) {
    getTemplateQuestion(id: $id) {
      res
      error
      id
      sort
      parentID
      name
      questionText
      requiresArtifacts
    }
  }
`;
export const getTemplateQuestionsInGroup = /* GraphQL */ `
  query GetTemplateQuestionsInGroup(
    $groupID: ID!
    $limit: Int
    $nextToken: String
  ) {
    getTemplateQuestionsInGroup(
      groupID: $groupID
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        res
        error
        id
        sort
        parentID
        name
        questionText
        requiresArtifacts
      }
      nextToken
    }
  }
`;
export const getAssessmentResponse = /* GraphQL */ `
  query GetAssessmentResponse($id: ID!) {
    getAssessmentResponse(id: $id) {
      res
      error
      id
      sort
      parentID
      title
      instructions
      notes
      responseType
      questionText
      response
      responseComments
      responseStatus
      artifactRequired
      artifactRequired_yes
      artifactRequired_no
      owner
      parent {
        res
        error
        id
        sort
        parentID
        templateRoot
        title
        assessmentStartDate
        assessmentEndDate
        assessor
        scope
        hours
        templateTitle
        name
        hsrID
        description
        questionText
        surveyState {
          unanswered
          noGapIdentified
          deferPendingReassignment
          deferPendingResponse
          clarify
          yesPendingRequestedArtifacts
          yesPendingArtifacts
          yesCompliant
          notApplicable
        }
        size
        parent {
          res
          error
          id
          sort
          parentID
          templateRoot
          title
          assessmentStartDate
          assessmentEndDate
          assessor
          scope
          hours
          templateTitle
          name
          hsrID
          description
          questionText
          surveyState {
            unanswered
            noGapIdentified
            deferPendingReassignment
            deferPendingResponse
            clarify
            yesPendingRequestedArtifacts
            yesPendingArtifacts
            yesCompliant
            notApplicable
          }
          size
          parent {
            res
            error
            id
            sort
            parentID
            templateRoot
            title
            assessmentStartDate
            assessmentEndDate
            assessor
            scope
            hours
            templateTitle
            name
            hsrID
            description
            questionText
            surveyState {
              unanswered
              noGapIdentified
              deferPendingReassignment
              deferPendingResponse
              clarify
              yesPendingRequestedArtifacts
              yesPendingArtifacts
              yesCompliant
              notApplicable
            }
            size
            parent {
              res
              error
              id
              sort
              parentID
              templateRoot
              title
              assessmentStartDate
              assessmentEndDate
              assessor
              scope
              hours
              templateTitle
              name
              hsrID
              description
              questionText
              surveyState {
                unanswered
                noGapIdentified
                deferPendingReassignment
                deferPendingResponse
                clarify
                yesPendingRequestedArtifacts
                yesPendingArtifacts
                yesCompliant
                notApplicable
              }
              size
              parent {
                res
                error
                id
                sort
                parentID
                templateRoot
                title
                assessmentStartDate
                assessmentEndDate
                assessor
                scope
                hours
                templateTitle
                name
                hsrID
                description
                questionText
                surveyState {
                  unanswered
                  noGapIdentified
                  deferPendingReassignment
                  deferPendingResponse
                  clarify
                  yesPendingRequestedArtifacts
                  yesPendingArtifacts
                  yesCompliant
                  notApplicable
                }
                size
                parent {
                  res
                  error
                  id
                  sort
                  parentID
                  templateRoot
                  title
                  assessmentStartDate
                  assessmentEndDate
                  assessor
                  scope
                  hours
                  templateTitle
                  name
                  hsrID
                  description
                  questionText
                  surveyState {
                    unanswered
                    noGapIdentified
                    deferPendingReassignment
                    deferPendingResponse
                    clarify
                    yesPendingRequestedArtifacts
                    yesPendingArtifacts
                    yesCompliant
                    notApplicable
                  }
                  size
                  parent {
                    res
                    error
                    id
                    sort
                    parentID
                    templateRoot
                    title
                    assessmentStartDate
                    assessmentEndDate
                    assessor
                    scope
                    hours
                    templateTitle
                    name
                    hsrID
                    description
                    questionText
                    size
                    groupID
                    archived
                  }
                  groupID
                  archived
                  assessmentAssessor {
                    res
                    error
                    id
                    sort
                    email
                    username
                    firstName
                    lastName
                    inactive
                    lastSeen
                    lastLoggedIn
                  }
                }
                groupID
                archived
                assessmentAssessor {
                  res
                  error
                  id
                  sort
                  email
                  username
                  firstName
                  lastName
                  inactive
                  lastSeen
                  lastLoggedIn
                }
              }
              groupID
              archived
              assessmentAssessor {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
            }
            groupID
            archived
            assessmentAssessor {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
          }
          groupID
          archived
          assessmentAssessor {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
        }
        groupID
        archived
        assessmentAssessor {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
      }
      groupID
      responseRelatedItems {
        items {
          id
          sort
          target
          targetType
          item {
            id
            sort
            groupID
            owner
            uuid
            title
            dateClosed
            dueDate
            status
            priority
            severity
            responseStatus
            archived
            createdAt
            lastModified
            compositeIDYear
            compositeID
            displayID
            relatedItemsOwner {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
            relatedItemsGroup {
              res
              error
              id
              sort
              organizationID
              title
              shortTitle
              riskThreshold {
                financial {
                  appetitePercent
                  appetiteAbsolute
                  capacityPercent
                  capacityAbsolute
                }
                privacy {
                  appetitePercent
                  appetiteAbsolute
                  capacityPercent
                  capacityAbsolute
                }
              }
              organization {
                res
                error
                id
                sort
                name
                organizationRelationships
                demographics
              }
              groupEmailFooter
            }
            fileName
            address
          }
        }
        nextToken
      }
    }
  }
`;
export const getOwnedIssues = /* GraphQL */ `
  query GetOwnedIssues($nextToken: String, $limit: Int, $id: ID!) {
    getOwnedIssues(nextToken: $nextToken, limit: $limit, id: $id) {
      items {
        id
        sort
        itemID
        issue {
          res
          error
          id
          sort
          createdAt
          createdBy
          modifiedBy
          verifiedBy
          lastModified
          issueID
          title
          description
          assumptions
          notes
          reportedBy
          reportedOn
          nextReview
          owner
          priority
          status
          approver
          dateClosed
          severity
          compositeIDYear
          compositeID
          archived
          isTemplate
          issueSourceId
          groupID
          issueRelatedItems {
            items {
              id
              sort
              target
              targetType
              item {
                id
                sort
                groupID
                owner
                uuid
                title
                dateClosed
                dueDate
                status
                priority
                severity
                responseStatus
                archived
                createdAt
                lastModified
                compositeIDYear
                compositeID
                displayID
                relatedItemsOwner {
                  res
                  error
                  id
                  sort
                  email
                  username
                  firstName
                  lastName
                  inactive
                  lastSeen
                  lastLoggedIn
                }
                relatedItemsGroup {
                  res
                  error
                  id
                  sort
                  organizationID
                  title
                  shortTitle
                  organization {
                    res
                    error
                    id
                    sort
                    name
                    organizationRelationships
                    demographics
                  }
                  groupEmailFooter
                }
                fileName
                address
              }
            }
            nextToken
          }
          acceptanceCriteria
          requiresScenario
          requiresActionplan
          issueOwner {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          issueCreatedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          issueModifiedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          issueVerifiedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          issueSource {
            res
            error
            id
            sort
            organizationID
            groupID
            description
            year
            title
            archived
          }
          issueRASCIRoles {
            items {
              id
              sort
              userID
              userInfo {
                id
                sort
                email
                firstName
                lastName
                inactive
              }
              user {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
              userRASCIRole
            }
            nextToken
          }
          issueApprovers {
            items {
              id
              sort
              userID
              userInfo {
                id
                sort
                email
                firstName
                lastName
                inactive
              }
              user {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
            }
            nextToken
          }
        }
        createdAt
        userRASCIRole
      }
      nextToken
    }
  }
`;
export const getRASCIOwnedIssues = /* GraphQL */ `
  query GetRASCIOwnedIssues($nextToken: String, $limit: Int, $id: ID!) {
    getRASCIOwnedIssues(nextToken: $nextToken, limit: $limit, id: $id) {
      items {
        id
        sort
        itemID
        issue {
          res
          error
          id
          sort
          createdAt
          createdBy
          modifiedBy
          verifiedBy
          lastModified
          issueID
          title
          description
          assumptions
          notes
          reportedBy
          reportedOn
          nextReview
          owner
          priority
          status
          approver
          dateClosed
          severity
          compositeIDYear
          compositeID
          archived
          isTemplate
          issueSourceId
          groupID
          issueRelatedItems {
            items {
              id
              sort
              target
              targetType
              item {
                id
                sort
                groupID
                owner
                uuid
                title
                dateClosed
                dueDate
                status
                priority
                severity
                responseStatus
                archived
                createdAt
                lastModified
                compositeIDYear
                compositeID
                displayID
                relatedItemsOwner {
                  res
                  error
                  id
                  sort
                  email
                  username
                  firstName
                  lastName
                  inactive
                  lastSeen
                  lastLoggedIn
                }
                relatedItemsGroup {
                  res
                  error
                  id
                  sort
                  organizationID
                  title
                  shortTitle
                  organization {
                    res
                    error
                    id
                    sort
                    name
                    organizationRelationships
                    demographics
                  }
                  groupEmailFooter
                }
                fileName
                address
              }
            }
            nextToken
          }
          acceptanceCriteria
          requiresScenario
          requiresActionplan
          issueOwner {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          issueCreatedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          issueModifiedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          issueVerifiedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          issueSource {
            res
            error
            id
            sort
            organizationID
            groupID
            description
            year
            title
            archived
          }
          issueRASCIRoles {
            items {
              id
              sort
              userID
              userInfo {
                id
                sort
                email
                firstName
                lastName
                inactive
              }
              user {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
              userRASCIRole
            }
            nextToken
          }
          issueApprovers {
            items {
              id
              sort
              userID
              userInfo {
                id
                sort
                email
                firstName
                lastName
                inactive
              }
              user {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
            }
            nextToken
          }
        }
        createdAt
        userRASCIRole
      }
      nextToken
    }
  }
`;
export const getIssueChangesInGroup = /* GraphQL */ `
  query GetIssueChangesInGroup($nextToken: String, $limit: Int, $id: ID!) {
    getIssueChangesInGroup(nextToken: $nextToken, limit: $limit, id: $id) {
      items {
        id
        sort
        issueID
        currentIssue {
          res
          error
          id
          sort
          createdAt
          createdBy
          modifiedBy
          verifiedBy
          lastModified
          issueID
          title
          description
          assumptions
          notes
          reportedBy
          reportedOn
          nextReview
          owner
          priority
          status
          approver
          dateClosed
          severity
          compositeIDYear
          compositeID
          archived
          isTemplate
          issueSourceId
          groupID
          issueRelatedItems {
            items {
              id
              sort
              target
              targetType
              item {
                id
                sort
                groupID
                owner
                uuid
                title
                dateClosed
                dueDate
                status
                priority
                severity
                responseStatus
                archived
                createdAt
                lastModified
                compositeIDYear
                compositeID
                displayID
                relatedItemsOwner {
                  res
                  error
                  id
                  sort
                  email
                  username
                  firstName
                  lastName
                  inactive
                  lastSeen
                  lastLoggedIn
                }
                relatedItemsGroup {
                  res
                  error
                  id
                  sort
                  organizationID
                  title
                  shortTitle
                  organization {
                    res
                    error
                    id
                    sort
                    name
                    organizationRelationships
                    demographics
                  }
                  groupEmailFooter
                }
                fileName
                address
              }
            }
            nextToken
          }
          acceptanceCriteria
          requiresScenario
          requiresActionplan
          issueOwner {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          issueCreatedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          issueModifiedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          issueVerifiedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          issueSource {
            res
            error
            id
            sort
            organizationID
            groupID
            description
            year
            title
            archived
          }
          issueRASCIRoles {
            items {
              id
              sort
              userID
              userInfo {
                id
                sort
                email
                firstName
                lastName
                inactive
              }
              user {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
              userRASCIRole
            }
            nextToken
          }
          issueApprovers {
            items {
              id
              sort
              userID
              userInfo {
                id
                sort
                email
                firstName
                lastName
                inactive
              }
              user {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
            }
            nextToken
          }
        }
        changeType
        requestingUser
        createdAt
        issueChanges {
          res
          error
          id
          sort
          createdAt
          createdBy
          modifiedBy
          verifiedBy
          lastModified
          issueID
          title
          description
          assumptions
          notes
          reportedBy
          reportedOn
          nextReview
          owner
          priority
          status
          approver
          dateClosed
          severity
          compositeIDYear
          compositeID
          archived
          isTemplate
          issueSourceId
          groupID
          issueRelatedItems {
            items {
              id
              sort
              target
              targetType
              item {
                id
                sort
                groupID
                owner
                uuid
                title
                dateClosed
                dueDate
                status
                priority
                severity
                responseStatus
                archived
                createdAt
                lastModified
                compositeIDYear
                compositeID
                displayID
                relatedItemsOwner {
                  res
                  error
                  id
                  sort
                  email
                  username
                  firstName
                  lastName
                  inactive
                  lastSeen
                  lastLoggedIn
                }
                relatedItemsGroup {
                  res
                  error
                  id
                  sort
                  organizationID
                  title
                  shortTitle
                  organization {
                    res
                    error
                    id
                    sort
                    name
                    organizationRelationships
                    demographics
                  }
                  groupEmailFooter
                }
                fileName
                address
              }
            }
            nextToken
          }
          acceptanceCriteria
          requiresScenario
          requiresActionplan
          issueOwner {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          issueCreatedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          issueModifiedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          issueVerifiedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          issueSource {
            res
            error
            id
            sort
            organizationID
            groupID
            description
            year
            title
            archived
          }
          issueRASCIRoles {
            items {
              id
              sort
              userID
              userInfo {
                id
                sort
                email
                firstName
                lastName
                inactive
              }
              user {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
              userRASCIRole
            }
            nextToken
          }
          issueApprovers {
            items {
              id
              sort
              userID
              userInfo {
                id
                sort
                email
                firstName
                lastName
                inactive
              }
              user {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getScenarioChangesInGroup = /* GraphQL */ `
  query GetScenarioChangesInGroup($nextToken: String, $limit: Int, $id: ID!) {
    getScenarioChangesInGroup(nextToken: $nextToken, limit: $limit, id: $id) {
      items {
        id
        sort
        scenarioID
        currentScenario {
          res
          error
          id
          sort
          createdAt
          createdBy
          modifiedBy
          lastModified
          FAIRSummary
          scenarioID
          title
          description
          notes
          assumptions
          creationDate
          lastRevision
          nextRevision
          categories
          owner
          status
          dateClosed
          indicator
          compositeIDYear
          compositeID
          archived
          isTemplate
          groupID
          scenarioRelatedItems {
            items {
              id
              sort
              target
              targetType
              item {
                id
                sort
                groupID
                owner
                uuid
                title
                dateClosed
                dueDate
                status
                priority
                severity
                responseStatus
                archived
                createdAt
                lastModified
                compositeIDYear
                compositeID
                displayID
                relatedItemsOwner {
                  res
                  error
                  id
                  sort
                  email
                  username
                  firstName
                  lastName
                  inactive
                  lastSeen
                  lastLoggedIn
                }
                relatedItemsGroup {
                  res
                  error
                  id
                  sort
                  organizationID
                  title
                  shortTitle
                  organization {
                    res
                    error
                    id
                    sort
                    name
                    organizationRelationships
                    demographics
                  }
                  groupEmailFooter
                }
                fileName
                address
              }
            }
            nextToken
          }
          scenarioOwner {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          scenarioCreatedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          scenarioModifiedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          scenarioRASCIRoles {
            items {
              id
              sort
              userID
              userInfo {
                id
                sort
                email
                firstName
                lastName
                inactive
              }
              user {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
              userRASCIRole
            }
            nextToken
          }
        }
        changeType
        requestingUser
        createdAt
        scenarioChanges {
          res
          error
          id
          sort
          createdAt
          createdBy
          modifiedBy
          lastModified
          FAIRSummary
          scenarioID
          title
          description
          notes
          assumptions
          creationDate
          lastRevision
          nextRevision
          categories
          owner
          status
          dateClosed
          indicator
          compositeIDYear
          compositeID
          archived
          isTemplate
          groupID
          scenarioRelatedItems {
            items {
              id
              sort
              target
              targetType
              item {
                id
                sort
                groupID
                owner
                uuid
                title
                dateClosed
                dueDate
                status
                priority
                severity
                responseStatus
                archived
                createdAt
                lastModified
                compositeIDYear
                compositeID
                displayID
                relatedItemsOwner {
                  res
                  error
                  id
                  sort
                  email
                  username
                  firstName
                  lastName
                  inactive
                  lastSeen
                  lastLoggedIn
                }
                relatedItemsGroup {
                  res
                  error
                  id
                  sort
                  organizationID
                  title
                  shortTitle
                  organization {
                    res
                    error
                    id
                    sort
                    name
                    organizationRelationships
                    demographics
                  }
                  groupEmailFooter
                }
                fileName
                address
              }
            }
            nextToken
          }
          scenarioOwner {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          scenarioCreatedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          scenarioModifiedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          scenarioRASCIRoles {
            items {
              id
              sort
              userID
              userInfo {
                id
                sort
                email
                firstName
                lastName
                inactive
              }
              user {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
              userRASCIRole
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getActionPlanChangesInGroup = /* GraphQL */ `
  query GetActionPlanChangesInGroup($nextToken: String, $limit: Int, $id: ID!) {
    getActionPlanChangesInGroup(nextToken: $nextToken, limit: $limit, id: $id) {
      items {
        id
        sort
        actionplanID
        currentActionPlan {
          res
          error
          id
          sort
          createdAt
          createdBy
          modifiedBy
          lastModified
          actionPlanID
          title
          description
          resources
          notes
          owner
          priority
          status
          approver
          dateClosed
          startDate
          dueDate
          reviewDate
          compositeIDYear
          compositeID
          archived
          isTemplate
          groupID
          actionPlanRelatedItems {
            items {
              id
              sort
              target
              targetType
              item {
                id
                sort
                groupID
                owner
                uuid
                title
                dateClosed
                dueDate
                status
                priority
                severity
                responseStatus
                archived
                createdAt
                lastModified
                compositeIDYear
                compositeID
                displayID
                relatedItemsOwner {
                  res
                  error
                  id
                  sort
                  email
                  username
                  firstName
                  lastName
                  inactive
                  lastSeen
                  lastLoggedIn
                }
                relatedItemsGroup {
                  res
                  error
                  id
                  sort
                  organizationID
                  title
                  shortTitle
                  organization {
                    res
                    error
                    id
                    sort
                    name
                    organizationRelationships
                    demographics
                  }
                  groupEmailFooter
                }
                fileName
                address
              }
            }
            nextToken
          }
          taskChildren {
            items {
              id
              sort
              itemID
              task {
                res
                error
                id
                sort
                createdAt
                createdBy
                modifiedBy
                lastModified
                parentID
                organizationID
                groupID
                title
                description
                resources
                notes
                owner
                priority
                status
                dateClosed
                dueDate
                archived
                taskOwner {
                  res
                  error
                  id
                  sort
                  email
                  username
                  firstName
                  lastName
                  inactive
                  lastSeen
                  lastLoggedIn
                }
                taskRASCIRoles {
                  items {
                    id
                    sort
                    userID
                    userRASCIRole
                  }
                  nextToken
                }
              }
              createdAt
              userRASCIRole
            }
            nextToken
          }
          effortAmount
          effortType
          actionPlanOwner {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          actionPlanCreatedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          actionPlanModifiedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          actionPlanRASCIRoles {
            items {
              id
              sort
              userID
              userInfo {
                id
                sort
                email
                firstName
                lastName
                inactive
              }
              user {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
              userRASCIRole
            }
            nextToken
          }
          actionplanApprovers {
            items {
              id
              sort
              userID
              userInfo {
                id
                sort
                email
                firstName
                lastName
                inactive
              }
              user {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
            }
            nextToken
          }
          type
        }
        changeType
        requestingUser
        createdAt
        actionplanChanges {
          res
          error
          id
          sort
          createdAt
          createdBy
          modifiedBy
          lastModified
          actionPlanID
          title
          description
          resources
          notes
          owner
          priority
          status
          approver
          dateClosed
          startDate
          dueDate
          reviewDate
          compositeIDYear
          compositeID
          archived
          isTemplate
          groupID
          actionPlanRelatedItems {
            items {
              id
              sort
              target
              targetType
              item {
                id
                sort
                groupID
                owner
                uuid
                title
                dateClosed
                dueDate
                status
                priority
                severity
                responseStatus
                archived
                createdAt
                lastModified
                compositeIDYear
                compositeID
                displayID
                relatedItemsOwner {
                  res
                  error
                  id
                  sort
                  email
                  username
                  firstName
                  lastName
                  inactive
                  lastSeen
                  lastLoggedIn
                }
                relatedItemsGroup {
                  res
                  error
                  id
                  sort
                  organizationID
                  title
                  shortTitle
                  organization {
                    res
                    error
                    id
                    sort
                    name
                    organizationRelationships
                    demographics
                  }
                  groupEmailFooter
                }
                fileName
                address
              }
            }
            nextToken
          }
          taskChildren {
            items {
              id
              sort
              itemID
              task {
                res
                error
                id
                sort
                createdAt
                createdBy
                modifiedBy
                lastModified
                parentID
                organizationID
                groupID
                title
                description
                resources
                notes
                owner
                priority
                status
                dateClosed
                dueDate
                archived
                taskOwner {
                  res
                  error
                  id
                  sort
                  email
                  username
                  firstName
                  lastName
                  inactive
                  lastSeen
                  lastLoggedIn
                }
                taskRASCIRoles {
                  items {
                    id
                    sort
                    userID
                    userRASCIRole
                  }
                  nextToken
                }
              }
              createdAt
              userRASCIRole
            }
            nextToken
          }
          effortAmount
          effortType
          actionPlanOwner {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          actionPlanCreatedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          actionPlanModifiedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          actionPlanRASCIRoles {
            items {
              id
              sort
              userID
              userInfo {
                id
                sort
                email
                firstName
                lastName
                inactive
              }
              user {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
              userRASCIRole
            }
            nextToken
          }
          actionplanApprovers {
            items {
              id
              sort
              userID
              userInfo {
                id
                sort
                email
                firstName
                lastName
                inactive
              }
              user {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
            }
            nextToken
          }
          type
        }
      }
      nextToken
    }
  }
`;
export const getIssuev3 = /* GraphQL */ `
  query GetIssuev3($id: ID!) {
    getIssuev3(id: $id) {
      res
      error
      id
      sort
      createdAt
      createdBy
      modifiedBy
      verifiedBy
      lastModified
      issueID
      title
      description
      assumptions
      notes
      reportedBy
      reportedOn
      nextReview
      owner
      priority
      status
      approver
      dateClosed
      severity
      compositeIDYear
      compositeID
      archived
      isTemplate
      issueSourceId
      groupID
      issueRelatedItems {
        items {
          id
          sort
          target
          targetType
          item {
            id
            sort
            groupID
            owner
            uuid
            title
            dateClosed
            dueDate
            status
            priority
            severity
            responseStatus
            archived
            createdAt
            lastModified
            compositeIDYear
            compositeID
            displayID
            relatedItemsOwner {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
            relatedItemsGroup {
              res
              error
              id
              sort
              organizationID
              title
              shortTitle
              riskThreshold {
                financial {
                  appetitePercent
                  appetiteAbsolute
                  capacityPercent
                  capacityAbsolute
                }
                privacy {
                  appetitePercent
                  appetiteAbsolute
                  capacityPercent
                  capacityAbsolute
                }
              }
              organization {
                res
                error
                id
                sort
                name
                organizationRelationships
                demographics
              }
              groupEmailFooter
            }
            fileName
            address
          }
        }
        nextToken
      }
      acceptanceCriteria
      requiresScenario
      requiresActionplan
      issueOwner {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      issueCreatedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      issueModifiedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      issueVerifiedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      issueSource {
        res
        error
        id
        sort
        organizationID
        groupID
        description
        year
        title
        archived
      }
      issueRASCIRoles {
        items {
          id
          sort
          userID
          userInfo {
            id
            sort
            email
            firstName
            lastName
            inactive
          }
          user {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          userRASCIRole
        }
        nextToken
      }
      issueApprovers {
        items {
          id
          sort
          userID
          userInfo {
            id
            sort
            email
            firstName
            lastName
            inactive
          }
          user {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
        }
        nextToken
      }
    }
  }
`;
export const getIssuesInGroupv3 = /* GraphQL */ `
  query GetIssuesInGroupv3($nextToken: String, $limit: Int, $id: ID!) {
    getIssuesInGroupv3(nextToken: $nextToken, limit: $limit, id: $id) {
      items {
        id
        sort
        itemID
        issue {
          res
          error
          id
          sort
          createdAt
          createdBy
          modifiedBy
          verifiedBy
          lastModified
          issueID
          title
          description
          assumptions
          notes
          reportedBy
          reportedOn
          nextReview
          owner
          priority
          status
          approver
          dateClosed
          severity
          compositeIDYear
          compositeID
          archived
          isTemplate
          issueSourceId
          groupID
          issueRelatedItems {
            items {
              id
              sort
              target
              targetType
              item {
                id
                sort
                groupID
                owner
                uuid
                title
                dateClosed
                dueDate
                status
                priority
                severity
                responseStatus
                archived
                createdAt
                lastModified
                compositeIDYear
                compositeID
                displayID
                relatedItemsOwner {
                  res
                  error
                  id
                  sort
                  email
                  username
                  firstName
                  lastName
                  inactive
                  lastSeen
                  lastLoggedIn
                }
                relatedItemsGroup {
                  res
                  error
                  id
                  sort
                  organizationID
                  title
                  shortTitle
                  organization {
                    res
                    error
                    id
                    sort
                    name
                    organizationRelationships
                    demographics
                  }
                  groupEmailFooter
                }
                fileName
                address
              }
            }
            nextToken
          }
          acceptanceCriteria
          requiresScenario
          requiresActionplan
          issueOwner {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          issueCreatedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          issueModifiedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          issueVerifiedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          issueSource {
            res
            error
            id
            sort
            organizationID
            groupID
            description
            year
            title
            archived
          }
          issueRASCIRoles {
            items {
              id
              sort
              userID
              userInfo {
                id
                sort
                email
                firstName
                lastName
                inactive
              }
              user {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
              userRASCIRole
            }
            nextToken
          }
          issueApprovers {
            items {
              id
              sort
              userID
              userInfo {
                id
                sort
                email
                firstName
                lastName
                inactive
              }
              user {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
            }
            nextToken
          }
        }
        createdAt
        userRASCIRole
      }
      nextToken
    }
  }
`;
export const getIssuesTriagedPerMonth = /* GraphQL */ `
  query GetIssuesTriagedPerMonth($nextToken: String, $limit: Int, $id: ID!) {
    getIssuesTriagedPerMonth(nextToken: $nextToken, limit: $limit, id: $id) {
      items {
        id
        sort
        total
        year
        month
        firstDate
      }
      nextToken
    }
  }
`;
export const getIssuesCreatedPerMonth = /* GraphQL */ `
  query GetIssuesCreatedPerMonth($nextToken: String, $limit: Int, $id: ID!) {
    getIssuesCreatedPerMonth(nextToken: $nextToken, limit: $limit, id: $id) {
      items {
        id
        sort
        total
        year
        month
        firstDate
      }
      nextToken
    }
  }
`;
export const getIssuesCreatedPerMonthInGroup = /* GraphQL */ `
  query GetIssuesCreatedPerMonthInGroup(
    $nextToken: String
    $limit: Int
    $id: ID!
  ) {
    getIssuesCreatedPerMonthInGroup(
      nextToken: $nextToken
      limit: $limit
      id: $id
    ) {
      items {
        id
        sort
        total
        year
        month
        firstDate
      }
      nextToken
    }
  }
`;
export const getUserGroupMembership = /* GraphQL */ `
  query GetUserGroupMembership($nextToken: String, $limit: Int, $id: ID!) {
    getUserGroupMembership(nextToken: $nextToken, limit: $limit, id: $id) {
      items {
        id
        sort
        userID
        groupID
        userRole
        group {
          res
          error
          id
          sort
          organizationID
          title
          shortTitle
          riskThreshold {
            financial {
              appetitePercent
              appetiteAbsolute
              capacityPercent
              capacityAbsolute
            }
            privacy {
              appetitePercent
              appetiteAbsolute
              capacityPercent
              capacityAbsolute
            }
          }
          organization {
            res
            error
            id
            sort
            name
            organizationRelationships
            demographics
          }
          groupEmailFooter
        }
      }
      nextToken
    }
  }
`;
export const getGroupsInOrganization = /* GraphQL */ `
  query GetGroupsInOrganization($nextToken: String, $limit: Int, $id: ID!) {
    getGroupsInOrganization(nextToken: $nextToken, limit: $limit, id: $id) {
      items {
        id
        sort
        itemID
        group {
          res
          error
          id
          sort
          organizationID
          title
          shortTitle
          riskThreshold {
            financial {
              appetitePercent
              appetiteAbsolute
              capacityPercent
              capacityAbsolute
            }
            privacy {
              appetitePercent
              appetiteAbsolute
              capacityPercent
              capacityAbsolute
            }
          }
          organization {
            res
            error
            id
            sort
            name
            organizationRelationships
            demographics
          }
          groupEmailFooter
        }
        createdAt
      }
      nextToken
    }
  }
`;
export const getAssessmentResponses = /* GraphQL */ `
  query GetAssessmentResponses($nextToken: String, $limit: Int, $id: ID!) {
    getAssessmentResponses(nextToken: $nextToken, limit: $limit, id: $id) {
      items {
        res
        error
        id
        sort
        parentID
        title
        instructions
        notes
        responseType
        questionText
        response
        responseComments
        responseStatus
        artifactRequired
        artifactRequired_yes
        artifactRequired_no
        owner
        parent {
          res
          error
          id
          sort
          parentID
          templateRoot
          title
          assessmentStartDate
          assessmentEndDate
          assessor
          scope
          hours
          templateTitle
          name
          hsrID
          description
          questionText
          surveyState {
            unanswered
            noGapIdentified
            deferPendingReassignment
            deferPendingResponse
            clarify
            yesPendingRequestedArtifacts
            yesPendingArtifacts
            yesCompliant
            notApplicable
          }
          size
          parent {
            res
            error
            id
            sort
            parentID
            templateRoot
            title
            assessmentStartDate
            assessmentEndDate
            assessor
            scope
            hours
            templateTitle
            name
            hsrID
            description
            questionText
            surveyState {
              unanswered
              noGapIdentified
              deferPendingReassignment
              deferPendingResponse
              clarify
              yesPendingRequestedArtifacts
              yesPendingArtifacts
              yesCompliant
              notApplicable
            }
            size
            parent {
              res
              error
              id
              sort
              parentID
              templateRoot
              title
              assessmentStartDate
              assessmentEndDate
              assessor
              scope
              hours
              templateTitle
              name
              hsrID
              description
              questionText
              surveyState {
                unanswered
                noGapIdentified
                deferPendingReassignment
                deferPendingResponse
                clarify
                yesPendingRequestedArtifacts
                yesPendingArtifacts
                yesCompliant
                notApplicable
              }
              size
              parent {
                res
                error
                id
                sort
                parentID
                templateRoot
                title
                assessmentStartDate
                assessmentEndDate
                assessor
                scope
                hours
                templateTitle
                name
                hsrID
                description
                questionText
                surveyState {
                  unanswered
                  noGapIdentified
                  deferPendingReassignment
                  deferPendingResponse
                  clarify
                  yesPendingRequestedArtifacts
                  yesPendingArtifacts
                  yesCompliant
                  notApplicable
                }
                size
                parent {
                  res
                  error
                  id
                  sort
                  parentID
                  templateRoot
                  title
                  assessmentStartDate
                  assessmentEndDate
                  assessor
                  scope
                  hours
                  templateTitle
                  name
                  hsrID
                  description
                  questionText
                  surveyState {
                    unanswered
                    noGapIdentified
                    deferPendingReassignment
                    deferPendingResponse
                    clarify
                    yesPendingRequestedArtifacts
                    yesPendingArtifacts
                    yesCompliant
                    notApplicable
                  }
                  size
                  parent {
                    res
                    error
                    id
                    sort
                    parentID
                    templateRoot
                    title
                    assessmentStartDate
                    assessmentEndDate
                    assessor
                    scope
                    hours
                    templateTitle
                    name
                    hsrID
                    description
                    questionText
                    size
                    groupID
                    archived
                  }
                  groupID
                  archived
                  assessmentAssessor {
                    res
                    error
                    id
                    sort
                    email
                    username
                    firstName
                    lastName
                    inactive
                    lastSeen
                    lastLoggedIn
                  }
                }
                groupID
                archived
                assessmentAssessor {
                  res
                  error
                  id
                  sort
                  email
                  username
                  firstName
                  lastName
                  inactive
                  lastSeen
                  lastLoggedIn
                }
              }
              groupID
              archived
              assessmentAssessor {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
            }
            groupID
            archived
            assessmentAssessor {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
          }
          groupID
          archived
          assessmentAssessor {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
        }
        groupID
        responseRelatedItems {
          items {
            id
            sort
            target
            targetType
            item {
              id
              sort
              groupID
              owner
              uuid
              title
              dateClosed
              dueDate
              status
              priority
              severity
              responseStatus
              archived
              createdAt
              lastModified
              compositeIDYear
              compositeID
              displayID
              relatedItemsOwner {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
              relatedItemsGroup {
                res
                error
                id
                sort
                organizationID
                title
                shortTitle
                riskThreshold {
                  financial {
                    appetitePercent
                    appetiteAbsolute
                    capacityPercent
                    capacityAbsolute
                  }
                  privacy {
                    appetitePercent
                    appetiteAbsolute
                    capacityPercent
                    capacityAbsolute
                  }
                }
                organization {
                  res
                  error
                  id
                  sort
                  name
                  organizationRelationships
                  demographics
                }
                groupEmailFooter
              }
              fileName
              address
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getNestedAssessments = /* GraphQL */ `
  query GetNestedAssessments($nextToken: String, $limit: Int, $id: ID!) {
    getNestedAssessments(nextToken: $nextToken, limit: $limit, id: $id) {
      items {
        res
        error
        id
        sort
        parentID
        templateRoot
        title
        assessmentStartDate
        assessmentEndDate
        assessor
        scope
        hours
        templateTitle
        name
        hsrID
        description
        questionText
        surveyState {
          unanswered
          noGapIdentified
          deferPendingReassignment
          deferPendingResponse
          clarify
          yesPendingRequestedArtifacts
          yesPendingArtifacts
          yesCompliant
          notApplicable
        }
        size
        parent {
          res
          error
          id
          sort
          parentID
          templateRoot
          title
          assessmentStartDate
          assessmentEndDate
          assessor
          scope
          hours
          templateTitle
          name
          hsrID
          description
          questionText
          surveyState {
            unanswered
            noGapIdentified
            deferPendingReassignment
            deferPendingResponse
            clarify
            yesPendingRequestedArtifacts
            yesPendingArtifacts
            yesCompliant
            notApplicable
          }
          size
          parent {
            res
            error
            id
            sort
            parentID
            templateRoot
            title
            assessmentStartDate
            assessmentEndDate
            assessor
            scope
            hours
            templateTitle
            name
            hsrID
            description
            questionText
            surveyState {
              unanswered
              noGapIdentified
              deferPendingReassignment
              deferPendingResponse
              clarify
              yesPendingRequestedArtifacts
              yesPendingArtifacts
              yesCompliant
              notApplicable
            }
            size
            parent {
              res
              error
              id
              sort
              parentID
              templateRoot
              title
              assessmentStartDate
              assessmentEndDate
              assessor
              scope
              hours
              templateTitle
              name
              hsrID
              description
              questionText
              surveyState {
                unanswered
                noGapIdentified
                deferPendingReassignment
                deferPendingResponse
                clarify
                yesPendingRequestedArtifacts
                yesPendingArtifacts
                yesCompliant
                notApplicable
              }
              size
              parent {
                res
                error
                id
                sort
                parentID
                templateRoot
                title
                assessmentStartDate
                assessmentEndDate
                assessor
                scope
                hours
                templateTitle
                name
                hsrID
                description
                questionText
                surveyState {
                  unanswered
                  noGapIdentified
                  deferPendingReassignment
                  deferPendingResponse
                  clarify
                  yesPendingRequestedArtifacts
                  yesPendingArtifacts
                  yesCompliant
                  notApplicable
                }
                size
                parent {
                  res
                  error
                  id
                  sort
                  parentID
                  templateRoot
                  title
                  assessmentStartDate
                  assessmentEndDate
                  assessor
                  scope
                  hours
                  templateTitle
                  name
                  hsrID
                  description
                  questionText
                  surveyState {
                    unanswered
                    noGapIdentified
                    deferPendingReassignment
                    deferPendingResponse
                    clarify
                    yesPendingRequestedArtifacts
                    yesPendingArtifacts
                    yesCompliant
                    notApplicable
                  }
                  size
                  parent {
                    res
                    error
                    id
                    sort
                    parentID
                    templateRoot
                    title
                    assessmentStartDate
                    assessmentEndDate
                    assessor
                    scope
                    hours
                    templateTitle
                    name
                    hsrID
                    description
                    questionText
                    size
                    groupID
                    archived
                  }
                  groupID
                  archived
                  assessmentAssessor {
                    res
                    error
                    id
                    sort
                    email
                    username
                    firstName
                    lastName
                    inactive
                    lastSeen
                    lastLoggedIn
                  }
                }
                groupID
                archived
                assessmentAssessor {
                  res
                  error
                  id
                  sort
                  email
                  username
                  firstName
                  lastName
                  inactive
                  lastSeen
                  lastLoggedIn
                }
              }
              groupID
              archived
              assessmentAssessor {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
            }
            groupID
            archived
            assessmentAssessor {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
          }
          groupID
          archived
          assessmentAssessor {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
        }
        groupID
        archived
        assessmentAssessor {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
      }
      nextToken
    }
  }
`;
export const getTasksInActionPlanv3 = /* GraphQL */ `
  query GetTasksInActionPlanv3($nextToken: String, $limit: Int, $id: ID!) {
    getTasksInActionPlanv3(nextToken: $nextToken, limit: $limit, id: $id) {
      items {
        id
        sort
        itemID
        task {
          res
          error
          id
          sort
          createdAt
          createdBy
          modifiedBy
          lastModified
          parentID
          organizationID
          groupID
          title
          description
          resources
          notes
          owner
          priority
          status
          dateClosed
          dueDate
          archived
          taskOwner {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          taskRASCIRoles {
            items {
              id
              sort
              userID
              userInfo {
                id
                sort
                email
                firstName
                lastName
                inactive
              }
              user {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
              userRASCIRole
            }
            nextToken
          }
        }
        createdAt
        userRASCIRole
      }
      nextToken
    }
  }
`;
export const getOwnedActionPlans = /* GraphQL */ `
  query GetOwnedActionPlans($nextToken: String, $limit: Int, $id: ID!) {
    getOwnedActionPlans(nextToken: $nextToken, limit: $limit, id: $id) {
      items {
        id
        sort
        itemID
        actionplan {
          res
          error
          id
          sort
          createdAt
          createdBy
          modifiedBy
          lastModified
          actionPlanID
          title
          description
          resources
          notes
          owner
          priority
          status
          approver
          dateClosed
          startDate
          dueDate
          reviewDate
          compositeIDYear
          compositeID
          archived
          isTemplate
          groupID
          actionPlanRelatedItems {
            items {
              id
              sort
              target
              targetType
              item {
                id
                sort
                groupID
                owner
                uuid
                title
                dateClosed
                dueDate
                status
                priority
                severity
                responseStatus
                archived
                createdAt
                lastModified
                compositeIDYear
                compositeID
                displayID
                relatedItemsOwner {
                  res
                  error
                  id
                  sort
                  email
                  username
                  firstName
                  lastName
                  inactive
                  lastSeen
                  lastLoggedIn
                }
                relatedItemsGroup {
                  res
                  error
                  id
                  sort
                  organizationID
                  title
                  shortTitle
                  organization {
                    res
                    error
                    id
                    sort
                    name
                    organizationRelationships
                    demographics
                  }
                  groupEmailFooter
                }
                fileName
                address
              }
            }
            nextToken
          }
          taskChildren {
            items {
              id
              sort
              itemID
              task {
                res
                error
                id
                sort
                createdAt
                createdBy
                modifiedBy
                lastModified
                parentID
                organizationID
                groupID
                title
                description
                resources
                notes
                owner
                priority
                status
                dateClosed
                dueDate
                archived
                taskOwner {
                  res
                  error
                  id
                  sort
                  email
                  username
                  firstName
                  lastName
                  inactive
                  lastSeen
                  lastLoggedIn
                }
                taskRASCIRoles {
                  items {
                    id
                    sort
                    userID
                    userRASCIRole
                  }
                  nextToken
                }
              }
              createdAt
              userRASCIRole
            }
            nextToken
          }
          effortAmount
          effortType
          actionPlanOwner {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          actionPlanCreatedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          actionPlanModifiedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          actionPlanRASCIRoles {
            items {
              id
              sort
              userID
              userInfo {
                id
                sort
                email
                firstName
                lastName
                inactive
              }
              user {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
              userRASCIRole
            }
            nextToken
          }
          actionplanApprovers {
            items {
              id
              sort
              userID
              userInfo {
                id
                sort
                email
                firstName
                lastName
                inactive
              }
              user {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
            }
            nextToken
          }
          type
        }
        createdAt
        userRASCIRole
      }
      nextToken
    }
  }
`;
export const getRASCIOwnedActionPlans = /* GraphQL */ `
  query GetRASCIOwnedActionPlans($nextToken: String, $limit: Int, $id: ID!) {
    getRASCIOwnedActionPlans(nextToken: $nextToken, limit: $limit, id: $id) {
      items {
        id
        sort
        itemID
        actionplan {
          res
          error
          id
          sort
          createdAt
          createdBy
          modifiedBy
          lastModified
          actionPlanID
          title
          description
          resources
          notes
          owner
          priority
          status
          approver
          dateClosed
          startDate
          dueDate
          reviewDate
          compositeIDYear
          compositeID
          archived
          isTemplate
          groupID
          actionPlanRelatedItems {
            items {
              id
              sort
              target
              targetType
              item {
                id
                sort
                groupID
                owner
                uuid
                title
                dateClosed
                dueDate
                status
                priority
                severity
                responseStatus
                archived
                createdAt
                lastModified
                compositeIDYear
                compositeID
                displayID
                relatedItemsOwner {
                  res
                  error
                  id
                  sort
                  email
                  username
                  firstName
                  lastName
                  inactive
                  lastSeen
                  lastLoggedIn
                }
                relatedItemsGroup {
                  res
                  error
                  id
                  sort
                  organizationID
                  title
                  shortTitle
                  organization {
                    res
                    error
                    id
                    sort
                    name
                    organizationRelationships
                    demographics
                  }
                  groupEmailFooter
                }
                fileName
                address
              }
            }
            nextToken
          }
          taskChildren {
            items {
              id
              sort
              itemID
              task {
                res
                error
                id
                sort
                createdAt
                createdBy
                modifiedBy
                lastModified
                parentID
                organizationID
                groupID
                title
                description
                resources
                notes
                owner
                priority
                status
                dateClosed
                dueDate
                archived
                taskOwner {
                  res
                  error
                  id
                  sort
                  email
                  username
                  firstName
                  lastName
                  inactive
                  lastSeen
                  lastLoggedIn
                }
                taskRASCIRoles {
                  items {
                    id
                    sort
                    userID
                    userRASCIRole
                  }
                  nextToken
                }
              }
              createdAt
              userRASCIRole
            }
            nextToken
          }
          effortAmount
          effortType
          actionPlanOwner {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          actionPlanCreatedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          actionPlanModifiedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          actionPlanRASCIRoles {
            items {
              id
              sort
              userID
              userInfo {
                id
                sort
                email
                firstName
                lastName
                inactive
              }
              user {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
              userRASCIRole
            }
            nextToken
          }
          actionplanApprovers {
            items {
              id
              sort
              userID
              userInfo {
                id
                sort
                email
                firstName
                lastName
                inactive
              }
              user {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
            }
            nextToken
          }
          type
        }
        createdAt
        userRASCIRole
      }
      nextToken
    }
  }
`;
export const getActionPlanv3 = /* GraphQL */ `
  query GetActionPlanv3($id: ID!) {
    getActionPlanv3(id: $id) {
      res
      error
      id
      sort
      createdAt
      createdBy
      modifiedBy
      lastModified
      actionPlanID
      title
      description
      resources
      notes
      owner
      priority
      status
      approver
      dateClosed
      startDate
      dueDate
      reviewDate
      compositeIDYear
      compositeID
      archived
      isTemplate
      groupID
      actionPlanRelatedItems {
        items {
          id
          sort
          target
          targetType
          item {
            id
            sort
            groupID
            owner
            uuid
            title
            dateClosed
            dueDate
            status
            priority
            severity
            responseStatus
            archived
            createdAt
            lastModified
            compositeIDYear
            compositeID
            displayID
            relatedItemsOwner {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
            relatedItemsGroup {
              res
              error
              id
              sort
              organizationID
              title
              shortTitle
              riskThreshold {
                financial {
                  appetitePercent
                  appetiteAbsolute
                  capacityPercent
                  capacityAbsolute
                }
                privacy {
                  appetitePercent
                  appetiteAbsolute
                  capacityPercent
                  capacityAbsolute
                }
              }
              organization {
                res
                error
                id
                sort
                name
                organizationRelationships
                demographics
              }
              groupEmailFooter
            }
            fileName
            address
          }
        }
        nextToken
      }
      taskChildren {
        items {
          id
          sort
          itemID
          task {
            res
            error
            id
            sort
            createdAt
            createdBy
            modifiedBy
            lastModified
            parentID
            organizationID
            groupID
            title
            description
            resources
            notes
            owner
            priority
            status
            dateClosed
            dueDate
            archived
            taskOwner {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
            taskRASCIRoles {
              items {
                id
                sort
                userID
                userInfo {
                  id
                  sort
                  email
                  firstName
                  lastName
                  inactive
                }
                user {
                  res
                  error
                  id
                  sort
                  email
                  username
                  firstName
                  lastName
                  inactive
                  lastSeen
                  lastLoggedIn
                }
                userRASCIRole
              }
              nextToken
            }
          }
          createdAt
          userRASCIRole
        }
        nextToken
      }
      effortAmount
      effortType
      actionPlanOwner {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      actionPlanCreatedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      actionPlanModifiedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      actionPlanRASCIRoles {
        items {
          id
          sort
          userID
          userInfo {
            id
            sort
            email
            firstName
            lastName
            inactive
          }
          user {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          userRASCIRole
        }
        nextToken
      }
      actionplanApprovers {
        items {
          id
          sort
          userID
          userInfo {
            id
            sort
            email
            firstName
            lastName
            inactive
          }
          user {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
        }
        nextToken
      }
      type
    }
  }
`;
export const getActionPlansInGroupv3 = /* GraphQL */ `
  query GetActionPlansInGroupv3($nextToken: String, $limit: Int, $id: ID!) {
    getActionPlansInGroupv3(nextToken: $nextToken, limit: $limit, id: $id) {
      items {
        id
        sort
        itemID
        actionplan {
          res
          error
          id
          sort
          createdAt
          createdBy
          modifiedBy
          lastModified
          actionPlanID
          title
          description
          resources
          notes
          owner
          priority
          status
          approver
          dateClosed
          startDate
          dueDate
          reviewDate
          compositeIDYear
          compositeID
          archived
          isTemplate
          groupID
          actionPlanRelatedItems {
            items {
              id
              sort
              target
              targetType
              item {
                id
                sort
                groupID
                owner
                uuid
                title
                dateClosed
                dueDate
                status
                priority
                severity
                responseStatus
                archived
                createdAt
                lastModified
                compositeIDYear
                compositeID
                displayID
                relatedItemsOwner {
                  res
                  error
                  id
                  sort
                  email
                  username
                  firstName
                  lastName
                  inactive
                  lastSeen
                  lastLoggedIn
                }
                relatedItemsGroup {
                  res
                  error
                  id
                  sort
                  organizationID
                  title
                  shortTitle
                  organization {
                    res
                    error
                    id
                    sort
                    name
                    organizationRelationships
                    demographics
                  }
                  groupEmailFooter
                }
                fileName
                address
              }
            }
            nextToken
          }
          taskChildren {
            items {
              id
              sort
              itemID
              task {
                res
                error
                id
                sort
                createdAt
                createdBy
                modifiedBy
                lastModified
                parentID
                organizationID
                groupID
                title
                description
                resources
                notes
                owner
                priority
                status
                dateClosed
                dueDate
                archived
                taskOwner {
                  res
                  error
                  id
                  sort
                  email
                  username
                  firstName
                  lastName
                  inactive
                  lastSeen
                  lastLoggedIn
                }
                taskRASCIRoles {
                  items {
                    id
                    sort
                    userID
                    userRASCIRole
                  }
                  nextToken
                }
              }
              createdAt
              userRASCIRole
            }
            nextToken
          }
          effortAmount
          effortType
          actionPlanOwner {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          actionPlanCreatedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          actionPlanModifiedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          actionPlanRASCIRoles {
            items {
              id
              sort
              userID
              userInfo {
                id
                sort
                email
                firstName
                lastName
                inactive
              }
              user {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
              userRASCIRole
            }
            nextToken
          }
          actionplanApprovers {
            items {
              id
              sort
              userID
              userInfo {
                id
                sort
                email
                firstName
                lastName
                inactive
              }
              user {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
            }
            nextToken
          }
          type
        }
        createdAt
        userRASCIRole
      }
      nextToken
    }
  }
`;
export const getActionPlansUpdatedPerMonth = /* GraphQL */ `
  query GetActionPlansUpdatedPerMonth(
    $nextToken: String
    $limit: Int
    $id: ID!
  ) {
    getActionPlansUpdatedPerMonth(
      nextToken: $nextToken
      limit: $limit
      id: $id
    ) {
      items {
        id
        sort
        total
        year
        month
        firstDate
      }
      nextToken
    }
  }
`;
export const getActionPlansCreatedPerMonth = /* GraphQL */ `
  query GetActionPlansCreatedPerMonth(
    $nextToken: String
    $limit: Int
    $id: ID!
  ) {
    getActionPlansCreatedPerMonth(
      nextToken: $nextToken
      limit: $limit
      id: $id
    ) {
      items {
        id
        sort
        total
        year
        month
        firstDate
      }
      nextToken
    }
  }
`;
export const getOwnedTasks = /* GraphQL */ `
  query GetOwnedTasks($nextToken: String, $limit: Int, $id: ID!) {
    getOwnedTasks(nextToken: $nextToken, limit: $limit, id: $id) {
      items {
        id
        sort
        itemID
        task {
          res
          error
          id
          sort
          createdAt
          createdBy
          modifiedBy
          lastModified
          parentID
          organizationID
          groupID
          title
          description
          resources
          notes
          owner
          priority
          status
          dateClosed
          dueDate
          archived
          taskOwner {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          taskRASCIRoles {
            items {
              id
              sort
              userID
              userInfo {
                id
                sort
                email
                firstName
                lastName
                inactive
              }
              user {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
              userRASCIRole
            }
            nextToken
          }
        }
        createdAt
        userRASCIRole
      }
      nextToken
    }
  }
`;
export const getRASCIOwnedTasks = /* GraphQL */ `
  query GetRASCIOwnedTasks($nextToken: String, $limit: Int, $id: ID!) {
    getRASCIOwnedTasks(nextToken: $nextToken, limit: $limit, id: $id) {
      items {
        id
        sort
        itemID
        task {
          res
          error
          id
          sort
          createdAt
          createdBy
          modifiedBy
          lastModified
          parentID
          organizationID
          groupID
          title
          description
          resources
          notes
          owner
          priority
          status
          dateClosed
          dueDate
          archived
          taskOwner {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          taskRASCIRoles {
            items {
              id
              sort
              userID
              userInfo {
                id
                sort
                email
                firstName
                lastName
                inactive
              }
              user {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
              userRASCIRole
            }
            nextToken
          }
        }
        createdAt
        userRASCIRole
      }
      nextToken
    }
  }
`;
export const getTaskv3 = /* GraphQL */ `
  query GetTaskv3($id: ID!) {
    getTaskv3(id: $id) {
      res
      error
      id
      sort
      createdAt
      createdBy
      modifiedBy
      lastModified
      parentID
      organizationID
      groupID
      title
      description
      resources
      notes
      owner
      priority
      status
      dateClosed
      dueDate
      archived
      taskOwner {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      taskRASCIRoles {
        items {
          id
          sort
          userID
          userInfo {
            id
            sort
            email
            firstName
            lastName
            inactive
          }
          user {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          userRASCIRole
        }
        nextToken
      }
    }
  }
`;
export const getOwnedScenarios = /* GraphQL */ `
  query GetOwnedScenarios($nextToken: String, $limit: Int, $id: ID!) {
    getOwnedScenarios(nextToken: $nextToken, limit: $limit, id: $id) {
      items {
        id
        sort
        itemID
        scenario {
          res
          error
          id
          sort
          createdAt
          createdBy
          modifiedBy
          lastModified
          FAIRSummary
          scenarioID
          title
          description
          notes
          assumptions
          creationDate
          lastRevision
          nextRevision
          categories
          owner
          status
          dateClosed
          indicator
          compositeIDYear
          compositeID
          archived
          isTemplate
          groupID
          scenarioRelatedItems {
            items {
              id
              sort
              target
              targetType
              item {
                id
                sort
                groupID
                owner
                uuid
                title
                dateClosed
                dueDate
                status
                priority
                severity
                responseStatus
                archived
                createdAt
                lastModified
                compositeIDYear
                compositeID
                displayID
                relatedItemsOwner {
                  res
                  error
                  id
                  sort
                  email
                  username
                  firstName
                  lastName
                  inactive
                  lastSeen
                  lastLoggedIn
                }
                relatedItemsGroup {
                  res
                  error
                  id
                  sort
                  organizationID
                  title
                  shortTitle
                  organization {
                    res
                    error
                    id
                    sort
                    name
                    organizationRelationships
                    demographics
                  }
                  groupEmailFooter
                }
                fileName
                address
              }
            }
            nextToken
          }
          scenarioOwner {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          scenarioCreatedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          scenarioModifiedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          scenarioRASCIRoles {
            items {
              id
              sort
              userID
              userInfo {
                id
                sort
                email
                firstName
                lastName
                inactive
              }
              user {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
              userRASCIRole
            }
            nextToken
          }
        }
        createdAt
        userRASCIRole
      }
      nextToken
    }
  }
`;
export const getRASCIOwnedScenarios = /* GraphQL */ `
  query GetRASCIOwnedScenarios($nextToken: String, $limit: Int, $id: ID!) {
    getRASCIOwnedScenarios(nextToken: $nextToken, limit: $limit, id: $id) {
      items {
        id
        sort
        itemID
        scenario {
          res
          error
          id
          sort
          createdAt
          createdBy
          modifiedBy
          lastModified
          FAIRSummary
          scenarioID
          title
          description
          notes
          assumptions
          creationDate
          lastRevision
          nextRevision
          categories
          owner
          status
          dateClosed
          indicator
          compositeIDYear
          compositeID
          archived
          isTemplate
          groupID
          scenarioRelatedItems {
            items {
              id
              sort
              target
              targetType
              item {
                id
                sort
                groupID
                owner
                uuid
                title
                dateClosed
                dueDate
                status
                priority
                severity
                responseStatus
                archived
                createdAt
                lastModified
                compositeIDYear
                compositeID
                displayID
                relatedItemsOwner {
                  res
                  error
                  id
                  sort
                  email
                  username
                  firstName
                  lastName
                  inactive
                  lastSeen
                  lastLoggedIn
                }
                relatedItemsGroup {
                  res
                  error
                  id
                  sort
                  organizationID
                  title
                  shortTitle
                  organization {
                    res
                    error
                    id
                    sort
                    name
                    organizationRelationships
                    demographics
                  }
                  groupEmailFooter
                }
                fileName
                address
              }
            }
            nextToken
          }
          scenarioOwner {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          scenarioCreatedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          scenarioModifiedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          scenarioRASCIRoles {
            items {
              id
              sort
              userID
              userInfo {
                id
                sort
                email
                firstName
                lastName
                inactive
              }
              user {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
              userRASCIRole
            }
            nextToken
          }
        }
        createdAt
        userRASCIRole
      }
      nextToken
    }
  }
`;
export const getScenariov3 = /* GraphQL */ `
  query GetScenariov3($id: ID!) {
    getScenariov3(id: $id) {
      res
      error
      id
      sort
      createdAt
      createdBy
      modifiedBy
      lastModified
      FAIRSummary
      scenarioID
      title
      description
      notes
      assumptions
      creationDate
      lastRevision
      nextRevision
      categories
      owner
      status
      dateClosed
      indicator
      compositeIDYear
      compositeID
      archived
      isTemplate
      groupID
      scenarioRelatedItems {
        items {
          id
          sort
          target
          targetType
          item {
            id
            sort
            groupID
            owner
            uuid
            title
            dateClosed
            dueDate
            status
            priority
            severity
            responseStatus
            archived
            createdAt
            lastModified
            compositeIDYear
            compositeID
            displayID
            relatedItemsOwner {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
            relatedItemsGroup {
              res
              error
              id
              sort
              organizationID
              title
              shortTitle
              riskThreshold {
                financial {
                  appetitePercent
                  appetiteAbsolute
                  capacityPercent
                  capacityAbsolute
                }
                privacy {
                  appetitePercent
                  appetiteAbsolute
                  capacityPercent
                  capacityAbsolute
                }
              }
              organization {
                res
                error
                id
                sort
                name
                organizationRelationships
                demographics
              }
              groupEmailFooter
            }
            fileName
            address
          }
        }
        nextToken
      }
      scenarioOwner {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      scenarioCreatedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      scenarioModifiedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      scenarioRASCIRoles {
        items {
          id
          sort
          userID
          userInfo {
            id
            sort
            email
            firstName
            lastName
            inactive
          }
          user {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          userRASCIRole
        }
        nextToken
      }
    }
  }
`;
export const getScenariosInGroupv3 = /* GraphQL */ `
  query GetScenariosInGroupv3($nextToken: String, $limit: Int, $id: ID!) {
    getScenariosInGroupv3(nextToken: $nextToken, limit: $limit, id: $id) {
      items {
        id
        sort
        itemID
        scenario {
          res
          error
          id
          sort
          createdAt
          createdBy
          modifiedBy
          lastModified
          FAIRSummary
          scenarioID
          title
          description
          notes
          assumptions
          creationDate
          lastRevision
          nextRevision
          categories
          owner
          status
          dateClosed
          indicator
          compositeIDYear
          compositeID
          archived
          isTemplate
          groupID
          scenarioRelatedItems {
            items {
              id
              sort
              target
              targetType
              item {
                id
                sort
                groupID
                owner
                uuid
                title
                dateClosed
                dueDate
                status
                priority
                severity
                responseStatus
                archived
                createdAt
                lastModified
                compositeIDYear
                compositeID
                displayID
                relatedItemsOwner {
                  res
                  error
                  id
                  sort
                  email
                  username
                  firstName
                  lastName
                  inactive
                  lastSeen
                  lastLoggedIn
                }
                relatedItemsGroup {
                  res
                  error
                  id
                  sort
                  organizationID
                  title
                  shortTitle
                  organization {
                    res
                    error
                    id
                    sort
                    name
                    organizationRelationships
                    demographics
                  }
                  groupEmailFooter
                }
                fileName
                address
              }
            }
            nextToken
          }
          scenarioOwner {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          scenarioCreatedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          scenarioModifiedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          scenarioRASCIRoles {
            items {
              id
              sort
              userID
              userInfo {
                id
                sort
                email
                firstName
                lastName
                inactive
              }
              user {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
              userRASCIRole
            }
            nextToken
          }
        }
        createdAt
        userRASCIRole
      }
      nextToken
    }
  }
`;
export const getRiskAnalysisCompletedPerMonth = /* GraphQL */ `
  query GetRiskAnalysisCompletedPerMonth(
    $nextToken: String
    $limit: Int
    $id: ID!
  ) {
    getRiskAnalysisCompletedPerMonth(
      nextToken: $nextToken
      limit: $limit
      id: $id
    ) {
      items {
        id
        sort
        total
        year
        month
        firstDate
      }
      nextToken
    }
  }
`;
export const getMyUserMeta = /* GraphQL */ `
  query GetMyUserMeta($id: ID!) {
    getMyUserMeta(id: $id) {
      id
      sort
      forceResetPassword
      emailPreferences
      notificationPreferences
      leftsidePreferences
      snippets
    }
  }
`;
export const getDailyActiveUsersInGroup = /* GraphQL */ `
  query GetDailyActiveUsersInGroup(
    $nextToken: String
    $limit: Int
    $id: ID!
    $filter: DailyActiveUsersFilterInput
  ) {
    getDailyActiveUsersInGroup(
      nextToken: $nextToken
      limit: $limit
      id: $id
      filter: $filter
    ) {
      items {
        id
        sort
        totalActiveUsers
        users
        date
      }
      nextToken
    }
  }
`;
export const getDailyActiveUsersInOrganization = /* GraphQL */ `
  query GetDailyActiveUsersInOrganization(
    $nextToken: String
    $limit: Int
    $id: ID!
    $filter: DailyActiveUsersFilterInput
  ) {
    getDailyActiveUsersInOrganization(
      nextToken: $nextToken
      limit: $limit
      id: $id
      filter: $filter
    ) {
      items {
        id
        sort
        totalActiveUsers
        users
        date
      }
      nextToken
    }
  }
`;
export const getUsersInGroup = /* GraphQL */ `
  query GetUsersInGroup($nextToken: String, $limit: Int, $id: ID!) {
    getUsersInGroup(nextToken: $nextToken, limit: $limit, id: $id) {
      items {
        id
        sort
        userID
        userInfo {
          id
          sort
          email
          firstName
          lastName
          inactive
        }
        groupID
        group {
          res
          error
          id
          sort
          organizationID
          title
          shortTitle
          riskThreshold {
            financial {
              appetitePercent
              appetiteAbsolute
              capacityPercent
              capacityAbsolute
            }
            privacy {
              appetitePercent
              appetiteAbsolute
              capacityPercent
              capacityAbsolute
            }
          }
          organization {
            res
            error
            id
            sort
            name
            organizationRelationships
            demographics
          }
          groupEmailFooter
        }
        user {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        userRole
      }
      nextToken
    }
  }
`;
export const getUsersInOrganization = /* GraphQL */ `
  query GetUsersInOrganization($nextToken: String, $limit: Int, $id: ID!) {
    getUsersInOrganization(nextToken: $nextToken, limit: $limit, id: $id) {
      items {
        id
        sort
        userID
        userInfo {
          id
          sort
          email
          firstName
          lastName
          inactive
        }
        organizationID
        organization {
          res
          error
          id
          sort
          name
          organizationRelationships
          demographics
        }
        user {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        userRole
      }
      nextToken
    }
  }
`;
export const getUserOrganizationMembership = /* GraphQL */ `
  query GetUserOrganizationMembership(
    $nextToken: String
    $limit: Int
    $id: ID!
  ) {
    getUserOrganizationMembership(
      nextToken: $nextToken
      limit: $limit
      id: $id
    ) {
      items {
        id
        sort
        userID
        organizationID
        organization {
          res
          error
          id
          sort
          name
          organizationRelationships
          demographics
        }
        user {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        userRole
      }
      nextToken
    }
  }
`;
export const getOwnedNotifications = /* GraphQL */ `
  query GetOwnedNotifications(
    $nextToken: String
    $limit: Int
    $id: ID!
    $sortDirection: FilterSortDirection
  ) {
    getOwnedNotifications(
      nextToken: $nextToken
      limit: $limit
      id: $id
      sortDirection: $sortDirection
    ) {
      items {
        id
        sort
        itemID
        notification {
          res
          error
          id
          sort
          createdAt
          createdBy
          modifiedBy
          lastModified
          itemID
          moduleName
          item {
            id
            sort
            groupID
            owner
            uuid
            title
            dateClosed
            dueDate
            status
            priority
            severity
            responseStatus
            archived
            createdAt
            lastModified
            compositeIDYear
            compositeID
            displayID
            relatedItemsOwner {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
            relatedItemsGroup {
              res
              error
              id
              sort
              organizationID
              title
              shortTitle
              riskThreshold {
                financial {
                  appetitePercent
                  appetiteAbsolute
                  capacityPercent
                  capacityAbsolute
                }
                privacy {
                  appetitePercent
                  appetiteAbsolute
                  capacityPercent
                  capacityAbsolute
                }
              }
              organization {
                res
                error
                id
                sort
                name
                organizationRelationships
                demographics
              }
              groupEmailFooter
            }
            fileName
            address
          }
          notificationType
          data
          isRead
        }
        createdAt
      }
      nextToken
    }
  }
`;
export const getArtifactsInGroup = /* GraphQL */ `
  query GetArtifactsInGroup($nextToken: String, $limit: Int, $groupID: ID!) {
    getArtifactsInGroup(
      nextToken: $nextToken
      limit: $limit
      groupID: $groupID
    ) {
      items {
        res
        error
        id
        sort
        groupID
        fileName
        displayFileName
        pointOfContact
        artifactID
        archived
      }
      nextToken
    }
  }
`;
export const getControlsSurveys = /* GraphQL */ `
  query GetControlsSurveys($nextToken: String, $limit: Int) {
    GetControlsSurveys(nextToken: $nextToken, limit: $limit) {
      items {
        res
        error
        id
        sort
        displayID
        uuid
        parentID
        title
        description
        discussion
        relationships
        controlsList {
          items {
            res
            error
            id
            sort
            displayID
            uuid
            parentID
            title
            description
            discussion
            relationships
            controlsList {
              items {
                res
                error
                id
                sort
                displayID
                uuid
                parentID
                title
                description
                discussion
                relationships
                controlsList {
                  items {
                    res
                    error
                    id
                    sort
                    displayID
                    uuid
                    parentID
                    title
                    description
                    discussion
                    relationships
                  }
                  nextToken
                }
                controlsRelatedItems {
                  items {
                    id
                    sort
                    target
                    targetType
                  }
                  nextToken
                }
              }
              nextToken
            }
            controlsRelatedItems {
              items {
                id
                sort
                target
                targetType
                item {
                  id
                  sort
                  groupID
                  owner
                  uuid
                  title
                  dateClosed
                  dueDate
                  status
                  priority
                  severity
                  responseStatus
                  archived
                  createdAt
                  lastModified
                  compositeIDYear
                  compositeID
                  displayID
                  relatedItemsOwner {
                    res
                    error
                    id
                    sort
                    email
                    username
                    firstName
                    lastName
                    inactive
                    lastSeen
                    lastLoggedIn
                  }
                  relatedItemsGroup {
                    res
                    error
                    id
                    sort
                    organizationID
                    title
                    shortTitle
                    groupEmailFooter
                  }
                  fileName
                  address
                }
              }
              nextToken
            }
          }
          nextToken
        }
        controlsRelatedItems {
          items {
            id
            sort
            target
            targetType
            item {
              id
              sort
              groupID
              owner
              uuid
              title
              dateClosed
              dueDate
              status
              priority
              severity
              responseStatus
              archived
              createdAt
              lastModified
              compositeIDYear
              compositeID
              displayID
              relatedItemsOwner {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
              relatedItemsGroup {
                res
                error
                id
                sort
                organizationID
                title
                shortTitle
                riskThreshold {
                  financial {
                    appetitePercent
                    appetiteAbsolute
                    capacityPercent
                    capacityAbsolute
                  }
                  privacy {
                    appetitePercent
                    appetiteAbsolute
                    capacityPercent
                    capacityAbsolute
                  }
                }
                organization {
                  res
                  error
                  id
                  sort
                  name
                  organizationRelationships
                  demographics
                }
                groupEmailFooter
              }
              fileName
              address
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getRiskPortfolioScenarios = /* GraphQL */ `
  query GetRiskPortfolioScenarios($nextToken: String, $limit: Int, $id: ID!) {
    getRiskPortfolioScenarios(nextToken: $nextToken, limit: $limit, id: $id) {
      items {
        id
        sort
        scenarioID
        portfolioID
        scenario {
          res
          error
          id
          sort
          createdAt
          createdBy
          modifiedBy
          lastModified
          FAIRSummary
          scenarioID
          title
          description
          notes
          assumptions
          creationDate
          lastRevision
          nextRevision
          categories
          owner
          status
          dateClosed
          indicator
          compositeIDYear
          compositeID
          archived
          isTemplate
          groupID
          scenarioRelatedItems {
            items {
              id
              sort
              target
              targetType
              item {
                id
                sort
                groupID
                owner
                uuid
                title
                dateClosed
                dueDate
                status
                priority
                severity
                responseStatus
                archived
                createdAt
                lastModified
                compositeIDYear
                compositeID
                displayID
                relatedItemsOwner {
                  res
                  error
                  id
                  sort
                  email
                  username
                  firstName
                  lastName
                  inactive
                  lastSeen
                  lastLoggedIn
                }
                relatedItemsGroup {
                  res
                  error
                  id
                  sort
                  organizationID
                  title
                  shortTitle
                  organization {
                    res
                    error
                    id
                    sort
                    name
                    organizationRelationships
                    demographics
                  }
                  groupEmailFooter
                }
                fileName
                address
              }
            }
            nextToken
          }
          scenarioOwner {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          scenarioCreatedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          scenarioModifiedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          scenarioRASCIRoles {
            items {
              id
              sort
              userID
              userInfo {
                id
                sort
                email
                firstName
                lastName
                inactive
              }
              user {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
              userRASCIRole
            }
            nextToken
          }
        }
        portfolio {
          res
          error
          id
          sort
          groupID
          title
          description
          notes
          owner
          status
          assumptions
          archived
          modifiedBy
        }
      }
      nextToken
    }
  }
`;
export const getAuditPlanningsInGroupv2 = /* GraphQL */ `
  query GetAuditPlanningsInGroupv2($nextToken: String, $limit: Int, $id: ID!) {
    getAuditPlanningsInGroupv2(nextToken: $nextToken, limit: $limit, id: $id) {
      items {
        id
        sort
        itemID
        auditPlanning {
          res
          error
          id
          title
          groupID
          sort
          createdAt
          createdBy
          modifiedBy
          lastModified
          auditPlanID
          riskDomain
          year
          hours
          owner
          days
          status
          background
          objective
          scope
          methodology
          preliminaryAuditWork
          points
          summary
          auditFindings
          recommendations
          auditStartDate
          auditEndDate
          auditor
          totalHours
          archived
          auditPlanningRelatedItems {
            items {
              id
              sort
              target
              targetType
              item {
                id
                sort
                groupID
                owner
                uuid
                title
                dateClosed
                dueDate
                status
                priority
                severity
                responseStatus
                archived
                createdAt
                lastModified
                compositeIDYear
                compositeID
                displayID
                relatedItemsOwner {
                  res
                  error
                  id
                  sort
                  email
                  username
                  firstName
                  lastName
                  inactive
                  lastSeen
                  lastLoggedIn
                }
                relatedItemsGroup {
                  res
                  error
                  id
                  sort
                  organizationID
                  title
                  shortTitle
                  organization {
                    res
                    error
                    id
                    sort
                    name
                    organizationRelationships
                    demographics
                  }
                  groupEmailFooter
                }
                fileName
                address
              }
            }
            nextToken
          }
          isTemplate
          auditPlanningOwner {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          auditPlanningCreatedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          auditPlanningModifiedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          riskDomainDetails {
            res
            error
            id
            groupID
            sort
            createdAt
            createdBy
            modifiedBy
            lastModified
            title
            description
            displayID
            parentID
            parent {
              res
              error
              id
              groupID
              sort
              createdAt
              createdBy
              modifiedBy
              lastModified
              title
              description
              displayID
              parentID
              parent {
                res
                error
                id
                groupID
                sort
                createdAt
                createdBy
                modifiedBy
                lastModified
                title
                description
                displayID
                parentID
                parent {
                  res
                  error
                  id
                  groupID
                  sort
                  createdAt
                  createdBy
                  modifiedBy
                  lastModified
                  title
                  description
                  displayID
                  parentID
                  parent {
                    res
                    error
                    id
                    groupID
                    sort
                    createdAt
                    createdBy
                    modifiedBy
                    lastModified
                    title
                    description
                    displayID
                    parentID
                    archived
                  }
                  archived
                  riskDomainCreatedBy {
                    res
                    error
                    id
                    sort
                    email
                    username
                    firstName
                    lastName
                    inactive
                    lastSeen
                    lastLoggedIn
                  }
                  riskDomainModifiedBy {
                    res
                    error
                    id
                    sort
                    email
                    username
                    firstName
                    lastName
                    inactive
                    lastSeen
                    lastLoggedIn
                  }
                }
                archived
                riskDomainCreatedBy {
                  res
                  error
                  id
                  sort
                  email
                  username
                  firstName
                  lastName
                  inactive
                  lastSeen
                  lastLoggedIn
                }
                riskDomainModifiedBy {
                  res
                  error
                  id
                  sort
                  email
                  username
                  firstName
                  lastName
                  inactive
                  lastSeen
                  lastLoggedIn
                }
              }
              archived
              riskDomainCreatedBy {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
              riskDomainModifiedBy {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
            }
            archived
            riskDomainCreatedBy {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
            riskDomainModifiedBy {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
          }
          managementResponse
          viewType
        }
        createdAt
        userRASCIRole
      }
      nextToken
    }
  }
`;
export const getAssetsInGroupv2 = /* GraphQL */ `
  query GetAssetsInGroupv2($nextToken: String, $limit: Int, $id: ID!) {
    getAssetsInGroupv2(nextToken: $nextToken, limit: $limit, id: $id) {
      items {
        id
        sort
        itemID
        assets {
          res
          error
          id
          itemID
          groupID
          sort
          createdAt
          createdBy
          modifiedBy
          lastModified
          title
          qty
          assetsGroupID
          notesAboutControls
          notesAboutThreats
          category
          assetCategory {
            res
            error
            id
            groupID
            sort
            createdAt
            createdBy
            modifiedBy
            lastModified
            title
            archived
            categoryCreatedBy {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
            categoryModifiedBy {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
          }
          location
          assetLocation {
            res
            error
            id
            groupID
            sort
            createdAt
            createdBy
            modifiedBy
            lastModified
            title
            archived
            locationCreatedBy {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
            locationModifiedBy {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
          }
          support
          assetSupport {
            res
            error
            id
            groupID
            sort
            createdAt
            createdBy
            modifiedBy
            lastModified
            title
            pointOfContact
            pocEmail
            pocPhoneNumber
            notes
            archived
            supportCreatedBy {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
            supportModifiedBy {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
          }
          owner
          assetsAnalysisInput
          assetsAnalysisOutput
          assetOwner {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          assetCreatedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          assetModifiedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          notes
          displayID
          assetRelatedItems {
            items {
              id
              sort
              target
              targetType
              item {
                id
                sort
                groupID
                owner
                uuid
                title
                dateClosed
                dueDate
                status
                priority
                severity
                responseStatus
                archived
                createdAt
                lastModified
                compositeIDYear
                compositeID
                displayID
                relatedItemsOwner {
                  res
                  error
                  id
                  sort
                  email
                  username
                  firstName
                  lastName
                  inactive
                  lastSeen
                  lastLoggedIn
                }
                relatedItemsGroup {
                  res
                  error
                  id
                  sort
                  organizationID
                  title
                  shortTitle
                  organization {
                    res
                    error
                    id
                    sort
                    name
                    organizationRelationships
                    demographics
                  }
                  groupEmailFooter
                }
                fileName
                address
              }
            }
            nextToken
          }
          archived
          threatAssessments {
            items {
              res
              error
              id
              sort
              createdAt
              createdBy
              modifiedBy
              lastModified
              threatID
              assetID
              min
              mostLikely
              max
              vulnerability
              index
              archived
            }
            nextToken
          }
          PHIRecords
          assetsGroupDetails {
            res
            error
            id
            groupID
            sort
            createdAt
            createdBy
            modifiedBy
            lastModified
            title
            archived
            assetsGroupCreatedBy {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
            assetsGroupModifiedBy {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
          }
          assetID
          assessmentID
        }
        createdAt
        userRASCIRole
      }
      nextToken
    }
  }
`;
export const getAssessmentAssets = /* GraphQL */ `
  query GetAssessmentAssets($nextToken: String, $limit: Int, $id: ID!) {
    getAssessmentAssets(nextToken: $nextToken, limit: $limit, id: $id) {
      items {
        res
        error
        id
        itemID
        groupID
        sort
        createdAt
        createdBy
        modifiedBy
        lastModified
        title
        qty
        assetsGroupID
        notesAboutControls
        notesAboutThreats
        category
        assetCategory {
          res
          error
          id
          groupID
          sort
          createdAt
          createdBy
          modifiedBy
          lastModified
          title
          archived
          categoryCreatedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          categoryModifiedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
        }
        location
        assetLocation {
          res
          error
          id
          groupID
          sort
          createdAt
          createdBy
          modifiedBy
          lastModified
          title
          archived
          locationCreatedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          locationModifiedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
        }
        support
        assetSupport {
          res
          error
          id
          groupID
          sort
          createdAt
          createdBy
          modifiedBy
          lastModified
          title
          pointOfContact
          pocEmail
          pocPhoneNumber
          notes
          archived
          supportCreatedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          supportModifiedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
        }
        owner
        assetsAnalysisInput
        assetsAnalysisOutput
        assetOwner {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        assetCreatedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        assetModifiedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        notes
        displayID
        assetRelatedItems {
          items {
            id
            sort
            target
            targetType
            item {
              id
              sort
              groupID
              owner
              uuid
              title
              dateClosed
              dueDate
              status
              priority
              severity
              responseStatus
              archived
              createdAt
              lastModified
              compositeIDYear
              compositeID
              displayID
              relatedItemsOwner {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
              relatedItemsGroup {
                res
                error
                id
                sort
                organizationID
                title
                shortTitle
                riskThreshold {
                  financial {
                    appetitePercent
                    appetiteAbsolute
                    capacityPercent
                    capacityAbsolute
                  }
                  privacy {
                    appetitePercent
                    appetiteAbsolute
                    capacityPercent
                    capacityAbsolute
                  }
                }
                organization {
                  res
                  error
                  id
                  sort
                  name
                  organizationRelationships
                  demographics
                }
                groupEmailFooter
              }
              fileName
              address
            }
          }
          nextToken
        }
        archived
        threatAssessments {
          items {
            res
            error
            id
            sort
            createdAt
            createdBy
            modifiedBy
            lastModified
            threatID
            assetID
            min
            mostLikely
            max
            vulnerability
            index
            archived
          }
          nextToken
        }
        PHIRecords
        assetsGroupDetails {
          res
          error
          id
          groupID
          sort
          createdAt
          createdBy
          modifiedBy
          lastModified
          title
          archived
          assetsGroupCreatedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          assetsGroupModifiedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
        }
        assetID
        assessmentID
      }
      nextToken
    }
  }
`;
export const getSystemSurveys = /* GraphQL */ `
  query GetSystemSurveys($nextToken: String, $limit: Int, $id: ID!) {
    getSystemSurveys(nextToken: $nextToken, limit: $limit, id: $id) {
      items {
        res
        error
        id
        sort
        parentID
        templateRoot
        title
        locked
      }
      nextToken
    }
  }
`;
export const getTemplateChildren = /* GraphQL */ `
  query GetTemplateChildren($nextToken: String, $limit: Int, $id: ID!) {
    getTemplateChildren(nextToken: $nextToken, limit: $limit, id: $id) {
      items {
        id
        sort
        title
        type
      }
      nextToken
    }
  }
`;
export const getIssueGroupSummary = /* GraphQL */ `
  query GetIssueGroupSummary($id: ID!) {
    getIssueGroupSummary(id: $id) {
      items {
        groupID
        status
        priority
        severity
        count
      }
    }
  }
`;
export const getIssueTimeSeriesGroupSummary = /* GraphQL */ `
  query GetIssueTimeSeriesGroupSummary($id: ID!, $dates: [AWSDateTime]!) {
    getIssueTimeSeriesGroupSummary(id: $id, dates: $dates) {
      items {
        groupID
        status
        priority
        severity
        count
      }
    }
  }
`;
export const getSQLIssues = /* GraphQL */ `
  query GetSQLIssues(
    $id: ID!
    $limit: Int
    $nextToken: String
    $filter: IssueFilterInput
    $sort: IssueSortInput
  ) {
    getSQLIssues(
      id: $id
      limit: $limit
      nextToken: $nextToken
      filter: $filter
      sort: $sort
    ) {
      items {
        res
        error
        id
        sort
        createdAt
        createdBy
        modifiedBy
        verifiedBy
        lastModified
        issueID
        title
        description
        assumptions
        notes
        reportedBy
        reportedOn
        nextReview
        owner
        priority
        status
        approver
        dateClosed
        severity
        compositeIDYear
        compositeID
        archived
        isTemplate
        issueSourceId
        groupID
        issueRelatedItems {
          items {
            id
            sort
            target
            targetType
            item {
              id
              sort
              groupID
              owner
              uuid
              title
              dateClosed
              dueDate
              status
              priority
              severity
              responseStatus
              archived
              createdAt
              lastModified
              compositeIDYear
              compositeID
              displayID
              relatedItemsOwner {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
              relatedItemsGroup {
                res
                error
                id
                sort
                organizationID
                title
                shortTitle
                riskThreshold {
                  financial {
                    appetitePercent
                    appetiteAbsolute
                    capacityPercent
                    capacityAbsolute
                  }
                  privacy {
                    appetitePercent
                    appetiteAbsolute
                    capacityPercent
                    capacityAbsolute
                  }
                }
                organization {
                  res
                  error
                  id
                  sort
                  name
                  organizationRelationships
                  demographics
                }
                groupEmailFooter
              }
              fileName
              address
            }
          }
          nextToken
        }
        acceptanceCriteria
        requiresScenario
        requiresActionplan
        issueOwner {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        issueCreatedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        issueModifiedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        issueVerifiedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        issueSource {
          res
          error
          id
          sort
          organizationID
          groupID
          description
          year
          title
          archived
        }
        issueRASCIRoles {
          items {
            id
            sort
            userID
            userInfo {
              id
              sort
              email
              firstName
              lastName
              inactive
            }
            user {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
            userRASCIRole
          }
          nextToken
        }
        issueApprovers {
          items {
            id
            sort
            userID
            userInfo {
              id
              sort
              email
              firstName
              lastName
              inactive
            }
            user {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getIssueOrganizationSummary = /* GraphQL */ `
  query GetIssueOrganizationSummary($id: ID!) {
    getIssueOrganizationSummary(id: $id) {
      items {
        organizationID
        status
        priority
        severity
        count
      }
    }
  }
`;
export const getSQLActionPlan = /* GraphQL */ `
  query GetSQLActionPlan(
    $id: ID!
    $limit: Int
    $nextToken: String
    $filter: ActionPlanFilterInput
    $sort: ActionPlanSortInput
  ) {
    getSQLActionPlan(
      id: $id
      limit: $limit
      nextToken: $nextToken
      filter: $filter
      sort: $sort
    ) {
      items {
        res
        error
        id
        sort
        createdAt
        createdBy
        modifiedBy
        lastModified
        actionPlanID
        title
        description
        resources
        notes
        owner
        priority
        status
        approver
        dateClosed
        startDate
        dueDate
        reviewDate
        compositeIDYear
        compositeID
        archived
        isTemplate
        groupID
        actionPlanRelatedItems {
          items {
            id
            sort
            target
            targetType
            item {
              id
              sort
              groupID
              owner
              uuid
              title
              dateClosed
              dueDate
              status
              priority
              severity
              responseStatus
              archived
              createdAt
              lastModified
              compositeIDYear
              compositeID
              displayID
              relatedItemsOwner {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
              relatedItemsGroup {
                res
                error
                id
                sort
                organizationID
                title
                shortTitle
                riskThreshold {
                  financial {
                    appetitePercent
                    appetiteAbsolute
                    capacityPercent
                    capacityAbsolute
                  }
                  privacy {
                    appetitePercent
                    appetiteAbsolute
                    capacityPercent
                    capacityAbsolute
                  }
                }
                organization {
                  res
                  error
                  id
                  sort
                  name
                  organizationRelationships
                  demographics
                }
                groupEmailFooter
              }
              fileName
              address
            }
          }
          nextToken
        }
        taskChildren {
          items {
            id
            sort
            itemID
            task {
              res
              error
              id
              sort
              createdAt
              createdBy
              modifiedBy
              lastModified
              parentID
              organizationID
              groupID
              title
              description
              resources
              notes
              owner
              priority
              status
              dateClosed
              dueDate
              archived
              taskOwner {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
              taskRASCIRoles {
                items {
                  id
                  sort
                  userID
                  userInfo {
                    id
                    sort
                    email
                    firstName
                    lastName
                    inactive
                  }
                  user {
                    res
                    error
                    id
                    sort
                    email
                    username
                    firstName
                    lastName
                    inactive
                    lastSeen
                    lastLoggedIn
                  }
                  userRASCIRole
                }
                nextToken
              }
            }
            createdAt
            userRASCIRole
          }
          nextToken
        }
        effortAmount
        effortType
        actionPlanOwner {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        actionPlanCreatedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        actionPlanModifiedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        actionPlanRASCIRoles {
          items {
            id
            sort
            userID
            userInfo {
              id
              sort
              email
              firstName
              lastName
              inactive
            }
            user {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
            userRASCIRole
          }
          nextToken
        }
        actionplanApprovers {
          items {
            id
            sort
            userID
            userInfo {
              id
              sort
              email
              firstName
              lastName
              inactive
            }
            user {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
          }
          nextToken
        }
        type
      }
      nextToken
    }
  }
`;
export const getActionPlanGroupSummary = /* GraphQL */ `
  query GetActionPlanGroupSummary($id: ID!) {
    getActionPlanGroupSummary(id: $id) {
      items {
        groupID
        status
        priority
        duedate
        count
      }
    }
  }
`;
export const getActionPlanTimeSeriesGroupSummary = /* GraphQL */ `
  query GetActionPlanTimeSeriesGroupSummary($id: ID!, $dates: [AWSDateTime]!) {
    getActionPlanTimeSeriesGroupSummary(id: $id, dates: $dates) {
      items {
        groupID
        status
        priority
        duedate
        count
      }
    }
  }
`;
export const getSQLScenarios = /* GraphQL */ `
  query GetSQLScenarios(
    $id: ID!
    $limit: Int
    $nextToken: String
    $filter: ScenarioFilterInput
    $sort: ScenarioSortInput
  ) {
    getSQLScenarios(
      id: $id
      limit: $limit
      nextToken: $nextToken
      filter: $filter
      sort: $sort
    ) {
      items {
        res
        error
        id
        sort
        createdAt
        createdBy
        modifiedBy
        lastModified
        FAIRSummary
        scenarioID
        title
        description
        notes
        assumptions
        creationDate
        lastRevision
        nextRevision
        categories
        owner
        status
        dateClosed
        indicator
        compositeIDYear
        compositeID
        archived
        isTemplate
        groupID
        scenarioRelatedItems {
          items {
            id
            sort
            target
            targetType
            item {
              id
              sort
              groupID
              owner
              uuid
              title
              dateClosed
              dueDate
              status
              priority
              severity
              responseStatus
              archived
              createdAt
              lastModified
              compositeIDYear
              compositeID
              displayID
              relatedItemsOwner {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
              relatedItemsGroup {
                res
                error
                id
                sort
                organizationID
                title
                shortTitle
                riskThreshold {
                  financial {
                    appetitePercent
                    appetiteAbsolute
                    capacityPercent
                    capacityAbsolute
                  }
                  privacy {
                    appetitePercent
                    appetiteAbsolute
                    capacityPercent
                    capacityAbsolute
                  }
                }
                organization {
                  res
                  error
                  id
                  sort
                  name
                  organizationRelationships
                  demographics
                }
                groupEmailFooter
              }
              fileName
              address
            }
          }
          nextToken
        }
        scenarioOwner {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        scenarioCreatedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        scenarioModifiedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        scenarioRASCIRoles {
          items {
            id
            sort
            userID
            userInfo {
              id
              sort
              email
              firstName
              lastName
              inactive
            }
            user {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
            userRASCIRole
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getAnalysis = /* GraphQL */ `
  query GetAnalysis($id: ID!) {
    getAnalysis(id: $id) {
      id
      sort
      name
      owner
      summary {
        id
        results
        ALE25
        ALE50
        ALE75
        ALE95
        ALEs
        max
        mean
        median
        min
        mode
      }
      frequency {
        CEF {
          min
          max
          mode
          shape
        }
        CEFAssumptions
        LEF {
          min
          max
          mode
          shape
        }
        LEFAssumptions
        TEF {
          min
          max
          mode
          shape
        }
        TEFAssumptions
        POA
        POAAssumptions
        vulnerability
        vulnerabilityAssumptions
        currentLayer
        seed
      }
      magnitudes {
        items {
          magnitudeType
          assumptions
          primaryMagnitude {
            min
            max
            mode
            shape
          }
          secondaryFrequency
          secondaryMagnitude {
            min
            max
            mode
            shape
          }
          secondaryAssumptions
        }
        nextToken
      }
      lastRun {
        settings
        results
        frequencyResults
        seed
      }
    }
  }
`;
export const getCommentsByFeed = /* GraphQL */ `
  query GetCommentsByFeed($id: ID!, $limit: Int, $nextToken: String) {
    getCommentsByFeed(id: $id, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sort
        owner
        message
        change
      }
      nextToken
    }
  }
`;
export const getLinksByItem = /* GraphQL */ `
  query GetLinksByItem($id: ID!, $limit: Int, $nextToken: String) {
    getLinksByItem(id: $id, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sort
        target
        targetType
        item {
          id
          sort
          groupID
          owner
          uuid
          title
          dateClosed
          dueDate
          status
          priority
          severity
          responseStatus
          archived
          createdAt
          lastModified
          compositeIDYear
          compositeID
          displayID
          relatedItemsOwner {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          relatedItemsGroup {
            res
            error
            id
            sort
            organizationID
            title
            shortTitle
            riskThreshold {
              financial {
                appetitePercent
                appetiteAbsolute
                capacityPercent
                capacityAbsolute
              }
              privacy {
                appetitePercent
                appetiteAbsolute
                capacityPercent
                capacityAbsolute
              }
            }
            organization {
              res
              error
              id
              sort
              name
              organizationRelationships
              demographics
            }
            groupEmailFooter
          }
          fileName
          address
        }
      }
      nextToken
    }
  }
`;
export const getAssessmentCSV = /* GraphQL */ `
  query GetAssessmentCSV($id: ID!) {
    getAssessmentCSV(id: $id)
  }
`;
