import React, { useRef, useState } from "react";
import { Button } from "react-bootstrap";
import Dialog from "react-bootstrap-dialog";
import { useItemEditContext } from "./bulkEdit";
import { sleep } from "utils/useSleep";
import { useAssetComparisonContext } from "Modules/Customers/HIPAARiskAssessment/Assets/BulkEditAssets";
import { useScenarioComparisonContext } from "Modules/Customers/Risk/Comparison/TableComparison";
import HGConfirmationModal from "./HGConfirmationModal";

export const CreateBulkArchive = ({ items, type, modifyItem }) => {
  const itemEdit =
    type === "Scenario"
      ? useScenarioComparisonContext()
      : type === "Assets"
      ? useAssetComparisonContext()
      : useItemEditContext();
  const isArchived =
    items.find((i) => i.id === itemEdit.checked[0])?.archived || false;
  const dialog = useRef(null);
  const [modalState, setModalState] = useState({
    show: false,
    title: "",
    body: null,
    onYes: null,
    onNo: null,
  });

  const openModal = (title, body, onYes, onNo) => {
    setModalState({
      show: true,
      title,
      body,
      onYes,
      onNo,
    });
  };

  const closeModal = () => {
    setModalState((prevState) => ({ ...prevState, show: false }));
  };

  const bulkArchiveItems = async () => {
    openModal(
      "Confirm Bulk Update",
      <div>
        <p>Are you sure you want to update all selected items?</p>
      </div>,
      async () => {
        closeModal();
        await modifyItem({
          variables: {
            input: itemEdit.checked.map((item) => ({
              id: item,
              archived: !isArchived,
            })),
          },
        });
        itemEdit.clear();
      },
      () => {
        closeModal();
      }
    );
  };
  return (
    <>
      <Dialog ref={dialog} />
      <Button
        className="Modify-text mr-2"
        variant="light"
        onClick={bulkArchiveItems}
      >
        {isArchived === false ? "Archive" : "Unarchive"}
      </Button>
      <HGConfirmationModal
        show={modalState.show}
        title={modalState.title}
        body={modalState.body}
        onYes={modalState.onYes}
        onNo={modalState.onNo}
      />
    </>
  );
};
