import { ReportConfig } from "utils/docx-export/reportSchemaParse.types";
import { AssetType, ThreatType } from "./AssetType";
import { useGenerateReportFilename } from "utils/docx-export/useCreateFilename";
import { DateTime } from "luxon";
import { usePermissions } from "common/Permissions";
import { AlignmentType, Paragraph, TextRun } from "docx";
import { createPHIRecordTable, createThreatsTable } from "./assetsReportUtils";
import {
  relatedItemsParse,
  useGetGroupByRelatedItem,
} from "utils/useGetGroupByRelatedItems";
import { useGetThreatsInGroup } from "utils/connectors/threatsConnectors";
import { useMemo } from "react";

export const useGetAssetsReportSchema = (): (() => ReportConfig<AssetType>) => {
  const { threats } = useGetThreatsInGroup();
  return useMemo(() => assetsReportSchema(threats), [threats]);
};

export const assetsReportSchema =
  (threats: Array<ThreatType>) => (): ReportConfig<AssetType> => {
    const getFilename = useGenerateReportFilename();
    const { getOrganizationUser, groups } = usePermissions();

    return {
      getFilename: (asset) =>
        getFilename({
          type: "filename",
          fileName: `Decipher Risk - ISSUE ${asset.title ?? "title"}`,
          timestamp: DateTime.now(),
        }),
      fields: [
        { type: "Header", content: (asset) => asset?.title ?? "" },
        {
          type: "InlineText",
          label: "ID",
          content: (asset) => {
            return asset.displayID ?? "";
          },
        },
        {
          type: "InlineText",
          label: "Qty",
          content: (asset) => asset?.qty?.toString() ?? "0",
        },
        {
          type: "InlineText",
          label: "Owner",
          content: (asset) =>
            getOrganizationUser(asset?.owner)?.displayName ?? "",
        },
        {
          type: "RichText",
          label: "Notes",
          content: (asset) => asset.notes ?? "",
        },
        {
          type: "RichText",
          label: "Notes About Controls",
          content: (asset) => asset.notesAboutControls ?? "",
        },
        {
          type: "RichText",
          label: "Notes About Threats",
          content: (asset) => asset.notesAboutThreats ?? "",
        },
        {
          type: "InlineText",
          label: "Location",
          content: (asset) => asset.assetLocation?.title ?? "",
        },
        {
          type: "InlineText",
          label: "Category",
          content: (asset) => asset.assetCategory?.title ?? "",
        },
        {
          type: "InlineText",
          label: "Asset Group",
          content: (asset) => asset.assetsGroupDetails?.title ?? "",
        },
        {
          type: "Custom",
          label: "",
          formatting: (asset) => [
            new Paragraph({
              alignment: AlignmentType.LEFT,
              children: [
                new TextRun({
                  text: "PHI Records",
                  bold: true,
                  break: 1,
                }),
              ],
            }),
            createPHIRecordTable(asset?.PHIRecords),
          ],
        },
        {
          type: "Custom",
          label: "",
          formatting: (asset) => {
            const attachedThreats = asset.threatAssessments?.items.map(
              (attachedThreat) => ({
                ...attachedThreat,
                title: threats.find(
                  (threat) => attachedThreat.threatID === threat.id
                )?.title,
              })
            );

            return [
              new Paragraph({
                alignment: AlignmentType.LEFT,
                children: [
                  new TextRun({
                    text: "Threats",
                    bold: true,
                    break: 1,
                  }),
                ],
              }),
              createThreatsTable(attachedThreats),
            ];
          },
        },
        {
          type: "Custom",
          label: "Related Items",
          formatting: (asset) => [
            new Paragraph({
              alignment: AlignmentType.LEFT,
              heading: "Heading2",
              children: [
                new TextRun({ text: "Related Items", bold: true, break: 1 }),
              ],
            }),
            ...relatedItemsParse(
              useGetGroupByRelatedItem(asset?.assetRelatedItems?.items, groups)
            ),
          ],
        },
      ],
    };
  };
