import { HGForm, HGFormControl, HGToggleSubmit } from "common/HGForm";
import React, { useState, useEffect } from "react";
import { Col, Card, Row } from "react-bootstrap";
import { useGetThreatsInGroup } from "utils/connectors/threatsConnectors";
import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as yup from "yup";
import mapValues from "lodash/mapValues";
import { useParams } from "react-router";
import { useMutation, ApolloError } from "@apollo/client";
import {
  createThreatAssessment,
  removeThreatAssessment,
} from "graphql/mutations";
import {
  CreateThreatAssessmentMutationVariables,
  CreateThreatAssessmentMutation,
  RemoveThreatAssessmentMutationVariables,
  RemoveThreatAssessmentMutation,
} from "API";
import gql from "graphql-tag";
import ToastifyQueue from "common/Overlays/ToastifyQueue";
import { useGetAssets } from "utils/connectors/assetsConnectors";
import { sleep } from "utils/useSleep";
import { HGNumber, HGSelect } from "common/HGForm/Inputs";

const AssetsThreatsInputs: React.FC<
  { assetID; mode; assetsList?: any; name; updateModal } | any
> = ({ assetID, mode, assetsList, name, updateModal }) => {
  const { id } = useParams<{ id: string; tab: string }>();
  const { threats } = useGetThreatsInGroup();
  const { assets, refetch } = useGetAssets(id);
  const [isEdit, setIsEdit] = useState(false);
  const [inputList, setInputList] = useState([] as any);
  const [threatsList, setThreatsList] = useState([] as any);
  const [isDisable, setIsDisable] = useState(true);
  const attachedThreats = [] as any;
  const attachedAssets = [] as any;

  const threatInputObject = yup
    .number()
    .min(0)
    .typeError("${path} requires a number")
    .max(100)
    .typeError("${path} has a maximum limit of 100.")
    .required()
    .typeError("${path} is required.")
    .default(0);

  const threatSchema = yup.lazy((obj: any) =>
    yup.object(
      mapValues(obj, (value, key) => {
        const index = key.split("-")[1];
        if (key.includes("threatID-")) {
          return yup.string().label("Threat").required();
        }
        if (key.includes("min-")) {
          return threatInputObject
            .label("Min")
            .test(
              "is-less",
              "${path} must be less than Most Likely and Max",
              function (value) {
                return (
                  value !== undefined &&
                  value !== null &&
                  obj[`mostLikely-${index}`] >= value &&
                  obj[`max-${index}`] >= value
                );
              }
            );
        }
        if (key.includes("max-")) {
          return threatInputObject
            .label("Max")
            .test(
              "is-greater",
              "${path} must be greater than Most Likely and Min",
              function (value) {
                return (
                  value !== undefined &&
                  value !== null &&
                  obj[`min-${index}`] <= value &&
                  obj[`mostLikely-${index}`] <= value
                );
              }
            );
        }
        if (key.includes("mostLikely-")) {
          return threatInputObject
            .label("Most Likely")
            .test(
              "is-between",
              "${path} must be between Min and Max",
              function (value) {
                return (
                  value !== undefined &&
                  value !== null &&
                  obj[`min-${index}`] <= value &&
                  obj[`max-${index}`] >= value
                );
              }
            );
        }
        if (key.includes("vulnerability-")) {
          return threatInputObject.label("Vulnerability").required();
        }
        return yup.mixed().notRequired();
      })
    )
  );

  function comparer(otherArray) {
    return function (current) {
      return (
        otherArray.filter(function (other) {
          return (
            other?.threats === current?.id && other?.id === current?.threats
          );
        }).length == 0
      );
    };
  }

  const [mutationAttachThreats] = useMutation<
    CreateThreatAssessmentMutation,
    CreateThreatAssessmentMutationVariables
  >(gql(createThreatAssessment), {
    onCompleted: () => {
      if (!isDisable) setIsDisable(true);
      ToastifyQueue("Threats Attach Successfully.", "success");
      sleep(500).then(refetch);
    },
    onError: ({ message }: ApolloError) => {
      ToastifyQueue(message, "danger");
    },
  });

  const [mutationRemoveThreats] = useMutation<
    RemoveThreatAssessmentMutation,
    RemoveThreatAssessmentMutationVariables
  >(gql(removeThreatAssessment), {
    onCompleted: () => {
      if (!isDisable) setIsDisable(true);
      ToastifyQueue("Threats detach Successfully.", "success");
      sleep(500).then(refetch);
    },
    onError: ({ message }: ApolloError) => {
      ToastifyQueue(message, "danger");
    },
  });

  const handleInputChange = (e, index) => {
    if (isDisable) setIsDisable(false);
    const { value } = e.target;
    const defaultName = e.target.getAttribute("defaultName");
    const list = [...inputList];
    list[index][defaultName] = value;
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (position) => {
    const list = [...inputList];
    list.splice(position, 1);
    const tempArray = list.map((obj, i) => {
      return { ...obj, index: i };
    });
    setInputList(tempArray);

    const onlyInA = tempArray.filter(comparer(threats));
    const onlyInB = threats.filter(comparer(tempArray));
    const result = onlyInA.concat(onlyInB);
    const threatData = [{ id: null, title: "" }];
    result &&
      result.forEach(function (element) {
        if (!element.hasOwnProperty("min")) {
          return element.archived === true
            ? threatData.push({
                id: element?.id,
                title: `${element?.title} (Archived)`,
              })
            : threatData.push({ id: element?.id, title: element?.title });
        }
      });
    setThreatsList(threatData);

    const detachIDs = [
      { id: inputList[position]?.id.replace("THREATASSESSMENT#", "") },
    ];
    if (mode === "Details") {
      return mutationRemoveThreats({
        variables: {
          input: detachIDs,
        },
      });
    }
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([
      ...inputList,
      {
        min: 0,
        mostLikely: 0,
        max: 0,
        vulnerability: 0,
        threatID: "",
      },
    ]);

    const onlyInA = inputList.filter(comparer(threats));
    const onlyInB = threats.filter(comparer(inputList));
    const result = onlyInA.concat(onlyInB);
    const threatData = [{ id: null, title: "" }];
    result &&
      result.forEach(function (element) {
        if (!element.hasOwnProperty("min")) {
          return element.archived === true
            ? threatData.push({
                id: element?.id,
                title: `${element?.title} (Archived)`,
              })
            : threatData.push({ id: element?.id, title: element?.title });
        }
      });
    setThreatsList(threatData);
  };

  useEffect(() => {
    if (
      assets?.threatAssessments?.items &&
      assets?.threatAssessments?.items?.length > 0
    ) {
      assets?.threatAssessments?.items.forEach((item) => {
        if (item?.id === id) {
          attachedAssets.push({
            assetID: item?.assetID,
            threatID: item?.threatID,
            mostLikely: item?.mostLikely,
            vulnerability: item?.vulnerability,
            min: item?.min,
            max: item?.max,
            index: item?.index,
            id: item?.sort?.replaceAll("THREATASSESSMENT#", ""),
            __typename: "ThreatAssessment",
          });
          attachedAssets.sort((a, b) =>
            a.index > b.index ? 1 : b.index > a.index ? -1 : 0
          );
          setInputList(attachedAssets);
        }
        if (threats?.length > 0) {
          const a: any = threats.find(
            (i) => i.id === item?.threatID && item?.id === id
          );
          if (a !== undefined) {
            a?.archived === true
              ? attachedThreats.push({
                  id: a?.id,
                  title: `${a?.title} (Archived)`,
                })
              : attachedThreats.push({ id: a?.id, title: a?.title });
            const onlyInA = attachedThreats.filter(comparer(threats));
            const onlyInB = threats.filter(comparer(attachedThreats));
            const result = onlyInA.concat(onlyInB);
            const threatData = [{ id: null, title: "" }];
            result &&
              result.forEach(function (element) {
                if (element.hasOwnProperty("archived")) {
                  return element.archived === true
                    ? threatData.push({
                        id: element?.id,
                        title: `${element?.title} (Archived)`,
                      })
                    : threatData.push({
                        id: element?.id,
                        title: element?.title,
                      });
                }
              });
            setThreatsList(threatData);
          }
        }
      });
    }
    sleep(500).then(refetch);
  }, [assets?.threatAssessments?.items, threats?.length, isEdit]);

  return (
    <HGForm
      onSubmit={async (input) => {
        // Check for duplicate selection of threats
        const newArray = inputList.map((obj, index) => ({ ...obj, index }));
        const threatIDS = inputList.map((obj) => {
          return obj.threatID;
        });
        const isDuplicate = threatIDS.some(function (item, idx) {
          return threatIDS.indexOf(item) != idx;
        });
        if (isDuplicate) {
          if (!isDisable) setIsDisable(true);
          ToastifyQueue("Attached threats must be unique", "danger");
        } else if (mode === "Bulk") {
          const bulkResult = assetID
            ?.map((_id) => {
              return (
                inputList?.map((data) => {
                  const _threatAssessments = assetsList.find(
                    (_asset) => _asset?.itemID == _id
                  )?.threatAssessments?.items;
                  const _threatAssessment = _threatAssessments?.find(
                    (_threatAssessment) =>
                      _threatAssessment.threatID === data.threatID
                  );
                  return {
                    ...data,
                    assetID: _id,
                    id:
                      _threatAssessment?.sort?.replaceAll(
                        "THREATASSESSMENT#",
                        ""
                      ) ?? undefined,
                  };
                }) ?? []
              );
            })
            .reduce((a, b) => [...a, ...b], []);
          return mutationAttachThreats({
            variables: {
              input: bulkResult,
            },
          });
        } else if (mode === "Details") {
          const result = newArray.map((d) => {
            if (!d?.__typename && !d?.assetID) {
              d.assetID = id;
            }
            return d;
          });
          return mutationAttachThreats({
            variables: {
              input: result,
            },
          });
        }
      }}
      editToggle
      resolver={threatSchema}
    >
      <>
        <Card>
          <Card.Header>
            <HGToggleSubmit
              className="float-right"
              applyDirty
              disable={isDisable}
              setIsEdit={setIsEdit}
              isEdit={isEdit}
            />
            Threats
            {isEdit === true && (
              <FontAwesomeIcon
                className="mr-1"
                icon={faPlusCircle}
                aria-hidden="true"
                onClick={handleAddClick}
                style={{ marginLeft: "5px", marginTop: "5px" }}
              />
            )}
          </Card.Header>
          <Card.Body>
            {inputList?.length > 0 ? (
              <Row>
                {" "}
                <Col sm={3}>
                  <p>
                    <b>Threats</b>
                  </p>
                </Col>
                <Col sm={2}>
                  <p>
                    <b>Min</b>
                  </p>
                </Col>
                <Col sm={2}>
                  <p>
                    <b>Most Likely</b>
                  </p>
                </Col>
                <Col sm={2}>
                  <p>
                    <b>Max</b>
                  </p>
                </Col>
                <Col sm={2}>
                  <p>
                    <b>Vulnerability</b>
                  </p>
                </Col>
                <Col sm={1} />
              </Row>
            ) : null}
            {inputList?.map((x, i) => {
              return (
                <Row key={`threatsRow${i}`} className="pb-1">
                  <Col sm={3}>
                    <HGSelect
                      name={`threatID-${i}`}
                      defaultName="threatID"
                      options={threatsList || []}
                      onChange={(e) => handleInputChange(e, i)}
                      defaultValue={x.threatID}
                      overrideFormat={
                        threats.find((item) => item.id === x.threatID)?.title
                      }
                    />
                  </Col>
                  <Col sm={2}>
                    <HGNumber
                      name={`min-${i}`}
                      defaultName="min"
                      onChange={(e) => handleInputChange(e, i)}
                      defaultValue={x.min}
                    />
                  </Col>
                  <Col sm={2}>
                    <HGNumber
                      name={`mostLikely-${i}`}
                      defaultName="mostLikely"
                      onChange={(e) => handleInputChange(e, i)}
                      defaultValue={x.mostLikely}
                    />
                  </Col>
                  <Col sm={2}>
                    <HGNumber
                      name={`max-${i}`}
                      defaultName="max"
                      onChange={(e) => handleInputChange(e, i)}
                      defaultValue={x.max}
                    />
                  </Col>
                  <Col sm={2}>
                    <HGNumber
                      name={`vulnerability-${i}`}
                      defaultName="vulnerability"
                      onChange={(e) => handleInputChange(e, i)}
                      defaultValue={x.vulnerability}
                    />
                  </Col>
                  <Col sm={1}>
                    {inputList.length !== 0 && isEdit && (
                      <FontAwesomeIcon
                        className="mr-1"
                        icon={faMinusCircle}
                        aria-hidden="true"
                        onClick={() => handleRemoveClick(i)}
                      />
                    )}{" "}
                  </Col>
                </Row>
              );
            })}
          </Card.Body>
        </Card>
      </>
    </HGForm>
  );
};
export default AssetsThreatsInputs;
