import { StatusBadge } from "common/Badges";
import React, { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { getCompositeId } from "utils/dataFormatters";
import { formatStatus } from "utils/formatStatus";

interface ResultHolderProps {
  type: Object;
  data: Object[];
  filter: Function;
}
interface ResultHolderState {
  initialAttachments: number[];
  attachments: number[];
}

const ResultHolder: React.FC<{
  attach: Function;
  detach: Function;
  addButton?: React.FC;
  attachedData: Array<any>;
  type: any;
  data: any;
  filter: any;
  setOpen: Function;
  setModalOpen: Function;
  refetch?: Function;
  id?: string;
}> = ({ attach, detach, addButton, refetch, id, ...props }) => {
  const initial = props.attachedData.map((d) => d.target);
  const [loading, setLoading] = useState(false);
  const [currentId, setCurrentID] = useState([] as any);
  const { type, data, filter } = props;

  const handleAttachClick = async (e, d) => {
    setLoading(true);
    setCurrentID((oldArray) => [...oldArray, e.target.id]);
    await attach(type.field(d));
    if (refetch) {
      await refetch();
    }
    setCurrentID((currentId) => {
      return currentId?.filter((a) => a !== e.target.id);
    });
    setLoading(false);
  };

  const handleDetachClick = async (e, d) => {
    setLoading(true);
    setCurrentID((oldArray) => [...oldArray, e.target.id]);
    await detach(type.field(d));
    if (refetch) {
      await refetch();
    }
    setCurrentID((currentId) => {
      return currentId?.filter((a) => a !== e.target.id);
    });
    setLoading(false);
  };
  // show controls value
  return (
    <div
      style={{
        height: "auto",
        padding: "0",
        maxHeight: "200px",
        overflowY: "scroll",
        overflowX: "hidden",
      }}
    >
      
            <div>
              <table style={{ borderCollapse: "collapse" }}>
                <tbody>
                  {data
                    ?.filter((entity) =>
                      !filter || entity?.__typename === "Controls"
                        ? entity?.displayID
                            ?.toLowerCase()
                            .indexOf(filter?.toLowerCase()) > -1 ||
                          entity?.title
                            ?.toLowerCase()
                            .indexOf(filter?.toLowerCase()) > -1
                        : entity.title
                            ?.toLowerCase()
                            .indexOf(filter?.toLowerCase()) > -1
                    )
                    .filter(
                      (entity) =>
                        !(
                          !initial.some((a) => a === entity.id) &&
                          entity?.archived
                        )
                    )
                    .map((d) => {
                      const attached =
                        d?.__typename === "Controls"
                          ? initial.some((a) => a === d?.uuid)
                          : initial.some((a) => a === d.id);
                      return (
                        <tr
                          key={d.id || d.uuid}
                          style={{ borderBottom: "none" }}
                        >
                          {d?.__typename !== "Controls" &&
                          d?.__typename !== "Url" &&
                          d?.__typename !== "Artifact" &&
                          d?.__typename !== "AuditPlanning" ? (
                            <td style={{ width: "50%", padding: "8px" }}>
                              {getCompositeId(d)}
                            </td>
                          ) : (
                            <></>
                          )}

                          <td style={{ width: "50%", padding: "8px" }}>
                            {d?.__typename === "Controls" ? (
                              <>
                                <span style={{ fontWeight: "bold" }}>
                                  {d?.displayID}
                                </span>{" "}
                                : {d?.title}
                              </>
                            ) : (
                              <>
                                {type.format(d)} {d.archived && " (archived)"}
                              </>
                            )}
                          </td>

                          {/* Status column */}
                          <td style={{ width: "25%", padding: "8px" }}>
                            {d.status && <StatusBadge status={d.status} />}
                          </td>

                          {/* Action button column */}
                          <td style={{ width: "25%", padding: "8px" }}>
                            {!attached ? (
                              <Button
                                variant="link"
                                id={
                                  d?.__typename !== "Controls" ? d.id : d.uuid
                                }
                                onClick={async (e) =>
                                  await handleAttachClick(e, d)
                                }
                                className="p0"
                              >
                                {loading === false ? (
                                  "Add"
                                ) : (
                                    d?.__typename !== "Controls"
                                      ? currentId?.includes(d.id)
                                      : currentId?.includes(d.uuid)
                                  ) ? (
                                  <Spinner
                                    size="sm"
                                    animation="border"
                                    variant="primary"
                                  />
                                ) : (
                                  "Add"
                                )}
                              </Button>
                            ) : (
                              <Button
                                variant="link"
                                id={
                                  d?.__typename !== "Controls" ? d.id : d.uuid
                                }
                                onClick={async (e) =>
                                  await handleDetachClick(e, d)
                                }
                                className="p0"
                              >
                                {loading === false ? (
                                  "Remove"
                                ) : (
                                    d?.__typename !== "Controls"
                                      ? currentId?.includes(d.id)
                                      : currentId?.includes(d.uuid)
                                  ) ? (
                                  <Spinner
                                    size="sm"
                                    animation="border"
                                    variant="primary"
                                  />
                                ) : (
                                  "Remove"
                                )}
                              </Button>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
    </div>
  );
};

export default ResultHolder;
