import { ITableRecordFormatting } from "common/TabbedTable";
import React, { useCallback } from "react";
import { Badge, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  getDateFormat,
  getDateDisplayFormat,
  getDateTimeFormat,
  getDateTimeDisplayFormat,
  getCompositeId,
  getUserFormat,
  getActivityFeedUserFormat,
  getItemApprover,
} from "utils/dataFormatters";
import {
  ActionPlanStatus,
  formatObj,
  formatPriority,
  formatStatus,
  getStyleByStatus,
} from "utils/formatStatus";
import { HGRichTextRead } from "common/HGForm/HGRichText";
import { usePermissions } from "common/Permissions";
import { EffortType } from "API";
import { PriorityBadge, StatusBadge, RelatedItemBadge } from "common/Badges";
import { BulkEdit } from "common/bulkEdit";
import {
  getRelatedIssueSummary,
  getRelatedItemsSearchString,
  getRelatedTaskSummary,
} from "formatRelatedItemsSearchString";
import { ActionPlanType } from "./actionPlanTypes";
import { getActionPlanTaskCompletionStats } from "./ActionPlanPage/ActionPlanTabs/ActionPlanTasks/ActionPlanTasksCompletion";
import { ProgressBar } from "react-bootstrap";

type schemaParams = {
  updateActionPlan?: Function;
  showGroupPrefix?: boolean;
  userRole?: string;
  refetch?: any;
};

export const useActionPlanSchema = (
  params?: schemaParams
): Array<ITableRecordFormatting<any>> => {
  const { getOrganizationGroup } = usePermissions();
  const actionPlanStatus = formatObj(ActionPlanStatus, formatStatus);

  const archiveActionPlan = useCallback(
    (id: string, archived = true) => {
      params?.updateActionPlan?.({
        variables: {
          input: [
            {
              id,
              archived,
            },
          ],
        },
      });
    },
    [params?.updateActionPlan]
  );
  const actionPlanSchema = [
    {
      id: "checkbox",
      name: "",
      format: () => "",
      tableDisplayFormat: (actionPlan) => <BulkEdit item={actionPlan} />,
      required: true,
      first: true,
    },
    {
      id: "compositeID",
      name: "ID",
      format: (actionPlan) => `${getCompositeId(actionPlan)}`,
      required: true,
      first: true,
    },
    {
      id: "title",
      name: "Title",
      format: (actionPlan) => actionPlan.title,
      tableDisplayFormat: (actionPlan) => {
        return (
          <Link
            to={`/actionplans/${actionPlan.id?.replace("ACTIONPLAN#", "")}`}
          >
            {actionPlan.title}
          </Link>
        );
      },
      required: true,
      first: true,
    },
    {
      id: "description",
      name: "Description",
      format: (actionPlan) => actionPlan?.description,
      tableDisplayFormat: (actionPlan) => (
        <HGRichTextRead content={actionPlan?.description} />
      ),
      defaultVisible: false,
    },
    {
      id: "resources",
      name: "Resources",
      format: (actionPlan) => actionPlan?.resources,
      tableDisplayFormat: (actionPlan) => (
        <HGRichTextRead content={actionPlan?.resources} />
      ),
      defaultVisible: false,
    },
    {
      id: "notes",
      name: "Notes",
      format: (actionPlan) => actionPlan?.notes,
      tableDisplayFormat: (actionPlan) => (
        <HGRichTextRead content={actionPlan?.notes} />
      ),
      defaultVisible: false,
    },
    {
      id: "owner",
      name: "Owner",
      format: getUserFormat("actionPlanOwner"),
      tableDisplayFormat: (actionPlan) => {
        const owner = getUserFormat("actionPlanOwner")(actionPlan);
        if (owner === "Not Set") {
          return (
            <Badge title={owner} pill>
              {owner}
            </Badge>
          );
        }
        return owner;
      },
      activityFeed: getActivityFeedUserFormat("owner"),
      defaultVisible: false,
    },
    {
      id: "approver",
      name: "Approver",
      format: (actionPlan) => {
        return getItemApprover(actionPlan, "actionplanApprovers");
      },
      tableDisplayFormat: (actionPlan) => {
        const actionPlanApprover = getItemApprover(
          actionPlan,
          "actionplanApprovers"
        );
        if (actionPlanApprover === "Not Set") {
          return (
            <Badge title={actionPlanApprover} pill>
              {actionPlanApprover}
            </Badge>
          );
        }
        return actionPlanApprover;
      },
      defaultVisible: false,
    },
    {
      id: "createdBy",
      name: "Created By",
      format: getUserFormat("actionPlanCreatedBy"),
      defaultVisible: false,
    },
    {
      id: "modifiedBy",
      name: "Modified By",
      format: getUserFormat("actionPlanModifiedBy"),
      defaultVisible: false,
    },
    {
      id: "priority",
      name: "Priority",
      format: (actionPlan) => formatPriority(actionPlan.priority),
      tableDisplayFormat: ({ priority }) => (
        <PriorityBadge priority={priority} />
      ),
      sortFormat: (actionplan: ActionPlanType) => {
        switch (actionplan.priority) {
          case "Null":
            return 0;
          case "Low":
            return 1;
          case "Medium":
            return 2;
          case "High":
            return 3;
          case "Critical":
            return 4;
          default:
            return 0;
        }
      },
      defaultVisible: true,
      type: "Options",
    },
    {
      id: "status",
      name: "Status",
      format: ({ status }) => formatStatus(status),
      tableDisplayFormat: ({ status }) => <StatusBadge status={status} />,
      defaultVisible: true,
      type: "Options",
      optionValues: actionPlanStatus,
    },
    {
      id: "type",
      name: "Type",
      format: (actionPlan) => actionPlan?.type,
      tableDisplayFormat: (actionPlan) => (
        <HGRichTextRead content={actionPlan?.type} />
      ),
      defaultVisible: false,
    },
    {
      id: "startDate",
      name: "Start Date",
      type: "Date",
      format: getDateFormat("startDate"),
      displayFormat: getDateDisplayFormat("startDate"),
      defaultVisible: false,
    },
    {
      id: "dueDate",
      name: "Due Date",
      type: "Date",
      format: getDateFormat("dueDate"),
      tableDisplayFormat: (actionPlan) => (
        <p
          style={
            actionPlan?.dueDate
              ? getStyleByStatus(actionPlan?.status, actionPlan?.dueDate)
              : undefined
          }
        >
          {getDateDisplayFormat("dueDate")(actionPlan)}
        </p>
      ),

      defaultVisible: false,
    },
    {
      id: "reviewDate",
      name: "Review Date",
      type: "Date",
      format: getDateFormat("reviewDate"),
      tableDisplayFormat: (actionPlan) => (
        <p
          style={
            actionPlan?.reviewDate
              ? getStyleByStatus(actionPlan?.status, actionPlan?.reviewDate)
              : undefined
          }
        >
          {getDateDisplayFormat("reviewDate")(actionPlan)}
        </p>
      ),
      defaultVisible: false,
    },
    {
      id: "createdAt",
      name: "Created At",
      type: "Date",
      format: getDateTimeFormat("createdAt"),
      displayFormat: getDateTimeDisplayFormat("createdAt"),
      defaultVisible: false,
    },
    {
      id: "lastModified",
      name: "Last Modified",
      type: "Date",
      format: getDateTimeFormat("lastModified"),
      displayFormat: getDateTimeDisplayFormat("lastModified"),
      defaultVisible: false,
    },
    {
      id: "closedDate",
      name: "Closed Date",
      type: "Date",
      format: getDateTimeFormat("dateClosed"),
      tableDisplayFormat: getDateTimeDisplayFormat("dateClosed"),
      defaultVisible: false,
    },
    {
      id: "effort",
      name: "Estimated Effort",
      format: (actionPlan) =>
        actionPlan?.effortAmount === -1 || actionPlan?.effortAmount === null
          ? "Not Set"
          : `${actionPlan?.effortAmount} ${
              actionPlan?.effortType || Object.values(EffortType)[0]
            }`,
      defaultVisible: false,
    },
    {
      id: "issues",
      name: "Related Issues",
      format: (issue) =>
        getRelatedItemsSearchString(
          issue.actionPlanRelatedItems?.items,
          getRelatedIssueSummary
        ),
      tableDisplayFormat: (actionPlan) => {
        const items = actionPlan.actionPlanRelatedItems?.items;
        return (
          <RelatedItemBadge items={items} display="issue" filter="issues" />
        );
      },
      required: true,
      last: true,
    },
    {
      id: "tasks",
      name: "Related Tasks",
      format: (actionplan) =>
        getRelatedItemsSearchString(
          actionplan.taskChildren?.items.filter(
            (item) => item.task.archived !== true
          ),
          getRelatedTaskSummary
        ),
      tableDisplayFormat: (actionplan) => {
        const items = actionplan.taskChildren?.items?.filter(
          (item) => item.task.archived !== true
        );
        return <RelatedItemBadge items={items} display="task" />;
      },
    },
    {
      name: "Task completion",
      format: (actionPlan) => {
        let actionplanTaskStats = getActionPlanTaskCompletionStats(
          actionPlan?.taskChildren.items
        );
        if (actionplanTaskStats > 0) return `${actionplanTaskStats}%`;
        else if (actionplanTaskStats === 0) return `${0}%`;
        else return "No Tasks";
      },
      sortFormat: (actionPlan) => {
        return actionPlan?.taskChildren.items.length !== 0
          ? getActionPlanTaskCompletionStats(actionPlan?.taskChildren.items)
          : -1;
      },
      tableDisplayFormat: (actionPlan) => {
        let actionplanTaskStats = getActionPlanTaskCompletionStats(
          actionPlan?.taskChildren.items
        );
        return (
          <>
            <Link
              to={`/actionplans/${actionPlan.id}/tasks`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              {actionplanTaskStats > 0 ? (
                <ProgressBar
                  variant={actionplanTaskStats === 100 ? "info" : "warning"}
                  now={actionplanTaskStats}
                  label={`${actionplanTaskStats}%`}
                />
              ) : actionplanTaskStats === 0 ? (
                `0%`
              ) : (
                "No Tasks"
              )}
            </Link>
          </>
        );
      },
      required: true,
      last: true,
      // visible: false,
    },
    {
      name: "",
      format: () => "",
      tableDisplayFormat: (actionPlan) =>
        params?.userRole !== "read" ? (
          <Button
            variant="outline-primary"
            onClick={() =>
              archiveActionPlan?.(actionPlan.id, !actionPlan.archived)
            }
          >
            {(actionPlan.archived && "Unarchive") || "Archive"}
          </Button>
        ) : (
          <></>
        ),
      required: true,
      last: true,
    },
  ];
  params?.showGroupPrefix &&
    actionPlanSchema.splice(1, 0, {
      id: "department",
      name: "Department",
      format: (actionPlan) =>
        `${
          params?.showGroupPrefix
            ? `${getOrganizationGroup(actionPlan.groupID)?.title} `
            : ""
        }`,
      required: true,
      first: true,
    });
  return actionPlanSchema;
};
