/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateIssueInput = {
  id?: string | null,
  sort?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  verifiedBy?: string | null,
  lastModified?: string | null,
  issueID?: string | null,
  title: string,
  description?: string | null,
  assumptions?: string | null,
  notes?: string | null,
  reportedBy?: string | null,
  reportedOn?: string | null,
  nextReview?: string | null,
  owner?: string | null,
  priority: Priority,
  status: Status,
  approver: string,
  dateClosed?: string | null,
  severity: Severity,
  compositeIDYear?: string | null,
  compositeID?: number | null,
  archived?: boolean | null,
  isTemplate?: boolean | null,
  issueSourceId?: string | null,
  groupID: string,
  acceptanceCriteria?: string | null,
  requiresScenario?: boolean | null,
  requiresActionplan?: boolean | null,
};

export enum Priority {
  Null = "Null",
  Low = "Low",
  Medium = "Medium",
  High = "High",
  Critical = "Critical",
}


export enum Status {
  Created = "Created",
  In_Triage = "In_Triage",
  Deferred = "Deferred",
  Pending = "Pending",
  In_Progress = "In_Progress",
  Closed_Pending_Approval = "Closed_Pending_Approval",
  Closed = "Closed",
  Planning = "Planning",
  Complete = "Complete",
}


export enum Severity {
  Null = "Null",
  Low = "Low",
  Minor = "Minor",
  Major = "Major",
  Critical = "Critical",
}


export type Issue = {
  __typename: "Issue",
  res?: string | null,
  error?: string | null,
  id?: string | null,
  sort?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  verifiedBy?: string | null,
  lastModified?: string | null,
  issueID?: string | null,
  title?: string | null,
  description?: string | null,
  assumptions?: string | null,
  notes?: string | null,
  reportedBy?: string | null,
  reportedOn?: string | null,
  nextReview?: string | null,
  owner?: string | null,
  priority?: Priority | null,
  status?: Status | null,
  approver?: string | null,
  dateClosed?: string | null,
  severity?: Severity | null,
  compositeIDYear?: string | null,
  compositeID?: number | null,
  archived?: boolean | null,
  isTemplate?: boolean | null,
  issueSourceId?: string | null,
  groupID?: string | null,
  issueRelatedItems?: PaginatedLinks | null,
  acceptanceCriteria?: string | null,
  requiresScenario?: boolean | null,
  requiresActionplan?: boolean | null,
  issueOwner?: User | null,
  issueCreatedBy?: User | null,
  issueModifiedBy?: User | null,
  issueVerifiedBy?: User | null,
  issueSource?: Source | null,
  issueRASCIRoles?: PaginatedRASCIUsersOnItem | null,
  issueApprovers?: PaginatedItemApprover | null,
};

export type PaginatedLinks = {
  __typename: "PaginatedLinks",
  items:  Array<Link >,
  nextToken?: string | null,
};

export type Link = {
  __typename: "Link",
  id: string,
  sort: string,
  target?: string | null,
  targetType?: LinkTarget | null,
  item?: RelatableItem | null,
};

export enum LinkTarget {
  issues = "issues",
  scenarios = "scenarios",
  actionplans = "actionplans",
  artifacts = "artifacts",
  urls = "urls",
  assessmentresponse = "assessmentresponse",
  auditplanning = "auditplanning",
  assets = "assets",
  controls = "controls",
}


export type RelatableItem = {
  __typename: "RelatableItem",
  id?: string | null,
  sort?: string | null,
  groupID?: string | null,
  owner?: string | null,
  uuid?: string | null,
  title?: string | null,
  dateClosed?: string | null,
  dueDate?: string | null,
  status?: Status | null,
  priority?: Priority | null,
  severity?: Severity | null,
  responseStatus?: string | null,
  archived?: boolean | null,
  createdAt?: string | null,
  lastModified?: string | null,
  compositeIDYear?: string | null,
  compositeID?: number | null,
  displayID?: string | null,
  relatedItemsOwner?: User | null,
  relatedItemsGroup?: Group | null,
  fileName?: string | null,
  address?: string | null,
};

export type User = {
  __typename: "User",
  res?: string | null,
  error?: string | null,
  id?: string | null,
  sort?: string | null,
  email?: string | null,
  username?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  inactive?: boolean | null,
  lastSeen?: string | null,
  lastLoggedIn?: string | null,
};

export type Group = {
  __typename: "Group",
  res?: string | null,
  error?: string | null,
  id?: string | null,
  sort?: string | null,
  organizationID?: string | null,
  title?: string | null,
  shortTitle?: string | null,
  riskThreshold?: RiskThreshold | null,
  organization?: Organization | null,
  groupEmailFooter?: string | null,
};

export type RiskThreshold = {
  __typename: "RiskThreshold",
  financial?: Threshold | null,
  privacy?: Threshold | null,
};

export type Threshold = {
  __typename: "Threshold",
  appetitePercent?: number | null,
  appetiteAbsolute?: number | null,
  capacityPercent?: number | null,
  capacityAbsolute?: number | null,
};

export type Organization = {
  __typename: "Organization",
  res?: string | null,
  error?: string | null,
  id?: string | null,
  sort?: string | null,
  name?: string | null,
  organizationRelationships?: string | null,
  demographics?: string | null,
};

export type Source = {
  __typename: "Source",
  res?: string | null,
  error?: string | null,
  id?: string | null,
  sort?: string | null,
  organizationID?: string | null,
  groupID?: string | null,
  description?: string | null,
  year?: string | null,
  title?: string | null,
  archived?: boolean | null,
};

export type PaginatedRASCIUsersOnItem = {
  __typename: "PaginatedRASCIUsersOnItem",
  items:  Array<RASCIUserOnItem >,
  nextToken?: string | null,
};

export type RASCIUserOnItem = {
  __typename: "RASCIUserOnItem",
  id?: string | null,
  sort?: string | null,
  userID?: string | null,
  userInfo?: UserInfo | null,
  user?: User | null,
  userRASCIRole?: UserRoles | null,
};

export type UserInfo = {
  __typename: "UserInfo",
  id?: string | null,
  sort?: string | null,
  email?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  inactive?: boolean | null,
};

export enum UserRoles {
  read = "read",
  write = "write",
  moderate = "moderate",
  admin = "admin",
}


export type PaginatedItemApprover = {
  __typename: "PaginatedItemApprover",
  items:  Array<ItemApprover >,
  nextToken?: string | null,
};

export type ItemApprover = {
  __typename: "ItemApprover",
  id?: string | null,
  sort?: string | null,
  userID?: string | null,
  userInfo?: UserInfo | null,
  user?: User | null,
};

export type UpdateIssueInput = {
  id: string,
  sort?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  verifiedBy?: string | null,
  lastModified?: string | null,
  issueID?: string | null,
  title?: string | null,
  description?: string | null,
  assumptions?: string | null,
  notes?: string | null,
  reportedBy?: string | null,
  reportedOn?: string | null,
  nextReview?: string | null,
  owner?: string | null,
  priority?: Priority | null,
  dateClosed?: string | null,
  severity?: Severity | null,
  compositeIDYear?: string | null,
  compositeID?: number | null,
  archived?: boolean | null,
  isTemplate?: boolean | null,
  issueSourceId?: string | null,
  groupID?: string | null,
  acceptanceCriteria?: string | null,
  requiresScenario?: boolean | null,
  requiresActionplan?: boolean | null,
};

export type DeleteIssueInput = {
  id: string,
};

export type CreateSourceInput = {
  id?: string | null,
  sort?: string | null,
  organizationID: string,
  groupID: string,
  description?: string | null,
  year?: string | null,
  title: string,
  archived?: boolean | null,
};

export type UpdateSourceInput = {
  id: string,
  sort?: string | null,
  organizationID?: string | null,
  groupID?: string | null,
  description?: string | null,
  year?: string | null,
  title?: string | null,
  archived?: boolean | null,
};

export type DeleteSourceInput = {
  id: string,
};

export type CreateActionPlanInput = {
  id?: string | null,
  sort?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  lastModified?: string | null,
  actionPlanID?: string | null,
  title: string,
  description?: string | null,
  resources?: string | null,
  notes?: string | null,
  owner?: string | null,
  priority: Priority,
  status: Status,
  approver: string,
  dateClosed?: string | null,
  startDate?: string | null,
  dueDate: string,
  reviewDate?: string | null,
  compositeIDYear?: string | null,
  compositeID?: number | null,
  archived?: boolean | null,
  isTemplate?: boolean | null,
  groupID: string,
  effortAmount?: number | null,
  effortType?: EffortType | null,
  type?: string | null,
};

export enum EffortType {
  Hours = "Hours",
  Days = "Days",
  Weeks = "Weeks",
  Months = "Months",
}


export type ActionPlan = {
  __typename: "ActionPlan",
  res?: string | null,
  error?: string | null,
  id?: string | null,
  sort?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  lastModified?: string | null,
  actionPlanID?: string | null,
  title?: string | null,
  description?: string | null,
  resources?: string | null,
  notes?: string | null,
  owner?: string | null,
  priority?: Priority | null,
  status?: Status | null,
  approver?: string | null,
  dateClosed?: string | null,
  startDate?: string | null,
  dueDate?: string | null,
  reviewDate?: string | null,
  compositeIDYear?: string | null,
  compositeID?: number | null,
  archived?: boolean | null,
  isTemplate?: boolean | null,
  groupID?: string | null,
  actionPlanRelatedItems?: PaginatedLinks | null,
  taskChildren?: PaginatedTaskReferences | null,
  effortAmount?: number | null,
  effortType?: EffortType | null,
  actionPlanOwner?: User | null,
  actionPlanCreatedBy?: User | null,
  actionPlanModifiedBy?: User | null,
  actionPlanRASCIRoles?: PaginatedRASCIUsersOnItem | null,
  actionplanApprovers?: PaginatedItemApprover | null,
  type?: string | null,
};

export type PaginatedTaskReferences = {
  __typename: "PaginatedTaskReferences",
  items:  Array<TaskReference >,
  nextToken?: string | null,
};

export type TaskReference = {
  __typename: "TaskReference",
  id: string,
  sort: string,
  itemID: string,
  task?: Task | null,
  createdAt?: string | null,
  userRASCIRole?: UserRoles | null,
};

export type Task = {
  __typename: "Task",
  res?: string | null,
  error?: string | null,
  id?: string | null,
  sort?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  lastModified?: string | null,
  parentID?: string | null,
  organizationID?: string | null,
  groupID?: string | null,
  title?: string | null,
  description?: string | null,
  resources?: string | null,
  notes?: string | null,
  owner?: string | null,
  priority?: Priority | null,
  status?: Status | null,
  dateClosed?: string | null,
  dueDate?: string | null,
  archived?: boolean | null,
  taskOwner?: User | null,
  taskRASCIRoles?: PaginatedRASCIUsersOnItem | null,
};

export type UpdateActionPlanInput = {
  id: string,
  sort?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  lastModified?: string | null,
  actionPlanID?: string | null,
  title?: string | null,
  description?: string | null,
  resources?: string | null,
  notes?: string | null,
  owner?: string | null,
  priority?: Priority | null,
  dateClosed?: string | null,
  startDate?: string | null,
  dueDate?: string | null,
  reviewDate?: string | null,
  compositeIDYear?: string | null,
  compositeID?: number | null,
  archived?: boolean | null,
  isTemplate?: boolean | null,
  groupID?: string | null,
  effortAmount?: number | null,
  effortType?: EffortType | null,
  type?: string | null,
};

export type DeleteActionPlanInput = {
  id: string,
};

export type CreateTaskInput = {
  id?: string | null,
  sort?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  lastModified?: string | null,
  parentID: string,
  organizationID: string,
  groupID: string,
  title: string,
  description?: string | null,
  resources?: string | null,
  notes?: string | null,
  owner?: string | null,
  priority?: Priority | null,
  status?: Status | null,
  dateClosed?: string | null,
  dueDate?: string | null,
  archived?: boolean | null,
};

export type UpdateTaskInput = {
  id: string,
  sort?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  lastModified?: string | null,
  parentID?: string | null,
  organizationID?: string | null,
  groupID: string,
  title?: string | null,
  description?: string | null,
  resources?: string | null,
  notes?: string | null,
  owner?: string | null,
  priority?: Priority | null,
  status?: Status | null,
  dateClosed?: string | null,
  dueDate?: string | null,
  archived?: boolean | null,
};

export type DeleteTaskInput = {
  id: string,
};

export type CreateScenarioInput = {
  id?: string | null,
  sort?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  lastModified?: string | null,
  FAIRSummary?: string | null,
  scenarioID?: string | null,
  title: string,
  description?: string | null,
  notes?: string | null,
  assumptions?: string | null,
  creationDate?: string | null,
  lastRevision?: string | null,
  nextRevision?: string | null,
  categories?: string | null,
  owner?: string | null,
  status: Status,
  dateClosed?: string | null,
  indicator: Indicator,
  compositeIDYear?: string | null,
  compositeID?: number | null,
  archived?: boolean | null,
  isTemplate?: boolean | null,
  groupID: string,
};

export enum Indicator {
  Default = "Default",
  Fragile = "Fragile",
  Unstable = "Unstable",
}


export type Scenario = {
  __typename: "Scenario",
  res?: string | null,
  error?: string | null,
  id?: string | null,
  sort?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  lastModified?: string | null,
  FAIRSummary?: string | null,
  scenarioID?: string | null,
  title?: string | null,
  description?: string | null,
  notes?: string | null,
  assumptions?: string | null,
  creationDate?: string | null,
  lastRevision?: string | null,
  nextRevision?: string | null,
  categories?: string | null,
  owner?: string | null,
  status?: Status | null,
  dateClosed?: string | null,
  indicator?: Indicator | null,
  compositeIDYear?: string | null,
  compositeID?: number | null,
  archived?: boolean | null,
  isTemplate?: boolean | null,
  groupID?: string | null,
  scenarioRelatedItems?: PaginatedLinks | null,
  scenarioOwner?: User | null,
  scenarioCreatedBy?: User | null,
  scenarioModifiedBy?: User | null,
  scenarioRASCIRoles?: PaginatedRASCIUsersOnItem | null,
};

export type UpdateScenarioInput = {
  id: string,
  sort?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  lastModified?: string | null,
  FAIRSummary?: string | null,
  scenarioID?: string | null,
  title?: string | null,
  description?: string | null,
  notes?: string | null,
  assumptions?: string | null,
  creationDate?: string | null,
  lastRevision?: string | null,
  nextRevision?: string | null,
  categories?: string | null,
  owner?: string | null,
  status?: Status | null,
  dateClosed?: string | null,
  indicator?: Indicator | null,
  compositeIDYear?: string | null,
  compositeID?: number | null,
  archived?: boolean | null,
  isTemplate?: boolean | null,
  groupID?: string | null,
};

export type DeleteScenarioInput = {
  id: string,
};

export type CreateRiskPortfolioInput = {
  id?: string | null,
  sort?: string | null,
  groupID: string,
  title: string,
  description?: string | null,
  notes?: string | null,
  owner?: string | null,
  status?: Status | null,
  assumptions?: string | null,
  archived?: boolean | null,
  modifiedBy?: string | null,
};

export type RiskPortfolio = {
  __typename: "RiskPortfolio",
  res?: string | null,
  error?: string | null,
  id?: string | null,
  sort?: string | null,
  groupID?: string | null,
  title?: string | null,
  description?: string | null,
  notes?: string | null,
  owner?: string | null,
  status?: Status | null,
  assumptions?: string | null,
  archived?: boolean | null,
  modifiedBy?: string | null,
};

export type UpdateRiskPortfolioInput = {
  id: string,
  sort?: string | null,
  groupID?: string | null,
  title?: string | null,
  description?: string | null,
  notes?: string | null,
  owner?: string | null,
  status?: Status | null,
  assumptions?: string | null,
  archived?: boolean | null,
  modifiedBy?: string | null,
};

export type DeleteRiskPortfolioInput = {
  id: string,
};

export type CreateRiskComparisonInput = {
  id?: string | null,
  sort?: string | null,
  groupID: string,
  title: string,
  description?: string | null,
  scenarioOneID?: string | null,
  scenarioTwoID?: string | null,
  scenarioThreeID?: string | null,
  scenarioOneDescription?: string | null,
  scenarioTwoDescription?: string | null,
  scenarioThreeDescription?: string | null,
  lossThreshold?: number | null,
  archived?: boolean | null,
  modifiedBy?: string | null,
};

export type RiskComparison = {
  __typename: "RiskComparison",
  res?: string | null,
  error?: string | null,
  id?: string | null,
  sort?: string | null,
  groupID?: string | null,
  title?: string | null,
  description?: string | null,
  scenarioOneID?: string | null,
  scenarioTwoID?: string | null,
  scenarioThreeID?: string | null,
  scenarioOne?: Scenario | null,
  scenarioTwo?: Scenario | null,
  scenarioThree?: Scenario | null,
  scenarioOneDescription?: string | null,
  scenarioTwoDescription?: string | null,
  scenarioThreeDescription?: string | null,
  lossThreshold?: number | null,
  archived?: boolean | null,
  modifiedBy?: string | null,
};

export type UpdateRiskComparisonInput = {
  id: string,
  sort?: string | null,
  groupID?: string | null,
  title?: string | null,
  description?: string | null,
  scenarioOneID?: string | null,
  scenarioTwoID?: string | null,
  scenarioThreeID?: string | null,
  scenarioOneDescription?: string | null,
  scenarioTwoDescription?: string | null,
  scenarioThreeDescription?: string | null,
  lossThreshold?: number | null,
  archived?: boolean | null,
  modifiedBy?: string | null,
};

export type DeleteRiskComparisonInput = {
  id: string,
};

export type CreateRiskDomainInput = {
  id?: string | null,
  groupID: string,
  sort?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  lastModified?: string | null,
  title: string,
  description?: string | null,
  displayID?: string | null,
  parentID?: string | null,
  archived?: boolean | null,
};

export type RiskDomain = {
  __typename: "RiskDomain",
  res?: string | null,
  error?: string | null,
  id?: string | null,
  groupID?: string | null,
  sort?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  lastModified?: string | null,
  title?: string | null,
  description?: string | null,
  displayID?: string | null,
  parentID?: string | null,
  parent?: RiskDomain | null,
  archived?: boolean | null,
  riskDomainCreatedBy?: User | null,
  riskDomainModifiedBy?: User | null,
};

export type UpdateRiskDomainInput = {
  id: string,
  groupID?: string | null,
  sort?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  lastModified?: string | null,
  title?: string | null,
  description?: string | null,
  displayID?: string | null,
  parentID?: string | null,
  archived?: boolean | null,
};

export type DeleteRiskDomainInput = {
  id: string,
};

export type CreateAuditPlanningInput = {
  id?: string | null,
  title: string,
  groupID: string,
  sort?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  lastModified?: string | null,
  auditPlanID?: string | null,
  riskDomain?: string | null,
  year?: string | null,
  hours?: string | null,
  owner?: string | null,
  days?: string | null,
  status: Status,
  background?: string | null,
  objective?: string | null,
  scope?: string | null,
  methodology?: string | null,
  preliminaryAuditWork?: string | null,
  points?: string | null,
  summary?: string | null,
  auditFindings?: string | null,
  recommendations?: string | null,
  auditStartDate?: string | null,
  auditEndDate?: string | null,
  auditor?: string | null,
  totalHours?: string | null,
  archived?: boolean | null,
  isTemplate?: boolean | null,
  managementResponse?: string | null,
  viewType?: ViewType | null,
};

export enum ViewType {
  Standard = "Standard",
  Advanced = "Advanced",
}


export type AuditPlanning = {
  __typename: "AuditPlanning",
  res?: string | null,
  error?: string | null,
  id?: string | null,
  title?: string | null,
  groupID?: string | null,
  sort?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  lastModified?: string | null,
  auditPlanID?: string | null,
  riskDomain?: string | null,
  year?: string | null,
  hours?: string | null,
  owner?: string | null,
  days?: string | null,
  status?: Status | null,
  background?: string | null,
  objective?: string | null,
  scope?: string | null,
  methodology?: string | null,
  preliminaryAuditWork?: string | null,
  points?: string | null,
  summary?: string | null,
  auditFindings?: string | null,
  recommendations?: string | null,
  auditStartDate?: string | null,
  auditEndDate?: string | null,
  auditor?: string | null,
  totalHours?: string | null,
  archived?: boolean | null,
  auditPlanningRelatedItems?: PaginatedLinks | null,
  isTemplate?: boolean | null,
  auditPlanningOwner?: User | null,
  auditPlanningCreatedBy?: User | null,
  auditPlanningModifiedBy?: User | null,
  riskDomainDetails?: RiskDomain | null,
  managementResponse?: string | null,
  viewType?: ViewType | null,
};

export type UpdateAuditPlanningInput = {
  id: string,
  title?: string | null,
  groupID?: string | null,
  sort?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  lastModified?: string | null,
  auditPlanID?: string | null,
  riskDomain?: string | null,
  year?: string | null,
  hours?: string | null,
  owner?: string | null,
  days?: string | null,
  status?: Status | null,
  background?: string | null,
  objective?: string | null,
  scope?: string | null,
  methodology?: string | null,
  preliminaryAuditWork?: string | null,
  points?: string | null,
  summary?: string | null,
  auditFindings?: string | null,
  recommendations?: string | null,
  auditStartDate?: string | null,
  auditEndDate?: string | null,
  auditor?: string | null,
  totalHours?: string | null,
  archived?: boolean | null,
  isTemplate?: boolean | null,
  managementResponse?: string | null,
  viewType?: ViewType | null,
};

export type DeleteAuditPlanningInput = {
  id: string,
};

export type CreateUrlInput = {
  id?: string | null,
  sort?: string | null,
  address?: string | null,
  title: string,
  groupID: string,
};

export type Url = {
  __typename: "Url",
  res?: string | null,
  error?: string | null,
  id?: string | null,
  sort?: string | null,
  address?: string | null,
  title?: string | null,
  groupID?: string | null,
};

export type UpdateUrlInput = {
  id: string,
  sort?: string | null,
  address?: string | null,
  title?: string | null,
  groupID?: string | null,
};

export type DeleteUrlInput = {
  id: string,
};

export type CreateCategoryInput = {
  id?: string | null,
  groupID: string,
  sort?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  lastModified?: string | null,
  title: string,
  archived?: boolean | null,
};

export type Category = {
  __typename: "Category",
  res?: string | null,
  error?: string | null,
  id?: string | null,
  groupID?: string | null,
  sort?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  lastModified?: string | null,
  title?: string | null,
  archived?: boolean | null,
  categoryCreatedBy?: User | null,
  categoryModifiedBy?: User | null,
};

export type UpdateCategoryInput = {
  id: string,
  groupID?: string | null,
  sort?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  lastModified?: string | null,
  title?: string | null,
  archived?: boolean | null,
};

export type DeleteCategoryInput = {
  id: string,
};

export type CreateSupportInput = {
  id?: string | null,
  groupID: string,
  sort?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  lastModified?: string | null,
  title: string,
  pointOfContact?: string | null,
  pocEmail?: string | null,
  pocPhoneNumber?: string | null,
  notes?: string | null,
  archived?: boolean | null,
};

export type Support = {
  __typename: "Support",
  res?: string | null,
  error?: string | null,
  id?: string | null,
  groupID?: string | null,
  sort?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  lastModified?: string | null,
  title?: string | null,
  pointOfContact?: string | null,
  pocEmail?: string | null,
  pocPhoneNumber?: string | null,
  notes?: string | null,
  archived?: boolean | null,
  supportCreatedBy?: User | null,
  supportModifiedBy?: User | null,
};

export type UpdateSupportInput = {
  id: string,
  groupID?: string | null,
  sort?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  lastModified?: string | null,
  title?: string | null,
  pointOfContact?: string | null,
  pocEmail?: string | null,
  pocPhoneNumber?: string | null,
  notes?: string | null,
  archived?: boolean | null,
};

export type DeleteSupportInput = {
  id: string,
};

export type CreateLocationInput = {
  id?: string | null,
  groupID: string,
  sort?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  lastModified?: string | null,
  title: string,
  archived?: boolean | null,
};

export type Location = {
  __typename: "Location",
  res?: string | null,
  error?: string | null,
  id?: string | null,
  groupID?: string | null,
  sort?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  lastModified?: string | null,
  title?: string | null,
  archived?: boolean | null,
  locationCreatedBy?: User | null,
  locationModifiedBy?: User | null,
};

export type UpdateLocationInput = {
  id: string,
  groupID?: string | null,
  sort?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  lastModified?: string | null,
  title?: string | null,
  archived?: boolean | null,
};

export type DeleteLocationInput = {
  id: string,
};

export type CreateAssetsGroupInput = {
  id?: string | null,
  groupID: string,
  sort?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  lastModified?: string | null,
  title: string,
  archived?: boolean | null,
};

export type AssetsGroup = {
  __typename: "AssetsGroup",
  res?: string | null,
  error?: string | null,
  id?: string | null,
  groupID?: string | null,
  sort?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  lastModified?: string | null,
  title?: string | null,
  archived?: boolean | null,
  assetsGroupCreatedBy?: User | null,
  assetsGroupModifiedBy?: User | null,
};

export type UpdateAssetsGroupInput = {
  id: string,
  groupID?: string | null,
  sort?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  lastModified?: string | null,
  title?: string | null,
  archived?: boolean | null,
};

export type DeleteAssetsGroupInput = {
  id: string,
};

export type CreateThreatsInput = {
  id?: string | null,
  itemID?: string | null,
  groupID: string,
  sort?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  lastModified?: string | null,
  title: string,
  description?: string | null,
  impact?: Impact | null,
  archived?: boolean | null,
};

export enum Impact {
  Availability = "Availability",
  Confidentiality = "Confidentiality",
  Integrity = "Integrity",
}


export type Threats = {
  __typename: "Threats",
  res?: string | null,
  error?: string | null,
  id?: string | null,
  itemID?: string | null,
  groupID?: string | null,
  sort?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  lastModified?: string | null,
  title?: string | null,
  description?: string | null,
  impact?: Impact | null,
  archived?: boolean | null,
  threatsCreatedBy?: User | null,
  threatsModifiedBy?: User | null,
  threatAssessments?: PaginatedThreatAssessments | null,
};

export type PaginatedThreatAssessments = {
  __typename: "PaginatedThreatAssessments",
  items:  Array<ThreatAssessment >,
  nextToken?: string | null,
};

export type ThreatAssessment = {
  __typename: "ThreatAssessment",
  res?: string | null,
  error?: string | null,
  id?: string | null,
  sort?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  lastModified?: string | null,
  threatID?: string | null,
  assetID?: string | null,
  min?: number | null,
  mostLikely?: number | null,
  max?: number | null,
  vulnerability?: number | null,
  index?: number | null,
  archived?: boolean | null,
};

export type UpdateThreatsInput = {
  id: string,
  itemID?: string | null,
  groupID?: string | null,
  sort?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  lastModified?: string | null,
  title?: string | null,
  description?: string | null,
  impact?: Impact | null,
  archived?: boolean | null,
};

export type DeleteThreatsInput = {
  id: string,
};

export type CreateAssetsInput = {
  id?: string | null,
  itemID?: string | null,
  groupID: string,
  sort?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  lastModified?: string | null,
  title: string,
  qty?: number | null,
  assetsGroupID?: string | null,
  notesAboutControls?: string | null,
  notesAboutThreats?: string | null,
  category?: string | null,
  location?: string | null,
  support?: string | null,
  owner?: string | null,
  assetsAnalysisInput?: string | null,
  assetsAnalysisOutput?: string | null,
  notes?: string | null,
  displayID?: string | null,
  archived?: boolean | null,
  PHIRecords?: string | null,
  assetID?: string | null,
  assessmentID?: string | null,
};

export type Assets = {
  __typename: "Assets",
  res?: string | null,
  error?: string | null,
  id?: string | null,
  itemID?: string | null,
  groupID?: string | null,
  sort?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  lastModified?: string | null,
  title?: string | null,
  qty?: number | null,
  assetsGroupID?: string | null,
  notesAboutControls?: string | null,
  notesAboutThreats?: string | null,
  category?: string | null,
  assetCategory?: Category | null,
  location?: string | null,
  assetLocation?: Location | null,
  support?: string | null,
  assetSupport?: Support | null,
  owner?: string | null,
  assetsAnalysisInput?: string | null,
  assetsAnalysisOutput?: string | null,
  assetOwner?: User | null,
  assetCreatedBy?: User | null,
  assetModifiedBy?: User | null,
  notes?: string | null,
  displayID?: string | null,
  assetRelatedItems?: PaginatedLinks | null,
  archived?: boolean | null,
  threatAssessments?: PaginatedThreatAssessments | null,
  PHIRecords?: string | null,
  assetsGroupDetails?: AssetsGroup | null,
  assetID?: string | null,
  assessmentID?: string | null,
};

export type UpdateAssetsInput = {
  id: string,
  itemID?: string | null,
  groupID?: string | null,
  sort?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  lastModified?: string | null,
  title?: string | null,
  qty?: number | null,
  assetsGroupID?: string | null,
  notesAboutControls?: string | null,
  notesAboutThreats?: string | null,
  category?: string | null,
  location?: string | null,
  support?: string | null,
  owner?: string | null,
  assetsAnalysisInput?: string | null,
  assetsAnalysisOutput?: string | null,
  notes?: string | null,
  displayID?: string | null,
  archived?: boolean | null,
  PHIRecords?: string | null,
  assetID?: string | null,
  assessmentID?: string | null,
};

export type DeleteAssetsInput = {
  id: string,
};

export type CreateRiskAssessmentInput = {
  id?: string | null,
  title: string,
  groupID: string,
  sort?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  lastModified?: string | null,
  owner?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  overview?: string | null,
  assessor?: string | null,
  scope?: string | null,
  executiveSummary?: string | null,
  methodology?: string | null,
  archived?: boolean | null,
};

export type RiskAssessment = {
  __typename: "RiskAssessment",
  res?: string | null,
  error?: string | null,
  id?: string | null,
  title?: string | null,
  groupID?: string | null,
  sort?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  lastModified?: string | null,
  owner?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  overview?: string | null,
  assessor?: string | null,
  scope?: string | null,
  executiveSummary?: string | null,
  methodology?: string | null,
  archived?: boolean | null,
  riskAssessmentOwner?: User | null,
  riskAssessmentCreatedBy?: User | null,
  riskAssessmentModifiedBy?: User | null,
};

export type UpdateRiskAssessmentInput = {
  id: string,
  title?: string | null,
  groupID?: string | null,
  sort?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  lastModified?: string | null,
  owner?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  overview?: string | null,
  assessor?: string | null,
  scope?: string | null,
  executiveSummary?: string | null,
  methodology?: string | null,
  archived?: boolean | null,
};

export type DeleteRiskAssessmentInput = {
  id: string,
};

export type CloneIssueInput = {
  issueID: string,
  title?: string | null,
  isTemplate?: boolean | null,
};

export type IssueTemplateInput = {
  issueID: string,
  title?: string | null,
};

export type CreateGroupInput = {
  id?: string | null,
  title: string,
  organizationID: string,
  shortTitle: string,
  groupEmailFooter?: string | null,
};

export type ModifyGroupInput = {
  id: string,
  title?: string | null,
  shortTitle?: string | null,
  groupEmailFooter?: string | null,
};

export type SetGroupRiskThresholdInput = {
  id: string,
  riskThreshold: RiskThresholdInput,
};

export type RiskThresholdInput = {
  financial?: ThresholdInput | null,
  privacy?: ThresholdInput | null,
};

export type ThresholdInput = {
  appetitePercent?: number | null,
  appetiteAbsolute?: number | null,
  capacityPercent?: number | null,
  capacityAbsolute?: number | null,
};

export type AddUserToGroupInput = {
  groupID: string,
  userID: string,
  userRole: UserRoles,
};

export type setUserRoleInGroupInput = {
  groupID: string,
  userID: string,
  userRole: UserRoles,
};

export type RemoveUserFromGroupInput = {
  groupID: string,
  userID: string,
};

export type CreateAssessmentInput = {
  templateID: string,
  groupID: string,
  title: string,
  assessmentStartDate?: string | null,
  assessmentEndDate?: string | null,
  assessor?: string | null,
  scope?: string | null,
  hours?: string | null,
};

export type ModifyAssessmentInput = {
  id: string,
  title?: string | null,
  archived?: boolean | null,
  assessmentStartDate?: string | null,
  assessmentEndDate?: string | null,
  assessor?: string | null,
  scope?: string | null,
  hours?: string | null,
};

export type CloneAssessmentInput = {
  assessmentID: string,
  title?: string | null,
};

export type CloneActionPlanInput = {
  actionPlanID: string,
  title?: string | null,
  isTemplate?: boolean | null,
};

export type ActionPlanTemplateInput = {
  actionPlanID: string,
  title?: string | null,
};

export type CloneScenarioInput = {
  scenarioID: string,
  title?: string | null,
  isTemplate?: boolean | null,
};

export type RiskTemplateInput = {
  scenarioID: string,
  title?: string | null,
};

export type CreateUserInput = {
  username: string,
  email: string,
  organizationID: string,
  userRole?: UserRoles | null,
  groupID?: string | null,
  groupRole?: UserRoles | null,
  firstName?: string | null,
  lastName?: string | null,
};

export type ModifyUserMetaInput = {
  id: string,
  forceResetPassword?: boolean | null,
  emailPreferences?: string | null,
  notificationPreferences?: string | null,
  leftsidePreferences?: string | null,
  snippets?: string | null,
};

export type DisableUserInput = {
  id: string,
  organizationID: string,
};

export type EnableUserInput = {
  id: string,
  organizationID: string,
};

export type ModifyUserAttributesInput = {
  id: string,
  firstName?: string | null,
  lastName?: string | null,
  inactive?: boolean | null,
};

export type ModifyEmailInput = {
  id: string,
  newEmail: string,
};

export type CreateOrganizationInput = {
  name: string,
};

export type modifyOrganizationInput = {
  id: string,
  demographics?: string | null,
  organizationRelationships?: string | null,
};

export type AddUserToOrganizationInput = {
  organizationID: string,
  userID: string,
  userRole: UserRoles,
};

export type setUserRoleInOrganizationInput = {
  organizationID: string,
  userID: string,
  userRole: UserRoles,
};

export type RemoveUserFromOrganizationInput = {
  organizationID: string,
  userID: string,
};

export type ModifyNotificationInput = {
  id: string,
  isRead?: boolean | null,
};

export type UploadArtifactInput = {
  groupID: string,
  fileName: string,
  displayFileName: string,
};

export type RequestArtifactInput = {
  id: string,
};

export type ModifyArtifactInput = {
  id: string,
  displayFileName?: string | null,
  pointOfContact?: string | null,
  archived?: boolean | null,
};

export type CreateControlsInput = {
  id?: string | null,
  displayID: string,
  uuid: string,
  parentID?: string | null,
  title: string,
  description: string,
  discussion: string,
  related?: string | null,
};

export type AddScenarioToPortfolioInput = {
  portfolioID: string,
  scenarioID: string,
};

export type RemoveScenarioFromPortfolioInput = {
  portfolioID: string,
  scenarioID: string,
};

export type CloneAuditPlanInput = {
  auditPlanID: string,
  title?: string | null,
  isTemplate?: boolean | null,
};

export type AuditPlanTemplateInput = {
  auditPlanID: string,
  title?: string | null,
};

export type CreateAssessmentAssetsInput = {
  assetID?: string | null,
  assessmentID?: string | null,
};

export type ModifyAssessmentAssetsInput = {
  assessmentID?: string | null,
  assetID?: string | null,
  displayID?: string | null,
  title?: string | null,
  qty?: string | null,
  assetsGroupID?: string | null,
  notesAboutControls?: string | null,
  notesAboutThreats?: string | null,
  category?: string | null,
  location?: string | null,
  support?: string | null,
  owner?: string | null,
  assetsAnalysisInput?: string | null,
  assetsAnalysisOutput?: string | null,
  notes?: string | null,
};

export type CreateThreatAssessmentInput = {
  id?: string | null,
  assetID?: string | null,
  threatID?: string | null,
  min?: number | null,
  mostLikely?: number | null,
  max?: number | null,
  index?: number | null,
  vulnerability?: number | null,
};

export type RemoveThreatAssessmentInput = {
  id?: string | null,
};

export type CreateSurveyTemplateInput = {
  id?: string | null,
  parentID?: string | null,
  templateRoot: boolean,
  name: string,
};

export type CreateTemplateQuestionInput = {
  id?: string | null,
  parentID: string,
  title: string,
  questionText: string,
  requiresArtifacts?: string | null,
};

export type ModifyResponseInput = {
  id?: string | null,
  sort?: string | null,
  response: string,
  responseStatus: string,
  responseComments?: string | null,
  owner?: string | null,
};

export type AssessmentResponse = {
  __typename: "AssessmentResponse",
  res?: string | null,
  error?: string | null,
  id?: string | null,
  sort?: string | null,
  parentID?: string | null,
  title?: string | null,
  instructions?: string | null,
  notes?: string | null,
  responseType?: string | null,
  questionText?: string | null,
  response?: string | null,
  responseComments?: string | null,
  responseStatus?: string | null,
  artifactRequired?: boolean | null,
  artifactRequired_yes?: boolean | null,
  artifactRequired_no?: boolean | null,
  owner?: string | null,
  parent?: Assessment | null,
  groupID?: string | null,
  responseRelatedItems?: PaginatedLinks | null,
};

export type Assessment = {
  __typename: "Assessment",
  res?: string | null,
  error?: string | null,
  id?: string | null,
  sort?: string | null,
  parentID?: string | null,
  templateRoot?: boolean | null,
  title?: string | null,
  assessmentStartDate?: string | null,
  assessmentEndDate?: string | null,
  assessor?: string | null,
  scope?: string | null,
  hours?: string | null,
  templateTitle?: string | null,
  name?: string | null,
  hsrID?: string | null,
  description?: string | null,
  questionText?: string | null,
  surveyState?: SurveyTotals | null,
  size?: number | null,
  parent?: Assessment | null,
  groupID?: string | null,
  archived?: boolean | null,
  assessmentAssessor?: User | null,
};

export type SurveyTotals = {
  __typename: "SurveyTotals",
  unanswered?: number | null,
  noGapIdentified?: number | null,
  deferPendingReassignment?: number | null,
  deferPendingResponse?: number | null,
  clarify?: number | null,
  yesPendingRequestedArtifacts?: number | null,
  yesPendingArtifacts?: number | null,
  yesCompliant?: number | null,
  notApplicable?: number | null,
};

export type CreateCommentInput = {
  id?: string | null,
  message?: string | null,
};

export type LinkItemsInput = {
  id: string,
  id2: string,
};

export type UnlinkItemsInput = {
  id: string,
  id2: string,
};

export type SetUserRASCIRoleOnItemInput = {
  itemID: string,
  userID: string,
  userRASCIRole: UserRoles,
};

export type RemoveUserRASCIRoleFromItemInput = {
  itemID: string,
  userID: string,
};

export type SetUserApproverItemInput = {
  itemID: string,
  userID: string,
};

export type RemoveUserApproverFromItemInput = {
  itemID: string,
  userID: string,
};

export type SetItemStatusInput = {
  itemID: string,
  status: Status,
};

export type SetAnalysisInput = {
  id: string,
  frequency?: AnalysisFrequencyInput | null,
  magnitudes?: Array< AnalysisMagnitudeInput | null > | null,
};

export type AnalysisFrequencyInput = {
  vulnerability?: number | null,
  vulnerabilityAssumptions?: string | null,
  LEF?: PertConfig | null,
  LEFAssumptions?: string | null,
  TEF?: PertConfig | null,
  TEFAssumptions?: string | null,
  CEF?: PertConfig | null,
  CEFAssumptions?: string | null,
  POA?: number | null,
  POAAssumptions?: string | null,
  sips?: Array< number | null > | null,
  currentLayer: FrequencyLayer,
  seed?: number | null,
};

export type PertConfig = {
  min: number,
  max: number,
  mode: number,
  shape?: number | null,
};

export enum FrequencyLayer {
  LEF = "LEF",
  TEF = "TEF",
  CEF = "CEF",
}


export type AnalysisMagnitudeInput = {
  magnitudeType: string,
  primaryMagnitude: PertConfig,
  secondaryFrequency?: number | null,
  secondaryMagnitude?: PertConfig | null,
  assumptions?: string | null,
  secondaryAssumptions?: string | null,
};

export type PaginatedSource = {
  __typename: "PaginatedSource",
  items:  Array<Source >,
  nextToken?: string | null,
};

export type PaginatedAssessment = {
  __typename: "PaginatedAssessment",
  items:  Array<Assessment >,
  nextToken?: string | null,
};

export type Notification = {
  __typename: "Notification",
  res?: string | null,
  error?: string | null,
  id?: string | null,
  sort?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  lastModified?: string | null,
  itemID?: string | null,
  moduleName?: string | null,
  item?: RelatableItem | null,
  notificationType?: string | null,
  data?: string | null,
  isRead?: boolean | null,
};

export type Artifact = {
  __typename: "Artifact",
  res?: string | null,
  error?: string | null,
  id?: string | null,
  sort?: string | null,
  groupID?: string | null,
  fileName?: string | null,
  displayFileName?: string | null,
  pointOfContact?: string | null,
  artifactID?: string | null,
  archived?: boolean | null,
};

export type Controls = {
  __typename: "Controls",
  res?: string | null,
  error?: string | null,
  id?: string | null,
  sort?: string | null,
  displayID?: string | null,
  uuid?: string | null,
  parentID?: string | null,
  title?: string | null,
  description?: string | null,
  discussion?: string | null,
  relationships?: string | null,
  controlsList?: PaginatedControls | null,
  controlsRelatedItems?: PaginatedLinks | null,
};

export type PaginatedControls = {
  __typename: "PaginatedControls",
  items:  Array<Controls >,
  nextToken?: string | null,
};

export type PaginatedRiskPortfolio = {
  __typename: "PaginatedRiskPortfolio",
  items:  Array<RiskPortfolio >,
  nextToken?: string | null,
};

export type PaginatedRiskComparison = {
  __typename: "PaginatedRiskComparison",
  items:  Array<RiskComparison >,
  nextToken?: string | null,
};

export type PaginatedRiskDomain = {
  __typename: "PaginatedRiskDomain",
  items:  Array<RiskDomain >,
  nextToken?: string | null,
};

export type PaginatedUrl = {
  __typename: "PaginatedUrl",
  items:  Array<Url >,
  nextToken?: string | null,
};

export type PaginatedCategory = {
  __typename: "PaginatedCategory",
  items:  Array<Category >,
  nextToken?: string | null,
};

export type PaginatedSupport = {
  __typename: "PaginatedSupport",
  items:  Array<Support >,
  nextToken?: string | null,
};

export type PaginatedLocation = {
  __typename: "PaginatedLocation",
  items:  Array<Location >,
  nextToken?: string | null,
};

export type PaginatedAssetsGroup = {
  __typename: "PaginatedAssetsGroup",
  items:  Array<AssetsGroup >,
  nextToken?: string | null,
};

export type PaginatedThreats = {
  __typename: "PaginatedThreats",
  items:  Array<Threats >,
  nextToken?: string | null,
};

export type PaginatedRiskAssessment = {
  __typename: "PaginatedRiskAssessment",
  items:  Array<RiskAssessment >,
  nextToken?: string | null,
};

export type SurveyTemplate = {
  __typename: "SurveyTemplate",
  res?: string | null,
  error?: string | null,
  id?: string | null,
  sort?: string | null,
  parentID?: string | null,
  templateRoot?: boolean | null,
  title?: string | null,
  locked?: number | null,
};

export type TemplateQuestion = {
  __typename: "TemplateQuestion",
  res?: string | null,
  error?: string | null,
  id?: string | null,
  sort?: string | null,
  parentID?: string | null,
  name?: string | null,
  questionText?: string | null,
  requiresArtifacts?: string | null,
};

export type PaginatedTemplateQuestion = {
  __typename: "PaginatedTemplateQuestion",
  items:  Array<TemplateQuestion >,
  nextToken?: string | null,
};

export type PaginatedIssueReferences = {
  __typename: "PaginatedIssueReferences",
  items:  Array<IssueReference >,
  nextToken?: string | null,
};

export type IssueReference = {
  __typename: "IssueReference",
  id: string,
  sort: string,
  itemID: string,
  issue?: Issue | null,
  createdAt?: string | null,
  userRASCIRole?: UserRoles | null,
};

export type PaginatedIssueChanges = {
  __typename: "PaginatedIssueChanges",
  items:  Array<IssueChanges >,
  nextToken?: string | null,
};

export type IssueChanges = {
  __typename: "IssueChanges",
  id: string,
  sort: string,
  issueID: string,
  currentIssue?: Issue | null,
  changeType?: string | null,
  requestingUser?: string | null,
  createdAt?: string | null,
  issueChanges?: Issue | null,
};

export type PaginatedScenarioChanges = {
  __typename: "PaginatedScenarioChanges",
  items:  Array<ScenarioChanges >,
  nextToken?: string | null,
};

export type ScenarioChanges = {
  __typename: "ScenarioChanges",
  id: string,
  sort: string,
  scenarioID: string,
  currentScenario?: Scenario | null,
  changeType?: string | null,
  requestingUser?: string | null,
  createdAt?: string | null,
  scenarioChanges?: Scenario | null,
};

export type PaginatedActionPlanChanges = {
  __typename: "PaginatedActionPlanChanges",
  items:  Array<ActionPlanChanges >,
  nextToken?: string | null,
};

export type ActionPlanChanges = {
  __typename: "ActionPlanChanges",
  id: string,
  sort: string,
  actionplanID: string,
  currentActionPlan?: ActionPlan | null,
  changeType?: string | null,
  requestingUser?: string | null,
  createdAt?: string | null,
  actionplanChanges?: ActionPlan | null,
};

export type PaginatedIssuesTriagedPerMonthInGroup = {
  __typename: "PaginatedIssuesTriagedPerMonthInGroup",
  items:  Array<IssuesTriagedPerMonthInGroup >,
  nextToken?: string | null,
};

export type IssuesTriagedPerMonthInGroup = {
  __typename: "IssuesTriagedPerMonthInGroup",
  id: string,
  sort: string,
  total?: number | null,
  year?: number | null,
  month?: string | null,
  firstDate?: string | null,
};

export type PaginatedIssuesCreatedPerMonth = {
  __typename: "PaginatedIssuesCreatedPerMonth",
  items:  Array<IssuesCreatedPerMonth >,
  nextToken?: string | null,
};

export type IssuesCreatedPerMonth = {
  __typename: "IssuesCreatedPerMonth",
  id: string,
  sort: string,
  total?: number | null,
  year?: number | null,
  month?: string | null,
  firstDate?: string | null,
};

export type PaginatedIssuesCreatedPerMonthInGroup = {
  __typename: "PaginatedIssuesCreatedPerMonthInGroup",
  items:  Array<IssuesCreatedPerMonthInGroup >,
  nextToken?: string | null,
};

export type IssuesCreatedPerMonthInGroup = {
  __typename: "IssuesCreatedPerMonthInGroup",
  id: string,
  sort: string,
  total?: number | null,
  year?: number | null,
  month?: string | null,
  firstDate?: string | null,
};

export type PaginatedGroupMembership = {
  __typename: "PaginatedGroupMembership",
  items:  Array<GroupMembership >,
  nextToken?: string | null,
};

export type GroupMembership = {
  __typename: "GroupMembership",
  id?: string | null,
  sort?: string | null,
  userID?: string | null,
  groupID?: string | null,
  userRole?: UserRoles | null,
  group?: Group | null,
};

export type PaginatedGroupReferences = {
  __typename: "PaginatedGroupReferences",
  items:  Array<GroupReference >,
  nextToken?: string | null,
};

export type GroupReference = {
  __typename: "GroupReference",
  id: string,
  sort: string,
  itemID: string,
  group?: Group | null,
  createdAt?: string | null,
};

export type PaginatedAssessmentResponse = {
  __typename: "PaginatedAssessmentResponse",
  items:  Array<AssessmentResponse >,
  nextToken?: string | null,
};

export type PaginatedActionPlanReferences = {
  __typename: "PaginatedActionPlanReferences",
  items:  Array<ActionPlanReference >,
  nextToken?: string | null,
};

export type ActionPlanReference = {
  __typename: "ActionPlanReference",
  id: string,
  sort: string,
  itemID: string,
  actionplan?: ActionPlan | null,
  createdAt?: string | null,
  userRASCIRole?: UserRoles | null,
};

export type PaginatedActionPlansUpdatedPerMonth = {
  __typename: "PaginatedActionPlansUpdatedPerMonth",
  items:  Array<ActionPlansUpdatedPerMonth >,
  nextToken?: string | null,
};

export type ActionPlansUpdatedPerMonth = {
  __typename: "ActionPlansUpdatedPerMonth",
  id: string,
  sort: string,
  total?: number | null,
  year?: number | null,
  month?: string | null,
  firstDate?: string | null,
};

export type PaginatedActionPlansCreatedPerMonth = {
  __typename: "PaginatedActionPlansCreatedPerMonth",
  items:  Array<ActionPlansCreatedPerMonth >,
  nextToken?: string | null,
};

export type ActionPlansCreatedPerMonth = {
  __typename: "ActionPlansCreatedPerMonth",
  id: string,
  sort: string,
  total?: number | null,
  year?: number | null,
  month?: string | null,
  firstDate?: string | null,
};

export type PaginatedScenarioReferences = {
  __typename: "PaginatedScenarioReferences",
  items:  Array<ScenarioReference >,
  nextToken?: string | null,
};

export type ScenarioReference = {
  __typename: "ScenarioReference",
  id: string,
  sort: string,
  itemID: string,
  scenario?: Scenario | null,
  createdAt?: string | null,
  userRASCIRole?: UserRoles | null,
};

export type PaginatedRiskAnalysisCompletedPerMonthInGroup = {
  __typename: "PaginatedRiskAnalysisCompletedPerMonthInGroup",
  items:  Array<RiskAnalysisCompletedPerMonthInGroup >,
  nextToken?: string | null,
};

export type RiskAnalysisCompletedPerMonthInGroup = {
  __typename: "RiskAnalysisCompletedPerMonthInGroup",
  id: string,
  sort: string,
  total?: number | null,
  year?: number | null,
  month?: string | null,
  firstDate?: string | null,
};

export type UserMeta = {
  __typename: "UserMeta",
  id?: string | null,
  sort?: string | null,
  forceResetPassword?: boolean | null,
  emailPreferences?: string | null,
  notificationPreferences?: string | null,
  leftsidePreferences?: string | null,
  snippets?: string | null,
};

export type DailyActiveUsersFilterInput = {
  id?: FilterIDInput | null,
  totalActiveUsers?: FilterIntInput | null,
  date?: FilterStringInput | null,
  and?: Array< DailyActiveUsersFilterInput | null > | null,
  or?: Array< DailyActiveUsersFilterInput | null > | null,
  not?: DailyActiveUsersFilterInput | null,
};

export type FilterIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: FilterAttributeTypes | null,
  size?: FilterSizeInput | null,
};

export enum FilterAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type FilterSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type FilterIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: FilterAttributeTypes | null,
};

export type FilterStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: FilterAttributeTypes | null,
  size?: FilterSizeInput | null,
};

export type PaginatedDailyActiveUsers = {
  __typename: "PaginatedDailyActiveUsers",
  items:  Array<DailyActiveUsers >,
  nextToken?: string | null,
};

export type DailyActiveUsers = {
  __typename: "DailyActiveUsers",
  id?: string | null,
  sort?: string | null,
  totalActiveUsers?: number | null,
  users?: Array< string | null > | null,
  date?: string | null,
};

export type PaginatedUsersInGroup = {
  __typename: "PaginatedUsersInGroup",
  items:  Array<UsersInGroup >,
  nextToken?: string | null,
};

export type UsersInGroup = {
  __typename: "UsersInGroup",
  id?: string | null,
  sort?: string | null,
  userID?: string | null,
  userInfo?: UserInfo | null,
  groupID?: string | null,
  group?: Group | null,
  user?: User | null,
  userRole?: UserRoles | null,
};

export type PaginatedUsersInOrganization = {
  __typename: "PaginatedUsersInOrganization",
  items:  Array<UsersInOrganization >,
  nextToken?: string | null,
};

export type UsersInOrganization = {
  __typename: "UsersInOrganization",
  id?: string | null,
  sort?: string | null,
  userID?: string | null,
  userInfo?: UserInfo | null,
  organizationID?: string | null,
  organization?: Organization | null,
  user?: User | null,
  userRole?: UserRoles | null,
};

export type PaginatedOrganizationMembership = {
  __typename: "PaginatedOrganizationMembership",
  items:  Array<OrganizationMembership >,
  nextToken?: string | null,
};

export type OrganizationMembership = {
  __typename: "OrganizationMembership",
  id?: string | null,
  sort?: string | null,
  userID?: string | null,
  organizationID?: string | null,
  organization?: Organization | null,
  user?: User | null,
  userRole?: UserRoles | null,
};

export enum FilterSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type PaginatedNotificationReferences = {
  __typename: "PaginatedNotificationReferences",
  items:  Array<NotificationReference >,
  nextToken?: string | null,
};

export type NotificationReference = {
  __typename: "NotificationReference",
  id: string,
  sort: string,
  itemID: string,
  notification?: Notification | null,
  createdAt?: string | null,
};

export type PaginatedArtifact = {
  __typename: "PaginatedArtifact",
  items:  Array<Artifact >,
  nextToken?: string | null,
};

export type PaginatedScenarioInPortfolio = {
  __typename: "PaginatedScenarioInPortfolio",
  items:  Array<ScenarioInPortfolio >,
  nextToken?: string | null,
};

export type ScenarioInPortfolio = {
  __typename: "ScenarioInPortfolio",
  id: string,
  sort: string,
  scenarioID?: string | null,
  portfolioID?: string | null,
  scenario?: Scenario | null,
  portfolio?: RiskPortfolio | null,
};

export type PaginatedAuditPlanningReferences = {
  __typename: "PaginatedAuditPlanningReferences",
  items:  Array<AuditPlanningReference >,
  nextToken?: string | null,
};

export type AuditPlanningReference = {
  __typename: "AuditPlanningReference",
  id: string,
  sort: string,
  itemID: string,
  auditPlanning?: AuditPlanning | null,
  createdAt?: string | null,
  userRASCIRole?: UserRoles | null,
};

export type PaginatedAssetsReferences = {
  __typename: "PaginatedAssetsReferences",
  items:  Array<AssetsReference >,
  nextToken?: string | null,
};

export type AssetsReference = {
  __typename: "AssetsReference",
  id: string,
  sort: string,
  itemID: string,
  assets?: Assets | null,
  createdAt?: string | null,
  userRASCIRole?: UserRoles | null,
};

export type PaginatedAssets = {
  __typename: "PaginatedAssets",
  items:  Array<Assets >,
  nextToken?: string | null,
};

export type PaginatedSurveyTemplate = {
  __typename: "PaginatedSurveyTemplate",
  items:  Array<SurveyTemplate >,
  nextToken?: string | null,
};

export type PaginatedTemplateChildren = {
  __typename: "PaginatedTemplateChildren",
  items:  Array<TemplateChildren >,
  nextToken?: string | null,
};

export type TemplateChildren = {
  __typename: "TemplateChildren",
  id: string,
  sort: string,
  title?: string | null,
  type?: string | null,
};

export type PaginatedIssueGroupSummary = {
  __typename: "PaginatedIssueGroupSummary",
  items:  Array<IssueGroupSummary >,
};

export type IssueGroupSummary = {
  __typename: "IssueGroupSummary",
  groupID: string,
  status?: Status | null,
  priority?: Priority | null,
  severity?: Severity | null,
  count?: number | null,
};

export type IssueFilterInput = {
  id?: FilterIDInput | null,
  sort?: FilterIDInput | null,
  createdAt?: FilterStringInput | null,
  createdBy?: FilterStringInput | null,
  modifiedBy?: FilterStringInput | null,
  verifiedBy?: FilterStringInput | null,
  lastModified?: FilterStringInput | null,
  issueID?: FilterIDInput | null,
  title?: FilterStringInput | null,
  description?: FilterStringInput | null,
  assumptions?: FilterStringInput | null,
  notes?: FilterStringInput | null,
  reportedBy?: FilterStringInput | null,
  reportedOn?: FilterStringInput | null,
  nextReview?: FilterStringInput | null,
  owner?: FilterStringInput | null,
  priority?: FilterStringInput | null,
  status?: FilterStringInput | null,
  approver?: FilterIDInput | null,
  dateClosed?: FilterStringInput | null,
  severity?: FilterStringInput | null,
  compositeIDYear?: FilterStringInput | null,
  compositeID?: FilterIntInput | null,
  archived?: FilterBooleanInput | null,
  isTemplate?: FilterBooleanInput | null,
  issueSourceId?: FilterIDInput | null,
  groupID?: FilterIDInput | null,
  acceptanceCriteria?: FilterStringInput | null,
  requiresScenario?: FilterBooleanInput | null,
  requiresActionplan?: FilterBooleanInput | null,
  and?: Array< IssueFilterInput | null > | null,
  or?: Array< IssueFilterInput | null > | null,
  not?: IssueFilterInput | null,
};

export type FilterBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: FilterAttributeTypes | null,
};

export type IssueSortInput = {
  id?: FilterSortDirection | null,
  sort?: FilterSortDirection | null,
  createdAt?: FilterSortDirection | null,
  createdBy?: FilterSortDirection | null,
  modifiedBy?: FilterSortDirection | null,
  verifiedBy?: FilterSortDirection | null,
  lastModified?: FilterSortDirection | null,
  issueID?: FilterSortDirection | null,
  title?: FilterSortDirection | null,
  description?: FilterSortDirection | null,
  assumptions?: FilterSortDirection | null,
  notes?: FilterSortDirection | null,
  reportedBy?: FilterSortDirection | null,
  reportedOn?: FilterSortDirection | null,
  nextReview?: FilterSortDirection | null,
  owner?: FilterSortDirection | null,
  priority?: FilterSortDirection | null,
  status?: FilterSortDirection | null,
  approver?: FilterSortDirection | null,
  dateClosed?: FilterSortDirection | null,
  severity?: FilterSortDirection | null,
  compositeIDYear?: FilterSortDirection | null,
  compositeID?: FilterSortDirection | null,
  archived?: FilterSortDirection | null,
  isTemplate?: FilterSortDirection | null,
  issueSourceId?: FilterSortDirection | null,
  groupID?: FilterSortDirection | null,
  acceptanceCriteria?: FilterSortDirection | null,
  requiresScenario?: FilterSortDirection | null,
  requiresActionplan?: FilterSortDirection | null,
};

export type PaginatedIssue = {
  __typename: "PaginatedIssue",
  items:  Array<Issue >,
  nextToken?: string | null,
};

export type PaginatedIssueOrganizationSummary = {
  __typename: "PaginatedIssueOrganizationSummary",
  items:  Array<IssueOrganizationSummary >,
};

export type IssueOrganizationSummary = {
  __typename: "IssueOrganizationSummary",
  organizationID: string,
  status?: Status | null,
  priority?: Priority | null,
  severity?: Severity | null,
  count?: number | null,
};

export type ActionPlanFilterInput = {
  id?: FilterIDInput | null,
  sort?: FilterIDInput | null,
  createdAt?: FilterStringInput | null,
  createdBy?: FilterStringInput | null,
  modifiedBy?: FilterStringInput | null,
  lastModified?: FilterStringInput | null,
  actionPlanID?: FilterIDInput | null,
  title?: FilterStringInput | null,
  description?: FilterStringInput | null,
  resources?: FilterStringInput | null,
  notes?: FilterStringInput | null,
  owner?: FilterStringInput | null,
  priority?: FilterStringInput | null,
  status?: FilterStringInput | null,
  approver?: FilterIDInput | null,
  dateClosed?: FilterStringInput | null,
  startDate?: FilterStringInput | null,
  dueDate?: FilterStringInput | null,
  reviewDate?: FilterStringInput | null,
  compositeIDYear?: FilterStringInput | null,
  compositeID?: FilterIntInput | null,
  archived?: FilterBooleanInput | null,
  isTemplate?: FilterBooleanInput | null,
  groupID?: FilterIDInput | null,
  effortAmount?: FilterIntInput | null,
  effortType?: FilterStringInput | null,
  type?: FilterStringInput | null,
  and?: Array< ActionPlanFilterInput | null > | null,
  or?: Array< ActionPlanFilterInput | null > | null,
  not?: ActionPlanFilterInput | null,
};

export type ActionPlanSortInput = {
  id?: FilterSortDirection | null,
  sort?: FilterSortDirection | null,
  createdAt?: FilterSortDirection | null,
  createdBy?: FilterSortDirection | null,
  modifiedBy?: FilterSortDirection | null,
  lastModified?: FilterSortDirection | null,
  actionPlanID?: FilterSortDirection | null,
  title?: FilterSortDirection | null,
  description?: FilterSortDirection | null,
  resources?: FilterSortDirection | null,
  notes?: FilterSortDirection | null,
  owner?: FilterSortDirection | null,
  priority?: FilterSortDirection | null,
  status?: FilterSortDirection | null,
  approver?: FilterSortDirection | null,
  dateClosed?: FilterSortDirection | null,
  startDate?: FilterSortDirection | null,
  dueDate?: FilterSortDirection | null,
  reviewDate?: FilterSortDirection | null,
  compositeIDYear?: FilterSortDirection | null,
  compositeID?: FilterSortDirection | null,
  archived?: FilterSortDirection | null,
  isTemplate?: FilterSortDirection | null,
  groupID?: FilterSortDirection | null,
  effortAmount?: FilterSortDirection | null,
  effortType?: FilterSortDirection | null,
  type?: FilterSortDirection | null,
};

export type PaginatedActionPlan = {
  __typename: "PaginatedActionPlan",
  items:  Array<ActionPlan >,
  nextToken?: string | null,
};

export type PaginatedActionPlanGroupSummary = {
  __typename: "PaginatedActionPlanGroupSummary",
  items:  Array<ActionPlanGroupSummary >,
};

export type ActionPlanGroupSummary = {
  __typename: "ActionPlanGroupSummary",
  groupID: string,
  status?: Status | null,
  priority?: Priority | null,
  duedate?: ActionPlanDueDates | null,
  count?: number | null,
};

export enum ActionPlanDueDates {
  not_set = "not_set",
  this_month = "this_month",
  next_month = "next_month",
  past_due = "past_due",
}


export type ScenarioFilterInput = {
  id?: FilterIDInput | null,
  sort?: FilterIDInput | null,
  createdAt?: FilterStringInput | null,
  createdBy?: FilterStringInput | null,
  modifiedBy?: FilterStringInput | null,
  lastModified?: FilterStringInput | null,
  FAIRSummary?: FilterStringInput | null,
  scenarioID?: FilterIDInput | null,
  title?: FilterStringInput | null,
  description?: FilterStringInput | null,
  notes?: FilterStringInput | null,
  assumptions?: FilterStringInput | null,
  creationDate?: FilterStringInput | null,
  lastRevision?: FilterStringInput | null,
  nextRevision?: FilterStringInput | null,
  categories?: FilterStringInput | null,
  owner?: FilterStringInput | null,
  status?: FilterStringInput | null,
  dateClosed?: FilterStringInput | null,
  indicator?: FilterStringInput | null,
  compositeIDYear?: FilterStringInput | null,
  compositeID?: FilterIntInput | null,
  archived?: FilterBooleanInput | null,
  isTemplate?: FilterBooleanInput | null,
  groupID?: FilterIDInput | null,
  and?: Array< ScenarioFilterInput | null > | null,
  or?: Array< ScenarioFilterInput | null > | null,
  not?: ScenarioFilterInput | null,
};

export type ScenarioSortInput = {
  id?: FilterSortDirection | null,
  sort?: FilterSortDirection | null,
  createdAt?: FilterSortDirection | null,
  createdBy?: FilterSortDirection | null,
  modifiedBy?: FilterSortDirection | null,
  lastModified?: FilterSortDirection | null,
  FAIRSummary?: FilterSortDirection | null,
  scenarioID?: FilterSortDirection | null,
  title?: FilterSortDirection | null,
  description?: FilterSortDirection | null,
  notes?: FilterSortDirection | null,
  assumptions?: FilterSortDirection | null,
  creationDate?: FilterSortDirection | null,
  lastRevision?: FilterSortDirection | null,
  nextRevision?: FilterSortDirection | null,
  categories?: FilterSortDirection | null,
  owner?: FilterSortDirection | null,
  status?: FilterSortDirection | null,
  dateClosed?: FilterSortDirection | null,
  indicator?: FilterSortDirection | null,
  compositeIDYear?: FilterSortDirection | null,
  compositeID?: FilterSortDirection | null,
  archived?: FilterSortDirection | null,
  isTemplate?: FilterSortDirection | null,
  groupID?: FilterSortDirection | null,
};

export type PaginatedScenario = {
  __typename: "PaginatedScenario",
  items:  Array<Scenario >,
  nextToken?: string | null,
};

export type Analysis = {
  __typename: "Analysis",
  id: string,
  sort: string,
  name?: string | null,
  owner?: string | null,
  summary?: SummaryStats | null,
  frequency?: FrequencyData | null,
  magnitudes?: PaginatedMagnitudeData | null,
  lastRun?: AnalysisRun | null,
};

export type SummaryStats = {
  __typename: "SummaryStats",
  id?: string | null,
  results?: string | null,
  ALE25?: number | null,
  ALE50?: number | null,
  ALE75?: number | null,
  ALE95?: number | null,
  ALEs?: Array< number | null > | null,
  max?: number | null,
  mean?: number | null,
  median?: number | null,
  min?: number | null,
  mode?: number | null,
};

export type FrequencyData = {
  __typename: "FrequencyData",
  CEF?: PertInfo | null,
  CEFAssumptions?: string | null,
  LEF?: PertInfo | null,
  LEFAssumptions?: string | null,
  TEF?: PertInfo | null,
  TEFAssumptions?: string | null,
  POA?: number | null,
  POAAssumptions?: string | null,
  vulnerability?: number | null,
  vulnerabilityAssumptions?: string | null,
  currentLayer?: string | null,
  seed?: number | null,
};

export type PertInfo = {
  __typename: "PertInfo",
  min?: number | null,
  max?: number | null,
  mode?: number | null,
  shape?: number | null,
};

export type PaginatedMagnitudeData = {
  __typename: "PaginatedMagnitudeData",
  items:  Array<MagnitudeData >,
  nextToken?: string | null,
};

export type MagnitudeData = {
  __typename: "MagnitudeData",
  magnitudeType?: string | null,
  assumptions?: string | null,
  primaryMagnitude?: PertInfo | null,
  secondaryFrequency?: number | null,
  secondaryMagnitude?: PertInfo | null,
  secondaryAssumptions?: string | null,
};

export type AnalysisRun = {
  __typename: "AnalysisRun",
  settings?: string | null,
  results?: string | null,
  frequencyResults?: string | null,
  seed?: number | null,
};

export type PaginatedComments = {
  __typename: "PaginatedComments",
  items:  Array<Comment >,
  nextToken?: string | null,
};

export type Comment = {
  __typename: "Comment",
  id: string,
  sort: string,
  owner?: string | null,
  message: string,
  change?: boolean | null,
};

export type CreateIssueMutationVariables = {
  input: Array< CreateIssueInput >,
};

export type CreateIssueMutation = {
  CreateIssue?:  Array< {
    __typename: "Issue",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    sort?: string | null,
    createdAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    verifiedBy?: string | null,
    lastModified?: string | null,
    issueID?: string | null,
    title?: string | null,
    description?: string | null,
    assumptions?: string | null,
    notes?: string | null,
    reportedBy?: string | null,
    reportedOn?: string | null,
    nextReview?: string | null,
    owner?: string | null,
    priority?: Priority | null,
    status?: Status | null,
    approver?: string | null,
    dateClosed?: string | null,
    severity?: Severity | null,
    compositeIDYear?: string | null,
    compositeID?: number | null,
    archived?: boolean | null,
    isTemplate?: boolean | null,
    issueSourceId?: string | null,
    groupID?: string | null,
    issueRelatedItems?:  {
      __typename: "PaginatedLinks",
      items:  Array< {
        __typename: "Link",
        id: string,
        sort: string,
        target?: string | null,
        targetType?: LinkTarget | null,
        item?:  {
          __typename: "RelatableItem",
          id?: string | null,
          sort?: string | null,
          groupID?: string | null,
          owner?: string | null,
          uuid?: string | null,
          title?: string | null,
          dateClosed?: string | null,
          dueDate?: string | null,
          status?: Status | null,
          priority?: Priority | null,
          severity?: Severity | null,
          responseStatus?: string | null,
          archived?: boolean | null,
          createdAt?: string | null,
          lastModified?: string | null,
          compositeIDYear?: string | null,
          compositeID?: number | null,
          displayID?: string | null,
          relatedItemsOwner?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
          relatedItemsGroup?:  {
            __typename: "Group",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            organizationID?: string | null,
            title?: string | null,
            shortTitle?: string | null,
            riskThreshold?:  {
              __typename: "RiskThreshold",
              financial?:  {
                __typename: "Threshold",
                appetitePercent?: number | null,
                appetiteAbsolute?: number | null,
                capacityPercent?: number | null,
                capacityAbsolute?: number | null,
              } | null,
              privacy?:  {
                __typename: "Threshold",
                appetitePercent?: number | null,
                appetiteAbsolute?: number | null,
                capacityPercent?: number | null,
                capacityAbsolute?: number | null,
              } | null,
            } | null,
            organization?:  {
              __typename: "Organization",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              name?: string | null,
              organizationRelationships?: string | null,
              demographics?: string | null,
            } | null,
            groupEmailFooter?: string | null,
          } | null,
          fileName?: string | null,
          address?: string | null,
        } | null,
      } >,
      nextToken?: string | null,
    } | null,
    acceptanceCriteria?: string | null,
    requiresScenario?: boolean | null,
    requiresActionplan?: boolean | null,
    issueOwner?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    issueCreatedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    issueModifiedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    issueVerifiedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    issueSource?:  {
      __typename: "Source",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      organizationID?: string | null,
      groupID?: string | null,
      description?: string | null,
      year?: string | null,
      title?: string | null,
      archived?: boolean | null,
    } | null,
    issueRASCIRoles?:  {
      __typename: "PaginatedRASCIUsersOnItem",
      items:  Array< {
        __typename: "RASCIUserOnItem",
        id?: string | null,
        sort?: string | null,
        userID?: string | null,
        userInfo?:  {
          __typename: "UserInfo",
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
        } | null,
        user?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        userRASCIRole?: UserRoles | null,
      } >,
      nextToken?: string | null,
    } | null,
    issueApprovers?:  {
      __typename: "PaginatedItemApprover",
      items:  Array< {
        __typename: "ItemApprover",
        id?: string | null,
        sort?: string | null,
        userID?: string | null,
        userInfo?:  {
          __typename: "UserInfo",
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
        } | null,
        user?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
      } >,
      nextToken?: string | null,
    } | null,
  } | null > | null,
};

export type ModifyIssueMutationVariables = {
  input: Array< UpdateIssueInput >,
};

export type ModifyIssueMutation = {
  ModifyIssue?:  Array< {
    __typename: "Issue",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    sort?: string | null,
    createdAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    verifiedBy?: string | null,
    lastModified?: string | null,
    issueID?: string | null,
    title?: string | null,
    description?: string | null,
    assumptions?: string | null,
    notes?: string | null,
    reportedBy?: string | null,
    reportedOn?: string | null,
    nextReview?: string | null,
    owner?: string | null,
    priority?: Priority | null,
    status?: Status | null,
    approver?: string | null,
    dateClosed?: string | null,
    severity?: Severity | null,
    compositeIDYear?: string | null,
    compositeID?: number | null,
    archived?: boolean | null,
    isTemplate?: boolean | null,
    issueSourceId?: string | null,
    groupID?: string | null,
    issueRelatedItems?:  {
      __typename: "PaginatedLinks",
      items:  Array< {
        __typename: "Link",
        id: string,
        sort: string,
        target?: string | null,
        targetType?: LinkTarget | null,
        item?:  {
          __typename: "RelatableItem",
          id?: string | null,
          sort?: string | null,
          groupID?: string | null,
          owner?: string | null,
          uuid?: string | null,
          title?: string | null,
          dateClosed?: string | null,
          dueDate?: string | null,
          status?: Status | null,
          priority?: Priority | null,
          severity?: Severity | null,
          responseStatus?: string | null,
          archived?: boolean | null,
          createdAt?: string | null,
          lastModified?: string | null,
          compositeIDYear?: string | null,
          compositeID?: number | null,
          displayID?: string | null,
          relatedItemsOwner?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
          relatedItemsGroup?:  {
            __typename: "Group",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            organizationID?: string | null,
            title?: string | null,
            shortTitle?: string | null,
            riskThreshold?:  {
              __typename: "RiskThreshold",
              financial?:  {
                __typename: "Threshold",
                appetitePercent?: number | null,
                appetiteAbsolute?: number | null,
                capacityPercent?: number | null,
                capacityAbsolute?: number | null,
              } | null,
              privacy?:  {
                __typename: "Threshold",
                appetitePercent?: number | null,
                appetiteAbsolute?: number | null,
                capacityPercent?: number | null,
                capacityAbsolute?: number | null,
              } | null,
            } | null,
            organization?:  {
              __typename: "Organization",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              name?: string | null,
              organizationRelationships?: string | null,
              demographics?: string | null,
            } | null,
            groupEmailFooter?: string | null,
          } | null,
          fileName?: string | null,
          address?: string | null,
        } | null,
      } >,
      nextToken?: string | null,
    } | null,
    acceptanceCriteria?: string | null,
    requiresScenario?: boolean | null,
    requiresActionplan?: boolean | null,
    issueOwner?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    issueCreatedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    issueModifiedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    issueVerifiedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    issueSource?:  {
      __typename: "Source",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      organizationID?: string | null,
      groupID?: string | null,
      description?: string | null,
      year?: string | null,
      title?: string | null,
      archived?: boolean | null,
    } | null,
    issueRASCIRoles?:  {
      __typename: "PaginatedRASCIUsersOnItem",
      items:  Array< {
        __typename: "RASCIUserOnItem",
        id?: string | null,
        sort?: string | null,
        userID?: string | null,
        userInfo?:  {
          __typename: "UserInfo",
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
        } | null,
        user?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        userRASCIRole?: UserRoles | null,
      } >,
      nextToken?: string | null,
    } | null,
    issueApprovers?:  {
      __typename: "PaginatedItemApprover",
      items:  Array< {
        __typename: "ItemApprover",
        id?: string | null,
        sort?: string | null,
        userID?: string | null,
        userInfo?:  {
          __typename: "UserInfo",
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
        } | null,
        user?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
      } >,
      nextToken?: string | null,
    } | null,
  } | null > | null,
};

export type DeleteIssueMutationVariables = {
  input: Array< DeleteIssueInput >,
};

export type DeleteIssueMutation = {
  DeleteIssue?:  Array< {
    __typename: "Issue",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    sort?: string | null,
    createdAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    verifiedBy?: string | null,
    lastModified?: string | null,
    issueID?: string | null,
    title?: string | null,
    description?: string | null,
    assumptions?: string | null,
    notes?: string | null,
    reportedBy?: string | null,
    reportedOn?: string | null,
    nextReview?: string | null,
    owner?: string | null,
    priority?: Priority | null,
    status?: Status | null,
    approver?: string | null,
    dateClosed?: string | null,
    severity?: Severity | null,
    compositeIDYear?: string | null,
    compositeID?: number | null,
    archived?: boolean | null,
    isTemplate?: boolean | null,
    issueSourceId?: string | null,
    groupID?: string | null,
    issueRelatedItems?:  {
      __typename: "PaginatedLinks",
      items:  Array< {
        __typename: "Link",
        id: string,
        sort: string,
        target?: string | null,
        targetType?: LinkTarget | null,
        item?:  {
          __typename: "RelatableItem",
          id?: string | null,
          sort?: string | null,
          groupID?: string | null,
          owner?: string | null,
          uuid?: string | null,
          title?: string | null,
          dateClosed?: string | null,
          dueDate?: string | null,
          status?: Status | null,
          priority?: Priority | null,
          severity?: Severity | null,
          responseStatus?: string | null,
          archived?: boolean | null,
          createdAt?: string | null,
          lastModified?: string | null,
          compositeIDYear?: string | null,
          compositeID?: number | null,
          displayID?: string | null,
          relatedItemsOwner?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
          relatedItemsGroup?:  {
            __typename: "Group",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            organizationID?: string | null,
            title?: string | null,
            shortTitle?: string | null,
            riskThreshold?:  {
              __typename: "RiskThreshold",
              financial?:  {
                __typename: "Threshold",
                appetitePercent?: number | null,
                appetiteAbsolute?: number | null,
                capacityPercent?: number | null,
                capacityAbsolute?: number | null,
              } | null,
              privacy?:  {
                __typename: "Threshold",
                appetitePercent?: number | null,
                appetiteAbsolute?: number | null,
                capacityPercent?: number | null,
                capacityAbsolute?: number | null,
              } | null,
            } | null,
            organization?:  {
              __typename: "Organization",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              name?: string | null,
              organizationRelationships?: string | null,
              demographics?: string | null,
            } | null,
            groupEmailFooter?: string | null,
          } | null,
          fileName?: string | null,
          address?: string | null,
        } | null,
      } >,
      nextToken?: string | null,
    } | null,
    acceptanceCriteria?: string | null,
    requiresScenario?: boolean | null,
    requiresActionplan?: boolean | null,
    issueOwner?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    issueCreatedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    issueModifiedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    issueVerifiedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    issueSource?:  {
      __typename: "Source",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      organizationID?: string | null,
      groupID?: string | null,
      description?: string | null,
      year?: string | null,
      title?: string | null,
      archived?: boolean | null,
    } | null,
    issueRASCIRoles?:  {
      __typename: "PaginatedRASCIUsersOnItem",
      items:  Array< {
        __typename: "RASCIUserOnItem",
        id?: string | null,
        sort?: string | null,
        userID?: string | null,
        userInfo?:  {
          __typename: "UserInfo",
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
        } | null,
        user?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        userRASCIRole?: UserRoles | null,
      } >,
      nextToken?: string | null,
    } | null,
    issueApprovers?:  {
      __typename: "PaginatedItemApprover",
      items:  Array< {
        __typename: "ItemApprover",
        id?: string | null,
        sort?: string | null,
        userID?: string | null,
        userInfo?:  {
          __typename: "UserInfo",
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
        } | null,
        user?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
      } >,
      nextToken?: string | null,
    } | null,
  } | null > | null,
};

export type CreateSourceMutationVariables = {
  input: Array< CreateSourceInput >,
};

export type CreateSourceMutation = {
  CreateSource?:  Array< {
    __typename: "Source",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    sort?: string | null,
    organizationID?: string | null,
    groupID?: string | null,
    description?: string | null,
    year?: string | null,
    title?: string | null,
    archived?: boolean | null,
  } | null > | null,
};

export type ModifySourceMutationVariables = {
  input: Array< UpdateSourceInput >,
};

export type ModifySourceMutation = {
  ModifySource?:  Array< {
    __typename: "Source",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    sort?: string | null,
    organizationID?: string | null,
    groupID?: string | null,
    description?: string | null,
    year?: string | null,
    title?: string | null,
    archived?: boolean | null,
  } | null > | null,
};

export type DeleteSourceMutationVariables = {
  input: Array< DeleteSourceInput >,
};

export type DeleteSourceMutation = {
  DeleteSource?:  Array< {
    __typename: "Source",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    sort?: string | null,
    organizationID?: string | null,
    groupID?: string | null,
    description?: string | null,
    year?: string | null,
    title?: string | null,
    archived?: boolean | null,
  } | null > | null,
};

export type CreateActionPlanMutationVariables = {
  input: Array< CreateActionPlanInput >,
};

export type CreateActionPlanMutation = {
  CreateActionPlan?:  Array< {
    __typename: "ActionPlan",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    sort?: string | null,
    createdAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    lastModified?: string | null,
    actionPlanID?: string | null,
    title?: string | null,
    description?: string | null,
    resources?: string | null,
    notes?: string | null,
    owner?: string | null,
    priority?: Priority | null,
    status?: Status | null,
    approver?: string | null,
    dateClosed?: string | null,
    startDate?: string | null,
    dueDate?: string | null,
    reviewDate?: string | null,
    compositeIDYear?: string | null,
    compositeID?: number | null,
    archived?: boolean | null,
    isTemplate?: boolean | null,
    groupID?: string | null,
    actionPlanRelatedItems?:  {
      __typename: "PaginatedLinks",
      items:  Array< {
        __typename: "Link",
        id: string,
        sort: string,
        target?: string | null,
        targetType?: LinkTarget | null,
        item?:  {
          __typename: "RelatableItem",
          id?: string | null,
          sort?: string | null,
          groupID?: string | null,
          owner?: string | null,
          uuid?: string | null,
          title?: string | null,
          dateClosed?: string | null,
          dueDate?: string | null,
          status?: Status | null,
          priority?: Priority | null,
          severity?: Severity | null,
          responseStatus?: string | null,
          archived?: boolean | null,
          createdAt?: string | null,
          lastModified?: string | null,
          compositeIDYear?: string | null,
          compositeID?: number | null,
          displayID?: string | null,
          relatedItemsOwner?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
          relatedItemsGroup?:  {
            __typename: "Group",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            organizationID?: string | null,
            title?: string | null,
            shortTitle?: string | null,
            riskThreshold?:  {
              __typename: "RiskThreshold",
              financial?:  {
                __typename: "Threshold",
                appetitePercent?: number | null,
                appetiteAbsolute?: number | null,
                capacityPercent?: number | null,
                capacityAbsolute?: number | null,
              } | null,
              privacy?:  {
                __typename: "Threshold",
                appetitePercent?: number | null,
                appetiteAbsolute?: number | null,
                capacityPercent?: number | null,
                capacityAbsolute?: number | null,
              } | null,
            } | null,
            organization?:  {
              __typename: "Organization",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              name?: string | null,
              organizationRelationships?: string | null,
              demographics?: string | null,
            } | null,
            groupEmailFooter?: string | null,
          } | null,
          fileName?: string | null,
          address?: string | null,
        } | null,
      } >,
      nextToken?: string | null,
    } | null,
    taskChildren?:  {
      __typename: "PaginatedTaskReferences",
      items:  Array< {
        __typename: "TaskReference",
        id: string,
        sort: string,
        itemID: string,
        task?:  {
          __typename: "Task",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          createdAt?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          lastModified?: string | null,
          parentID?: string | null,
          organizationID?: string | null,
          groupID?: string | null,
          title?: string | null,
          description?: string | null,
          resources?: string | null,
          notes?: string | null,
          owner?: string | null,
          priority?: Priority | null,
          status?: Status | null,
          dateClosed?: string | null,
          dueDate?: string | null,
          archived?: boolean | null,
          taskOwner?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
          taskRASCIRoles?:  {
            __typename: "PaginatedRASCIUsersOnItem",
            items:  Array< {
              __typename: "RASCIUserOnItem",
              id?: string | null,
              sort?: string | null,
              userID?: string | null,
              userInfo?:  {
                __typename: "UserInfo",
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
              } | null,
              user?:  {
                __typename: "User",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                username?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
                lastSeen?: string | null,
                lastLoggedIn?: string | null,
              } | null,
              userRASCIRole?: UserRoles | null,
            } >,
            nextToken?: string | null,
          } | null,
        } | null,
        createdAt?: string | null,
        userRASCIRole?: UserRoles | null,
      } >,
      nextToken?: string | null,
    } | null,
    effortAmount?: number | null,
    effortType?: EffortType | null,
    actionPlanOwner?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    actionPlanCreatedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    actionPlanModifiedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    actionPlanRASCIRoles?:  {
      __typename: "PaginatedRASCIUsersOnItem",
      items:  Array< {
        __typename: "RASCIUserOnItem",
        id?: string | null,
        sort?: string | null,
        userID?: string | null,
        userInfo?:  {
          __typename: "UserInfo",
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
        } | null,
        user?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        userRASCIRole?: UserRoles | null,
      } >,
      nextToken?: string | null,
    } | null,
    actionplanApprovers?:  {
      __typename: "PaginatedItemApprover",
      items:  Array< {
        __typename: "ItemApprover",
        id?: string | null,
        sort?: string | null,
        userID?: string | null,
        userInfo?:  {
          __typename: "UserInfo",
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
        } | null,
        user?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
      } >,
      nextToken?: string | null,
    } | null,
    type?: string | null,
  } | null > | null,
};

export type ModifyActionPlanMutationVariables = {
  input: Array< UpdateActionPlanInput >,
};

export type ModifyActionPlanMutation = {
  ModifyActionPlan?:  Array< {
    __typename: "ActionPlan",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    sort?: string | null,
    createdAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    lastModified?: string | null,
    actionPlanID?: string | null,
    title?: string | null,
    description?: string | null,
    resources?: string | null,
    notes?: string | null,
    owner?: string | null,
    priority?: Priority | null,
    status?: Status | null,
    approver?: string | null,
    dateClosed?: string | null,
    startDate?: string | null,
    dueDate?: string | null,
    reviewDate?: string | null,
    compositeIDYear?: string | null,
    compositeID?: number | null,
    archived?: boolean | null,
    isTemplate?: boolean | null,
    groupID?: string | null,
    actionPlanRelatedItems?:  {
      __typename: "PaginatedLinks",
      items:  Array< {
        __typename: "Link",
        id: string,
        sort: string,
        target?: string | null,
        targetType?: LinkTarget | null,
        item?:  {
          __typename: "RelatableItem",
          id?: string | null,
          sort?: string | null,
          groupID?: string | null,
          owner?: string | null,
          uuid?: string | null,
          title?: string | null,
          dateClosed?: string | null,
          dueDate?: string | null,
          status?: Status | null,
          priority?: Priority | null,
          severity?: Severity | null,
          responseStatus?: string | null,
          archived?: boolean | null,
          createdAt?: string | null,
          lastModified?: string | null,
          compositeIDYear?: string | null,
          compositeID?: number | null,
          displayID?: string | null,
          relatedItemsOwner?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
          relatedItemsGroup?:  {
            __typename: "Group",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            organizationID?: string | null,
            title?: string | null,
            shortTitle?: string | null,
            riskThreshold?:  {
              __typename: "RiskThreshold",
              financial?:  {
                __typename: "Threshold",
                appetitePercent?: number | null,
                appetiteAbsolute?: number | null,
                capacityPercent?: number | null,
                capacityAbsolute?: number | null,
              } | null,
              privacy?:  {
                __typename: "Threshold",
                appetitePercent?: number | null,
                appetiteAbsolute?: number | null,
                capacityPercent?: number | null,
                capacityAbsolute?: number | null,
              } | null,
            } | null,
            organization?:  {
              __typename: "Organization",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              name?: string | null,
              organizationRelationships?: string | null,
              demographics?: string | null,
            } | null,
            groupEmailFooter?: string | null,
          } | null,
          fileName?: string | null,
          address?: string | null,
        } | null,
      } >,
      nextToken?: string | null,
    } | null,
    taskChildren?:  {
      __typename: "PaginatedTaskReferences",
      items:  Array< {
        __typename: "TaskReference",
        id: string,
        sort: string,
        itemID: string,
        task?:  {
          __typename: "Task",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          createdAt?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          lastModified?: string | null,
          parentID?: string | null,
          organizationID?: string | null,
          groupID?: string | null,
          title?: string | null,
          description?: string | null,
          resources?: string | null,
          notes?: string | null,
          owner?: string | null,
          priority?: Priority | null,
          status?: Status | null,
          dateClosed?: string | null,
          dueDate?: string | null,
          archived?: boolean | null,
          taskOwner?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
          taskRASCIRoles?:  {
            __typename: "PaginatedRASCIUsersOnItem",
            items:  Array< {
              __typename: "RASCIUserOnItem",
              id?: string | null,
              sort?: string | null,
              userID?: string | null,
              userInfo?:  {
                __typename: "UserInfo",
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
              } | null,
              user?:  {
                __typename: "User",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                username?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
                lastSeen?: string | null,
                lastLoggedIn?: string | null,
              } | null,
              userRASCIRole?: UserRoles | null,
            } >,
            nextToken?: string | null,
          } | null,
        } | null,
        createdAt?: string | null,
        userRASCIRole?: UserRoles | null,
      } >,
      nextToken?: string | null,
    } | null,
    effortAmount?: number | null,
    effortType?: EffortType | null,
    actionPlanOwner?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    actionPlanCreatedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    actionPlanModifiedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    actionPlanRASCIRoles?:  {
      __typename: "PaginatedRASCIUsersOnItem",
      items:  Array< {
        __typename: "RASCIUserOnItem",
        id?: string | null,
        sort?: string | null,
        userID?: string | null,
        userInfo?:  {
          __typename: "UserInfo",
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
        } | null,
        user?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        userRASCIRole?: UserRoles | null,
      } >,
      nextToken?: string | null,
    } | null,
    actionplanApprovers?:  {
      __typename: "PaginatedItemApprover",
      items:  Array< {
        __typename: "ItemApprover",
        id?: string | null,
        sort?: string | null,
        userID?: string | null,
        userInfo?:  {
          __typename: "UserInfo",
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
        } | null,
        user?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
      } >,
      nextToken?: string | null,
    } | null,
    type?: string | null,
  } | null > | null,
};

export type DeleteActionPlanMutationVariables = {
  input: Array< DeleteActionPlanInput >,
};

export type DeleteActionPlanMutation = {
  DeleteActionPlan?:  Array< {
    __typename: "ActionPlan",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    sort?: string | null,
    createdAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    lastModified?: string | null,
    actionPlanID?: string | null,
    title?: string | null,
    description?: string | null,
    resources?: string | null,
    notes?: string | null,
    owner?: string | null,
    priority?: Priority | null,
    status?: Status | null,
    approver?: string | null,
    dateClosed?: string | null,
    startDate?: string | null,
    dueDate?: string | null,
    reviewDate?: string | null,
    compositeIDYear?: string | null,
    compositeID?: number | null,
    archived?: boolean | null,
    isTemplate?: boolean | null,
    groupID?: string | null,
    actionPlanRelatedItems?:  {
      __typename: "PaginatedLinks",
      items:  Array< {
        __typename: "Link",
        id: string,
        sort: string,
        target?: string | null,
        targetType?: LinkTarget | null,
        item?:  {
          __typename: "RelatableItem",
          id?: string | null,
          sort?: string | null,
          groupID?: string | null,
          owner?: string | null,
          uuid?: string | null,
          title?: string | null,
          dateClosed?: string | null,
          dueDate?: string | null,
          status?: Status | null,
          priority?: Priority | null,
          severity?: Severity | null,
          responseStatus?: string | null,
          archived?: boolean | null,
          createdAt?: string | null,
          lastModified?: string | null,
          compositeIDYear?: string | null,
          compositeID?: number | null,
          displayID?: string | null,
          relatedItemsOwner?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
          relatedItemsGroup?:  {
            __typename: "Group",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            organizationID?: string | null,
            title?: string | null,
            shortTitle?: string | null,
            riskThreshold?:  {
              __typename: "RiskThreshold",
              financial?:  {
                __typename: "Threshold",
                appetitePercent?: number | null,
                appetiteAbsolute?: number | null,
                capacityPercent?: number | null,
                capacityAbsolute?: number | null,
              } | null,
              privacy?:  {
                __typename: "Threshold",
                appetitePercent?: number | null,
                appetiteAbsolute?: number | null,
                capacityPercent?: number | null,
                capacityAbsolute?: number | null,
              } | null,
            } | null,
            organization?:  {
              __typename: "Organization",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              name?: string | null,
              organizationRelationships?: string | null,
              demographics?: string | null,
            } | null,
            groupEmailFooter?: string | null,
          } | null,
          fileName?: string | null,
          address?: string | null,
        } | null,
      } >,
      nextToken?: string | null,
    } | null,
    taskChildren?:  {
      __typename: "PaginatedTaskReferences",
      items:  Array< {
        __typename: "TaskReference",
        id: string,
        sort: string,
        itemID: string,
        task?:  {
          __typename: "Task",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          createdAt?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          lastModified?: string | null,
          parentID?: string | null,
          organizationID?: string | null,
          groupID?: string | null,
          title?: string | null,
          description?: string | null,
          resources?: string | null,
          notes?: string | null,
          owner?: string | null,
          priority?: Priority | null,
          status?: Status | null,
          dateClosed?: string | null,
          dueDate?: string | null,
          archived?: boolean | null,
          taskOwner?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
          taskRASCIRoles?:  {
            __typename: "PaginatedRASCIUsersOnItem",
            items:  Array< {
              __typename: "RASCIUserOnItem",
              id?: string | null,
              sort?: string | null,
              userID?: string | null,
              userInfo?:  {
                __typename: "UserInfo",
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
              } | null,
              user?:  {
                __typename: "User",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                username?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
                lastSeen?: string | null,
                lastLoggedIn?: string | null,
              } | null,
              userRASCIRole?: UserRoles | null,
            } >,
            nextToken?: string | null,
          } | null,
        } | null,
        createdAt?: string | null,
        userRASCIRole?: UserRoles | null,
      } >,
      nextToken?: string | null,
    } | null,
    effortAmount?: number | null,
    effortType?: EffortType | null,
    actionPlanOwner?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    actionPlanCreatedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    actionPlanModifiedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    actionPlanRASCIRoles?:  {
      __typename: "PaginatedRASCIUsersOnItem",
      items:  Array< {
        __typename: "RASCIUserOnItem",
        id?: string | null,
        sort?: string | null,
        userID?: string | null,
        userInfo?:  {
          __typename: "UserInfo",
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
        } | null,
        user?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        userRASCIRole?: UserRoles | null,
      } >,
      nextToken?: string | null,
    } | null,
    actionplanApprovers?:  {
      __typename: "PaginatedItemApprover",
      items:  Array< {
        __typename: "ItemApprover",
        id?: string | null,
        sort?: string | null,
        userID?: string | null,
        userInfo?:  {
          __typename: "UserInfo",
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
        } | null,
        user?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
      } >,
      nextToken?: string | null,
    } | null,
    type?: string | null,
  } | null > | null,
};

export type CreateTaskMutationVariables = {
  input: Array< CreateTaskInput >,
};

export type CreateTaskMutation = {
  CreateTask?:  Array< {
    __typename: "Task",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    sort?: string | null,
    createdAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    lastModified?: string | null,
    parentID?: string | null,
    organizationID?: string | null,
    groupID?: string | null,
    title?: string | null,
    description?: string | null,
    resources?: string | null,
    notes?: string | null,
    owner?: string | null,
    priority?: Priority | null,
    status?: Status | null,
    dateClosed?: string | null,
    dueDate?: string | null,
    archived?: boolean | null,
    taskOwner?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    taskRASCIRoles?:  {
      __typename: "PaginatedRASCIUsersOnItem",
      items:  Array< {
        __typename: "RASCIUserOnItem",
        id?: string | null,
        sort?: string | null,
        userID?: string | null,
        userInfo?:  {
          __typename: "UserInfo",
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
        } | null,
        user?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        userRASCIRole?: UserRoles | null,
      } >,
      nextToken?: string | null,
    } | null,
  } | null > | null,
};

export type ModifyTaskMutationVariables = {
  input: Array< UpdateTaskInput >,
};

export type ModifyTaskMutation = {
  ModifyTask?:  Array< {
    __typename: "Task",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    sort?: string | null,
    createdAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    lastModified?: string | null,
    parentID?: string | null,
    organizationID?: string | null,
    groupID?: string | null,
    title?: string | null,
    description?: string | null,
    resources?: string | null,
    notes?: string | null,
    owner?: string | null,
    priority?: Priority | null,
    status?: Status | null,
    dateClosed?: string | null,
    dueDate?: string | null,
    archived?: boolean | null,
    taskOwner?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    taskRASCIRoles?:  {
      __typename: "PaginatedRASCIUsersOnItem",
      items:  Array< {
        __typename: "RASCIUserOnItem",
        id?: string | null,
        sort?: string | null,
        userID?: string | null,
        userInfo?:  {
          __typename: "UserInfo",
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
        } | null,
        user?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        userRASCIRole?: UserRoles | null,
      } >,
      nextToken?: string | null,
    } | null,
  } | null > | null,
};

export type DeleteTaskMutationVariables = {
  input: Array< DeleteTaskInput >,
};

export type DeleteTaskMutation = {
  DeleteTask?:  Array< {
    __typename: "Task",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    sort?: string | null,
    createdAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    lastModified?: string | null,
    parentID?: string | null,
    organizationID?: string | null,
    groupID?: string | null,
    title?: string | null,
    description?: string | null,
    resources?: string | null,
    notes?: string | null,
    owner?: string | null,
    priority?: Priority | null,
    status?: Status | null,
    dateClosed?: string | null,
    dueDate?: string | null,
    archived?: boolean | null,
    taskOwner?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    taskRASCIRoles?:  {
      __typename: "PaginatedRASCIUsersOnItem",
      items:  Array< {
        __typename: "RASCIUserOnItem",
        id?: string | null,
        sort?: string | null,
        userID?: string | null,
        userInfo?:  {
          __typename: "UserInfo",
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
        } | null,
        user?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        userRASCIRole?: UserRoles | null,
      } >,
      nextToken?: string | null,
    } | null,
  } | null > | null,
};

export type CreateScenarioMutationVariables = {
  input: Array< CreateScenarioInput >,
};

export type CreateScenarioMutation = {
  CreateScenario?:  Array< {
    __typename: "Scenario",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    sort?: string | null,
    createdAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    lastModified?: string | null,
    FAIRSummary?: string | null,
    scenarioID?: string | null,
    title?: string | null,
    description?: string | null,
    notes?: string | null,
    assumptions?: string | null,
    creationDate?: string | null,
    lastRevision?: string | null,
    nextRevision?: string | null,
    categories?: string | null,
    owner?: string | null,
    status?: Status | null,
    dateClosed?: string | null,
    indicator?: Indicator | null,
    compositeIDYear?: string | null,
    compositeID?: number | null,
    archived?: boolean | null,
    isTemplate?: boolean | null,
    groupID?: string | null,
    scenarioRelatedItems?:  {
      __typename: "PaginatedLinks",
      items:  Array< {
        __typename: "Link",
        id: string,
        sort: string,
        target?: string | null,
        targetType?: LinkTarget | null,
        item?:  {
          __typename: "RelatableItem",
          id?: string | null,
          sort?: string | null,
          groupID?: string | null,
          owner?: string | null,
          uuid?: string | null,
          title?: string | null,
          dateClosed?: string | null,
          dueDate?: string | null,
          status?: Status | null,
          priority?: Priority | null,
          severity?: Severity | null,
          responseStatus?: string | null,
          archived?: boolean | null,
          createdAt?: string | null,
          lastModified?: string | null,
          compositeIDYear?: string | null,
          compositeID?: number | null,
          displayID?: string | null,
          relatedItemsOwner?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
          relatedItemsGroup?:  {
            __typename: "Group",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            organizationID?: string | null,
            title?: string | null,
            shortTitle?: string | null,
            riskThreshold?:  {
              __typename: "RiskThreshold",
              financial?:  {
                __typename: "Threshold",
                appetitePercent?: number | null,
                appetiteAbsolute?: number | null,
                capacityPercent?: number | null,
                capacityAbsolute?: number | null,
              } | null,
              privacy?:  {
                __typename: "Threshold",
                appetitePercent?: number | null,
                appetiteAbsolute?: number | null,
                capacityPercent?: number | null,
                capacityAbsolute?: number | null,
              } | null,
            } | null,
            organization?:  {
              __typename: "Organization",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              name?: string | null,
              organizationRelationships?: string | null,
              demographics?: string | null,
            } | null,
            groupEmailFooter?: string | null,
          } | null,
          fileName?: string | null,
          address?: string | null,
        } | null,
      } >,
      nextToken?: string | null,
    } | null,
    scenarioOwner?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    scenarioCreatedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    scenarioModifiedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    scenarioRASCIRoles?:  {
      __typename: "PaginatedRASCIUsersOnItem",
      items:  Array< {
        __typename: "RASCIUserOnItem",
        id?: string | null,
        sort?: string | null,
        userID?: string | null,
        userInfo?:  {
          __typename: "UserInfo",
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
        } | null,
        user?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        userRASCIRole?: UserRoles | null,
      } >,
      nextToken?: string | null,
    } | null,
  } | null > | null,
};

export type ModifyScenarioMutationVariables = {
  input: Array< UpdateScenarioInput >,
};

export type ModifyScenarioMutation = {
  ModifyScenario?:  Array< {
    __typename: "Scenario",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    sort?: string | null,
    createdAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    lastModified?: string | null,
    FAIRSummary?: string | null,
    scenarioID?: string | null,
    title?: string | null,
    description?: string | null,
    notes?: string | null,
    assumptions?: string | null,
    creationDate?: string | null,
    lastRevision?: string | null,
    nextRevision?: string | null,
    categories?: string | null,
    owner?: string | null,
    status?: Status | null,
    dateClosed?: string | null,
    indicator?: Indicator | null,
    compositeIDYear?: string | null,
    compositeID?: number | null,
    archived?: boolean | null,
    isTemplate?: boolean | null,
    groupID?: string | null,
    scenarioRelatedItems?:  {
      __typename: "PaginatedLinks",
      items:  Array< {
        __typename: "Link",
        id: string,
        sort: string,
        target?: string | null,
        targetType?: LinkTarget | null,
        item?:  {
          __typename: "RelatableItem",
          id?: string | null,
          sort?: string | null,
          groupID?: string | null,
          owner?: string | null,
          uuid?: string | null,
          title?: string | null,
          dateClosed?: string | null,
          dueDate?: string | null,
          status?: Status | null,
          priority?: Priority | null,
          severity?: Severity | null,
          responseStatus?: string | null,
          archived?: boolean | null,
          createdAt?: string | null,
          lastModified?: string | null,
          compositeIDYear?: string | null,
          compositeID?: number | null,
          displayID?: string | null,
          relatedItemsOwner?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
          relatedItemsGroup?:  {
            __typename: "Group",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            organizationID?: string | null,
            title?: string | null,
            shortTitle?: string | null,
            riskThreshold?:  {
              __typename: "RiskThreshold",
              financial?:  {
                __typename: "Threshold",
                appetitePercent?: number | null,
                appetiteAbsolute?: number | null,
                capacityPercent?: number | null,
                capacityAbsolute?: number | null,
              } | null,
              privacy?:  {
                __typename: "Threshold",
                appetitePercent?: number | null,
                appetiteAbsolute?: number | null,
                capacityPercent?: number | null,
                capacityAbsolute?: number | null,
              } | null,
            } | null,
            organization?:  {
              __typename: "Organization",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              name?: string | null,
              organizationRelationships?: string | null,
              demographics?: string | null,
            } | null,
            groupEmailFooter?: string | null,
          } | null,
          fileName?: string | null,
          address?: string | null,
        } | null,
      } >,
      nextToken?: string | null,
    } | null,
    scenarioOwner?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    scenarioCreatedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    scenarioModifiedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    scenarioRASCIRoles?:  {
      __typename: "PaginatedRASCIUsersOnItem",
      items:  Array< {
        __typename: "RASCIUserOnItem",
        id?: string | null,
        sort?: string | null,
        userID?: string | null,
        userInfo?:  {
          __typename: "UserInfo",
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
        } | null,
        user?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        userRASCIRole?: UserRoles | null,
      } >,
      nextToken?: string | null,
    } | null,
  } | null > | null,
};

export type DeleteScenarioMutationVariables = {
  input: Array< DeleteScenarioInput >,
};

export type DeleteScenarioMutation = {
  DeleteScenario?:  Array< {
    __typename: "Scenario",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    sort?: string | null,
    createdAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    lastModified?: string | null,
    FAIRSummary?: string | null,
    scenarioID?: string | null,
    title?: string | null,
    description?: string | null,
    notes?: string | null,
    assumptions?: string | null,
    creationDate?: string | null,
    lastRevision?: string | null,
    nextRevision?: string | null,
    categories?: string | null,
    owner?: string | null,
    status?: Status | null,
    dateClosed?: string | null,
    indicator?: Indicator | null,
    compositeIDYear?: string | null,
    compositeID?: number | null,
    archived?: boolean | null,
    isTemplate?: boolean | null,
    groupID?: string | null,
    scenarioRelatedItems?:  {
      __typename: "PaginatedLinks",
      items:  Array< {
        __typename: "Link",
        id: string,
        sort: string,
        target?: string | null,
        targetType?: LinkTarget | null,
        item?:  {
          __typename: "RelatableItem",
          id?: string | null,
          sort?: string | null,
          groupID?: string | null,
          owner?: string | null,
          uuid?: string | null,
          title?: string | null,
          dateClosed?: string | null,
          dueDate?: string | null,
          status?: Status | null,
          priority?: Priority | null,
          severity?: Severity | null,
          responseStatus?: string | null,
          archived?: boolean | null,
          createdAt?: string | null,
          lastModified?: string | null,
          compositeIDYear?: string | null,
          compositeID?: number | null,
          displayID?: string | null,
          relatedItemsOwner?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
          relatedItemsGroup?:  {
            __typename: "Group",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            organizationID?: string | null,
            title?: string | null,
            shortTitle?: string | null,
            riskThreshold?:  {
              __typename: "RiskThreshold",
              financial?:  {
                __typename: "Threshold",
                appetitePercent?: number | null,
                appetiteAbsolute?: number | null,
                capacityPercent?: number | null,
                capacityAbsolute?: number | null,
              } | null,
              privacy?:  {
                __typename: "Threshold",
                appetitePercent?: number | null,
                appetiteAbsolute?: number | null,
                capacityPercent?: number | null,
                capacityAbsolute?: number | null,
              } | null,
            } | null,
            organization?:  {
              __typename: "Organization",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              name?: string | null,
              organizationRelationships?: string | null,
              demographics?: string | null,
            } | null,
            groupEmailFooter?: string | null,
          } | null,
          fileName?: string | null,
          address?: string | null,
        } | null,
      } >,
      nextToken?: string | null,
    } | null,
    scenarioOwner?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    scenarioCreatedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    scenarioModifiedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    scenarioRASCIRoles?:  {
      __typename: "PaginatedRASCIUsersOnItem",
      items:  Array< {
        __typename: "RASCIUserOnItem",
        id?: string | null,
        sort?: string | null,
        userID?: string | null,
        userInfo?:  {
          __typename: "UserInfo",
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
        } | null,
        user?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        userRASCIRole?: UserRoles | null,
      } >,
      nextToken?: string | null,
    } | null,
  } | null > | null,
};

export type CreateRiskPortfolioMutationVariables = {
  input: Array< CreateRiskPortfolioInput >,
};

export type CreateRiskPortfolioMutation = {
  CreateRiskPortfolio?:  Array< {
    __typename: "RiskPortfolio",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    sort?: string | null,
    groupID?: string | null,
    title?: string | null,
    description?: string | null,
    notes?: string | null,
    owner?: string | null,
    status?: Status | null,
    assumptions?: string | null,
    archived?: boolean | null,
    modifiedBy?: string | null,
  } | null > | null,
};

export type ModifyRiskPortfolioMutationVariables = {
  input: Array< UpdateRiskPortfolioInput >,
};

export type ModifyRiskPortfolioMutation = {
  ModifyRiskPortfolio?:  Array< {
    __typename: "RiskPortfolio",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    sort?: string | null,
    groupID?: string | null,
    title?: string | null,
    description?: string | null,
    notes?: string | null,
    owner?: string | null,
    status?: Status | null,
    assumptions?: string | null,
    archived?: boolean | null,
    modifiedBy?: string | null,
  } | null > | null,
};

export type DeleteRiskPortfolioMutationVariables = {
  input: Array< DeleteRiskPortfolioInput >,
};

export type DeleteRiskPortfolioMutation = {
  DeleteRiskPortfolio?:  Array< {
    __typename: "RiskPortfolio",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    sort?: string | null,
    groupID?: string | null,
    title?: string | null,
    description?: string | null,
    notes?: string | null,
    owner?: string | null,
    status?: Status | null,
    assumptions?: string | null,
    archived?: boolean | null,
    modifiedBy?: string | null,
  } | null > | null,
};

export type CreateRiskComparisonMutationVariables = {
  input: Array< CreateRiskComparisonInput >,
};

export type CreateRiskComparisonMutation = {
  CreateRiskComparison?:  Array< {
    __typename: "RiskComparison",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    sort?: string | null,
    groupID?: string | null,
    title?: string | null,
    description?: string | null,
    scenarioOneID?: string | null,
    scenarioTwoID?: string | null,
    scenarioThreeID?: string | null,
    scenarioOne?:  {
      __typename: "Scenario",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      createdAt?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      lastModified?: string | null,
      FAIRSummary?: string | null,
      scenarioID?: string | null,
      title?: string | null,
      description?: string | null,
      notes?: string | null,
      assumptions?: string | null,
      creationDate?: string | null,
      lastRevision?: string | null,
      nextRevision?: string | null,
      categories?: string | null,
      owner?: string | null,
      status?: Status | null,
      dateClosed?: string | null,
      indicator?: Indicator | null,
      compositeIDYear?: string | null,
      compositeID?: number | null,
      archived?: boolean | null,
      isTemplate?: boolean | null,
      groupID?: string | null,
      scenarioRelatedItems?:  {
        __typename: "PaginatedLinks",
        items:  Array< {
          __typename: "Link",
          id: string,
          sort: string,
          target?: string | null,
          targetType?: LinkTarget | null,
          item?:  {
            __typename: "RelatableItem",
            id?: string | null,
            sort?: string | null,
            groupID?: string | null,
            owner?: string | null,
            uuid?: string | null,
            title?: string | null,
            dateClosed?: string | null,
            dueDate?: string | null,
            status?: Status | null,
            priority?: Priority | null,
            severity?: Severity | null,
            responseStatus?: string | null,
            archived?: boolean | null,
            createdAt?: string | null,
            lastModified?: string | null,
            compositeIDYear?: string | null,
            compositeID?: number | null,
            displayID?: string | null,
            relatedItemsOwner?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
            relatedItemsGroup?:  {
              __typename: "Group",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              organizationID?: string | null,
              title?: string | null,
              shortTitle?: string | null,
              riskThreshold?:  {
                __typename: "RiskThreshold",
                financial?:  {
                  __typename: "Threshold",
                  appetitePercent?: number | null,
                  appetiteAbsolute?: number | null,
                  capacityPercent?: number | null,
                  capacityAbsolute?: number | null,
                } | null,
                privacy?:  {
                  __typename: "Threshold",
                  appetitePercent?: number | null,
                  appetiteAbsolute?: number | null,
                  capacityPercent?: number | null,
                  capacityAbsolute?: number | null,
                } | null,
              } | null,
              organization?:  {
                __typename: "Organization",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                name?: string | null,
                organizationRelationships?: string | null,
                demographics?: string | null,
              } | null,
              groupEmailFooter?: string | null,
            } | null,
            fileName?: string | null,
            address?: string | null,
          } | null,
        } >,
        nextToken?: string | null,
      } | null,
      scenarioOwner?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      scenarioCreatedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      scenarioModifiedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      scenarioRASCIRoles?:  {
        __typename: "PaginatedRASCIUsersOnItem",
        items:  Array< {
          __typename: "RASCIUserOnItem",
          id?: string | null,
          sort?: string | null,
          userID?: string | null,
          userInfo?:  {
            __typename: "UserInfo",
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
          } | null,
          user?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
          userRASCIRole?: UserRoles | null,
        } >,
        nextToken?: string | null,
      } | null,
    } | null,
    scenarioTwo?:  {
      __typename: "Scenario",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      createdAt?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      lastModified?: string | null,
      FAIRSummary?: string | null,
      scenarioID?: string | null,
      title?: string | null,
      description?: string | null,
      notes?: string | null,
      assumptions?: string | null,
      creationDate?: string | null,
      lastRevision?: string | null,
      nextRevision?: string | null,
      categories?: string | null,
      owner?: string | null,
      status?: Status | null,
      dateClosed?: string | null,
      indicator?: Indicator | null,
      compositeIDYear?: string | null,
      compositeID?: number | null,
      archived?: boolean | null,
      isTemplate?: boolean | null,
      groupID?: string | null,
      scenarioRelatedItems?:  {
        __typename: "PaginatedLinks",
        items:  Array< {
          __typename: "Link",
          id: string,
          sort: string,
          target?: string | null,
          targetType?: LinkTarget | null,
          item?:  {
            __typename: "RelatableItem",
            id?: string | null,
            sort?: string | null,
            groupID?: string | null,
            owner?: string | null,
            uuid?: string | null,
            title?: string | null,
            dateClosed?: string | null,
            dueDate?: string | null,
            status?: Status | null,
            priority?: Priority | null,
            severity?: Severity | null,
            responseStatus?: string | null,
            archived?: boolean | null,
            createdAt?: string | null,
            lastModified?: string | null,
            compositeIDYear?: string | null,
            compositeID?: number | null,
            displayID?: string | null,
            relatedItemsOwner?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
            relatedItemsGroup?:  {
              __typename: "Group",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              organizationID?: string | null,
              title?: string | null,
              shortTitle?: string | null,
              riskThreshold?:  {
                __typename: "RiskThreshold",
                financial?:  {
                  __typename: "Threshold",
                  appetitePercent?: number | null,
                  appetiteAbsolute?: number | null,
                  capacityPercent?: number | null,
                  capacityAbsolute?: number | null,
                } | null,
                privacy?:  {
                  __typename: "Threshold",
                  appetitePercent?: number | null,
                  appetiteAbsolute?: number | null,
                  capacityPercent?: number | null,
                  capacityAbsolute?: number | null,
                } | null,
              } | null,
              organization?:  {
                __typename: "Organization",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                name?: string | null,
                organizationRelationships?: string | null,
                demographics?: string | null,
              } | null,
              groupEmailFooter?: string | null,
            } | null,
            fileName?: string | null,
            address?: string | null,
          } | null,
        } >,
        nextToken?: string | null,
      } | null,
      scenarioOwner?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      scenarioCreatedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      scenarioModifiedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      scenarioRASCIRoles?:  {
        __typename: "PaginatedRASCIUsersOnItem",
        items:  Array< {
          __typename: "RASCIUserOnItem",
          id?: string | null,
          sort?: string | null,
          userID?: string | null,
          userInfo?:  {
            __typename: "UserInfo",
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
          } | null,
          user?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
          userRASCIRole?: UserRoles | null,
        } >,
        nextToken?: string | null,
      } | null,
    } | null,
    scenarioThree?:  {
      __typename: "Scenario",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      createdAt?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      lastModified?: string | null,
      FAIRSummary?: string | null,
      scenarioID?: string | null,
      title?: string | null,
      description?: string | null,
      notes?: string | null,
      assumptions?: string | null,
      creationDate?: string | null,
      lastRevision?: string | null,
      nextRevision?: string | null,
      categories?: string | null,
      owner?: string | null,
      status?: Status | null,
      dateClosed?: string | null,
      indicator?: Indicator | null,
      compositeIDYear?: string | null,
      compositeID?: number | null,
      archived?: boolean | null,
      isTemplate?: boolean | null,
      groupID?: string | null,
      scenarioRelatedItems?:  {
        __typename: "PaginatedLinks",
        items:  Array< {
          __typename: "Link",
          id: string,
          sort: string,
          target?: string | null,
          targetType?: LinkTarget | null,
          item?:  {
            __typename: "RelatableItem",
            id?: string | null,
            sort?: string | null,
            groupID?: string | null,
            owner?: string | null,
            uuid?: string | null,
            title?: string | null,
            dateClosed?: string | null,
            dueDate?: string | null,
            status?: Status | null,
            priority?: Priority | null,
            severity?: Severity | null,
            responseStatus?: string | null,
            archived?: boolean | null,
            createdAt?: string | null,
            lastModified?: string | null,
            compositeIDYear?: string | null,
            compositeID?: number | null,
            displayID?: string | null,
            relatedItemsOwner?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
            relatedItemsGroup?:  {
              __typename: "Group",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              organizationID?: string | null,
              title?: string | null,
              shortTitle?: string | null,
              riskThreshold?:  {
                __typename: "RiskThreshold",
                financial?:  {
                  __typename: "Threshold",
                  appetitePercent?: number | null,
                  appetiteAbsolute?: number | null,
                  capacityPercent?: number | null,
                  capacityAbsolute?: number | null,
                } | null,
                privacy?:  {
                  __typename: "Threshold",
                  appetitePercent?: number | null,
                  appetiteAbsolute?: number | null,
                  capacityPercent?: number | null,
                  capacityAbsolute?: number | null,
                } | null,
              } | null,
              organization?:  {
                __typename: "Organization",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                name?: string | null,
                organizationRelationships?: string | null,
                demographics?: string | null,
              } | null,
              groupEmailFooter?: string | null,
            } | null,
            fileName?: string | null,
            address?: string | null,
          } | null,
        } >,
        nextToken?: string | null,
      } | null,
      scenarioOwner?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      scenarioCreatedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      scenarioModifiedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      scenarioRASCIRoles?:  {
        __typename: "PaginatedRASCIUsersOnItem",
        items:  Array< {
          __typename: "RASCIUserOnItem",
          id?: string | null,
          sort?: string | null,
          userID?: string | null,
          userInfo?:  {
            __typename: "UserInfo",
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
          } | null,
          user?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
          userRASCIRole?: UserRoles | null,
        } >,
        nextToken?: string | null,
      } | null,
    } | null,
    scenarioOneDescription?: string | null,
    scenarioTwoDescription?: string | null,
    scenarioThreeDescription?: string | null,
    lossThreshold?: number | null,
    archived?: boolean | null,
    modifiedBy?: string | null,
  } | null > | null,
};

export type ModifyRiskComparisonMutationVariables = {
  input: Array< UpdateRiskComparisonInput >,
};

export type ModifyRiskComparisonMutation = {
  ModifyRiskComparison?:  Array< {
    __typename: "RiskComparison",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    sort?: string | null,
    groupID?: string | null,
    title?: string | null,
    description?: string | null,
    scenarioOneID?: string | null,
    scenarioTwoID?: string | null,
    scenarioThreeID?: string | null,
    scenarioOne?:  {
      __typename: "Scenario",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      createdAt?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      lastModified?: string | null,
      FAIRSummary?: string | null,
      scenarioID?: string | null,
      title?: string | null,
      description?: string | null,
      notes?: string | null,
      assumptions?: string | null,
      creationDate?: string | null,
      lastRevision?: string | null,
      nextRevision?: string | null,
      categories?: string | null,
      owner?: string | null,
      status?: Status | null,
      dateClosed?: string | null,
      indicator?: Indicator | null,
      compositeIDYear?: string | null,
      compositeID?: number | null,
      archived?: boolean | null,
      isTemplate?: boolean | null,
      groupID?: string | null,
      scenarioRelatedItems?:  {
        __typename: "PaginatedLinks",
        items:  Array< {
          __typename: "Link",
          id: string,
          sort: string,
          target?: string | null,
          targetType?: LinkTarget | null,
          item?:  {
            __typename: "RelatableItem",
            id?: string | null,
            sort?: string | null,
            groupID?: string | null,
            owner?: string | null,
            uuid?: string | null,
            title?: string | null,
            dateClosed?: string | null,
            dueDate?: string | null,
            status?: Status | null,
            priority?: Priority | null,
            severity?: Severity | null,
            responseStatus?: string | null,
            archived?: boolean | null,
            createdAt?: string | null,
            lastModified?: string | null,
            compositeIDYear?: string | null,
            compositeID?: number | null,
            displayID?: string | null,
            relatedItemsOwner?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
            relatedItemsGroup?:  {
              __typename: "Group",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              organizationID?: string | null,
              title?: string | null,
              shortTitle?: string | null,
              riskThreshold?:  {
                __typename: "RiskThreshold",
                financial?:  {
                  __typename: "Threshold",
                  appetitePercent?: number | null,
                  appetiteAbsolute?: number | null,
                  capacityPercent?: number | null,
                  capacityAbsolute?: number | null,
                } | null,
                privacy?:  {
                  __typename: "Threshold",
                  appetitePercent?: number | null,
                  appetiteAbsolute?: number | null,
                  capacityPercent?: number | null,
                  capacityAbsolute?: number | null,
                } | null,
              } | null,
              organization?:  {
                __typename: "Organization",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                name?: string | null,
                organizationRelationships?: string | null,
                demographics?: string | null,
              } | null,
              groupEmailFooter?: string | null,
            } | null,
            fileName?: string | null,
            address?: string | null,
          } | null,
        } >,
        nextToken?: string | null,
      } | null,
      scenarioOwner?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      scenarioCreatedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      scenarioModifiedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      scenarioRASCIRoles?:  {
        __typename: "PaginatedRASCIUsersOnItem",
        items:  Array< {
          __typename: "RASCIUserOnItem",
          id?: string | null,
          sort?: string | null,
          userID?: string | null,
          userInfo?:  {
            __typename: "UserInfo",
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
          } | null,
          user?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
          userRASCIRole?: UserRoles | null,
        } >,
        nextToken?: string | null,
      } | null,
    } | null,
    scenarioTwo?:  {
      __typename: "Scenario",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      createdAt?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      lastModified?: string | null,
      FAIRSummary?: string | null,
      scenarioID?: string | null,
      title?: string | null,
      description?: string | null,
      notes?: string | null,
      assumptions?: string | null,
      creationDate?: string | null,
      lastRevision?: string | null,
      nextRevision?: string | null,
      categories?: string | null,
      owner?: string | null,
      status?: Status | null,
      dateClosed?: string | null,
      indicator?: Indicator | null,
      compositeIDYear?: string | null,
      compositeID?: number | null,
      archived?: boolean | null,
      isTemplate?: boolean | null,
      groupID?: string | null,
      scenarioRelatedItems?:  {
        __typename: "PaginatedLinks",
        items:  Array< {
          __typename: "Link",
          id: string,
          sort: string,
          target?: string | null,
          targetType?: LinkTarget | null,
          item?:  {
            __typename: "RelatableItem",
            id?: string | null,
            sort?: string | null,
            groupID?: string | null,
            owner?: string | null,
            uuid?: string | null,
            title?: string | null,
            dateClosed?: string | null,
            dueDate?: string | null,
            status?: Status | null,
            priority?: Priority | null,
            severity?: Severity | null,
            responseStatus?: string | null,
            archived?: boolean | null,
            createdAt?: string | null,
            lastModified?: string | null,
            compositeIDYear?: string | null,
            compositeID?: number | null,
            displayID?: string | null,
            relatedItemsOwner?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
            relatedItemsGroup?:  {
              __typename: "Group",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              organizationID?: string | null,
              title?: string | null,
              shortTitle?: string | null,
              riskThreshold?:  {
                __typename: "RiskThreshold",
                financial?:  {
                  __typename: "Threshold",
                  appetitePercent?: number | null,
                  appetiteAbsolute?: number | null,
                  capacityPercent?: number | null,
                  capacityAbsolute?: number | null,
                } | null,
                privacy?:  {
                  __typename: "Threshold",
                  appetitePercent?: number | null,
                  appetiteAbsolute?: number | null,
                  capacityPercent?: number | null,
                  capacityAbsolute?: number | null,
                } | null,
              } | null,
              organization?:  {
                __typename: "Organization",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                name?: string | null,
                organizationRelationships?: string | null,
                demographics?: string | null,
              } | null,
              groupEmailFooter?: string | null,
            } | null,
            fileName?: string | null,
            address?: string | null,
          } | null,
        } >,
        nextToken?: string | null,
      } | null,
      scenarioOwner?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      scenarioCreatedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      scenarioModifiedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      scenarioRASCIRoles?:  {
        __typename: "PaginatedRASCIUsersOnItem",
        items:  Array< {
          __typename: "RASCIUserOnItem",
          id?: string | null,
          sort?: string | null,
          userID?: string | null,
          userInfo?:  {
            __typename: "UserInfo",
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
          } | null,
          user?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
          userRASCIRole?: UserRoles | null,
        } >,
        nextToken?: string | null,
      } | null,
    } | null,
    scenarioThree?:  {
      __typename: "Scenario",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      createdAt?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      lastModified?: string | null,
      FAIRSummary?: string | null,
      scenarioID?: string | null,
      title?: string | null,
      description?: string | null,
      notes?: string | null,
      assumptions?: string | null,
      creationDate?: string | null,
      lastRevision?: string | null,
      nextRevision?: string | null,
      categories?: string | null,
      owner?: string | null,
      status?: Status | null,
      dateClosed?: string | null,
      indicator?: Indicator | null,
      compositeIDYear?: string | null,
      compositeID?: number | null,
      archived?: boolean | null,
      isTemplate?: boolean | null,
      groupID?: string | null,
      scenarioRelatedItems?:  {
        __typename: "PaginatedLinks",
        items:  Array< {
          __typename: "Link",
          id: string,
          sort: string,
          target?: string | null,
          targetType?: LinkTarget | null,
          item?:  {
            __typename: "RelatableItem",
            id?: string | null,
            sort?: string | null,
            groupID?: string | null,
            owner?: string | null,
            uuid?: string | null,
            title?: string | null,
            dateClosed?: string | null,
            dueDate?: string | null,
            status?: Status | null,
            priority?: Priority | null,
            severity?: Severity | null,
            responseStatus?: string | null,
            archived?: boolean | null,
            createdAt?: string | null,
            lastModified?: string | null,
            compositeIDYear?: string | null,
            compositeID?: number | null,
            displayID?: string | null,
            relatedItemsOwner?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
            relatedItemsGroup?:  {
              __typename: "Group",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              organizationID?: string | null,
              title?: string | null,
              shortTitle?: string | null,
              riskThreshold?:  {
                __typename: "RiskThreshold",
                financial?:  {
                  __typename: "Threshold",
                  appetitePercent?: number | null,
                  appetiteAbsolute?: number | null,
                  capacityPercent?: number | null,
                  capacityAbsolute?: number | null,
                } | null,
                privacy?:  {
                  __typename: "Threshold",
                  appetitePercent?: number | null,
                  appetiteAbsolute?: number | null,
                  capacityPercent?: number | null,
                  capacityAbsolute?: number | null,
                } | null,
              } | null,
              organization?:  {
                __typename: "Organization",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                name?: string | null,
                organizationRelationships?: string | null,
                demographics?: string | null,
              } | null,
              groupEmailFooter?: string | null,
            } | null,
            fileName?: string | null,
            address?: string | null,
          } | null,
        } >,
        nextToken?: string | null,
      } | null,
      scenarioOwner?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      scenarioCreatedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      scenarioModifiedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      scenarioRASCIRoles?:  {
        __typename: "PaginatedRASCIUsersOnItem",
        items:  Array< {
          __typename: "RASCIUserOnItem",
          id?: string | null,
          sort?: string | null,
          userID?: string | null,
          userInfo?:  {
            __typename: "UserInfo",
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
          } | null,
          user?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
          userRASCIRole?: UserRoles | null,
        } >,
        nextToken?: string | null,
      } | null,
    } | null,
    scenarioOneDescription?: string | null,
    scenarioTwoDescription?: string | null,
    scenarioThreeDescription?: string | null,
    lossThreshold?: number | null,
    archived?: boolean | null,
    modifiedBy?: string | null,
  } | null > | null,
};

export type DeleteRiskComparisonMutationVariables = {
  input: Array< DeleteRiskComparisonInput >,
};

export type DeleteRiskComparisonMutation = {
  DeleteRiskComparison?:  Array< {
    __typename: "RiskComparison",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    sort?: string | null,
    groupID?: string | null,
    title?: string | null,
    description?: string | null,
    scenarioOneID?: string | null,
    scenarioTwoID?: string | null,
    scenarioThreeID?: string | null,
    scenarioOne?:  {
      __typename: "Scenario",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      createdAt?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      lastModified?: string | null,
      FAIRSummary?: string | null,
      scenarioID?: string | null,
      title?: string | null,
      description?: string | null,
      notes?: string | null,
      assumptions?: string | null,
      creationDate?: string | null,
      lastRevision?: string | null,
      nextRevision?: string | null,
      categories?: string | null,
      owner?: string | null,
      status?: Status | null,
      dateClosed?: string | null,
      indicator?: Indicator | null,
      compositeIDYear?: string | null,
      compositeID?: number | null,
      archived?: boolean | null,
      isTemplate?: boolean | null,
      groupID?: string | null,
      scenarioRelatedItems?:  {
        __typename: "PaginatedLinks",
        items:  Array< {
          __typename: "Link",
          id: string,
          sort: string,
          target?: string | null,
          targetType?: LinkTarget | null,
          item?:  {
            __typename: "RelatableItem",
            id?: string | null,
            sort?: string | null,
            groupID?: string | null,
            owner?: string | null,
            uuid?: string | null,
            title?: string | null,
            dateClosed?: string | null,
            dueDate?: string | null,
            status?: Status | null,
            priority?: Priority | null,
            severity?: Severity | null,
            responseStatus?: string | null,
            archived?: boolean | null,
            createdAt?: string | null,
            lastModified?: string | null,
            compositeIDYear?: string | null,
            compositeID?: number | null,
            displayID?: string | null,
            relatedItemsOwner?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
            relatedItemsGroup?:  {
              __typename: "Group",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              organizationID?: string | null,
              title?: string | null,
              shortTitle?: string | null,
              riskThreshold?:  {
                __typename: "RiskThreshold",
                financial?:  {
                  __typename: "Threshold",
                  appetitePercent?: number | null,
                  appetiteAbsolute?: number | null,
                  capacityPercent?: number | null,
                  capacityAbsolute?: number | null,
                } | null,
                privacy?:  {
                  __typename: "Threshold",
                  appetitePercent?: number | null,
                  appetiteAbsolute?: number | null,
                  capacityPercent?: number | null,
                  capacityAbsolute?: number | null,
                } | null,
              } | null,
              organization?:  {
                __typename: "Organization",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                name?: string | null,
                organizationRelationships?: string | null,
                demographics?: string | null,
              } | null,
              groupEmailFooter?: string | null,
            } | null,
            fileName?: string | null,
            address?: string | null,
          } | null,
        } >,
        nextToken?: string | null,
      } | null,
      scenarioOwner?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      scenarioCreatedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      scenarioModifiedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      scenarioRASCIRoles?:  {
        __typename: "PaginatedRASCIUsersOnItem",
        items:  Array< {
          __typename: "RASCIUserOnItem",
          id?: string | null,
          sort?: string | null,
          userID?: string | null,
          userInfo?:  {
            __typename: "UserInfo",
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
          } | null,
          user?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
          userRASCIRole?: UserRoles | null,
        } >,
        nextToken?: string | null,
      } | null,
    } | null,
    scenarioTwo?:  {
      __typename: "Scenario",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      createdAt?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      lastModified?: string | null,
      FAIRSummary?: string | null,
      scenarioID?: string | null,
      title?: string | null,
      description?: string | null,
      notes?: string | null,
      assumptions?: string | null,
      creationDate?: string | null,
      lastRevision?: string | null,
      nextRevision?: string | null,
      categories?: string | null,
      owner?: string | null,
      status?: Status | null,
      dateClosed?: string | null,
      indicator?: Indicator | null,
      compositeIDYear?: string | null,
      compositeID?: number | null,
      archived?: boolean | null,
      isTemplate?: boolean | null,
      groupID?: string | null,
      scenarioRelatedItems?:  {
        __typename: "PaginatedLinks",
        items:  Array< {
          __typename: "Link",
          id: string,
          sort: string,
          target?: string | null,
          targetType?: LinkTarget | null,
          item?:  {
            __typename: "RelatableItem",
            id?: string | null,
            sort?: string | null,
            groupID?: string | null,
            owner?: string | null,
            uuid?: string | null,
            title?: string | null,
            dateClosed?: string | null,
            dueDate?: string | null,
            status?: Status | null,
            priority?: Priority | null,
            severity?: Severity | null,
            responseStatus?: string | null,
            archived?: boolean | null,
            createdAt?: string | null,
            lastModified?: string | null,
            compositeIDYear?: string | null,
            compositeID?: number | null,
            displayID?: string | null,
            relatedItemsOwner?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
            relatedItemsGroup?:  {
              __typename: "Group",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              organizationID?: string | null,
              title?: string | null,
              shortTitle?: string | null,
              riskThreshold?:  {
                __typename: "RiskThreshold",
                financial?:  {
                  __typename: "Threshold",
                  appetitePercent?: number | null,
                  appetiteAbsolute?: number | null,
                  capacityPercent?: number | null,
                  capacityAbsolute?: number | null,
                } | null,
                privacy?:  {
                  __typename: "Threshold",
                  appetitePercent?: number | null,
                  appetiteAbsolute?: number | null,
                  capacityPercent?: number | null,
                  capacityAbsolute?: number | null,
                } | null,
              } | null,
              organization?:  {
                __typename: "Organization",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                name?: string | null,
                organizationRelationships?: string | null,
                demographics?: string | null,
              } | null,
              groupEmailFooter?: string | null,
            } | null,
            fileName?: string | null,
            address?: string | null,
          } | null,
        } >,
        nextToken?: string | null,
      } | null,
      scenarioOwner?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      scenarioCreatedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      scenarioModifiedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      scenarioRASCIRoles?:  {
        __typename: "PaginatedRASCIUsersOnItem",
        items:  Array< {
          __typename: "RASCIUserOnItem",
          id?: string | null,
          sort?: string | null,
          userID?: string | null,
          userInfo?:  {
            __typename: "UserInfo",
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
          } | null,
          user?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
          userRASCIRole?: UserRoles | null,
        } >,
        nextToken?: string | null,
      } | null,
    } | null,
    scenarioThree?:  {
      __typename: "Scenario",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      createdAt?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      lastModified?: string | null,
      FAIRSummary?: string | null,
      scenarioID?: string | null,
      title?: string | null,
      description?: string | null,
      notes?: string | null,
      assumptions?: string | null,
      creationDate?: string | null,
      lastRevision?: string | null,
      nextRevision?: string | null,
      categories?: string | null,
      owner?: string | null,
      status?: Status | null,
      dateClosed?: string | null,
      indicator?: Indicator | null,
      compositeIDYear?: string | null,
      compositeID?: number | null,
      archived?: boolean | null,
      isTemplate?: boolean | null,
      groupID?: string | null,
      scenarioRelatedItems?:  {
        __typename: "PaginatedLinks",
        items:  Array< {
          __typename: "Link",
          id: string,
          sort: string,
          target?: string | null,
          targetType?: LinkTarget | null,
          item?:  {
            __typename: "RelatableItem",
            id?: string | null,
            sort?: string | null,
            groupID?: string | null,
            owner?: string | null,
            uuid?: string | null,
            title?: string | null,
            dateClosed?: string | null,
            dueDate?: string | null,
            status?: Status | null,
            priority?: Priority | null,
            severity?: Severity | null,
            responseStatus?: string | null,
            archived?: boolean | null,
            createdAt?: string | null,
            lastModified?: string | null,
            compositeIDYear?: string | null,
            compositeID?: number | null,
            displayID?: string | null,
            relatedItemsOwner?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
            relatedItemsGroup?:  {
              __typename: "Group",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              organizationID?: string | null,
              title?: string | null,
              shortTitle?: string | null,
              riskThreshold?:  {
                __typename: "RiskThreshold",
                financial?:  {
                  __typename: "Threshold",
                  appetitePercent?: number | null,
                  appetiteAbsolute?: number | null,
                  capacityPercent?: number | null,
                  capacityAbsolute?: number | null,
                } | null,
                privacy?:  {
                  __typename: "Threshold",
                  appetitePercent?: number | null,
                  appetiteAbsolute?: number | null,
                  capacityPercent?: number | null,
                  capacityAbsolute?: number | null,
                } | null,
              } | null,
              organization?:  {
                __typename: "Organization",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                name?: string | null,
                organizationRelationships?: string | null,
                demographics?: string | null,
              } | null,
              groupEmailFooter?: string | null,
            } | null,
            fileName?: string | null,
            address?: string | null,
          } | null,
        } >,
        nextToken?: string | null,
      } | null,
      scenarioOwner?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      scenarioCreatedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      scenarioModifiedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      scenarioRASCIRoles?:  {
        __typename: "PaginatedRASCIUsersOnItem",
        items:  Array< {
          __typename: "RASCIUserOnItem",
          id?: string | null,
          sort?: string | null,
          userID?: string | null,
          userInfo?:  {
            __typename: "UserInfo",
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
          } | null,
          user?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
          userRASCIRole?: UserRoles | null,
        } >,
        nextToken?: string | null,
      } | null,
    } | null,
    scenarioOneDescription?: string | null,
    scenarioTwoDescription?: string | null,
    scenarioThreeDescription?: string | null,
    lossThreshold?: number | null,
    archived?: boolean | null,
    modifiedBy?: string | null,
  } | null > | null,
};

export type CreateRiskDomainMutationVariables = {
  input: Array< CreateRiskDomainInput >,
};

export type CreateRiskDomainMutation = {
  CreateRiskDomain?:  Array< {
    __typename: "RiskDomain",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    groupID?: string | null,
    sort?: string | null,
    createdAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    lastModified?: string | null,
    title?: string | null,
    description?: string | null,
    displayID?: string | null,
    parentID?: string | null,
    parent?:  {
      __typename: "RiskDomain",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      groupID?: string | null,
      sort?: string | null,
      createdAt?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      lastModified?: string | null,
      title?: string | null,
      description?: string | null,
      displayID?: string | null,
      parentID?: string | null,
      parent?:  {
        __typename: "RiskDomain",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        groupID?: string | null,
        sort?: string | null,
        createdAt?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        lastModified?: string | null,
        title?: string | null,
        description?: string | null,
        displayID?: string | null,
        parentID?: string | null,
        parent?:  {
          __typename: "RiskDomain",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          groupID?: string | null,
          sort?: string | null,
          createdAt?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          lastModified?: string | null,
          title?: string | null,
          description?: string | null,
          displayID?: string | null,
          parentID?: string | null,
          parent?:  {
            __typename: "RiskDomain",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            groupID?: string | null,
            sort?: string | null,
            createdAt?: string | null,
            createdBy?: string | null,
            modifiedBy?: string | null,
            lastModified?: string | null,
            title?: string | null,
            description?: string | null,
            displayID?: string | null,
            parentID?: string | null,
            parent?:  {
              __typename: "RiskDomain",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              groupID?: string | null,
              sort?: string | null,
              createdAt?: string | null,
              createdBy?: string | null,
              modifiedBy?: string | null,
              lastModified?: string | null,
              title?: string | null,
              description?: string | null,
              displayID?: string | null,
              parentID?: string | null,
              parent?:  {
                __typename: "RiskDomain",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                groupID?: string | null,
                sort?: string | null,
                createdAt?: string | null,
                createdBy?: string | null,
                modifiedBy?: string | null,
                lastModified?: string | null,
                title?: string | null,
                description?: string | null,
                displayID?: string | null,
                parentID?: string | null,
                parent?:  {
                  __typename: "RiskDomain",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  groupID?: string | null,
                  sort?: string | null,
                  createdAt?: string | null,
                  createdBy?: string | null,
                  modifiedBy?: string | null,
                  lastModified?: string | null,
                  title?: string | null,
                  description?: string | null,
                  displayID?: string | null,
                  parentID?: string | null,
                  archived?: boolean | null,
                } | null,
                archived?: boolean | null,
                riskDomainCreatedBy?:  {
                  __typename: "User",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  email?: string | null,
                  username?: string | null,
                  firstName?: string | null,
                  lastName?: string | null,
                  inactive?: boolean | null,
                  lastSeen?: string | null,
                  lastLoggedIn?: string | null,
                } | null,
                riskDomainModifiedBy?:  {
                  __typename: "User",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  email?: string | null,
                  username?: string | null,
                  firstName?: string | null,
                  lastName?: string | null,
                  inactive?: boolean | null,
                  lastSeen?: string | null,
                  lastLoggedIn?: string | null,
                } | null,
              } | null,
              archived?: boolean | null,
              riskDomainCreatedBy?:  {
                __typename: "User",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                username?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
                lastSeen?: string | null,
                lastLoggedIn?: string | null,
              } | null,
              riskDomainModifiedBy?:  {
                __typename: "User",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                username?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
                lastSeen?: string | null,
                lastLoggedIn?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            riskDomainCreatedBy?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
            riskDomainModifiedBy?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          riskDomainCreatedBy?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
          riskDomainModifiedBy?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        riskDomainCreatedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        riskDomainModifiedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      riskDomainCreatedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      riskDomainModifiedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
    } | null,
    archived?: boolean | null,
    riskDomainCreatedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    riskDomainModifiedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
  } | null > | null,
};

export type ModifyRiskDomainMutationVariables = {
  input: Array< UpdateRiskDomainInput >,
};

export type ModifyRiskDomainMutation = {
  ModifyRiskDomain?:  Array< {
    __typename: "RiskDomain",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    groupID?: string | null,
    sort?: string | null,
    createdAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    lastModified?: string | null,
    title?: string | null,
    description?: string | null,
    displayID?: string | null,
    parentID?: string | null,
    parent?:  {
      __typename: "RiskDomain",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      groupID?: string | null,
      sort?: string | null,
      createdAt?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      lastModified?: string | null,
      title?: string | null,
      description?: string | null,
      displayID?: string | null,
      parentID?: string | null,
      parent?:  {
        __typename: "RiskDomain",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        groupID?: string | null,
        sort?: string | null,
        createdAt?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        lastModified?: string | null,
        title?: string | null,
        description?: string | null,
        displayID?: string | null,
        parentID?: string | null,
        parent?:  {
          __typename: "RiskDomain",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          groupID?: string | null,
          sort?: string | null,
          createdAt?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          lastModified?: string | null,
          title?: string | null,
          description?: string | null,
          displayID?: string | null,
          parentID?: string | null,
          parent?:  {
            __typename: "RiskDomain",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            groupID?: string | null,
            sort?: string | null,
            createdAt?: string | null,
            createdBy?: string | null,
            modifiedBy?: string | null,
            lastModified?: string | null,
            title?: string | null,
            description?: string | null,
            displayID?: string | null,
            parentID?: string | null,
            parent?:  {
              __typename: "RiskDomain",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              groupID?: string | null,
              sort?: string | null,
              createdAt?: string | null,
              createdBy?: string | null,
              modifiedBy?: string | null,
              lastModified?: string | null,
              title?: string | null,
              description?: string | null,
              displayID?: string | null,
              parentID?: string | null,
              parent?:  {
                __typename: "RiskDomain",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                groupID?: string | null,
                sort?: string | null,
                createdAt?: string | null,
                createdBy?: string | null,
                modifiedBy?: string | null,
                lastModified?: string | null,
                title?: string | null,
                description?: string | null,
                displayID?: string | null,
                parentID?: string | null,
                parent?:  {
                  __typename: "RiskDomain",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  groupID?: string | null,
                  sort?: string | null,
                  createdAt?: string | null,
                  createdBy?: string | null,
                  modifiedBy?: string | null,
                  lastModified?: string | null,
                  title?: string | null,
                  description?: string | null,
                  displayID?: string | null,
                  parentID?: string | null,
                  archived?: boolean | null,
                } | null,
                archived?: boolean | null,
                riskDomainCreatedBy?:  {
                  __typename: "User",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  email?: string | null,
                  username?: string | null,
                  firstName?: string | null,
                  lastName?: string | null,
                  inactive?: boolean | null,
                  lastSeen?: string | null,
                  lastLoggedIn?: string | null,
                } | null,
                riskDomainModifiedBy?:  {
                  __typename: "User",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  email?: string | null,
                  username?: string | null,
                  firstName?: string | null,
                  lastName?: string | null,
                  inactive?: boolean | null,
                  lastSeen?: string | null,
                  lastLoggedIn?: string | null,
                } | null,
              } | null,
              archived?: boolean | null,
              riskDomainCreatedBy?:  {
                __typename: "User",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                username?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
                lastSeen?: string | null,
                lastLoggedIn?: string | null,
              } | null,
              riskDomainModifiedBy?:  {
                __typename: "User",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                username?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
                lastSeen?: string | null,
                lastLoggedIn?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            riskDomainCreatedBy?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
            riskDomainModifiedBy?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          riskDomainCreatedBy?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
          riskDomainModifiedBy?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        riskDomainCreatedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        riskDomainModifiedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      riskDomainCreatedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      riskDomainModifiedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
    } | null,
    archived?: boolean | null,
    riskDomainCreatedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    riskDomainModifiedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
  } | null > | null,
};

export type DeleteRiskDomainMutationVariables = {
  input: Array< DeleteRiskDomainInput >,
};

export type DeleteRiskDomainMutation = {
  DeleteRiskDomain?:  Array< {
    __typename: "RiskDomain",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    groupID?: string | null,
    sort?: string | null,
    createdAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    lastModified?: string | null,
    title?: string | null,
    description?: string | null,
    displayID?: string | null,
    parentID?: string | null,
    parent?:  {
      __typename: "RiskDomain",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      groupID?: string | null,
      sort?: string | null,
      createdAt?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      lastModified?: string | null,
      title?: string | null,
      description?: string | null,
      displayID?: string | null,
      parentID?: string | null,
      parent?:  {
        __typename: "RiskDomain",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        groupID?: string | null,
        sort?: string | null,
        createdAt?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        lastModified?: string | null,
        title?: string | null,
        description?: string | null,
        displayID?: string | null,
        parentID?: string | null,
        parent?:  {
          __typename: "RiskDomain",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          groupID?: string | null,
          sort?: string | null,
          createdAt?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          lastModified?: string | null,
          title?: string | null,
          description?: string | null,
          displayID?: string | null,
          parentID?: string | null,
          parent?:  {
            __typename: "RiskDomain",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            groupID?: string | null,
            sort?: string | null,
            createdAt?: string | null,
            createdBy?: string | null,
            modifiedBy?: string | null,
            lastModified?: string | null,
            title?: string | null,
            description?: string | null,
            displayID?: string | null,
            parentID?: string | null,
            parent?:  {
              __typename: "RiskDomain",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              groupID?: string | null,
              sort?: string | null,
              createdAt?: string | null,
              createdBy?: string | null,
              modifiedBy?: string | null,
              lastModified?: string | null,
              title?: string | null,
              description?: string | null,
              displayID?: string | null,
              parentID?: string | null,
              parent?:  {
                __typename: "RiskDomain",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                groupID?: string | null,
                sort?: string | null,
                createdAt?: string | null,
                createdBy?: string | null,
                modifiedBy?: string | null,
                lastModified?: string | null,
                title?: string | null,
                description?: string | null,
                displayID?: string | null,
                parentID?: string | null,
                parent?:  {
                  __typename: "RiskDomain",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  groupID?: string | null,
                  sort?: string | null,
                  createdAt?: string | null,
                  createdBy?: string | null,
                  modifiedBy?: string | null,
                  lastModified?: string | null,
                  title?: string | null,
                  description?: string | null,
                  displayID?: string | null,
                  parentID?: string | null,
                  archived?: boolean | null,
                } | null,
                archived?: boolean | null,
                riskDomainCreatedBy?:  {
                  __typename: "User",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  email?: string | null,
                  username?: string | null,
                  firstName?: string | null,
                  lastName?: string | null,
                  inactive?: boolean | null,
                  lastSeen?: string | null,
                  lastLoggedIn?: string | null,
                } | null,
                riskDomainModifiedBy?:  {
                  __typename: "User",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  email?: string | null,
                  username?: string | null,
                  firstName?: string | null,
                  lastName?: string | null,
                  inactive?: boolean | null,
                  lastSeen?: string | null,
                  lastLoggedIn?: string | null,
                } | null,
              } | null,
              archived?: boolean | null,
              riskDomainCreatedBy?:  {
                __typename: "User",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                username?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
                lastSeen?: string | null,
                lastLoggedIn?: string | null,
              } | null,
              riskDomainModifiedBy?:  {
                __typename: "User",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                username?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
                lastSeen?: string | null,
                lastLoggedIn?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            riskDomainCreatedBy?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
            riskDomainModifiedBy?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          riskDomainCreatedBy?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
          riskDomainModifiedBy?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        riskDomainCreatedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        riskDomainModifiedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      riskDomainCreatedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      riskDomainModifiedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
    } | null,
    archived?: boolean | null,
    riskDomainCreatedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    riskDomainModifiedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
  } | null > | null,
};

export type CreateAuditPlanningMutationVariables = {
  input: Array< CreateAuditPlanningInput >,
};

export type CreateAuditPlanningMutation = {
  CreateAuditPlanning?:  Array< {
    __typename: "AuditPlanning",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    title?: string | null,
    groupID?: string | null,
    sort?: string | null,
    createdAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    lastModified?: string | null,
    auditPlanID?: string | null,
    riskDomain?: string | null,
    year?: string | null,
    hours?: string | null,
    owner?: string | null,
    days?: string | null,
    status?: Status | null,
    background?: string | null,
    objective?: string | null,
    scope?: string | null,
    methodology?: string | null,
    preliminaryAuditWork?: string | null,
    points?: string | null,
    summary?: string | null,
    auditFindings?: string | null,
    recommendations?: string | null,
    auditStartDate?: string | null,
    auditEndDate?: string | null,
    auditor?: string | null,
    totalHours?: string | null,
    archived?: boolean | null,
    auditPlanningRelatedItems?:  {
      __typename: "PaginatedLinks",
      items:  Array< {
        __typename: "Link",
        id: string,
        sort: string,
        target?: string | null,
        targetType?: LinkTarget | null,
        item?:  {
          __typename: "RelatableItem",
          id?: string | null,
          sort?: string | null,
          groupID?: string | null,
          owner?: string | null,
          uuid?: string | null,
          title?: string | null,
          dateClosed?: string | null,
          dueDate?: string | null,
          status?: Status | null,
          priority?: Priority | null,
          severity?: Severity | null,
          responseStatus?: string | null,
          archived?: boolean | null,
          createdAt?: string | null,
          lastModified?: string | null,
          compositeIDYear?: string | null,
          compositeID?: number | null,
          displayID?: string | null,
          relatedItemsOwner?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
          relatedItemsGroup?:  {
            __typename: "Group",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            organizationID?: string | null,
            title?: string | null,
            shortTitle?: string | null,
            riskThreshold?:  {
              __typename: "RiskThreshold",
              financial?:  {
                __typename: "Threshold",
                appetitePercent?: number | null,
                appetiteAbsolute?: number | null,
                capacityPercent?: number | null,
                capacityAbsolute?: number | null,
              } | null,
              privacy?:  {
                __typename: "Threshold",
                appetitePercent?: number | null,
                appetiteAbsolute?: number | null,
                capacityPercent?: number | null,
                capacityAbsolute?: number | null,
              } | null,
            } | null,
            organization?:  {
              __typename: "Organization",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              name?: string | null,
              organizationRelationships?: string | null,
              demographics?: string | null,
            } | null,
            groupEmailFooter?: string | null,
          } | null,
          fileName?: string | null,
          address?: string | null,
        } | null,
      } >,
      nextToken?: string | null,
    } | null,
    isTemplate?: boolean | null,
    auditPlanningOwner?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    auditPlanningCreatedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    auditPlanningModifiedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    riskDomainDetails?:  {
      __typename: "RiskDomain",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      groupID?: string | null,
      sort?: string | null,
      createdAt?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      lastModified?: string | null,
      title?: string | null,
      description?: string | null,
      displayID?: string | null,
      parentID?: string | null,
      parent?:  {
        __typename: "RiskDomain",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        groupID?: string | null,
        sort?: string | null,
        createdAt?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        lastModified?: string | null,
        title?: string | null,
        description?: string | null,
        displayID?: string | null,
        parentID?: string | null,
        parent?:  {
          __typename: "RiskDomain",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          groupID?: string | null,
          sort?: string | null,
          createdAt?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          lastModified?: string | null,
          title?: string | null,
          description?: string | null,
          displayID?: string | null,
          parentID?: string | null,
          parent?:  {
            __typename: "RiskDomain",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            groupID?: string | null,
            sort?: string | null,
            createdAt?: string | null,
            createdBy?: string | null,
            modifiedBy?: string | null,
            lastModified?: string | null,
            title?: string | null,
            description?: string | null,
            displayID?: string | null,
            parentID?: string | null,
            parent?:  {
              __typename: "RiskDomain",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              groupID?: string | null,
              sort?: string | null,
              createdAt?: string | null,
              createdBy?: string | null,
              modifiedBy?: string | null,
              lastModified?: string | null,
              title?: string | null,
              description?: string | null,
              displayID?: string | null,
              parentID?: string | null,
              parent?:  {
                __typename: "RiskDomain",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                groupID?: string | null,
                sort?: string | null,
                createdAt?: string | null,
                createdBy?: string | null,
                modifiedBy?: string | null,
                lastModified?: string | null,
                title?: string | null,
                description?: string | null,
                displayID?: string | null,
                parentID?: string | null,
                parent?:  {
                  __typename: "RiskDomain",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  groupID?: string | null,
                  sort?: string | null,
                  createdAt?: string | null,
                  createdBy?: string | null,
                  modifiedBy?: string | null,
                  lastModified?: string | null,
                  title?: string | null,
                  description?: string | null,
                  displayID?: string | null,
                  parentID?: string | null,
                  archived?: boolean | null,
                } | null,
                archived?: boolean | null,
                riskDomainCreatedBy?:  {
                  __typename: "User",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  email?: string | null,
                  username?: string | null,
                  firstName?: string | null,
                  lastName?: string | null,
                  inactive?: boolean | null,
                  lastSeen?: string | null,
                  lastLoggedIn?: string | null,
                } | null,
                riskDomainModifiedBy?:  {
                  __typename: "User",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  email?: string | null,
                  username?: string | null,
                  firstName?: string | null,
                  lastName?: string | null,
                  inactive?: boolean | null,
                  lastSeen?: string | null,
                  lastLoggedIn?: string | null,
                } | null,
              } | null,
              archived?: boolean | null,
              riskDomainCreatedBy?:  {
                __typename: "User",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                username?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
                lastSeen?: string | null,
                lastLoggedIn?: string | null,
              } | null,
              riskDomainModifiedBy?:  {
                __typename: "User",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                username?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
                lastSeen?: string | null,
                lastLoggedIn?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            riskDomainCreatedBy?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
            riskDomainModifiedBy?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          riskDomainCreatedBy?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
          riskDomainModifiedBy?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        riskDomainCreatedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        riskDomainModifiedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      riskDomainCreatedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      riskDomainModifiedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
    } | null,
    managementResponse?: string | null,
    viewType?: ViewType | null,
  } | null > | null,
};

export type ModifyAuditPlanningMutationVariables = {
  input: Array< UpdateAuditPlanningInput >,
};

export type ModifyAuditPlanningMutation = {
  ModifyAuditPlanning?:  Array< {
    __typename: "AuditPlanning",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    title?: string | null,
    groupID?: string | null,
    sort?: string | null,
    createdAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    lastModified?: string | null,
    auditPlanID?: string | null,
    riskDomain?: string | null,
    year?: string | null,
    hours?: string | null,
    owner?: string | null,
    days?: string | null,
    status?: Status | null,
    background?: string | null,
    objective?: string | null,
    scope?: string | null,
    methodology?: string | null,
    preliminaryAuditWork?: string | null,
    points?: string | null,
    summary?: string | null,
    auditFindings?: string | null,
    recommendations?: string | null,
    auditStartDate?: string | null,
    auditEndDate?: string | null,
    auditor?: string | null,
    totalHours?: string | null,
    archived?: boolean | null,
    auditPlanningRelatedItems?:  {
      __typename: "PaginatedLinks",
      items:  Array< {
        __typename: "Link",
        id: string,
        sort: string,
        target?: string | null,
        targetType?: LinkTarget | null,
        item?:  {
          __typename: "RelatableItem",
          id?: string | null,
          sort?: string | null,
          groupID?: string | null,
          owner?: string | null,
          uuid?: string | null,
          title?: string | null,
          dateClosed?: string | null,
          dueDate?: string | null,
          status?: Status | null,
          priority?: Priority | null,
          severity?: Severity | null,
          responseStatus?: string | null,
          archived?: boolean | null,
          createdAt?: string | null,
          lastModified?: string | null,
          compositeIDYear?: string | null,
          compositeID?: number | null,
          displayID?: string | null,
          relatedItemsOwner?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
          relatedItemsGroup?:  {
            __typename: "Group",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            organizationID?: string | null,
            title?: string | null,
            shortTitle?: string | null,
            riskThreshold?:  {
              __typename: "RiskThreshold",
              financial?:  {
                __typename: "Threshold",
                appetitePercent?: number | null,
                appetiteAbsolute?: number | null,
                capacityPercent?: number | null,
                capacityAbsolute?: number | null,
              } | null,
              privacy?:  {
                __typename: "Threshold",
                appetitePercent?: number | null,
                appetiteAbsolute?: number | null,
                capacityPercent?: number | null,
                capacityAbsolute?: number | null,
              } | null,
            } | null,
            organization?:  {
              __typename: "Organization",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              name?: string | null,
              organizationRelationships?: string | null,
              demographics?: string | null,
            } | null,
            groupEmailFooter?: string | null,
          } | null,
          fileName?: string | null,
          address?: string | null,
        } | null,
      } >,
      nextToken?: string | null,
    } | null,
    isTemplate?: boolean | null,
    auditPlanningOwner?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    auditPlanningCreatedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    auditPlanningModifiedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    riskDomainDetails?:  {
      __typename: "RiskDomain",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      groupID?: string | null,
      sort?: string | null,
      createdAt?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      lastModified?: string | null,
      title?: string | null,
      description?: string | null,
      displayID?: string | null,
      parentID?: string | null,
      parent?:  {
        __typename: "RiskDomain",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        groupID?: string | null,
        sort?: string | null,
        createdAt?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        lastModified?: string | null,
        title?: string | null,
        description?: string | null,
        displayID?: string | null,
        parentID?: string | null,
        parent?:  {
          __typename: "RiskDomain",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          groupID?: string | null,
          sort?: string | null,
          createdAt?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          lastModified?: string | null,
          title?: string | null,
          description?: string | null,
          displayID?: string | null,
          parentID?: string | null,
          parent?:  {
            __typename: "RiskDomain",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            groupID?: string | null,
            sort?: string | null,
            createdAt?: string | null,
            createdBy?: string | null,
            modifiedBy?: string | null,
            lastModified?: string | null,
            title?: string | null,
            description?: string | null,
            displayID?: string | null,
            parentID?: string | null,
            parent?:  {
              __typename: "RiskDomain",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              groupID?: string | null,
              sort?: string | null,
              createdAt?: string | null,
              createdBy?: string | null,
              modifiedBy?: string | null,
              lastModified?: string | null,
              title?: string | null,
              description?: string | null,
              displayID?: string | null,
              parentID?: string | null,
              parent?:  {
                __typename: "RiskDomain",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                groupID?: string | null,
                sort?: string | null,
                createdAt?: string | null,
                createdBy?: string | null,
                modifiedBy?: string | null,
                lastModified?: string | null,
                title?: string | null,
                description?: string | null,
                displayID?: string | null,
                parentID?: string | null,
                parent?:  {
                  __typename: "RiskDomain",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  groupID?: string | null,
                  sort?: string | null,
                  createdAt?: string | null,
                  createdBy?: string | null,
                  modifiedBy?: string | null,
                  lastModified?: string | null,
                  title?: string | null,
                  description?: string | null,
                  displayID?: string | null,
                  parentID?: string | null,
                  archived?: boolean | null,
                } | null,
                archived?: boolean | null,
                riskDomainCreatedBy?:  {
                  __typename: "User",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  email?: string | null,
                  username?: string | null,
                  firstName?: string | null,
                  lastName?: string | null,
                  inactive?: boolean | null,
                  lastSeen?: string | null,
                  lastLoggedIn?: string | null,
                } | null,
                riskDomainModifiedBy?:  {
                  __typename: "User",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  email?: string | null,
                  username?: string | null,
                  firstName?: string | null,
                  lastName?: string | null,
                  inactive?: boolean | null,
                  lastSeen?: string | null,
                  lastLoggedIn?: string | null,
                } | null,
              } | null,
              archived?: boolean | null,
              riskDomainCreatedBy?:  {
                __typename: "User",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                username?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
                lastSeen?: string | null,
                lastLoggedIn?: string | null,
              } | null,
              riskDomainModifiedBy?:  {
                __typename: "User",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                username?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
                lastSeen?: string | null,
                lastLoggedIn?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            riskDomainCreatedBy?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
            riskDomainModifiedBy?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          riskDomainCreatedBy?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
          riskDomainModifiedBy?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        riskDomainCreatedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        riskDomainModifiedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      riskDomainCreatedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      riskDomainModifiedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
    } | null,
    managementResponse?: string | null,
    viewType?: ViewType | null,
  } | null > | null,
};

export type DeleteAuditPlanningMutationVariables = {
  input: Array< DeleteAuditPlanningInput >,
};

export type DeleteAuditPlanningMutation = {
  DeleteAuditPlanning?:  Array< {
    __typename: "AuditPlanning",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    title?: string | null,
    groupID?: string | null,
    sort?: string | null,
    createdAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    lastModified?: string | null,
    auditPlanID?: string | null,
    riskDomain?: string | null,
    year?: string | null,
    hours?: string | null,
    owner?: string | null,
    days?: string | null,
    status?: Status | null,
    background?: string | null,
    objective?: string | null,
    scope?: string | null,
    methodology?: string | null,
    preliminaryAuditWork?: string | null,
    points?: string | null,
    summary?: string | null,
    auditFindings?: string | null,
    recommendations?: string | null,
    auditStartDate?: string | null,
    auditEndDate?: string | null,
    auditor?: string | null,
    totalHours?: string | null,
    archived?: boolean | null,
    auditPlanningRelatedItems?:  {
      __typename: "PaginatedLinks",
      items:  Array< {
        __typename: "Link",
        id: string,
        sort: string,
        target?: string | null,
        targetType?: LinkTarget | null,
        item?:  {
          __typename: "RelatableItem",
          id?: string | null,
          sort?: string | null,
          groupID?: string | null,
          owner?: string | null,
          uuid?: string | null,
          title?: string | null,
          dateClosed?: string | null,
          dueDate?: string | null,
          status?: Status | null,
          priority?: Priority | null,
          severity?: Severity | null,
          responseStatus?: string | null,
          archived?: boolean | null,
          createdAt?: string | null,
          lastModified?: string | null,
          compositeIDYear?: string | null,
          compositeID?: number | null,
          displayID?: string | null,
          relatedItemsOwner?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
          relatedItemsGroup?:  {
            __typename: "Group",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            organizationID?: string | null,
            title?: string | null,
            shortTitle?: string | null,
            riskThreshold?:  {
              __typename: "RiskThreshold",
              financial?:  {
                __typename: "Threshold",
                appetitePercent?: number | null,
                appetiteAbsolute?: number | null,
                capacityPercent?: number | null,
                capacityAbsolute?: number | null,
              } | null,
              privacy?:  {
                __typename: "Threshold",
                appetitePercent?: number | null,
                appetiteAbsolute?: number | null,
                capacityPercent?: number | null,
                capacityAbsolute?: number | null,
              } | null,
            } | null,
            organization?:  {
              __typename: "Organization",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              name?: string | null,
              organizationRelationships?: string | null,
              demographics?: string | null,
            } | null,
            groupEmailFooter?: string | null,
          } | null,
          fileName?: string | null,
          address?: string | null,
        } | null,
      } >,
      nextToken?: string | null,
    } | null,
    isTemplate?: boolean | null,
    auditPlanningOwner?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    auditPlanningCreatedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    auditPlanningModifiedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    riskDomainDetails?:  {
      __typename: "RiskDomain",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      groupID?: string | null,
      sort?: string | null,
      createdAt?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      lastModified?: string | null,
      title?: string | null,
      description?: string | null,
      displayID?: string | null,
      parentID?: string | null,
      parent?:  {
        __typename: "RiskDomain",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        groupID?: string | null,
        sort?: string | null,
        createdAt?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        lastModified?: string | null,
        title?: string | null,
        description?: string | null,
        displayID?: string | null,
        parentID?: string | null,
        parent?:  {
          __typename: "RiskDomain",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          groupID?: string | null,
          sort?: string | null,
          createdAt?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          lastModified?: string | null,
          title?: string | null,
          description?: string | null,
          displayID?: string | null,
          parentID?: string | null,
          parent?:  {
            __typename: "RiskDomain",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            groupID?: string | null,
            sort?: string | null,
            createdAt?: string | null,
            createdBy?: string | null,
            modifiedBy?: string | null,
            lastModified?: string | null,
            title?: string | null,
            description?: string | null,
            displayID?: string | null,
            parentID?: string | null,
            parent?:  {
              __typename: "RiskDomain",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              groupID?: string | null,
              sort?: string | null,
              createdAt?: string | null,
              createdBy?: string | null,
              modifiedBy?: string | null,
              lastModified?: string | null,
              title?: string | null,
              description?: string | null,
              displayID?: string | null,
              parentID?: string | null,
              parent?:  {
                __typename: "RiskDomain",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                groupID?: string | null,
                sort?: string | null,
                createdAt?: string | null,
                createdBy?: string | null,
                modifiedBy?: string | null,
                lastModified?: string | null,
                title?: string | null,
                description?: string | null,
                displayID?: string | null,
                parentID?: string | null,
                parent?:  {
                  __typename: "RiskDomain",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  groupID?: string | null,
                  sort?: string | null,
                  createdAt?: string | null,
                  createdBy?: string | null,
                  modifiedBy?: string | null,
                  lastModified?: string | null,
                  title?: string | null,
                  description?: string | null,
                  displayID?: string | null,
                  parentID?: string | null,
                  archived?: boolean | null,
                } | null,
                archived?: boolean | null,
                riskDomainCreatedBy?:  {
                  __typename: "User",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  email?: string | null,
                  username?: string | null,
                  firstName?: string | null,
                  lastName?: string | null,
                  inactive?: boolean | null,
                  lastSeen?: string | null,
                  lastLoggedIn?: string | null,
                } | null,
                riskDomainModifiedBy?:  {
                  __typename: "User",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  email?: string | null,
                  username?: string | null,
                  firstName?: string | null,
                  lastName?: string | null,
                  inactive?: boolean | null,
                  lastSeen?: string | null,
                  lastLoggedIn?: string | null,
                } | null,
              } | null,
              archived?: boolean | null,
              riskDomainCreatedBy?:  {
                __typename: "User",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                username?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
                lastSeen?: string | null,
                lastLoggedIn?: string | null,
              } | null,
              riskDomainModifiedBy?:  {
                __typename: "User",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                username?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
                lastSeen?: string | null,
                lastLoggedIn?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            riskDomainCreatedBy?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
            riskDomainModifiedBy?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          riskDomainCreatedBy?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
          riskDomainModifiedBy?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        riskDomainCreatedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        riskDomainModifiedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      riskDomainCreatedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      riskDomainModifiedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
    } | null,
    managementResponse?: string | null,
    viewType?: ViewType | null,
  } | null > | null,
};

export type CreateUrlMutationVariables = {
  input: Array< CreateUrlInput >,
};

export type CreateUrlMutation = {
  CreateUrl?:  Array< {
    __typename: "Url",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    sort?: string | null,
    address?: string | null,
    title?: string | null,
    groupID?: string | null,
  } | null > | null,
};

export type ModifyUrlMutationVariables = {
  input: Array< UpdateUrlInput >,
};

export type ModifyUrlMutation = {
  ModifyUrl?:  Array< {
    __typename: "Url",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    sort?: string | null,
    address?: string | null,
    title?: string | null,
    groupID?: string | null,
  } | null > | null,
};

export type DeleteUrlMutationVariables = {
  input: Array< DeleteUrlInput >,
};

export type DeleteUrlMutation = {
  DeleteUrl?:  Array< {
    __typename: "Url",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    sort?: string | null,
    address?: string | null,
    title?: string | null,
    groupID?: string | null,
  } | null > | null,
};

export type CreateCategoryMutationVariables = {
  input: Array< CreateCategoryInput >,
};

export type CreateCategoryMutation = {
  CreateCategory?:  Array< {
    __typename: "Category",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    groupID?: string | null,
    sort?: string | null,
    createdAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    lastModified?: string | null,
    title?: string | null,
    archived?: boolean | null,
    categoryCreatedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    categoryModifiedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
  } | null > | null,
};

export type ModifyCategoryMutationVariables = {
  input: Array< UpdateCategoryInput >,
};

export type ModifyCategoryMutation = {
  ModifyCategory?:  Array< {
    __typename: "Category",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    groupID?: string | null,
    sort?: string | null,
    createdAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    lastModified?: string | null,
    title?: string | null,
    archived?: boolean | null,
    categoryCreatedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    categoryModifiedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
  } | null > | null,
};

export type DeleteCategoryMutationVariables = {
  input: Array< DeleteCategoryInput >,
};

export type DeleteCategoryMutation = {
  DeleteCategory?:  Array< {
    __typename: "Category",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    groupID?: string | null,
    sort?: string | null,
    createdAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    lastModified?: string | null,
    title?: string | null,
    archived?: boolean | null,
    categoryCreatedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    categoryModifiedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
  } | null > | null,
};

export type CreateSupportMutationVariables = {
  input: Array< CreateSupportInput >,
};

export type CreateSupportMutation = {
  CreateSupport?:  Array< {
    __typename: "Support",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    groupID?: string | null,
    sort?: string | null,
    createdAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    lastModified?: string | null,
    title?: string | null,
    pointOfContact?: string | null,
    pocEmail?: string | null,
    pocPhoneNumber?: string | null,
    notes?: string | null,
    archived?: boolean | null,
    supportCreatedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    supportModifiedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
  } | null > | null,
};

export type ModifySupportMutationVariables = {
  input: Array< UpdateSupportInput >,
};

export type ModifySupportMutation = {
  ModifySupport?:  Array< {
    __typename: "Support",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    groupID?: string | null,
    sort?: string | null,
    createdAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    lastModified?: string | null,
    title?: string | null,
    pointOfContact?: string | null,
    pocEmail?: string | null,
    pocPhoneNumber?: string | null,
    notes?: string | null,
    archived?: boolean | null,
    supportCreatedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    supportModifiedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
  } | null > | null,
};

export type DeleteSupportMutationVariables = {
  input: Array< DeleteSupportInput >,
};

export type DeleteSupportMutation = {
  DeleteSupport?:  Array< {
    __typename: "Support",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    groupID?: string | null,
    sort?: string | null,
    createdAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    lastModified?: string | null,
    title?: string | null,
    pointOfContact?: string | null,
    pocEmail?: string | null,
    pocPhoneNumber?: string | null,
    notes?: string | null,
    archived?: boolean | null,
    supportCreatedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    supportModifiedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
  } | null > | null,
};

export type CreateLocationMutationVariables = {
  input: Array< CreateLocationInput >,
};

export type CreateLocationMutation = {
  CreateLocation?:  Array< {
    __typename: "Location",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    groupID?: string | null,
    sort?: string | null,
    createdAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    lastModified?: string | null,
    title?: string | null,
    archived?: boolean | null,
    locationCreatedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    locationModifiedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
  } | null > | null,
};

export type ModifyLocationMutationVariables = {
  input: Array< UpdateLocationInput >,
};

export type ModifyLocationMutation = {
  ModifyLocation?:  Array< {
    __typename: "Location",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    groupID?: string | null,
    sort?: string | null,
    createdAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    lastModified?: string | null,
    title?: string | null,
    archived?: boolean | null,
    locationCreatedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    locationModifiedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
  } | null > | null,
};

export type DeleteLocationMutationVariables = {
  input: Array< DeleteLocationInput >,
};

export type DeleteLocationMutation = {
  DeleteLocation?:  Array< {
    __typename: "Location",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    groupID?: string | null,
    sort?: string | null,
    createdAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    lastModified?: string | null,
    title?: string | null,
    archived?: boolean | null,
    locationCreatedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    locationModifiedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
  } | null > | null,
};

export type CreateAssetsGroupMutationVariables = {
  input: Array< CreateAssetsGroupInput >,
};

export type CreateAssetsGroupMutation = {
  CreateAssetsGroup?:  Array< {
    __typename: "AssetsGroup",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    groupID?: string | null,
    sort?: string | null,
    createdAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    lastModified?: string | null,
    title?: string | null,
    archived?: boolean | null,
    assetsGroupCreatedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    assetsGroupModifiedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
  } | null > | null,
};

export type ModifyAssetsGroupMutationVariables = {
  input: Array< UpdateAssetsGroupInput >,
};

export type ModifyAssetsGroupMutation = {
  ModifyAssetsGroup?:  Array< {
    __typename: "AssetsGroup",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    groupID?: string | null,
    sort?: string | null,
    createdAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    lastModified?: string | null,
    title?: string | null,
    archived?: boolean | null,
    assetsGroupCreatedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    assetsGroupModifiedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
  } | null > | null,
};

export type DeleteAssetsGroupMutationVariables = {
  input: Array< DeleteAssetsGroupInput >,
};

export type DeleteAssetsGroupMutation = {
  DeleteAssetsGroup?:  Array< {
    __typename: "AssetsGroup",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    groupID?: string | null,
    sort?: string | null,
    createdAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    lastModified?: string | null,
    title?: string | null,
    archived?: boolean | null,
    assetsGroupCreatedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    assetsGroupModifiedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
  } | null > | null,
};

export type CreateThreatsMutationVariables = {
  input: Array< CreateThreatsInput >,
};

export type CreateThreatsMutation = {
  CreateThreats?:  Array< {
    __typename: "Threats",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    itemID?: string | null,
    groupID?: string | null,
    sort?: string | null,
    createdAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    lastModified?: string | null,
    title?: string | null,
    description?: string | null,
    impact?: Impact | null,
    archived?: boolean | null,
    threatsCreatedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    threatsModifiedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    threatAssessments?:  {
      __typename: "PaginatedThreatAssessments",
      items:  Array< {
        __typename: "ThreatAssessment",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        createdAt?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        lastModified?: string | null,
        threatID?: string | null,
        assetID?: string | null,
        min?: number | null,
        mostLikely?: number | null,
        max?: number | null,
        vulnerability?: number | null,
        index?: number | null,
        archived?: boolean | null,
      } >,
      nextToken?: string | null,
    } | null,
  } | null > | null,
};

export type ModifyThreatsMutationVariables = {
  input: Array< UpdateThreatsInput >,
};

export type ModifyThreatsMutation = {
  ModifyThreats?:  Array< {
    __typename: "Threats",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    itemID?: string | null,
    groupID?: string | null,
    sort?: string | null,
    createdAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    lastModified?: string | null,
    title?: string | null,
    description?: string | null,
    impact?: Impact | null,
    archived?: boolean | null,
    threatsCreatedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    threatsModifiedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    threatAssessments?:  {
      __typename: "PaginatedThreatAssessments",
      items:  Array< {
        __typename: "ThreatAssessment",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        createdAt?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        lastModified?: string | null,
        threatID?: string | null,
        assetID?: string | null,
        min?: number | null,
        mostLikely?: number | null,
        max?: number | null,
        vulnerability?: number | null,
        index?: number | null,
        archived?: boolean | null,
      } >,
      nextToken?: string | null,
    } | null,
  } | null > | null,
};

export type DeleteThreatsMutationVariables = {
  input: Array< DeleteThreatsInput >,
};

export type DeleteThreatsMutation = {
  DeleteThreats?:  Array< {
    __typename: "Threats",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    itemID?: string | null,
    groupID?: string | null,
    sort?: string | null,
    createdAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    lastModified?: string | null,
    title?: string | null,
    description?: string | null,
    impact?: Impact | null,
    archived?: boolean | null,
    threatsCreatedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    threatsModifiedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    threatAssessments?:  {
      __typename: "PaginatedThreatAssessments",
      items:  Array< {
        __typename: "ThreatAssessment",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        createdAt?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        lastModified?: string | null,
        threatID?: string | null,
        assetID?: string | null,
        min?: number | null,
        mostLikely?: number | null,
        max?: number | null,
        vulnerability?: number | null,
        index?: number | null,
        archived?: boolean | null,
      } >,
      nextToken?: string | null,
    } | null,
  } | null > | null,
};

export type CreateAssetsMutationVariables = {
  input: Array< CreateAssetsInput >,
};

export type CreateAssetsMutation = {
  CreateAssets?:  Array< {
    __typename: "Assets",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    itemID?: string | null,
    groupID?: string | null,
    sort?: string | null,
    createdAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    lastModified?: string | null,
    title?: string | null,
    qty?: number | null,
    assetsGroupID?: string | null,
    notesAboutControls?: string | null,
    notesAboutThreats?: string | null,
    category?: string | null,
    assetCategory?:  {
      __typename: "Category",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      groupID?: string | null,
      sort?: string | null,
      createdAt?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      lastModified?: string | null,
      title?: string | null,
      archived?: boolean | null,
      categoryCreatedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      categoryModifiedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
    } | null,
    location?: string | null,
    assetLocation?:  {
      __typename: "Location",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      groupID?: string | null,
      sort?: string | null,
      createdAt?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      lastModified?: string | null,
      title?: string | null,
      archived?: boolean | null,
      locationCreatedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      locationModifiedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
    } | null,
    support?: string | null,
    assetSupport?:  {
      __typename: "Support",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      groupID?: string | null,
      sort?: string | null,
      createdAt?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      lastModified?: string | null,
      title?: string | null,
      pointOfContact?: string | null,
      pocEmail?: string | null,
      pocPhoneNumber?: string | null,
      notes?: string | null,
      archived?: boolean | null,
      supportCreatedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      supportModifiedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
    } | null,
    owner?: string | null,
    assetsAnalysisInput?: string | null,
    assetsAnalysisOutput?: string | null,
    assetOwner?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    assetCreatedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    assetModifiedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    notes?: string | null,
    displayID?: string | null,
    assetRelatedItems?:  {
      __typename: "PaginatedLinks",
      items:  Array< {
        __typename: "Link",
        id: string,
        sort: string,
        target?: string | null,
        targetType?: LinkTarget | null,
        item?:  {
          __typename: "RelatableItem",
          id?: string | null,
          sort?: string | null,
          groupID?: string | null,
          owner?: string | null,
          uuid?: string | null,
          title?: string | null,
          dateClosed?: string | null,
          dueDate?: string | null,
          status?: Status | null,
          priority?: Priority | null,
          severity?: Severity | null,
          responseStatus?: string | null,
          archived?: boolean | null,
          createdAt?: string | null,
          lastModified?: string | null,
          compositeIDYear?: string | null,
          compositeID?: number | null,
          displayID?: string | null,
          relatedItemsOwner?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
          relatedItemsGroup?:  {
            __typename: "Group",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            organizationID?: string | null,
            title?: string | null,
            shortTitle?: string | null,
            riskThreshold?:  {
              __typename: "RiskThreshold",
              financial?:  {
                __typename: "Threshold",
                appetitePercent?: number | null,
                appetiteAbsolute?: number | null,
                capacityPercent?: number | null,
                capacityAbsolute?: number | null,
              } | null,
              privacy?:  {
                __typename: "Threshold",
                appetitePercent?: number | null,
                appetiteAbsolute?: number | null,
                capacityPercent?: number | null,
                capacityAbsolute?: number | null,
              } | null,
            } | null,
            organization?:  {
              __typename: "Organization",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              name?: string | null,
              organizationRelationships?: string | null,
              demographics?: string | null,
            } | null,
            groupEmailFooter?: string | null,
          } | null,
          fileName?: string | null,
          address?: string | null,
        } | null,
      } >,
      nextToken?: string | null,
    } | null,
    archived?: boolean | null,
    threatAssessments?:  {
      __typename: "PaginatedThreatAssessments",
      items:  Array< {
        __typename: "ThreatAssessment",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        createdAt?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        lastModified?: string | null,
        threatID?: string | null,
        assetID?: string | null,
        min?: number | null,
        mostLikely?: number | null,
        max?: number | null,
        vulnerability?: number | null,
        index?: number | null,
        archived?: boolean | null,
      } >,
      nextToken?: string | null,
    } | null,
    PHIRecords?: string | null,
    assetsGroupDetails?:  {
      __typename: "AssetsGroup",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      groupID?: string | null,
      sort?: string | null,
      createdAt?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      lastModified?: string | null,
      title?: string | null,
      archived?: boolean | null,
      assetsGroupCreatedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      assetsGroupModifiedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
    } | null,
    assetID?: string | null,
    assessmentID?: string | null,
  } | null > | null,
};

export type ModifyAssetsMutationVariables = {
  input: Array< UpdateAssetsInput >,
};

export type ModifyAssetsMutation = {
  ModifyAssets?:  Array< {
    __typename: "Assets",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    itemID?: string | null,
    groupID?: string | null,
    sort?: string | null,
    createdAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    lastModified?: string | null,
    title?: string | null,
    qty?: number | null,
    assetsGroupID?: string | null,
    notesAboutControls?: string | null,
    notesAboutThreats?: string | null,
    category?: string | null,
    assetCategory?:  {
      __typename: "Category",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      groupID?: string | null,
      sort?: string | null,
      createdAt?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      lastModified?: string | null,
      title?: string | null,
      archived?: boolean | null,
      categoryCreatedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      categoryModifiedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
    } | null,
    location?: string | null,
    assetLocation?:  {
      __typename: "Location",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      groupID?: string | null,
      sort?: string | null,
      createdAt?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      lastModified?: string | null,
      title?: string | null,
      archived?: boolean | null,
      locationCreatedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      locationModifiedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
    } | null,
    support?: string | null,
    assetSupport?:  {
      __typename: "Support",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      groupID?: string | null,
      sort?: string | null,
      createdAt?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      lastModified?: string | null,
      title?: string | null,
      pointOfContact?: string | null,
      pocEmail?: string | null,
      pocPhoneNumber?: string | null,
      notes?: string | null,
      archived?: boolean | null,
      supportCreatedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      supportModifiedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
    } | null,
    owner?: string | null,
    assetsAnalysisInput?: string | null,
    assetsAnalysisOutput?: string | null,
    assetOwner?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    assetCreatedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    assetModifiedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    notes?: string | null,
    displayID?: string | null,
    assetRelatedItems?:  {
      __typename: "PaginatedLinks",
      items:  Array< {
        __typename: "Link",
        id: string,
        sort: string,
        target?: string | null,
        targetType?: LinkTarget | null,
        item?:  {
          __typename: "RelatableItem",
          id?: string | null,
          sort?: string | null,
          groupID?: string | null,
          owner?: string | null,
          uuid?: string | null,
          title?: string | null,
          dateClosed?: string | null,
          dueDate?: string | null,
          status?: Status | null,
          priority?: Priority | null,
          severity?: Severity | null,
          responseStatus?: string | null,
          archived?: boolean | null,
          createdAt?: string | null,
          lastModified?: string | null,
          compositeIDYear?: string | null,
          compositeID?: number | null,
          displayID?: string | null,
          relatedItemsOwner?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
          relatedItemsGroup?:  {
            __typename: "Group",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            organizationID?: string | null,
            title?: string | null,
            shortTitle?: string | null,
            riskThreshold?:  {
              __typename: "RiskThreshold",
              financial?:  {
                __typename: "Threshold",
                appetitePercent?: number | null,
                appetiteAbsolute?: number | null,
                capacityPercent?: number | null,
                capacityAbsolute?: number | null,
              } | null,
              privacy?:  {
                __typename: "Threshold",
                appetitePercent?: number | null,
                appetiteAbsolute?: number | null,
                capacityPercent?: number | null,
                capacityAbsolute?: number | null,
              } | null,
            } | null,
            organization?:  {
              __typename: "Organization",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              name?: string | null,
              organizationRelationships?: string | null,
              demographics?: string | null,
            } | null,
            groupEmailFooter?: string | null,
          } | null,
          fileName?: string | null,
          address?: string | null,
        } | null,
      } >,
      nextToken?: string | null,
    } | null,
    archived?: boolean | null,
    threatAssessments?:  {
      __typename: "PaginatedThreatAssessments",
      items:  Array< {
        __typename: "ThreatAssessment",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        createdAt?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        lastModified?: string | null,
        threatID?: string | null,
        assetID?: string | null,
        min?: number | null,
        mostLikely?: number | null,
        max?: number | null,
        vulnerability?: number | null,
        index?: number | null,
        archived?: boolean | null,
      } >,
      nextToken?: string | null,
    } | null,
    PHIRecords?: string | null,
    assetsGroupDetails?:  {
      __typename: "AssetsGroup",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      groupID?: string | null,
      sort?: string | null,
      createdAt?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      lastModified?: string | null,
      title?: string | null,
      archived?: boolean | null,
      assetsGroupCreatedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      assetsGroupModifiedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
    } | null,
    assetID?: string | null,
    assessmentID?: string | null,
  } | null > | null,
};

export type DeleteAssetsMutationVariables = {
  input: Array< DeleteAssetsInput >,
};

export type DeleteAssetsMutation = {
  DeleteAssets?:  Array< {
    __typename: "Assets",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    itemID?: string | null,
    groupID?: string | null,
    sort?: string | null,
    createdAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    lastModified?: string | null,
    title?: string | null,
    qty?: number | null,
    assetsGroupID?: string | null,
    notesAboutControls?: string | null,
    notesAboutThreats?: string | null,
    category?: string | null,
    assetCategory?:  {
      __typename: "Category",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      groupID?: string | null,
      sort?: string | null,
      createdAt?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      lastModified?: string | null,
      title?: string | null,
      archived?: boolean | null,
      categoryCreatedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      categoryModifiedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
    } | null,
    location?: string | null,
    assetLocation?:  {
      __typename: "Location",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      groupID?: string | null,
      sort?: string | null,
      createdAt?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      lastModified?: string | null,
      title?: string | null,
      archived?: boolean | null,
      locationCreatedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      locationModifiedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
    } | null,
    support?: string | null,
    assetSupport?:  {
      __typename: "Support",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      groupID?: string | null,
      sort?: string | null,
      createdAt?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      lastModified?: string | null,
      title?: string | null,
      pointOfContact?: string | null,
      pocEmail?: string | null,
      pocPhoneNumber?: string | null,
      notes?: string | null,
      archived?: boolean | null,
      supportCreatedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      supportModifiedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
    } | null,
    owner?: string | null,
    assetsAnalysisInput?: string | null,
    assetsAnalysisOutput?: string | null,
    assetOwner?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    assetCreatedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    assetModifiedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    notes?: string | null,
    displayID?: string | null,
    assetRelatedItems?:  {
      __typename: "PaginatedLinks",
      items:  Array< {
        __typename: "Link",
        id: string,
        sort: string,
        target?: string | null,
        targetType?: LinkTarget | null,
        item?:  {
          __typename: "RelatableItem",
          id?: string | null,
          sort?: string | null,
          groupID?: string | null,
          owner?: string | null,
          uuid?: string | null,
          title?: string | null,
          dateClosed?: string | null,
          dueDate?: string | null,
          status?: Status | null,
          priority?: Priority | null,
          severity?: Severity | null,
          responseStatus?: string | null,
          archived?: boolean | null,
          createdAt?: string | null,
          lastModified?: string | null,
          compositeIDYear?: string | null,
          compositeID?: number | null,
          displayID?: string | null,
          relatedItemsOwner?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
          relatedItemsGroup?:  {
            __typename: "Group",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            organizationID?: string | null,
            title?: string | null,
            shortTitle?: string | null,
            riskThreshold?:  {
              __typename: "RiskThreshold",
              financial?:  {
                __typename: "Threshold",
                appetitePercent?: number | null,
                appetiteAbsolute?: number | null,
                capacityPercent?: number | null,
                capacityAbsolute?: number | null,
              } | null,
              privacy?:  {
                __typename: "Threshold",
                appetitePercent?: number | null,
                appetiteAbsolute?: number | null,
                capacityPercent?: number | null,
                capacityAbsolute?: number | null,
              } | null,
            } | null,
            organization?:  {
              __typename: "Organization",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              name?: string | null,
              organizationRelationships?: string | null,
              demographics?: string | null,
            } | null,
            groupEmailFooter?: string | null,
          } | null,
          fileName?: string | null,
          address?: string | null,
        } | null,
      } >,
      nextToken?: string | null,
    } | null,
    archived?: boolean | null,
    threatAssessments?:  {
      __typename: "PaginatedThreatAssessments",
      items:  Array< {
        __typename: "ThreatAssessment",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        createdAt?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        lastModified?: string | null,
        threatID?: string | null,
        assetID?: string | null,
        min?: number | null,
        mostLikely?: number | null,
        max?: number | null,
        vulnerability?: number | null,
        index?: number | null,
        archived?: boolean | null,
      } >,
      nextToken?: string | null,
    } | null,
    PHIRecords?: string | null,
    assetsGroupDetails?:  {
      __typename: "AssetsGroup",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      groupID?: string | null,
      sort?: string | null,
      createdAt?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      lastModified?: string | null,
      title?: string | null,
      archived?: boolean | null,
      assetsGroupCreatedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      assetsGroupModifiedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
    } | null,
    assetID?: string | null,
    assessmentID?: string | null,
  } | null > | null,
};

export type CreateRiskAssessmentMutationVariables = {
  input: Array< CreateRiskAssessmentInput >,
};

export type CreateRiskAssessmentMutation = {
  CreateRiskAssessment?:  Array< {
    __typename: "RiskAssessment",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    title?: string | null,
    groupID?: string | null,
    sort?: string | null,
    createdAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    lastModified?: string | null,
    owner?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    overview?: string | null,
    assessor?: string | null,
    scope?: string | null,
    executiveSummary?: string | null,
    methodology?: string | null,
    archived?: boolean | null,
    riskAssessmentOwner?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    riskAssessmentCreatedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    riskAssessmentModifiedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
  } | null > | null,
};

export type ModifyRiskAssessmentMutationVariables = {
  input: Array< UpdateRiskAssessmentInput >,
};

export type ModifyRiskAssessmentMutation = {
  ModifyRiskAssessment?:  Array< {
    __typename: "RiskAssessment",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    title?: string | null,
    groupID?: string | null,
    sort?: string | null,
    createdAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    lastModified?: string | null,
    owner?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    overview?: string | null,
    assessor?: string | null,
    scope?: string | null,
    executiveSummary?: string | null,
    methodology?: string | null,
    archived?: boolean | null,
    riskAssessmentOwner?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    riskAssessmentCreatedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    riskAssessmentModifiedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
  } | null > | null,
};

export type DeleteRiskAssessmentMutationVariables = {
  input: Array< DeleteRiskAssessmentInput >,
};

export type DeleteRiskAssessmentMutation = {
  DeleteRiskAssessment?:  Array< {
    __typename: "RiskAssessment",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    title?: string | null,
    groupID?: string | null,
    sort?: string | null,
    createdAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    lastModified?: string | null,
    owner?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    overview?: string | null,
    assessor?: string | null,
    scope?: string | null,
    executiveSummary?: string | null,
    methodology?: string | null,
    archived?: boolean | null,
    riskAssessmentOwner?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    riskAssessmentCreatedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    riskAssessmentModifiedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
  } | null > | null,
};

export type CloneIssueMutationVariables = {
  input: Array< CloneIssueInput >,
};

export type CloneIssueMutation = {
  CloneIssue?: Array< string | null > | null,
};

export type IssueTemplateMutationVariables = {
  input: Array< IssueTemplateInput >,
};

export type IssueTemplateMutation = {
  IssueTemplate?: Array< string | null > | null,
};

export type CreateGroupMutationVariables = {
  input: Array< CreateGroupInput >,
};

export type CreateGroupMutation = {
  CreateGroup?: Array< string | null > | null,
};

export type ModifyGroupMutationVariables = {
  input: Array< ModifyGroupInput >,
};

export type ModifyGroupMutation = {
  ModifyGroup?: Array< string | null > | null,
};

export type SetGroupRiskThresholdMutationVariables = {
  input: Array< SetGroupRiskThresholdInput >,
};

export type SetGroupRiskThresholdMutation = {
  SetGroupRiskThreshold?: Array< string | null > | null,
};

export type AddUserToGroupMutationVariables = {
  input: Array< AddUserToGroupInput >,
};

export type AddUserToGroupMutation = {
  AddUserToGroup?: Array< string | null > | null,
};

export type SetUserRoleInGroupMutationVariables = {
  input: Array< setUserRoleInGroupInput >,
};

export type SetUserRoleInGroupMutation = {
  setUserRoleInGroup?: Array< string | null > | null,
};

export type RemoveUserFromGroupMutationVariables = {
  input: Array< RemoveUserFromGroupInput >,
};

export type RemoveUserFromGroupMutation = {
  RemoveUserFromGroup?: Array< string | null > | null,
};

export type CreateAssessmentMutationVariables = {
  input: Array< CreateAssessmentInput >,
};

export type CreateAssessmentMutation = {
  CreateAssessment?: Array< string | null > | null,
};

export type ModifyAssessmentMutationVariables = {
  input: Array< ModifyAssessmentInput >,
};

export type ModifyAssessmentMutation = {
  ModifyAssessment?: Array< string | null > | null,
};

export type CloneAssessmentMutationVariables = {
  input: Array< CloneAssessmentInput >,
};

export type CloneAssessmentMutation = {
  CloneAssessment?: Array< string | null > | null,
};

export type CloneActionPlanMutationVariables = {
  input: Array< CloneActionPlanInput >,
};

export type CloneActionPlanMutation = {
  CloneActionPlan?: Array< string | null > | null,
};

export type ActionPlanTemplateMutationVariables = {
  input: Array< ActionPlanTemplateInput >,
};

export type ActionPlanTemplateMutation = {
  ActionPlanTemplate?: Array< string | null > | null,
};

export type CloneScenarioMutationVariables = {
  input: Array< CloneScenarioInput >,
};

export type CloneScenarioMutation = {
  CloneScenario?: Array< string | null > | null,
};

export type RiskTemplateMutationVariables = {
  input: Array< RiskTemplateInput >,
};

export type RiskTemplateMutation = {
  RiskTemplate?: Array< string | null > | null,
};

export type CreateUserMutationVariables = {
  input: Array< CreateUserInput >,
};

export type CreateUserMutation = {
  CreateUser?: Array< string | null > | null,
};

export type ModifyUserMetaMutationVariables = {
  input: Array< ModifyUserMetaInput >,
};

export type ModifyUserMetaMutation = {
  ModifyUserMeta?: Array< string | null > | null,
};

export type DisableUserMutationVariables = {
  input: Array< DisableUserInput >,
};

export type DisableUserMutation = {
  DisableUser?: Array< string | null > | null,
};

export type EnableUserMutationVariables = {
  input: Array< EnableUserInput >,
};

export type EnableUserMutation = {
  EnableUser?: Array< string | null > | null,
};

export type ModifyUserAttributesMutationVariables = {
  input: Array< ModifyUserAttributesInput >,
};

export type ModifyUserAttributesMutation = {
  ModifyUserAttributes?: Array< string | null > | null,
};

export type ModifyEmailMutationVariables = {
  input: Array< ModifyEmailInput >,
};

export type ModifyEmailMutation = {
  ModifyEmail?: Array< string | null > | null,
};

export type CreateOrganizationMutationVariables = {
  input: Array< CreateOrganizationInput >,
};

export type CreateOrganizationMutation = {
  CreateOrganization?: Array< string | null > | null,
};

export type ModifyOrganizationMutationVariables = {
  input: Array< modifyOrganizationInput >,
};

export type ModifyOrganizationMutation = {
  modifyOrganization?: Array< string | null > | null,
};

export type AddUserToOrganizationMutationVariables = {
  input: Array< AddUserToOrganizationInput >,
};

export type AddUserToOrganizationMutation = {
  AddUserToOrganization?: Array< string | null > | null,
};

export type SetUserRoleInOrganizationMutationVariables = {
  input: Array< setUserRoleInOrganizationInput >,
};

export type SetUserRoleInOrganizationMutation = {
  setUserRoleInOrganization?: Array< string | null > | null,
};

export type RemoveUserFromOrganizationMutationVariables = {
  input: Array< RemoveUserFromOrganizationInput >,
};

export type RemoveUserFromOrganizationMutation = {
  RemoveUserFromOrganization?: Array< string | null > | null,
};

export type ModifyNotificationMutationVariables = {
  input: Array< ModifyNotificationInput >,
};

export type ModifyNotificationMutation = {
  ModifyNotification?: Array< string | null > | null,
};

export type UploadArtifactMutationVariables = {
  input: Array< UploadArtifactInput >,
};

export type UploadArtifactMutation = {
  UploadArtifact?: Array< string | null > | null,
};

export type RequestArtifactMutationVariables = {
  input: Array< RequestArtifactInput >,
};

export type RequestArtifactMutation = {
  RequestArtifact?: Array< string | null > | null,
};

export type ModifyArtifactMutationVariables = {
  input: Array< ModifyArtifactInput >,
};

export type ModifyArtifactMutation = {
  ModifyArtifact?: Array< string | null > | null,
};

export type CreateControlsMutationVariables = {
  input: Array< CreateControlsInput >,
};

export type CreateControlsMutation = {
  CreateControls?: Array< string | null > | null,
};

export type AddScenarioToPortfolioMutationVariables = {
  input: Array< AddScenarioToPortfolioInput >,
};

export type AddScenarioToPortfolioMutation = {
  AddScenarioToPortfolio?: Array< string | null > | null,
};

export type RemoveScenarioFromPortfolioMutationVariables = {
  input: Array< RemoveScenarioFromPortfolioInput >,
};

export type RemoveScenarioFromPortfolioMutation = {
  RemoveScenarioFromPortfolio?: Array< string | null > | null,
};

export type CloneAuditPlanMutationVariables = {
  input: Array< CloneAuditPlanInput >,
};

export type CloneAuditPlanMutation = {
  CloneAuditPlan?: Array< string | null > | null,
};

export type AuditPlanTemplateMutationVariables = {
  input: Array< AuditPlanTemplateInput >,
};

export type AuditPlanTemplateMutation = {
  AuditPlanTemplate?: Array< string | null > | null,
};

export type CreateAssessmentAssetsMutationVariables = {
  input: Array< CreateAssessmentAssetsInput >,
};

export type CreateAssessmentAssetsMutation = {
  CreateAssessmentAssets?: Array< string | null > | null,
};

export type ModifyAssessmentAssetsMutationVariables = {
  input: Array< ModifyAssessmentAssetsInput >,
};

export type ModifyAssessmentAssetsMutation = {
  ModifyAssessmentAssets?: Array< string | null > | null,
};

export type CreateThreatAssessmentMutationVariables = {
  input: Array< CreateThreatAssessmentInput >,
};

export type CreateThreatAssessmentMutation = {
  CreateThreatAssessment?: Array< string | null > | null,
};

export type RemoveThreatAssessmentMutationVariables = {
  input: Array< RemoveThreatAssessmentInput >,
};

export type RemoveThreatAssessmentMutation = {
  RemoveThreatAssessment?: Array< string | null > | null,
};

export type CreateSurveyTemplateMutationVariables = {
  input: Array< CreateSurveyTemplateInput >,
};

export type CreateSurveyTemplateMutation = {
  CreateSurveyTemplate?: Array< string | null > | null,
};

export type CreateTemplateQuestionMutationVariables = {
  input: Array< CreateTemplateQuestionInput >,
};

export type CreateTemplateQuestionMutation = {
  CreateTemplateQuestion?: Array< string | null > | null,
};

export type ModifyResponseMutationVariables = {
  input: Array< ModifyResponseInput >,
};

export type ModifyResponseMutation = {
  ModifyResponse?:  Array< {
    __typename: "AssessmentResponse",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    sort?: string | null,
    parentID?: string | null,
    title?: string | null,
    instructions?: string | null,
    notes?: string | null,
    responseType?: string | null,
    questionText?: string | null,
    response?: string | null,
    responseComments?: string | null,
    responseStatus?: string | null,
    artifactRequired?: boolean | null,
    artifactRequired_yes?: boolean | null,
    artifactRequired_no?: boolean | null,
    owner?: string | null,
    parent?:  {
      __typename: "Assessment",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      parentID?: string | null,
      templateRoot?: boolean | null,
      title?: string | null,
      assessmentStartDate?: string | null,
      assessmentEndDate?: string | null,
      assessor?: string | null,
      scope?: string | null,
      hours?: string | null,
      templateTitle?: string | null,
      name?: string | null,
      hsrID?: string | null,
      description?: string | null,
      questionText?: string | null,
      surveyState?:  {
        __typename: "SurveyTotals",
        unanswered?: number | null,
        noGapIdentified?: number | null,
        deferPendingReassignment?: number | null,
        deferPendingResponse?: number | null,
        clarify?: number | null,
        yesPendingRequestedArtifacts?: number | null,
        yesPendingArtifacts?: number | null,
        yesCompliant?: number | null,
        notApplicable?: number | null,
      } | null,
      size?: number | null,
      parent?:  {
        __typename: "Assessment",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        parentID?: string | null,
        templateRoot?: boolean | null,
        title?: string | null,
        assessmentStartDate?: string | null,
        assessmentEndDate?: string | null,
        assessor?: string | null,
        scope?: string | null,
        hours?: string | null,
        templateTitle?: string | null,
        name?: string | null,
        hsrID?: string | null,
        description?: string | null,
        questionText?: string | null,
        surveyState?:  {
          __typename: "SurveyTotals",
          unanswered?: number | null,
          noGapIdentified?: number | null,
          deferPendingReassignment?: number | null,
          deferPendingResponse?: number | null,
          clarify?: number | null,
          yesPendingRequestedArtifacts?: number | null,
          yesPendingArtifacts?: number | null,
          yesCompliant?: number | null,
          notApplicable?: number | null,
        } | null,
        size?: number | null,
        parent?:  {
          __typename: "Assessment",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          parentID?: string | null,
          templateRoot?: boolean | null,
          title?: string | null,
          assessmentStartDate?: string | null,
          assessmentEndDate?: string | null,
          assessor?: string | null,
          scope?: string | null,
          hours?: string | null,
          templateTitle?: string | null,
          name?: string | null,
          hsrID?: string | null,
          description?: string | null,
          questionText?: string | null,
          surveyState?:  {
            __typename: "SurveyTotals",
            unanswered?: number | null,
            noGapIdentified?: number | null,
            deferPendingReassignment?: number | null,
            deferPendingResponse?: number | null,
            clarify?: number | null,
            yesPendingRequestedArtifacts?: number | null,
            yesPendingArtifacts?: number | null,
            yesCompliant?: number | null,
            notApplicable?: number | null,
          } | null,
          size?: number | null,
          parent?:  {
            __typename: "Assessment",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            parentID?: string | null,
            templateRoot?: boolean | null,
            title?: string | null,
            assessmentStartDate?: string | null,
            assessmentEndDate?: string | null,
            assessor?: string | null,
            scope?: string | null,
            hours?: string | null,
            templateTitle?: string | null,
            name?: string | null,
            hsrID?: string | null,
            description?: string | null,
            questionText?: string | null,
            surveyState?:  {
              __typename: "SurveyTotals",
              unanswered?: number | null,
              noGapIdentified?: number | null,
              deferPendingReassignment?: number | null,
              deferPendingResponse?: number | null,
              clarify?: number | null,
              yesPendingRequestedArtifacts?: number | null,
              yesPendingArtifacts?: number | null,
              yesCompliant?: number | null,
              notApplicable?: number | null,
            } | null,
            size?: number | null,
            parent?:  {
              __typename: "Assessment",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              parentID?: string | null,
              templateRoot?: boolean | null,
              title?: string | null,
              assessmentStartDate?: string | null,
              assessmentEndDate?: string | null,
              assessor?: string | null,
              scope?: string | null,
              hours?: string | null,
              templateTitle?: string | null,
              name?: string | null,
              hsrID?: string | null,
              description?: string | null,
              questionText?: string | null,
              surveyState?:  {
                __typename: "SurveyTotals",
                unanswered?: number | null,
                noGapIdentified?: number | null,
                deferPendingReassignment?: number | null,
                deferPendingResponse?: number | null,
                clarify?: number | null,
                yesPendingRequestedArtifacts?: number | null,
                yesPendingArtifacts?: number | null,
                yesCompliant?: number | null,
                notApplicable?: number | null,
              } | null,
              size?: number | null,
              parent?:  {
                __typename: "Assessment",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                parentID?: string | null,
                templateRoot?: boolean | null,
                title?: string | null,
                assessmentStartDate?: string | null,
                assessmentEndDate?: string | null,
                assessor?: string | null,
                scope?: string | null,
                hours?: string | null,
                templateTitle?: string | null,
                name?: string | null,
                hsrID?: string | null,
                description?: string | null,
                questionText?: string | null,
                surveyState?:  {
                  __typename: "SurveyTotals",
                  unanswered?: number | null,
                  noGapIdentified?: number | null,
                  deferPendingReassignment?: number | null,
                  deferPendingResponse?: number | null,
                  clarify?: number | null,
                  yesPendingRequestedArtifacts?: number | null,
                  yesPendingArtifacts?: number | null,
                  yesCompliant?: number | null,
                  notApplicable?: number | null,
                } | null,
                size?: number | null,
                parent?:  {
                  __typename: "Assessment",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  parentID?: string | null,
                  templateRoot?: boolean | null,
                  title?: string | null,
                  assessmentStartDate?: string | null,
                  assessmentEndDate?: string | null,
                  assessor?: string | null,
                  scope?: string | null,
                  hours?: string | null,
                  templateTitle?: string | null,
                  name?: string | null,
                  hsrID?: string | null,
                  description?: string | null,
                  questionText?: string | null,
                  size?: number | null,
                  groupID?: string | null,
                  archived?: boolean | null,
                } | null,
                groupID?: string | null,
                archived?: boolean | null,
                assessmentAssessor?:  {
                  __typename: "User",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  email?: string | null,
                  username?: string | null,
                  firstName?: string | null,
                  lastName?: string | null,
                  inactive?: boolean | null,
                  lastSeen?: string | null,
                  lastLoggedIn?: string | null,
                } | null,
              } | null,
              groupID?: string | null,
              archived?: boolean | null,
              assessmentAssessor?:  {
                __typename: "User",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                username?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
                lastSeen?: string | null,
                lastLoggedIn?: string | null,
              } | null,
            } | null,
            groupID?: string | null,
            archived?: boolean | null,
            assessmentAssessor?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
          } | null,
          groupID?: string | null,
          archived?: boolean | null,
          assessmentAssessor?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
        } | null,
        groupID?: string | null,
        archived?: boolean | null,
        assessmentAssessor?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
      } | null,
      groupID?: string | null,
      archived?: boolean | null,
      assessmentAssessor?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
    } | null,
    groupID?: string | null,
    responseRelatedItems?:  {
      __typename: "PaginatedLinks",
      items:  Array< {
        __typename: "Link",
        id: string,
        sort: string,
        target?: string | null,
        targetType?: LinkTarget | null,
        item?:  {
          __typename: "RelatableItem",
          id?: string | null,
          sort?: string | null,
          groupID?: string | null,
          owner?: string | null,
          uuid?: string | null,
          title?: string | null,
          dateClosed?: string | null,
          dueDate?: string | null,
          status?: Status | null,
          priority?: Priority | null,
          severity?: Severity | null,
          responseStatus?: string | null,
          archived?: boolean | null,
          createdAt?: string | null,
          lastModified?: string | null,
          compositeIDYear?: string | null,
          compositeID?: number | null,
          displayID?: string | null,
          relatedItemsOwner?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
          relatedItemsGroup?:  {
            __typename: "Group",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            organizationID?: string | null,
            title?: string | null,
            shortTitle?: string | null,
            riskThreshold?:  {
              __typename: "RiskThreshold",
              financial?:  {
                __typename: "Threshold",
                appetitePercent?: number | null,
                appetiteAbsolute?: number | null,
                capacityPercent?: number | null,
                capacityAbsolute?: number | null,
              } | null,
              privacy?:  {
                __typename: "Threshold",
                appetitePercent?: number | null,
                appetiteAbsolute?: number | null,
                capacityPercent?: number | null,
                capacityAbsolute?: number | null,
              } | null,
            } | null,
            organization?:  {
              __typename: "Organization",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              name?: string | null,
              organizationRelationships?: string | null,
              demographics?: string | null,
            } | null,
            groupEmailFooter?: string | null,
          } | null,
          fileName?: string | null,
          address?: string | null,
        } | null,
      } >,
      nextToken?: string | null,
    } | null,
  } | null > | null,
};

export type CreateCommentMutationVariables = {
  input: CreateCommentInput,
};

export type CreateCommentMutation = {
  CreateComment?: string | null,
};

export type LinkItemsMutationVariables = {
  input: Array< LinkItemsInput >,
};

export type LinkItemsMutation = {
  LinkItems?: string | null,
};

export type UnlinkItemsMutationVariables = {
  input: Array< UnlinkItemsInput >,
};

export type UnlinkItemsMutation = {
  UnlinkItems?: string | null,
};

export type SetUserRASCIRoleOnItemMutationVariables = {
  input: Array< SetUserRASCIRoleOnItemInput >,
};

export type SetUserRASCIRoleOnItemMutation = {
  SetUserRASCIRoleOnItem?: string | null,
};

export type RemoveUserRASCIRoleFromItemMutationVariables = {
  input: Array< RemoveUserRASCIRoleFromItemInput >,
};

export type RemoveUserRASCIRoleFromItemMutation = {
  RemoveUserRASCIRoleFromItem?: string | null,
};

export type SetUserApproverItemMutationVariables = {
  input: Array< SetUserApproverItemInput >,
};

export type SetUserApproverItemMutation = {
  SetUserApproverItem?: string | null,
};

export type RemoveUserApproverFromItemMutationVariables = {
  input: Array< RemoveUserApproverFromItemInput >,
};

export type RemoveUserApproverFromItemMutation = {
  RemoveUserApproverFromItem?: string | null,
};

export type SetItemStatusMutationVariables = {
  input: Array< SetItemStatusInput >,
};

export type SetItemStatusMutation = {
  SetItemStatus?: string | null,
};

export type CreateAnalysisMutationVariables = {
  id?: string | null,
  groupID: string,
  name: string,
};

export type CreateAnalysisMutation = {
  CreateAnalysis?: string | null,
};

export type SetAnalysisMutationVariables = {
  input: SetAnalysisInput,
};

export type SetAnalysisMutation = {
  SetAnalysis?: string | null,
};

export type SetFrequencyMutationVariables = {
  id: string,
  vulnerability?: number | null,
  vulnerabilityAssumptions?: string | null,
  LEF?: PertConfig | null,
  LEFAssumptions?: string | null,
  TEF?: PertConfig | null,
  TEFAssumptions?: string | null,
  CEF?: PertConfig | null,
  CEFAssumptions?: string | null,
  POA?: number | null,
  POAAssumptions?: string | null,
  sips?: Array< number | null > | null,
  currentLayer: FrequencyLayer,
  seed?: number | null,
};

export type SetFrequencyMutation = {
  SetFrequency?: string | null,
};

export type SetMagnitudeMutationVariables = {
  id: string,
  magnitudeType: string,
  primaryMagnitude: PertConfig,
  secondaryFrequency?: number | null,
  secondaryMagnitude?: PertConfig | null,
  assumptions?: string | null,
  secondaryAssumptions?: string | null,
};

export type SetMagnitudeMutation = {
  SetMagnitude?: string | null,
};

export type RunAnalysisMutationVariables = {
  id: string,
  seed?: number | null,
};

export type RunAnalysisMutation = {
  RunAnalysis?: string | null,
};

export type ModifyAnalysisMutationVariables = {
  id?: string | null,
  name?: string | null,
};

export type ModifyAnalysisMutation = {
  ModifyAnalysis?: string | null,
};

export type GetSourceQueryVariables = {
  id: string,
};

export type GetSourceQuery = {
  getSource?:  {
    __typename: "Source",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    sort?: string | null,
    organizationID?: string | null,
    groupID?: string | null,
    description?: string | null,
    year?: string | null,
    title?: string | null,
    archived?: boolean | null,
  } | null,
};

export type GetSourcesInGroupQueryVariables = {
  groupID: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetSourcesInGroupQuery = {
  getSourcesInGroup:  {
    __typename: "PaginatedSource",
    items:  Array< {
      __typename: "Source",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      organizationID?: string | null,
      groupID?: string | null,
      description?: string | null,
      year?: string | null,
      title?: string | null,
      archived?: boolean | null,
    } >,
    nextToken?: string | null,
  },
};

export type GetAssessmentQueryVariables = {
  id: string,
};

export type GetAssessmentQuery = {
  getAssessment?:  {
    __typename: "Assessment",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    sort?: string | null,
    parentID?: string | null,
    templateRoot?: boolean | null,
    title?: string | null,
    assessmentStartDate?: string | null,
    assessmentEndDate?: string | null,
    assessor?: string | null,
    scope?: string | null,
    hours?: string | null,
    templateTitle?: string | null,
    name?: string | null,
    hsrID?: string | null,
    description?: string | null,
    questionText?: string | null,
    surveyState?:  {
      __typename: "SurveyTotals",
      unanswered?: number | null,
      noGapIdentified?: number | null,
      deferPendingReassignment?: number | null,
      deferPendingResponse?: number | null,
      clarify?: number | null,
      yesPendingRequestedArtifacts?: number | null,
      yesPendingArtifacts?: number | null,
      yesCompliant?: number | null,
      notApplicable?: number | null,
    } | null,
    size?: number | null,
    parent?:  {
      __typename: "Assessment",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      parentID?: string | null,
      templateRoot?: boolean | null,
      title?: string | null,
      assessmentStartDate?: string | null,
      assessmentEndDate?: string | null,
      assessor?: string | null,
      scope?: string | null,
      hours?: string | null,
      templateTitle?: string | null,
      name?: string | null,
      hsrID?: string | null,
      description?: string | null,
      questionText?: string | null,
      surveyState?:  {
        __typename: "SurveyTotals",
        unanswered?: number | null,
        noGapIdentified?: number | null,
        deferPendingReassignment?: number | null,
        deferPendingResponse?: number | null,
        clarify?: number | null,
        yesPendingRequestedArtifacts?: number | null,
        yesPendingArtifacts?: number | null,
        yesCompliant?: number | null,
        notApplicable?: number | null,
      } | null,
      size?: number | null,
      parent?:  {
        __typename: "Assessment",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        parentID?: string | null,
        templateRoot?: boolean | null,
        title?: string | null,
        assessmentStartDate?: string | null,
        assessmentEndDate?: string | null,
        assessor?: string | null,
        scope?: string | null,
        hours?: string | null,
        templateTitle?: string | null,
        name?: string | null,
        hsrID?: string | null,
        description?: string | null,
        questionText?: string | null,
        surveyState?:  {
          __typename: "SurveyTotals",
          unanswered?: number | null,
          noGapIdentified?: number | null,
          deferPendingReassignment?: number | null,
          deferPendingResponse?: number | null,
          clarify?: number | null,
          yesPendingRequestedArtifacts?: number | null,
          yesPendingArtifacts?: number | null,
          yesCompliant?: number | null,
          notApplicable?: number | null,
        } | null,
        size?: number | null,
        parent?:  {
          __typename: "Assessment",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          parentID?: string | null,
          templateRoot?: boolean | null,
          title?: string | null,
          assessmentStartDate?: string | null,
          assessmentEndDate?: string | null,
          assessor?: string | null,
          scope?: string | null,
          hours?: string | null,
          templateTitle?: string | null,
          name?: string | null,
          hsrID?: string | null,
          description?: string | null,
          questionText?: string | null,
          surveyState?:  {
            __typename: "SurveyTotals",
            unanswered?: number | null,
            noGapIdentified?: number | null,
            deferPendingReassignment?: number | null,
            deferPendingResponse?: number | null,
            clarify?: number | null,
            yesPendingRequestedArtifacts?: number | null,
            yesPendingArtifacts?: number | null,
            yesCompliant?: number | null,
            notApplicable?: number | null,
          } | null,
          size?: number | null,
          parent?:  {
            __typename: "Assessment",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            parentID?: string | null,
            templateRoot?: boolean | null,
            title?: string | null,
            assessmentStartDate?: string | null,
            assessmentEndDate?: string | null,
            assessor?: string | null,
            scope?: string | null,
            hours?: string | null,
            templateTitle?: string | null,
            name?: string | null,
            hsrID?: string | null,
            description?: string | null,
            questionText?: string | null,
            surveyState?:  {
              __typename: "SurveyTotals",
              unanswered?: number | null,
              noGapIdentified?: number | null,
              deferPendingReassignment?: number | null,
              deferPendingResponse?: number | null,
              clarify?: number | null,
              yesPendingRequestedArtifacts?: number | null,
              yesPendingArtifacts?: number | null,
              yesCompliant?: number | null,
              notApplicable?: number | null,
            } | null,
            size?: number | null,
            parent?:  {
              __typename: "Assessment",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              parentID?: string | null,
              templateRoot?: boolean | null,
              title?: string | null,
              assessmentStartDate?: string | null,
              assessmentEndDate?: string | null,
              assessor?: string | null,
              scope?: string | null,
              hours?: string | null,
              templateTitle?: string | null,
              name?: string | null,
              hsrID?: string | null,
              description?: string | null,
              questionText?: string | null,
              surveyState?:  {
                __typename: "SurveyTotals",
                unanswered?: number | null,
                noGapIdentified?: number | null,
                deferPendingReassignment?: number | null,
                deferPendingResponse?: number | null,
                clarify?: number | null,
                yesPendingRequestedArtifacts?: number | null,
                yesPendingArtifacts?: number | null,
                yesCompliant?: number | null,
                notApplicable?: number | null,
              } | null,
              size?: number | null,
              parent?:  {
                __typename: "Assessment",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                parentID?: string | null,
                templateRoot?: boolean | null,
                title?: string | null,
                assessmentStartDate?: string | null,
                assessmentEndDate?: string | null,
                assessor?: string | null,
                scope?: string | null,
                hours?: string | null,
                templateTitle?: string | null,
                name?: string | null,
                hsrID?: string | null,
                description?: string | null,
                questionText?: string | null,
                surveyState?:  {
                  __typename: "SurveyTotals",
                  unanswered?: number | null,
                  noGapIdentified?: number | null,
                  deferPendingReassignment?: number | null,
                  deferPendingResponse?: number | null,
                  clarify?: number | null,
                  yesPendingRequestedArtifacts?: number | null,
                  yesPendingArtifacts?: number | null,
                  yesCompliant?: number | null,
                  notApplicable?: number | null,
                } | null,
                size?: number | null,
                parent?:  {
                  __typename: "Assessment",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  parentID?: string | null,
                  templateRoot?: boolean | null,
                  title?: string | null,
                  assessmentStartDate?: string | null,
                  assessmentEndDate?: string | null,
                  assessor?: string | null,
                  scope?: string | null,
                  hours?: string | null,
                  templateTitle?: string | null,
                  name?: string | null,
                  hsrID?: string | null,
                  description?: string | null,
                  questionText?: string | null,
                  size?: number | null,
                  groupID?: string | null,
                  archived?: boolean | null,
                } | null,
                groupID?: string | null,
                archived?: boolean | null,
                assessmentAssessor?:  {
                  __typename: "User",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  email?: string | null,
                  username?: string | null,
                  firstName?: string | null,
                  lastName?: string | null,
                  inactive?: boolean | null,
                  lastSeen?: string | null,
                  lastLoggedIn?: string | null,
                } | null,
              } | null,
              groupID?: string | null,
              archived?: boolean | null,
              assessmentAssessor?:  {
                __typename: "User",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                username?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
                lastSeen?: string | null,
                lastLoggedIn?: string | null,
              } | null,
            } | null,
            groupID?: string | null,
            archived?: boolean | null,
            assessmentAssessor?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
          } | null,
          groupID?: string | null,
          archived?: boolean | null,
          assessmentAssessor?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
        } | null,
        groupID?: string | null,
        archived?: boolean | null,
        assessmentAssessor?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
      } | null,
      groupID?: string | null,
      archived?: boolean | null,
      assessmentAssessor?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
    } | null,
    groupID?: string | null,
    archived?: boolean | null,
    assessmentAssessor?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
  } | null,
};

export type GetAssessmentsInGroupQueryVariables = {
  groupID: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetAssessmentsInGroupQuery = {
  getAssessmentsInGroup:  {
    __typename: "PaginatedAssessment",
    items:  Array< {
      __typename: "Assessment",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      parentID?: string | null,
      templateRoot?: boolean | null,
      title?: string | null,
      assessmentStartDate?: string | null,
      assessmentEndDate?: string | null,
      assessor?: string | null,
      scope?: string | null,
      hours?: string | null,
      templateTitle?: string | null,
      name?: string | null,
      hsrID?: string | null,
      description?: string | null,
      questionText?: string | null,
      surveyState?:  {
        __typename: "SurveyTotals",
        unanswered?: number | null,
        noGapIdentified?: number | null,
        deferPendingReassignment?: number | null,
        deferPendingResponse?: number | null,
        clarify?: number | null,
        yesPendingRequestedArtifacts?: number | null,
        yesPendingArtifacts?: number | null,
        yesCompliant?: number | null,
        notApplicable?: number | null,
      } | null,
      size?: number | null,
      parent?:  {
        __typename: "Assessment",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        parentID?: string | null,
        templateRoot?: boolean | null,
        title?: string | null,
        assessmentStartDate?: string | null,
        assessmentEndDate?: string | null,
        assessor?: string | null,
        scope?: string | null,
        hours?: string | null,
        templateTitle?: string | null,
        name?: string | null,
        hsrID?: string | null,
        description?: string | null,
        questionText?: string | null,
        surveyState?:  {
          __typename: "SurveyTotals",
          unanswered?: number | null,
          noGapIdentified?: number | null,
          deferPendingReassignment?: number | null,
          deferPendingResponse?: number | null,
          clarify?: number | null,
          yesPendingRequestedArtifacts?: number | null,
          yesPendingArtifacts?: number | null,
          yesCompliant?: number | null,
          notApplicable?: number | null,
        } | null,
        size?: number | null,
        parent?:  {
          __typename: "Assessment",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          parentID?: string | null,
          templateRoot?: boolean | null,
          title?: string | null,
          assessmentStartDate?: string | null,
          assessmentEndDate?: string | null,
          assessor?: string | null,
          scope?: string | null,
          hours?: string | null,
          templateTitle?: string | null,
          name?: string | null,
          hsrID?: string | null,
          description?: string | null,
          questionText?: string | null,
          surveyState?:  {
            __typename: "SurveyTotals",
            unanswered?: number | null,
            noGapIdentified?: number | null,
            deferPendingReassignment?: number | null,
            deferPendingResponse?: number | null,
            clarify?: number | null,
            yesPendingRequestedArtifacts?: number | null,
            yesPendingArtifacts?: number | null,
            yesCompliant?: number | null,
            notApplicable?: number | null,
          } | null,
          size?: number | null,
          parent?:  {
            __typename: "Assessment",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            parentID?: string | null,
            templateRoot?: boolean | null,
            title?: string | null,
            assessmentStartDate?: string | null,
            assessmentEndDate?: string | null,
            assessor?: string | null,
            scope?: string | null,
            hours?: string | null,
            templateTitle?: string | null,
            name?: string | null,
            hsrID?: string | null,
            description?: string | null,
            questionText?: string | null,
            surveyState?:  {
              __typename: "SurveyTotals",
              unanswered?: number | null,
              noGapIdentified?: number | null,
              deferPendingReassignment?: number | null,
              deferPendingResponse?: number | null,
              clarify?: number | null,
              yesPendingRequestedArtifacts?: number | null,
              yesPendingArtifacts?: number | null,
              yesCompliant?: number | null,
              notApplicable?: number | null,
            } | null,
            size?: number | null,
            parent?:  {
              __typename: "Assessment",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              parentID?: string | null,
              templateRoot?: boolean | null,
              title?: string | null,
              assessmentStartDate?: string | null,
              assessmentEndDate?: string | null,
              assessor?: string | null,
              scope?: string | null,
              hours?: string | null,
              templateTitle?: string | null,
              name?: string | null,
              hsrID?: string | null,
              description?: string | null,
              questionText?: string | null,
              surveyState?:  {
                __typename: "SurveyTotals",
                unanswered?: number | null,
                noGapIdentified?: number | null,
                deferPendingReassignment?: number | null,
                deferPendingResponse?: number | null,
                clarify?: number | null,
                yesPendingRequestedArtifacts?: number | null,
                yesPendingArtifacts?: number | null,
                yesCompliant?: number | null,
                notApplicable?: number | null,
              } | null,
              size?: number | null,
              parent?:  {
                __typename: "Assessment",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                parentID?: string | null,
                templateRoot?: boolean | null,
                title?: string | null,
                assessmentStartDate?: string | null,
                assessmentEndDate?: string | null,
                assessor?: string | null,
                scope?: string | null,
                hours?: string | null,
                templateTitle?: string | null,
                name?: string | null,
                hsrID?: string | null,
                description?: string | null,
                questionText?: string | null,
                surveyState?:  {
                  __typename: "SurveyTotals",
                  unanswered?: number | null,
                  noGapIdentified?: number | null,
                  deferPendingReassignment?: number | null,
                  deferPendingResponse?: number | null,
                  clarify?: number | null,
                  yesPendingRequestedArtifacts?: number | null,
                  yesPendingArtifacts?: number | null,
                  yesCompliant?: number | null,
                  notApplicable?: number | null,
                } | null,
                size?: number | null,
                parent?:  {
                  __typename: "Assessment",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  parentID?: string | null,
                  templateRoot?: boolean | null,
                  title?: string | null,
                  assessmentStartDate?: string | null,
                  assessmentEndDate?: string | null,
                  assessor?: string | null,
                  scope?: string | null,
                  hours?: string | null,
                  templateTitle?: string | null,
                  name?: string | null,
                  hsrID?: string | null,
                  description?: string | null,
                  questionText?: string | null,
                  size?: number | null,
                  groupID?: string | null,
                  archived?: boolean | null,
                } | null,
                groupID?: string | null,
                archived?: boolean | null,
                assessmentAssessor?:  {
                  __typename: "User",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  email?: string | null,
                  username?: string | null,
                  firstName?: string | null,
                  lastName?: string | null,
                  inactive?: boolean | null,
                  lastSeen?: string | null,
                  lastLoggedIn?: string | null,
                } | null,
              } | null,
              groupID?: string | null,
              archived?: boolean | null,
              assessmentAssessor?:  {
                __typename: "User",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                username?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
                lastSeen?: string | null,
                lastLoggedIn?: string | null,
              } | null,
            } | null,
            groupID?: string | null,
            archived?: boolean | null,
            assessmentAssessor?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
          } | null,
          groupID?: string | null,
          archived?: boolean | null,
          assessmentAssessor?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
        } | null,
        groupID?: string | null,
        archived?: boolean | null,
        assessmentAssessor?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
      } | null,
      groupID?: string | null,
      archived?: boolean | null,
      assessmentAssessor?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
    } >,
    nextToken?: string | null,
  },
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    sort?: string | null,
    email?: string | null,
    username?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    inactive?: boolean | null,
    lastSeen?: string | null,
    lastLoggedIn?: string | null,
  } | null,
};

export type GetOrganizationQueryVariables = {
  id: string,
};

export type GetOrganizationQuery = {
  getOrganization?:  {
    __typename: "Organization",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    sort?: string | null,
    name?: string | null,
    organizationRelationships?: string | null,
    demographics?: string | null,
  } | null,
};

export type GetNotificationQueryVariables = {
  id: string,
};

export type GetNotificationQuery = {
  getNotification?:  {
    __typename: "Notification",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    sort?: string | null,
    createdAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    lastModified?: string | null,
    itemID?: string | null,
    moduleName?: string | null,
    item?:  {
      __typename: "RelatableItem",
      id?: string | null,
      sort?: string | null,
      groupID?: string | null,
      owner?: string | null,
      uuid?: string | null,
      title?: string | null,
      dateClosed?: string | null,
      dueDate?: string | null,
      status?: Status | null,
      priority?: Priority | null,
      severity?: Severity | null,
      responseStatus?: string | null,
      archived?: boolean | null,
      createdAt?: string | null,
      lastModified?: string | null,
      compositeIDYear?: string | null,
      compositeID?: number | null,
      displayID?: string | null,
      relatedItemsOwner?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      relatedItemsGroup?:  {
        __typename: "Group",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        organizationID?: string | null,
        title?: string | null,
        shortTitle?: string | null,
        riskThreshold?:  {
          __typename: "RiskThreshold",
          financial?:  {
            __typename: "Threshold",
            appetitePercent?: number | null,
            appetiteAbsolute?: number | null,
            capacityPercent?: number | null,
            capacityAbsolute?: number | null,
          } | null,
          privacy?:  {
            __typename: "Threshold",
            appetitePercent?: number | null,
            appetiteAbsolute?: number | null,
            capacityPercent?: number | null,
            capacityAbsolute?: number | null,
          } | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          name?: string | null,
          organizationRelationships?: string | null,
          demographics?: string | null,
        } | null,
        groupEmailFooter?: string | null,
      } | null,
      fileName?: string | null,
      address?: string | null,
    } | null,
    notificationType?: string | null,
    data?: string | null,
    isRead?: boolean | null,
  } | null,
};

export type GetArtifactQueryVariables = {
  id: string,
};

export type GetArtifactQuery = {
  getArtifact?:  {
    __typename: "Artifact",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    sort?: string | null,
    groupID?: string | null,
    fileName?: string | null,
    displayFileName?: string | null,
    pointOfContact?: string | null,
    artifactID?: string | null,
    archived?: boolean | null,
  } | null,
};

export type GetControlsQueryVariables = {
  id: string,
};

export type GetControlsQuery = {
  getControls?:  {
    __typename: "Controls",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    sort?: string | null,
    displayID?: string | null,
    uuid?: string | null,
    parentID?: string | null,
    title?: string | null,
    description?: string | null,
    discussion?: string | null,
    relationships?: string | null,
    controlsList?:  {
      __typename: "PaginatedControls",
      items:  Array< {
        __typename: "Controls",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        displayID?: string | null,
        uuid?: string | null,
        parentID?: string | null,
        title?: string | null,
        description?: string | null,
        discussion?: string | null,
        relationships?: string | null,
        controlsList?:  {
          __typename: "PaginatedControls",
          items:  Array< {
            __typename: "Controls",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            displayID?: string | null,
            uuid?: string | null,
            parentID?: string | null,
            title?: string | null,
            description?: string | null,
            discussion?: string | null,
            relationships?: string | null,
            controlsList?:  {
              __typename: "PaginatedControls",
              items:  Array< {
                __typename: "Controls",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                displayID?: string | null,
                uuid?: string | null,
                parentID?: string | null,
                title?: string | null,
                description?: string | null,
                discussion?: string | null,
                relationships?: string | null,
                controlsList?:  {
                  __typename: "PaginatedControls",
                  nextToken?: string | null,
                } | null,
                controlsRelatedItems?:  {
                  __typename: "PaginatedLinks",
                  nextToken?: string | null,
                } | null,
              } >,
              nextToken?: string | null,
            } | null,
            controlsRelatedItems?:  {
              __typename: "PaginatedLinks",
              items:  Array< {
                __typename: "Link",
                id: string,
                sort: string,
                target?: string | null,
                targetType?: LinkTarget | null,
                item?:  {
                  __typename: "RelatableItem",
                  id?: string | null,
                  sort?: string | null,
                  groupID?: string | null,
                  owner?: string | null,
                  uuid?: string | null,
                  title?: string | null,
                  dateClosed?: string | null,
                  dueDate?: string | null,
                  status?: Status | null,
                  priority?: Priority | null,
                  severity?: Severity | null,
                  responseStatus?: string | null,
                  archived?: boolean | null,
                  createdAt?: string | null,
                  lastModified?: string | null,
                  compositeIDYear?: string | null,
                  compositeID?: number | null,
                  displayID?: string | null,
                  fileName?: string | null,
                  address?: string | null,
                } | null,
              } >,
              nextToken?: string | null,
            } | null,
          } >,
          nextToken?: string | null,
        } | null,
        controlsRelatedItems?:  {
          __typename: "PaginatedLinks",
          items:  Array< {
            __typename: "Link",
            id: string,
            sort: string,
            target?: string | null,
            targetType?: LinkTarget | null,
            item?:  {
              __typename: "RelatableItem",
              id?: string | null,
              sort?: string | null,
              groupID?: string | null,
              owner?: string | null,
              uuid?: string | null,
              title?: string | null,
              dateClosed?: string | null,
              dueDate?: string | null,
              status?: Status | null,
              priority?: Priority | null,
              severity?: Severity | null,
              responseStatus?: string | null,
              archived?: boolean | null,
              createdAt?: string | null,
              lastModified?: string | null,
              compositeIDYear?: string | null,
              compositeID?: number | null,
              displayID?: string | null,
              relatedItemsOwner?:  {
                __typename: "User",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                username?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
                lastSeen?: string | null,
                lastLoggedIn?: string | null,
              } | null,
              relatedItemsGroup?:  {
                __typename: "Group",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                organizationID?: string | null,
                title?: string | null,
                shortTitle?: string | null,
                organization?:  {
                  __typename: "Organization",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  name?: string | null,
                  organizationRelationships?: string | null,
                  demographics?: string | null,
                } | null,
                groupEmailFooter?: string | null,
              } | null,
              fileName?: string | null,
              address?: string | null,
            } | null,
          } >,
          nextToken?: string | null,
        } | null,
      } >,
      nextToken?: string | null,
    } | null,
    controlsRelatedItems?:  {
      __typename: "PaginatedLinks",
      items:  Array< {
        __typename: "Link",
        id: string,
        sort: string,
        target?: string | null,
        targetType?: LinkTarget | null,
        item?:  {
          __typename: "RelatableItem",
          id?: string | null,
          sort?: string | null,
          groupID?: string | null,
          owner?: string | null,
          uuid?: string | null,
          title?: string | null,
          dateClosed?: string | null,
          dueDate?: string | null,
          status?: Status | null,
          priority?: Priority | null,
          severity?: Severity | null,
          responseStatus?: string | null,
          archived?: boolean | null,
          createdAt?: string | null,
          lastModified?: string | null,
          compositeIDYear?: string | null,
          compositeID?: number | null,
          displayID?: string | null,
          relatedItemsOwner?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
          relatedItemsGroup?:  {
            __typename: "Group",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            organizationID?: string | null,
            title?: string | null,
            shortTitle?: string | null,
            riskThreshold?:  {
              __typename: "RiskThreshold",
              financial?:  {
                __typename: "Threshold",
                appetitePercent?: number | null,
                appetiteAbsolute?: number | null,
                capacityPercent?: number | null,
                capacityAbsolute?: number | null,
              } | null,
              privacy?:  {
                __typename: "Threshold",
                appetitePercent?: number | null,
                appetiteAbsolute?: number | null,
                capacityPercent?: number | null,
                capacityAbsolute?: number | null,
              } | null,
            } | null,
            organization?:  {
              __typename: "Organization",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              name?: string | null,
              organizationRelationships?: string | null,
              demographics?: string | null,
            } | null,
            groupEmailFooter?: string | null,
          } | null,
          fileName?: string | null,
          address?: string | null,
        } | null,
      } >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type GetRiskPortfolioQueryVariables = {
  id: string,
};

export type GetRiskPortfolioQuery = {
  getRiskPortfolio?:  {
    __typename: "RiskPortfolio",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    sort?: string | null,
    groupID?: string | null,
    title?: string | null,
    description?: string | null,
    notes?: string | null,
    owner?: string | null,
    status?: Status | null,
    assumptions?: string | null,
    archived?: boolean | null,
    modifiedBy?: string | null,
  } | null,
};

export type GetRiskPortfoliosInGroupQueryVariables = {
  groupID: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetRiskPortfoliosInGroupQuery = {
  getRiskPortfoliosInGroup:  {
    __typename: "PaginatedRiskPortfolio",
    items:  Array< {
      __typename: "RiskPortfolio",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      groupID?: string | null,
      title?: string | null,
      description?: string | null,
      notes?: string | null,
      owner?: string | null,
      status?: Status | null,
      assumptions?: string | null,
      archived?: boolean | null,
      modifiedBy?: string | null,
    } >,
    nextToken?: string | null,
  },
};

export type GetRiskComparisonQueryVariables = {
  id: string,
};

export type GetRiskComparisonQuery = {
  getRiskComparison?:  {
    __typename: "RiskComparison",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    sort?: string | null,
    groupID?: string | null,
    title?: string | null,
    description?: string | null,
    scenarioOneID?: string | null,
    scenarioTwoID?: string | null,
    scenarioThreeID?: string | null,
    scenarioOne?:  {
      __typename: "Scenario",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      createdAt?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      lastModified?: string | null,
      FAIRSummary?: string | null,
      scenarioID?: string | null,
      title?: string | null,
      description?: string | null,
      notes?: string | null,
      assumptions?: string | null,
      creationDate?: string | null,
      lastRevision?: string | null,
      nextRevision?: string | null,
      categories?: string | null,
      owner?: string | null,
      status?: Status | null,
      dateClosed?: string | null,
      indicator?: Indicator | null,
      compositeIDYear?: string | null,
      compositeID?: number | null,
      archived?: boolean | null,
      isTemplate?: boolean | null,
      groupID?: string | null,
      scenarioRelatedItems?:  {
        __typename: "PaginatedLinks",
        items:  Array< {
          __typename: "Link",
          id: string,
          sort: string,
          target?: string | null,
          targetType?: LinkTarget | null,
          item?:  {
            __typename: "RelatableItem",
            id?: string | null,
            sort?: string | null,
            groupID?: string | null,
            owner?: string | null,
            uuid?: string | null,
            title?: string | null,
            dateClosed?: string | null,
            dueDate?: string | null,
            status?: Status | null,
            priority?: Priority | null,
            severity?: Severity | null,
            responseStatus?: string | null,
            archived?: boolean | null,
            createdAt?: string | null,
            lastModified?: string | null,
            compositeIDYear?: string | null,
            compositeID?: number | null,
            displayID?: string | null,
            relatedItemsOwner?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
            relatedItemsGroup?:  {
              __typename: "Group",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              organizationID?: string | null,
              title?: string | null,
              shortTitle?: string | null,
              riskThreshold?:  {
                __typename: "RiskThreshold",
                financial?:  {
                  __typename: "Threshold",
                  appetitePercent?: number | null,
                  appetiteAbsolute?: number | null,
                  capacityPercent?: number | null,
                  capacityAbsolute?: number | null,
                } | null,
                privacy?:  {
                  __typename: "Threshold",
                  appetitePercent?: number | null,
                  appetiteAbsolute?: number | null,
                  capacityPercent?: number | null,
                  capacityAbsolute?: number | null,
                } | null,
              } | null,
              organization?:  {
                __typename: "Organization",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                name?: string | null,
                organizationRelationships?: string | null,
                demographics?: string | null,
              } | null,
              groupEmailFooter?: string | null,
            } | null,
            fileName?: string | null,
            address?: string | null,
          } | null,
        } >,
        nextToken?: string | null,
      } | null,
      scenarioOwner?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      scenarioCreatedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      scenarioModifiedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      scenarioRASCIRoles?:  {
        __typename: "PaginatedRASCIUsersOnItem",
        items:  Array< {
          __typename: "RASCIUserOnItem",
          id?: string | null,
          sort?: string | null,
          userID?: string | null,
          userInfo?:  {
            __typename: "UserInfo",
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
          } | null,
          user?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
          userRASCIRole?: UserRoles | null,
        } >,
        nextToken?: string | null,
      } | null,
    } | null,
    scenarioTwo?:  {
      __typename: "Scenario",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      createdAt?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      lastModified?: string | null,
      FAIRSummary?: string | null,
      scenarioID?: string | null,
      title?: string | null,
      description?: string | null,
      notes?: string | null,
      assumptions?: string | null,
      creationDate?: string | null,
      lastRevision?: string | null,
      nextRevision?: string | null,
      categories?: string | null,
      owner?: string | null,
      status?: Status | null,
      dateClosed?: string | null,
      indicator?: Indicator | null,
      compositeIDYear?: string | null,
      compositeID?: number | null,
      archived?: boolean | null,
      isTemplate?: boolean | null,
      groupID?: string | null,
      scenarioRelatedItems?:  {
        __typename: "PaginatedLinks",
        items:  Array< {
          __typename: "Link",
          id: string,
          sort: string,
          target?: string | null,
          targetType?: LinkTarget | null,
          item?:  {
            __typename: "RelatableItem",
            id?: string | null,
            sort?: string | null,
            groupID?: string | null,
            owner?: string | null,
            uuid?: string | null,
            title?: string | null,
            dateClosed?: string | null,
            dueDate?: string | null,
            status?: Status | null,
            priority?: Priority | null,
            severity?: Severity | null,
            responseStatus?: string | null,
            archived?: boolean | null,
            createdAt?: string | null,
            lastModified?: string | null,
            compositeIDYear?: string | null,
            compositeID?: number | null,
            displayID?: string | null,
            relatedItemsOwner?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
            relatedItemsGroup?:  {
              __typename: "Group",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              organizationID?: string | null,
              title?: string | null,
              shortTitle?: string | null,
              riskThreshold?:  {
                __typename: "RiskThreshold",
                financial?:  {
                  __typename: "Threshold",
                  appetitePercent?: number | null,
                  appetiteAbsolute?: number | null,
                  capacityPercent?: number | null,
                  capacityAbsolute?: number | null,
                } | null,
                privacy?:  {
                  __typename: "Threshold",
                  appetitePercent?: number | null,
                  appetiteAbsolute?: number | null,
                  capacityPercent?: number | null,
                  capacityAbsolute?: number | null,
                } | null,
              } | null,
              organization?:  {
                __typename: "Organization",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                name?: string | null,
                organizationRelationships?: string | null,
                demographics?: string | null,
              } | null,
              groupEmailFooter?: string | null,
            } | null,
            fileName?: string | null,
            address?: string | null,
          } | null,
        } >,
        nextToken?: string | null,
      } | null,
      scenarioOwner?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      scenarioCreatedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      scenarioModifiedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      scenarioRASCIRoles?:  {
        __typename: "PaginatedRASCIUsersOnItem",
        items:  Array< {
          __typename: "RASCIUserOnItem",
          id?: string | null,
          sort?: string | null,
          userID?: string | null,
          userInfo?:  {
            __typename: "UserInfo",
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
          } | null,
          user?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
          userRASCIRole?: UserRoles | null,
        } >,
        nextToken?: string | null,
      } | null,
    } | null,
    scenarioThree?:  {
      __typename: "Scenario",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      createdAt?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      lastModified?: string | null,
      FAIRSummary?: string | null,
      scenarioID?: string | null,
      title?: string | null,
      description?: string | null,
      notes?: string | null,
      assumptions?: string | null,
      creationDate?: string | null,
      lastRevision?: string | null,
      nextRevision?: string | null,
      categories?: string | null,
      owner?: string | null,
      status?: Status | null,
      dateClosed?: string | null,
      indicator?: Indicator | null,
      compositeIDYear?: string | null,
      compositeID?: number | null,
      archived?: boolean | null,
      isTemplate?: boolean | null,
      groupID?: string | null,
      scenarioRelatedItems?:  {
        __typename: "PaginatedLinks",
        items:  Array< {
          __typename: "Link",
          id: string,
          sort: string,
          target?: string | null,
          targetType?: LinkTarget | null,
          item?:  {
            __typename: "RelatableItem",
            id?: string | null,
            sort?: string | null,
            groupID?: string | null,
            owner?: string | null,
            uuid?: string | null,
            title?: string | null,
            dateClosed?: string | null,
            dueDate?: string | null,
            status?: Status | null,
            priority?: Priority | null,
            severity?: Severity | null,
            responseStatus?: string | null,
            archived?: boolean | null,
            createdAt?: string | null,
            lastModified?: string | null,
            compositeIDYear?: string | null,
            compositeID?: number | null,
            displayID?: string | null,
            relatedItemsOwner?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
            relatedItemsGroup?:  {
              __typename: "Group",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              organizationID?: string | null,
              title?: string | null,
              shortTitle?: string | null,
              riskThreshold?:  {
                __typename: "RiskThreshold",
                financial?:  {
                  __typename: "Threshold",
                  appetitePercent?: number | null,
                  appetiteAbsolute?: number | null,
                  capacityPercent?: number | null,
                  capacityAbsolute?: number | null,
                } | null,
                privacy?:  {
                  __typename: "Threshold",
                  appetitePercent?: number | null,
                  appetiteAbsolute?: number | null,
                  capacityPercent?: number | null,
                  capacityAbsolute?: number | null,
                } | null,
              } | null,
              organization?:  {
                __typename: "Organization",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                name?: string | null,
                organizationRelationships?: string | null,
                demographics?: string | null,
              } | null,
              groupEmailFooter?: string | null,
            } | null,
            fileName?: string | null,
            address?: string | null,
          } | null,
        } >,
        nextToken?: string | null,
      } | null,
      scenarioOwner?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      scenarioCreatedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      scenarioModifiedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      scenarioRASCIRoles?:  {
        __typename: "PaginatedRASCIUsersOnItem",
        items:  Array< {
          __typename: "RASCIUserOnItem",
          id?: string | null,
          sort?: string | null,
          userID?: string | null,
          userInfo?:  {
            __typename: "UserInfo",
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
          } | null,
          user?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
          userRASCIRole?: UserRoles | null,
        } >,
        nextToken?: string | null,
      } | null,
    } | null,
    scenarioOneDescription?: string | null,
    scenarioTwoDescription?: string | null,
    scenarioThreeDescription?: string | null,
    lossThreshold?: number | null,
    archived?: boolean | null,
    modifiedBy?: string | null,
  } | null,
};

export type GetRiskComparisonsInGroupQueryVariables = {
  groupID: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetRiskComparisonsInGroupQuery = {
  getRiskComparisonsInGroup:  {
    __typename: "PaginatedRiskComparison",
    items:  Array< {
      __typename: "RiskComparison",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      groupID?: string | null,
      title?: string | null,
      description?: string | null,
      scenarioOneID?: string | null,
      scenarioTwoID?: string | null,
      scenarioThreeID?: string | null,
      scenarioOne?:  {
        __typename: "Scenario",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        createdAt?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        lastModified?: string | null,
        FAIRSummary?: string | null,
        scenarioID?: string | null,
        title?: string | null,
        description?: string | null,
        notes?: string | null,
        assumptions?: string | null,
        creationDate?: string | null,
        lastRevision?: string | null,
        nextRevision?: string | null,
        categories?: string | null,
        owner?: string | null,
        status?: Status | null,
        dateClosed?: string | null,
        indicator?: Indicator | null,
        compositeIDYear?: string | null,
        compositeID?: number | null,
        archived?: boolean | null,
        isTemplate?: boolean | null,
        groupID?: string | null,
        scenarioRelatedItems?:  {
          __typename: "PaginatedLinks",
          items:  Array< {
            __typename: "Link",
            id: string,
            sort: string,
            target?: string | null,
            targetType?: LinkTarget | null,
            item?:  {
              __typename: "RelatableItem",
              id?: string | null,
              sort?: string | null,
              groupID?: string | null,
              owner?: string | null,
              uuid?: string | null,
              title?: string | null,
              dateClosed?: string | null,
              dueDate?: string | null,
              status?: Status | null,
              priority?: Priority | null,
              severity?: Severity | null,
              responseStatus?: string | null,
              archived?: boolean | null,
              createdAt?: string | null,
              lastModified?: string | null,
              compositeIDYear?: string | null,
              compositeID?: number | null,
              displayID?: string | null,
              relatedItemsOwner?:  {
                __typename: "User",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                username?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
                lastSeen?: string | null,
                lastLoggedIn?: string | null,
              } | null,
              relatedItemsGroup?:  {
                __typename: "Group",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                organizationID?: string | null,
                title?: string | null,
                shortTitle?: string | null,
                organization?:  {
                  __typename: "Organization",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  name?: string | null,
                  organizationRelationships?: string | null,
                  demographics?: string | null,
                } | null,
                groupEmailFooter?: string | null,
              } | null,
              fileName?: string | null,
              address?: string | null,
            } | null,
          } >,
          nextToken?: string | null,
        } | null,
        scenarioOwner?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        scenarioCreatedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        scenarioModifiedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        scenarioRASCIRoles?:  {
          __typename: "PaginatedRASCIUsersOnItem",
          items:  Array< {
            __typename: "RASCIUserOnItem",
            id?: string | null,
            sort?: string | null,
            userID?: string | null,
            userInfo?:  {
              __typename: "UserInfo",
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
            } | null,
            user?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
            userRASCIRole?: UserRoles | null,
          } >,
          nextToken?: string | null,
        } | null,
      } | null,
      scenarioTwo?:  {
        __typename: "Scenario",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        createdAt?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        lastModified?: string | null,
        FAIRSummary?: string | null,
        scenarioID?: string | null,
        title?: string | null,
        description?: string | null,
        notes?: string | null,
        assumptions?: string | null,
        creationDate?: string | null,
        lastRevision?: string | null,
        nextRevision?: string | null,
        categories?: string | null,
        owner?: string | null,
        status?: Status | null,
        dateClosed?: string | null,
        indicator?: Indicator | null,
        compositeIDYear?: string | null,
        compositeID?: number | null,
        archived?: boolean | null,
        isTemplate?: boolean | null,
        groupID?: string | null,
        scenarioRelatedItems?:  {
          __typename: "PaginatedLinks",
          items:  Array< {
            __typename: "Link",
            id: string,
            sort: string,
            target?: string | null,
            targetType?: LinkTarget | null,
            item?:  {
              __typename: "RelatableItem",
              id?: string | null,
              sort?: string | null,
              groupID?: string | null,
              owner?: string | null,
              uuid?: string | null,
              title?: string | null,
              dateClosed?: string | null,
              dueDate?: string | null,
              status?: Status | null,
              priority?: Priority | null,
              severity?: Severity | null,
              responseStatus?: string | null,
              archived?: boolean | null,
              createdAt?: string | null,
              lastModified?: string | null,
              compositeIDYear?: string | null,
              compositeID?: number | null,
              displayID?: string | null,
              relatedItemsOwner?:  {
                __typename: "User",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                username?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
                lastSeen?: string | null,
                lastLoggedIn?: string | null,
              } | null,
              relatedItemsGroup?:  {
                __typename: "Group",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                organizationID?: string | null,
                title?: string | null,
                shortTitle?: string | null,
                organization?:  {
                  __typename: "Organization",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  name?: string | null,
                  organizationRelationships?: string | null,
                  demographics?: string | null,
                } | null,
                groupEmailFooter?: string | null,
              } | null,
              fileName?: string | null,
              address?: string | null,
            } | null,
          } >,
          nextToken?: string | null,
        } | null,
        scenarioOwner?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        scenarioCreatedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        scenarioModifiedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        scenarioRASCIRoles?:  {
          __typename: "PaginatedRASCIUsersOnItem",
          items:  Array< {
            __typename: "RASCIUserOnItem",
            id?: string | null,
            sort?: string | null,
            userID?: string | null,
            userInfo?:  {
              __typename: "UserInfo",
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
            } | null,
            user?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
            userRASCIRole?: UserRoles | null,
          } >,
          nextToken?: string | null,
        } | null,
      } | null,
      scenarioThree?:  {
        __typename: "Scenario",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        createdAt?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        lastModified?: string | null,
        FAIRSummary?: string | null,
        scenarioID?: string | null,
        title?: string | null,
        description?: string | null,
        notes?: string | null,
        assumptions?: string | null,
        creationDate?: string | null,
        lastRevision?: string | null,
        nextRevision?: string | null,
        categories?: string | null,
        owner?: string | null,
        status?: Status | null,
        dateClosed?: string | null,
        indicator?: Indicator | null,
        compositeIDYear?: string | null,
        compositeID?: number | null,
        archived?: boolean | null,
        isTemplate?: boolean | null,
        groupID?: string | null,
        scenarioRelatedItems?:  {
          __typename: "PaginatedLinks",
          items:  Array< {
            __typename: "Link",
            id: string,
            sort: string,
            target?: string | null,
            targetType?: LinkTarget | null,
            item?:  {
              __typename: "RelatableItem",
              id?: string | null,
              sort?: string | null,
              groupID?: string | null,
              owner?: string | null,
              uuid?: string | null,
              title?: string | null,
              dateClosed?: string | null,
              dueDate?: string | null,
              status?: Status | null,
              priority?: Priority | null,
              severity?: Severity | null,
              responseStatus?: string | null,
              archived?: boolean | null,
              createdAt?: string | null,
              lastModified?: string | null,
              compositeIDYear?: string | null,
              compositeID?: number | null,
              displayID?: string | null,
              relatedItemsOwner?:  {
                __typename: "User",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                username?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
                lastSeen?: string | null,
                lastLoggedIn?: string | null,
              } | null,
              relatedItemsGroup?:  {
                __typename: "Group",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                organizationID?: string | null,
                title?: string | null,
                shortTitle?: string | null,
                organization?:  {
                  __typename: "Organization",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  name?: string | null,
                  organizationRelationships?: string | null,
                  demographics?: string | null,
                } | null,
                groupEmailFooter?: string | null,
              } | null,
              fileName?: string | null,
              address?: string | null,
            } | null,
          } >,
          nextToken?: string | null,
        } | null,
        scenarioOwner?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        scenarioCreatedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        scenarioModifiedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        scenarioRASCIRoles?:  {
          __typename: "PaginatedRASCIUsersOnItem",
          items:  Array< {
            __typename: "RASCIUserOnItem",
            id?: string | null,
            sort?: string | null,
            userID?: string | null,
            userInfo?:  {
              __typename: "UserInfo",
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
            } | null,
            user?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
            userRASCIRole?: UserRoles | null,
          } >,
          nextToken?: string | null,
        } | null,
      } | null,
      scenarioOneDescription?: string | null,
      scenarioTwoDescription?: string | null,
      scenarioThreeDescription?: string | null,
      lossThreshold?: number | null,
      archived?: boolean | null,
      modifiedBy?: string | null,
    } >,
    nextToken?: string | null,
  },
};

export type GetRiskDomainQueryVariables = {
  id: string,
};

export type GetRiskDomainQuery = {
  getRiskDomain?:  {
    __typename: "RiskDomain",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    groupID?: string | null,
    sort?: string | null,
    createdAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    lastModified?: string | null,
    title?: string | null,
    description?: string | null,
    displayID?: string | null,
    parentID?: string | null,
    parent?:  {
      __typename: "RiskDomain",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      groupID?: string | null,
      sort?: string | null,
      createdAt?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      lastModified?: string | null,
      title?: string | null,
      description?: string | null,
      displayID?: string | null,
      parentID?: string | null,
      parent?:  {
        __typename: "RiskDomain",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        groupID?: string | null,
        sort?: string | null,
        createdAt?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        lastModified?: string | null,
        title?: string | null,
        description?: string | null,
        displayID?: string | null,
        parentID?: string | null,
        parent?:  {
          __typename: "RiskDomain",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          groupID?: string | null,
          sort?: string | null,
          createdAt?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          lastModified?: string | null,
          title?: string | null,
          description?: string | null,
          displayID?: string | null,
          parentID?: string | null,
          parent?:  {
            __typename: "RiskDomain",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            groupID?: string | null,
            sort?: string | null,
            createdAt?: string | null,
            createdBy?: string | null,
            modifiedBy?: string | null,
            lastModified?: string | null,
            title?: string | null,
            description?: string | null,
            displayID?: string | null,
            parentID?: string | null,
            parent?:  {
              __typename: "RiskDomain",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              groupID?: string | null,
              sort?: string | null,
              createdAt?: string | null,
              createdBy?: string | null,
              modifiedBy?: string | null,
              lastModified?: string | null,
              title?: string | null,
              description?: string | null,
              displayID?: string | null,
              parentID?: string | null,
              parent?:  {
                __typename: "RiskDomain",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                groupID?: string | null,
                sort?: string | null,
                createdAt?: string | null,
                createdBy?: string | null,
                modifiedBy?: string | null,
                lastModified?: string | null,
                title?: string | null,
                description?: string | null,
                displayID?: string | null,
                parentID?: string | null,
                parent?:  {
                  __typename: "RiskDomain",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  groupID?: string | null,
                  sort?: string | null,
                  createdAt?: string | null,
                  createdBy?: string | null,
                  modifiedBy?: string | null,
                  lastModified?: string | null,
                  title?: string | null,
                  description?: string | null,
                  displayID?: string | null,
                  parentID?: string | null,
                  archived?: boolean | null,
                } | null,
                archived?: boolean | null,
                riskDomainCreatedBy?:  {
                  __typename: "User",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  email?: string | null,
                  username?: string | null,
                  firstName?: string | null,
                  lastName?: string | null,
                  inactive?: boolean | null,
                  lastSeen?: string | null,
                  lastLoggedIn?: string | null,
                } | null,
                riskDomainModifiedBy?:  {
                  __typename: "User",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  email?: string | null,
                  username?: string | null,
                  firstName?: string | null,
                  lastName?: string | null,
                  inactive?: boolean | null,
                  lastSeen?: string | null,
                  lastLoggedIn?: string | null,
                } | null,
              } | null,
              archived?: boolean | null,
              riskDomainCreatedBy?:  {
                __typename: "User",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                username?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
                lastSeen?: string | null,
                lastLoggedIn?: string | null,
              } | null,
              riskDomainModifiedBy?:  {
                __typename: "User",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                username?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
                lastSeen?: string | null,
                lastLoggedIn?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            riskDomainCreatedBy?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
            riskDomainModifiedBy?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          riskDomainCreatedBy?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
          riskDomainModifiedBy?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        riskDomainCreatedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        riskDomainModifiedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      riskDomainCreatedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      riskDomainModifiedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
    } | null,
    archived?: boolean | null,
    riskDomainCreatedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    riskDomainModifiedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
  } | null,
};

export type GetRiskDomainsInGroupQueryVariables = {
  groupID: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetRiskDomainsInGroupQuery = {
  getRiskDomainsInGroup:  {
    __typename: "PaginatedRiskDomain",
    items:  Array< {
      __typename: "RiskDomain",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      groupID?: string | null,
      sort?: string | null,
      createdAt?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      lastModified?: string | null,
      title?: string | null,
      description?: string | null,
      displayID?: string | null,
      parentID?: string | null,
      parent?:  {
        __typename: "RiskDomain",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        groupID?: string | null,
        sort?: string | null,
        createdAt?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        lastModified?: string | null,
        title?: string | null,
        description?: string | null,
        displayID?: string | null,
        parentID?: string | null,
        parent?:  {
          __typename: "RiskDomain",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          groupID?: string | null,
          sort?: string | null,
          createdAt?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          lastModified?: string | null,
          title?: string | null,
          description?: string | null,
          displayID?: string | null,
          parentID?: string | null,
          parent?:  {
            __typename: "RiskDomain",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            groupID?: string | null,
            sort?: string | null,
            createdAt?: string | null,
            createdBy?: string | null,
            modifiedBy?: string | null,
            lastModified?: string | null,
            title?: string | null,
            description?: string | null,
            displayID?: string | null,
            parentID?: string | null,
            parent?:  {
              __typename: "RiskDomain",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              groupID?: string | null,
              sort?: string | null,
              createdAt?: string | null,
              createdBy?: string | null,
              modifiedBy?: string | null,
              lastModified?: string | null,
              title?: string | null,
              description?: string | null,
              displayID?: string | null,
              parentID?: string | null,
              parent?:  {
                __typename: "RiskDomain",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                groupID?: string | null,
                sort?: string | null,
                createdAt?: string | null,
                createdBy?: string | null,
                modifiedBy?: string | null,
                lastModified?: string | null,
                title?: string | null,
                description?: string | null,
                displayID?: string | null,
                parentID?: string | null,
                parent?:  {
                  __typename: "RiskDomain",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  groupID?: string | null,
                  sort?: string | null,
                  createdAt?: string | null,
                  createdBy?: string | null,
                  modifiedBy?: string | null,
                  lastModified?: string | null,
                  title?: string | null,
                  description?: string | null,
                  displayID?: string | null,
                  parentID?: string | null,
                  archived?: boolean | null,
                } | null,
                archived?: boolean | null,
                riskDomainCreatedBy?:  {
                  __typename: "User",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  email?: string | null,
                  username?: string | null,
                  firstName?: string | null,
                  lastName?: string | null,
                  inactive?: boolean | null,
                  lastSeen?: string | null,
                  lastLoggedIn?: string | null,
                } | null,
                riskDomainModifiedBy?:  {
                  __typename: "User",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  email?: string | null,
                  username?: string | null,
                  firstName?: string | null,
                  lastName?: string | null,
                  inactive?: boolean | null,
                  lastSeen?: string | null,
                  lastLoggedIn?: string | null,
                } | null,
              } | null,
              archived?: boolean | null,
              riskDomainCreatedBy?:  {
                __typename: "User",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                username?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
                lastSeen?: string | null,
                lastLoggedIn?: string | null,
              } | null,
              riskDomainModifiedBy?:  {
                __typename: "User",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                username?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
                lastSeen?: string | null,
                lastLoggedIn?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            riskDomainCreatedBy?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
            riskDomainModifiedBy?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          riskDomainCreatedBy?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
          riskDomainModifiedBy?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        riskDomainCreatedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        riskDomainModifiedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      riskDomainCreatedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      riskDomainModifiedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
    } >,
    nextToken?: string | null,
  },
};

export type GetAuditPlanningQueryVariables = {
  id: string,
};

export type GetAuditPlanningQuery = {
  getAuditPlanning?:  {
    __typename: "AuditPlanning",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    title?: string | null,
    groupID?: string | null,
    sort?: string | null,
    createdAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    lastModified?: string | null,
    auditPlanID?: string | null,
    riskDomain?: string | null,
    year?: string | null,
    hours?: string | null,
    owner?: string | null,
    days?: string | null,
    status?: Status | null,
    background?: string | null,
    objective?: string | null,
    scope?: string | null,
    methodology?: string | null,
    preliminaryAuditWork?: string | null,
    points?: string | null,
    summary?: string | null,
    auditFindings?: string | null,
    recommendations?: string | null,
    auditStartDate?: string | null,
    auditEndDate?: string | null,
    auditor?: string | null,
    totalHours?: string | null,
    archived?: boolean | null,
    auditPlanningRelatedItems?:  {
      __typename: "PaginatedLinks",
      items:  Array< {
        __typename: "Link",
        id: string,
        sort: string,
        target?: string | null,
        targetType?: LinkTarget | null,
        item?:  {
          __typename: "RelatableItem",
          id?: string | null,
          sort?: string | null,
          groupID?: string | null,
          owner?: string | null,
          uuid?: string | null,
          title?: string | null,
          dateClosed?: string | null,
          dueDate?: string | null,
          status?: Status | null,
          priority?: Priority | null,
          severity?: Severity | null,
          responseStatus?: string | null,
          archived?: boolean | null,
          createdAt?: string | null,
          lastModified?: string | null,
          compositeIDYear?: string | null,
          compositeID?: number | null,
          displayID?: string | null,
          relatedItemsOwner?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
          relatedItemsGroup?:  {
            __typename: "Group",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            organizationID?: string | null,
            title?: string | null,
            shortTitle?: string | null,
            riskThreshold?:  {
              __typename: "RiskThreshold",
              financial?:  {
                __typename: "Threshold",
                appetitePercent?: number | null,
                appetiteAbsolute?: number | null,
                capacityPercent?: number | null,
                capacityAbsolute?: number | null,
              } | null,
              privacy?:  {
                __typename: "Threshold",
                appetitePercent?: number | null,
                appetiteAbsolute?: number | null,
                capacityPercent?: number | null,
                capacityAbsolute?: number | null,
              } | null,
            } | null,
            organization?:  {
              __typename: "Organization",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              name?: string | null,
              organizationRelationships?: string | null,
              demographics?: string | null,
            } | null,
            groupEmailFooter?: string | null,
          } | null,
          fileName?: string | null,
          address?: string | null,
        } | null,
      } >,
      nextToken?: string | null,
    } | null,
    isTemplate?: boolean | null,
    auditPlanningOwner?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    auditPlanningCreatedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    auditPlanningModifiedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    riskDomainDetails?:  {
      __typename: "RiskDomain",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      groupID?: string | null,
      sort?: string | null,
      createdAt?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      lastModified?: string | null,
      title?: string | null,
      description?: string | null,
      displayID?: string | null,
      parentID?: string | null,
      parent?:  {
        __typename: "RiskDomain",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        groupID?: string | null,
        sort?: string | null,
        createdAt?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        lastModified?: string | null,
        title?: string | null,
        description?: string | null,
        displayID?: string | null,
        parentID?: string | null,
        parent?:  {
          __typename: "RiskDomain",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          groupID?: string | null,
          sort?: string | null,
          createdAt?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          lastModified?: string | null,
          title?: string | null,
          description?: string | null,
          displayID?: string | null,
          parentID?: string | null,
          parent?:  {
            __typename: "RiskDomain",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            groupID?: string | null,
            sort?: string | null,
            createdAt?: string | null,
            createdBy?: string | null,
            modifiedBy?: string | null,
            lastModified?: string | null,
            title?: string | null,
            description?: string | null,
            displayID?: string | null,
            parentID?: string | null,
            parent?:  {
              __typename: "RiskDomain",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              groupID?: string | null,
              sort?: string | null,
              createdAt?: string | null,
              createdBy?: string | null,
              modifiedBy?: string | null,
              lastModified?: string | null,
              title?: string | null,
              description?: string | null,
              displayID?: string | null,
              parentID?: string | null,
              parent?:  {
                __typename: "RiskDomain",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                groupID?: string | null,
                sort?: string | null,
                createdAt?: string | null,
                createdBy?: string | null,
                modifiedBy?: string | null,
                lastModified?: string | null,
                title?: string | null,
                description?: string | null,
                displayID?: string | null,
                parentID?: string | null,
                parent?:  {
                  __typename: "RiskDomain",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  groupID?: string | null,
                  sort?: string | null,
                  createdAt?: string | null,
                  createdBy?: string | null,
                  modifiedBy?: string | null,
                  lastModified?: string | null,
                  title?: string | null,
                  description?: string | null,
                  displayID?: string | null,
                  parentID?: string | null,
                  archived?: boolean | null,
                } | null,
                archived?: boolean | null,
                riskDomainCreatedBy?:  {
                  __typename: "User",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  email?: string | null,
                  username?: string | null,
                  firstName?: string | null,
                  lastName?: string | null,
                  inactive?: boolean | null,
                  lastSeen?: string | null,
                  lastLoggedIn?: string | null,
                } | null,
                riskDomainModifiedBy?:  {
                  __typename: "User",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  email?: string | null,
                  username?: string | null,
                  firstName?: string | null,
                  lastName?: string | null,
                  inactive?: boolean | null,
                  lastSeen?: string | null,
                  lastLoggedIn?: string | null,
                } | null,
              } | null,
              archived?: boolean | null,
              riskDomainCreatedBy?:  {
                __typename: "User",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                username?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
                lastSeen?: string | null,
                lastLoggedIn?: string | null,
              } | null,
              riskDomainModifiedBy?:  {
                __typename: "User",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                username?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
                lastSeen?: string | null,
                lastLoggedIn?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            riskDomainCreatedBy?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
            riskDomainModifiedBy?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          riskDomainCreatedBy?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
          riskDomainModifiedBy?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        riskDomainCreatedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        riskDomainModifiedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      riskDomainCreatedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      riskDomainModifiedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
    } | null,
    managementResponse?: string | null,
    viewType?: ViewType | null,
  } | null,
};

export type GetUrlQueryVariables = {
  id: string,
};

export type GetUrlQuery = {
  getUrl?:  {
    __typename: "Url",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    sort?: string | null,
    address?: string | null,
    title?: string | null,
    groupID?: string | null,
  } | null,
};

export type GetUrlsInGroupQueryVariables = {
  groupID: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetUrlsInGroupQuery = {
  getUrlsInGroup:  {
    __typename: "PaginatedUrl",
    items:  Array< {
      __typename: "Url",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      address?: string | null,
      title?: string | null,
      groupID?: string | null,
    } >,
    nextToken?: string | null,
  },
};

export type GetCategoryQueryVariables = {
  id: string,
};

export type GetCategoryQuery = {
  getCategory?:  {
    __typename: "Category",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    groupID?: string | null,
    sort?: string | null,
    createdAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    lastModified?: string | null,
    title?: string | null,
    archived?: boolean | null,
    categoryCreatedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    categoryModifiedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
  } | null,
};

export type GetCategorysInGroupQueryVariables = {
  groupID: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetCategorysInGroupQuery = {
  getCategorysInGroup:  {
    __typename: "PaginatedCategory",
    items:  Array< {
      __typename: "Category",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      groupID?: string | null,
      sort?: string | null,
      createdAt?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      lastModified?: string | null,
      title?: string | null,
      archived?: boolean | null,
      categoryCreatedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      categoryModifiedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
    } >,
    nextToken?: string | null,
  },
};

export type GetSupportQueryVariables = {
  id: string,
};

export type GetSupportQuery = {
  getSupport?:  {
    __typename: "Support",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    groupID?: string | null,
    sort?: string | null,
    createdAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    lastModified?: string | null,
    title?: string | null,
    pointOfContact?: string | null,
    pocEmail?: string | null,
    pocPhoneNumber?: string | null,
    notes?: string | null,
    archived?: boolean | null,
    supportCreatedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    supportModifiedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
  } | null,
};

export type GetSupportsInGroupQueryVariables = {
  groupID: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetSupportsInGroupQuery = {
  getSupportsInGroup:  {
    __typename: "PaginatedSupport",
    items:  Array< {
      __typename: "Support",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      groupID?: string | null,
      sort?: string | null,
      createdAt?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      lastModified?: string | null,
      title?: string | null,
      pointOfContact?: string | null,
      pocEmail?: string | null,
      pocPhoneNumber?: string | null,
      notes?: string | null,
      archived?: boolean | null,
      supportCreatedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      supportModifiedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
    } >,
    nextToken?: string | null,
  },
};

export type GetLocationQueryVariables = {
  id: string,
};

export type GetLocationQuery = {
  getLocation?:  {
    __typename: "Location",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    groupID?: string | null,
    sort?: string | null,
    createdAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    lastModified?: string | null,
    title?: string | null,
    archived?: boolean | null,
    locationCreatedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    locationModifiedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
  } | null,
};

export type GetLocationsInGroupQueryVariables = {
  groupID: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetLocationsInGroupQuery = {
  getLocationsInGroup:  {
    __typename: "PaginatedLocation",
    items:  Array< {
      __typename: "Location",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      groupID?: string | null,
      sort?: string | null,
      createdAt?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      lastModified?: string | null,
      title?: string | null,
      archived?: boolean | null,
      locationCreatedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      locationModifiedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
    } >,
    nextToken?: string | null,
  },
};

export type GetAssetsGroupQueryVariables = {
  id: string,
};

export type GetAssetsGroupQuery = {
  getAssetsGroup?:  {
    __typename: "AssetsGroup",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    groupID?: string | null,
    sort?: string | null,
    createdAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    lastModified?: string | null,
    title?: string | null,
    archived?: boolean | null,
    assetsGroupCreatedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    assetsGroupModifiedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
  } | null,
};

export type GetAssetsGroupsInGroupQueryVariables = {
  groupID: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetAssetsGroupsInGroupQuery = {
  getAssetsGroupsInGroup:  {
    __typename: "PaginatedAssetsGroup",
    items:  Array< {
      __typename: "AssetsGroup",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      groupID?: string | null,
      sort?: string | null,
      createdAt?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      lastModified?: string | null,
      title?: string | null,
      archived?: boolean | null,
      assetsGroupCreatedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      assetsGroupModifiedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
    } >,
    nextToken?: string | null,
  },
};

export type GetThreatsQueryVariables = {
  id: string,
};

export type GetThreatsQuery = {
  getThreats?:  {
    __typename: "Threats",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    itemID?: string | null,
    groupID?: string | null,
    sort?: string | null,
    createdAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    lastModified?: string | null,
    title?: string | null,
    description?: string | null,
    impact?: Impact | null,
    archived?: boolean | null,
    threatsCreatedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    threatsModifiedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    threatAssessments?:  {
      __typename: "PaginatedThreatAssessments",
      items:  Array< {
        __typename: "ThreatAssessment",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        createdAt?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        lastModified?: string | null,
        threatID?: string | null,
        assetID?: string | null,
        min?: number | null,
        mostLikely?: number | null,
        max?: number | null,
        vulnerability?: number | null,
        index?: number | null,
        archived?: boolean | null,
      } >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type GetThreatssInGroupQueryVariables = {
  groupID: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetThreatssInGroupQuery = {
  getThreatssInGroup:  {
    __typename: "PaginatedThreats",
    items:  Array< {
      __typename: "Threats",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      itemID?: string | null,
      groupID?: string | null,
      sort?: string | null,
      createdAt?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      lastModified?: string | null,
      title?: string | null,
      description?: string | null,
      impact?: Impact | null,
      archived?: boolean | null,
      threatsCreatedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      threatsModifiedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      threatAssessments?:  {
        __typename: "PaginatedThreatAssessments",
        items:  Array< {
          __typename: "ThreatAssessment",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          createdAt?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          lastModified?: string | null,
          threatID?: string | null,
          assetID?: string | null,
          min?: number | null,
          mostLikely?: number | null,
          max?: number | null,
          vulnerability?: number | null,
          index?: number | null,
          archived?: boolean | null,
        } >,
        nextToken?: string | null,
      } | null,
    } >,
    nextToken?: string | null,
  },
};

export type GetAssetsQueryVariables = {
  id: string,
};

export type GetAssetsQuery = {
  getAssets?:  {
    __typename: "Assets",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    itemID?: string | null,
    groupID?: string | null,
    sort?: string | null,
    createdAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    lastModified?: string | null,
    title?: string | null,
    qty?: number | null,
    assetsGroupID?: string | null,
    notesAboutControls?: string | null,
    notesAboutThreats?: string | null,
    category?: string | null,
    assetCategory?:  {
      __typename: "Category",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      groupID?: string | null,
      sort?: string | null,
      createdAt?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      lastModified?: string | null,
      title?: string | null,
      archived?: boolean | null,
      categoryCreatedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      categoryModifiedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
    } | null,
    location?: string | null,
    assetLocation?:  {
      __typename: "Location",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      groupID?: string | null,
      sort?: string | null,
      createdAt?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      lastModified?: string | null,
      title?: string | null,
      archived?: boolean | null,
      locationCreatedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      locationModifiedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
    } | null,
    support?: string | null,
    assetSupport?:  {
      __typename: "Support",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      groupID?: string | null,
      sort?: string | null,
      createdAt?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      lastModified?: string | null,
      title?: string | null,
      pointOfContact?: string | null,
      pocEmail?: string | null,
      pocPhoneNumber?: string | null,
      notes?: string | null,
      archived?: boolean | null,
      supportCreatedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      supportModifiedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
    } | null,
    owner?: string | null,
    assetsAnalysisInput?: string | null,
    assetsAnalysisOutput?: string | null,
    assetOwner?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    assetCreatedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    assetModifiedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    notes?: string | null,
    displayID?: string | null,
    assetRelatedItems?:  {
      __typename: "PaginatedLinks",
      items:  Array< {
        __typename: "Link",
        id: string,
        sort: string,
        target?: string | null,
        targetType?: LinkTarget | null,
        item?:  {
          __typename: "RelatableItem",
          id?: string | null,
          sort?: string | null,
          groupID?: string | null,
          owner?: string | null,
          uuid?: string | null,
          title?: string | null,
          dateClosed?: string | null,
          dueDate?: string | null,
          status?: Status | null,
          priority?: Priority | null,
          severity?: Severity | null,
          responseStatus?: string | null,
          archived?: boolean | null,
          createdAt?: string | null,
          lastModified?: string | null,
          compositeIDYear?: string | null,
          compositeID?: number | null,
          displayID?: string | null,
          relatedItemsOwner?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
          relatedItemsGroup?:  {
            __typename: "Group",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            organizationID?: string | null,
            title?: string | null,
            shortTitle?: string | null,
            riskThreshold?:  {
              __typename: "RiskThreshold",
              financial?:  {
                __typename: "Threshold",
                appetitePercent?: number | null,
                appetiteAbsolute?: number | null,
                capacityPercent?: number | null,
                capacityAbsolute?: number | null,
              } | null,
              privacy?:  {
                __typename: "Threshold",
                appetitePercent?: number | null,
                appetiteAbsolute?: number | null,
                capacityPercent?: number | null,
                capacityAbsolute?: number | null,
              } | null,
            } | null,
            organization?:  {
              __typename: "Organization",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              name?: string | null,
              organizationRelationships?: string | null,
              demographics?: string | null,
            } | null,
            groupEmailFooter?: string | null,
          } | null,
          fileName?: string | null,
          address?: string | null,
        } | null,
      } >,
      nextToken?: string | null,
    } | null,
    archived?: boolean | null,
    threatAssessments?:  {
      __typename: "PaginatedThreatAssessments",
      items:  Array< {
        __typename: "ThreatAssessment",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        createdAt?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        lastModified?: string | null,
        threatID?: string | null,
        assetID?: string | null,
        min?: number | null,
        mostLikely?: number | null,
        max?: number | null,
        vulnerability?: number | null,
        index?: number | null,
        archived?: boolean | null,
      } >,
      nextToken?: string | null,
    } | null,
    PHIRecords?: string | null,
    assetsGroupDetails?:  {
      __typename: "AssetsGroup",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      groupID?: string | null,
      sort?: string | null,
      createdAt?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      lastModified?: string | null,
      title?: string | null,
      archived?: boolean | null,
      assetsGroupCreatedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      assetsGroupModifiedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
    } | null,
    assetID?: string | null,
    assessmentID?: string | null,
  } | null,
};

export type GetRiskAssessmentQueryVariables = {
  id: string,
};

export type GetRiskAssessmentQuery = {
  getRiskAssessment?:  {
    __typename: "RiskAssessment",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    title?: string | null,
    groupID?: string | null,
    sort?: string | null,
    createdAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    lastModified?: string | null,
    owner?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    overview?: string | null,
    assessor?: string | null,
    scope?: string | null,
    executiveSummary?: string | null,
    methodology?: string | null,
    archived?: boolean | null,
    riskAssessmentOwner?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    riskAssessmentCreatedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    riskAssessmentModifiedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
  } | null,
};

export type GetRiskAssessmentsInGroupQueryVariables = {
  groupID: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetRiskAssessmentsInGroupQuery = {
  getRiskAssessmentsInGroup:  {
    __typename: "PaginatedRiskAssessment",
    items:  Array< {
      __typename: "RiskAssessment",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      title?: string | null,
      groupID?: string | null,
      sort?: string | null,
      createdAt?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      lastModified?: string | null,
      owner?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      overview?: string | null,
      assessor?: string | null,
      scope?: string | null,
      executiveSummary?: string | null,
      methodology?: string | null,
      archived?: boolean | null,
      riskAssessmentOwner?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      riskAssessmentCreatedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      riskAssessmentModifiedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
    } >,
    nextToken?: string | null,
  },
};

export type GetSurveyTemplateQueryVariables = {
  id: string,
};

export type GetSurveyTemplateQuery = {
  getSurveyTemplate?:  {
    __typename: "SurveyTemplate",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    sort?: string | null,
    parentID?: string | null,
    templateRoot?: boolean | null,
    title?: string | null,
    locked?: number | null,
  } | null,
};

export type GetTemplateQuestionQueryVariables = {
  id: string,
};

export type GetTemplateQuestionQuery = {
  getTemplateQuestion?:  {
    __typename: "TemplateQuestion",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    sort?: string | null,
    parentID?: string | null,
    name?: string | null,
    questionText?: string | null,
    requiresArtifacts?: string | null,
  } | null,
};

export type GetTemplateQuestionsInGroupQueryVariables = {
  groupID: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetTemplateQuestionsInGroupQuery = {
  getTemplateQuestionsInGroup:  {
    __typename: "PaginatedTemplateQuestion",
    items:  Array< {
      __typename: "TemplateQuestion",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      parentID?: string | null,
      name?: string | null,
      questionText?: string | null,
      requiresArtifacts?: string | null,
    } >,
    nextToken?: string | null,
  },
};

export type GetAssessmentResponseQueryVariables = {
  id: string,
};

export type GetAssessmentResponseQuery = {
  getAssessmentResponse?:  {
    __typename: "AssessmentResponse",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    sort?: string | null,
    parentID?: string | null,
    title?: string | null,
    instructions?: string | null,
    notes?: string | null,
    responseType?: string | null,
    questionText?: string | null,
    response?: string | null,
    responseComments?: string | null,
    responseStatus?: string | null,
    artifactRequired?: boolean | null,
    artifactRequired_yes?: boolean | null,
    artifactRequired_no?: boolean | null,
    owner?: string | null,
    parent?:  {
      __typename: "Assessment",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      parentID?: string | null,
      templateRoot?: boolean | null,
      title?: string | null,
      assessmentStartDate?: string | null,
      assessmentEndDate?: string | null,
      assessor?: string | null,
      scope?: string | null,
      hours?: string | null,
      templateTitle?: string | null,
      name?: string | null,
      hsrID?: string | null,
      description?: string | null,
      questionText?: string | null,
      surveyState?:  {
        __typename: "SurveyTotals",
        unanswered?: number | null,
        noGapIdentified?: number | null,
        deferPendingReassignment?: number | null,
        deferPendingResponse?: number | null,
        clarify?: number | null,
        yesPendingRequestedArtifacts?: number | null,
        yesPendingArtifacts?: number | null,
        yesCompliant?: number | null,
        notApplicable?: number | null,
      } | null,
      size?: number | null,
      parent?:  {
        __typename: "Assessment",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        parentID?: string | null,
        templateRoot?: boolean | null,
        title?: string | null,
        assessmentStartDate?: string | null,
        assessmentEndDate?: string | null,
        assessor?: string | null,
        scope?: string | null,
        hours?: string | null,
        templateTitle?: string | null,
        name?: string | null,
        hsrID?: string | null,
        description?: string | null,
        questionText?: string | null,
        surveyState?:  {
          __typename: "SurveyTotals",
          unanswered?: number | null,
          noGapIdentified?: number | null,
          deferPendingReassignment?: number | null,
          deferPendingResponse?: number | null,
          clarify?: number | null,
          yesPendingRequestedArtifacts?: number | null,
          yesPendingArtifacts?: number | null,
          yesCompliant?: number | null,
          notApplicable?: number | null,
        } | null,
        size?: number | null,
        parent?:  {
          __typename: "Assessment",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          parentID?: string | null,
          templateRoot?: boolean | null,
          title?: string | null,
          assessmentStartDate?: string | null,
          assessmentEndDate?: string | null,
          assessor?: string | null,
          scope?: string | null,
          hours?: string | null,
          templateTitle?: string | null,
          name?: string | null,
          hsrID?: string | null,
          description?: string | null,
          questionText?: string | null,
          surveyState?:  {
            __typename: "SurveyTotals",
            unanswered?: number | null,
            noGapIdentified?: number | null,
            deferPendingReassignment?: number | null,
            deferPendingResponse?: number | null,
            clarify?: number | null,
            yesPendingRequestedArtifacts?: number | null,
            yesPendingArtifacts?: number | null,
            yesCompliant?: number | null,
            notApplicable?: number | null,
          } | null,
          size?: number | null,
          parent?:  {
            __typename: "Assessment",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            parentID?: string | null,
            templateRoot?: boolean | null,
            title?: string | null,
            assessmentStartDate?: string | null,
            assessmentEndDate?: string | null,
            assessor?: string | null,
            scope?: string | null,
            hours?: string | null,
            templateTitle?: string | null,
            name?: string | null,
            hsrID?: string | null,
            description?: string | null,
            questionText?: string | null,
            surveyState?:  {
              __typename: "SurveyTotals",
              unanswered?: number | null,
              noGapIdentified?: number | null,
              deferPendingReassignment?: number | null,
              deferPendingResponse?: number | null,
              clarify?: number | null,
              yesPendingRequestedArtifacts?: number | null,
              yesPendingArtifacts?: number | null,
              yesCompliant?: number | null,
              notApplicable?: number | null,
            } | null,
            size?: number | null,
            parent?:  {
              __typename: "Assessment",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              parentID?: string | null,
              templateRoot?: boolean | null,
              title?: string | null,
              assessmentStartDate?: string | null,
              assessmentEndDate?: string | null,
              assessor?: string | null,
              scope?: string | null,
              hours?: string | null,
              templateTitle?: string | null,
              name?: string | null,
              hsrID?: string | null,
              description?: string | null,
              questionText?: string | null,
              surveyState?:  {
                __typename: "SurveyTotals",
                unanswered?: number | null,
                noGapIdentified?: number | null,
                deferPendingReassignment?: number | null,
                deferPendingResponse?: number | null,
                clarify?: number | null,
                yesPendingRequestedArtifacts?: number | null,
                yesPendingArtifacts?: number | null,
                yesCompliant?: number | null,
                notApplicable?: number | null,
              } | null,
              size?: number | null,
              parent?:  {
                __typename: "Assessment",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                parentID?: string | null,
                templateRoot?: boolean | null,
                title?: string | null,
                assessmentStartDate?: string | null,
                assessmentEndDate?: string | null,
                assessor?: string | null,
                scope?: string | null,
                hours?: string | null,
                templateTitle?: string | null,
                name?: string | null,
                hsrID?: string | null,
                description?: string | null,
                questionText?: string | null,
                surveyState?:  {
                  __typename: "SurveyTotals",
                  unanswered?: number | null,
                  noGapIdentified?: number | null,
                  deferPendingReassignment?: number | null,
                  deferPendingResponse?: number | null,
                  clarify?: number | null,
                  yesPendingRequestedArtifacts?: number | null,
                  yesPendingArtifacts?: number | null,
                  yesCompliant?: number | null,
                  notApplicable?: number | null,
                } | null,
                size?: number | null,
                parent?:  {
                  __typename: "Assessment",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  parentID?: string | null,
                  templateRoot?: boolean | null,
                  title?: string | null,
                  assessmentStartDate?: string | null,
                  assessmentEndDate?: string | null,
                  assessor?: string | null,
                  scope?: string | null,
                  hours?: string | null,
                  templateTitle?: string | null,
                  name?: string | null,
                  hsrID?: string | null,
                  description?: string | null,
                  questionText?: string | null,
                  size?: number | null,
                  groupID?: string | null,
                  archived?: boolean | null,
                } | null,
                groupID?: string | null,
                archived?: boolean | null,
                assessmentAssessor?:  {
                  __typename: "User",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  email?: string | null,
                  username?: string | null,
                  firstName?: string | null,
                  lastName?: string | null,
                  inactive?: boolean | null,
                  lastSeen?: string | null,
                  lastLoggedIn?: string | null,
                } | null,
              } | null,
              groupID?: string | null,
              archived?: boolean | null,
              assessmentAssessor?:  {
                __typename: "User",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                username?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
                lastSeen?: string | null,
                lastLoggedIn?: string | null,
              } | null,
            } | null,
            groupID?: string | null,
            archived?: boolean | null,
            assessmentAssessor?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
          } | null,
          groupID?: string | null,
          archived?: boolean | null,
          assessmentAssessor?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
        } | null,
        groupID?: string | null,
        archived?: boolean | null,
        assessmentAssessor?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
      } | null,
      groupID?: string | null,
      archived?: boolean | null,
      assessmentAssessor?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
    } | null,
    groupID?: string | null,
    responseRelatedItems?:  {
      __typename: "PaginatedLinks",
      items:  Array< {
        __typename: "Link",
        id: string,
        sort: string,
        target?: string | null,
        targetType?: LinkTarget | null,
        item?:  {
          __typename: "RelatableItem",
          id?: string | null,
          sort?: string | null,
          groupID?: string | null,
          owner?: string | null,
          uuid?: string | null,
          title?: string | null,
          dateClosed?: string | null,
          dueDate?: string | null,
          status?: Status | null,
          priority?: Priority | null,
          severity?: Severity | null,
          responseStatus?: string | null,
          archived?: boolean | null,
          createdAt?: string | null,
          lastModified?: string | null,
          compositeIDYear?: string | null,
          compositeID?: number | null,
          displayID?: string | null,
          relatedItemsOwner?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
          relatedItemsGroup?:  {
            __typename: "Group",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            organizationID?: string | null,
            title?: string | null,
            shortTitle?: string | null,
            riskThreshold?:  {
              __typename: "RiskThreshold",
              financial?:  {
                __typename: "Threshold",
                appetitePercent?: number | null,
                appetiteAbsolute?: number | null,
                capacityPercent?: number | null,
                capacityAbsolute?: number | null,
              } | null,
              privacy?:  {
                __typename: "Threshold",
                appetitePercent?: number | null,
                appetiteAbsolute?: number | null,
                capacityPercent?: number | null,
                capacityAbsolute?: number | null,
              } | null,
            } | null,
            organization?:  {
              __typename: "Organization",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              name?: string | null,
              organizationRelationships?: string | null,
              demographics?: string | null,
            } | null,
            groupEmailFooter?: string | null,
          } | null,
          fileName?: string | null,
          address?: string | null,
        } | null,
      } >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type GetOwnedIssuesQueryVariables = {
  nextToken?: string | null,
  limit?: number | null,
  id: string,
};

export type GetOwnedIssuesQuery = {
  getOwnedIssues?:  {
    __typename: "PaginatedIssueReferences",
    items:  Array< {
      __typename: "IssueReference",
      id: string,
      sort: string,
      itemID: string,
      issue?:  {
        __typename: "Issue",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        createdAt?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        verifiedBy?: string | null,
        lastModified?: string | null,
        issueID?: string | null,
        title?: string | null,
        description?: string | null,
        assumptions?: string | null,
        notes?: string | null,
        reportedBy?: string | null,
        reportedOn?: string | null,
        nextReview?: string | null,
        owner?: string | null,
        priority?: Priority | null,
        status?: Status | null,
        approver?: string | null,
        dateClosed?: string | null,
        severity?: Severity | null,
        compositeIDYear?: string | null,
        compositeID?: number | null,
        archived?: boolean | null,
        isTemplate?: boolean | null,
        issueSourceId?: string | null,
        groupID?: string | null,
        issueRelatedItems?:  {
          __typename: "PaginatedLinks",
          items:  Array< {
            __typename: "Link",
            id: string,
            sort: string,
            target?: string | null,
            targetType?: LinkTarget | null,
            item?:  {
              __typename: "RelatableItem",
              id?: string | null,
              sort?: string | null,
              groupID?: string | null,
              owner?: string | null,
              uuid?: string | null,
              title?: string | null,
              dateClosed?: string | null,
              dueDate?: string | null,
              status?: Status | null,
              priority?: Priority | null,
              severity?: Severity | null,
              responseStatus?: string | null,
              archived?: boolean | null,
              createdAt?: string | null,
              lastModified?: string | null,
              compositeIDYear?: string | null,
              compositeID?: number | null,
              displayID?: string | null,
              relatedItemsOwner?:  {
                __typename: "User",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                username?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
                lastSeen?: string | null,
                lastLoggedIn?: string | null,
              } | null,
              relatedItemsGroup?:  {
                __typename: "Group",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                organizationID?: string | null,
                title?: string | null,
                shortTitle?: string | null,
                organization?:  {
                  __typename: "Organization",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  name?: string | null,
                  organizationRelationships?: string | null,
                  demographics?: string | null,
                } | null,
                groupEmailFooter?: string | null,
              } | null,
              fileName?: string | null,
              address?: string | null,
            } | null,
          } >,
          nextToken?: string | null,
        } | null,
        acceptanceCriteria?: string | null,
        requiresScenario?: boolean | null,
        requiresActionplan?: boolean | null,
        issueOwner?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        issueCreatedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        issueModifiedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        issueVerifiedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        issueSource?:  {
          __typename: "Source",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          organizationID?: string | null,
          groupID?: string | null,
          description?: string | null,
          year?: string | null,
          title?: string | null,
          archived?: boolean | null,
        } | null,
        issueRASCIRoles?:  {
          __typename: "PaginatedRASCIUsersOnItem",
          items:  Array< {
            __typename: "RASCIUserOnItem",
            id?: string | null,
            sort?: string | null,
            userID?: string | null,
            userInfo?:  {
              __typename: "UserInfo",
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
            } | null,
            user?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
            userRASCIRole?: UserRoles | null,
          } >,
          nextToken?: string | null,
        } | null,
        issueApprovers?:  {
          __typename: "PaginatedItemApprover",
          items:  Array< {
            __typename: "ItemApprover",
            id?: string | null,
            sort?: string | null,
            userID?: string | null,
            userInfo?:  {
              __typename: "UserInfo",
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
            } | null,
            user?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
          } >,
          nextToken?: string | null,
        } | null,
      } | null,
      createdAt?: string | null,
      userRASCIRole?: UserRoles | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetRASCIOwnedIssuesQueryVariables = {
  nextToken?: string | null,
  limit?: number | null,
  id: string,
};

export type GetRASCIOwnedIssuesQuery = {
  getRASCIOwnedIssues?:  {
    __typename: "PaginatedIssueReferences",
    items:  Array< {
      __typename: "IssueReference",
      id: string,
      sort: string,
      itemID: string,
      issue?:  {
        __typename: "Issue",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        createdAt?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        verifiedBy?: string | null,
        lastModified?: string | null,
        issueID?: string | null,
        title?: string | null,
        description?: string | null,
        assumptions?: string | null,
        notes?: string | null,
        reportedBy?: string | null,
        reportedOn?: string | null,
        nextReview?: string | null,
        owner?: string | null,
        priority?: Priority | null,
        status?: Status | null,
        approver?: string | null,
        dateClosed?: string | null,
        severity?: Severity | null,
        compositeIDYear?: string | null,
        compositeID?: number | null,
        archived?: boolean | null,
        isTemplate?: boolean | null,
        issueSourceId?: string | null,
        groupID?: string | null,
        issueRelatedItems?:  {
          __typename: "PaginatedLinks",
          items:  Array< {
            __typename: "Link",
            id: string,
            sort: string,
            target?: string | null,
            targetType?: LinkTarget | null,
            item?:  {
              __typename: "RelatableItem",
              id?: string | null,
              sort?: string | null,
              groupID?: string | null,
              owner?: string | null,
              uuid?: string | null,
              title?: string | null,
              dateClosed?: string | null,
              dueDate?: string | null,
              status?: Status | null,
              priority?: Priority | null,
              severity?: Severity | null,
              responseStatus?: string | null,
              archived?: boolean | null,
              createdAt?: string | null,
              lastModified?: string | null,
              compositeIDYear?: string | null,
              compositeID?: number | null,
              displayID?: string | null,
              relatedItemsOwner?:  {
                __typename: "User",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                username?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
                lastSeen?: string | null,
                lastLoggedIn?: string | null,
              } | null,
              relatedItemsGroup?:  {
                __typename: "Group",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                organizationID?: string | null,
                title?: string | null,
                shortTitle?: string | null,
                organization?:  {
                  __typename: "Organization",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  name?: string | null,
                  organizationRelationships?: string | null,
                  demographics?: string | null,
                } | null,
                groupEmailFooter?: string | null,
              } | null,
              fileName?: string | null,
              address?: string | null,
            } | null,
          } >,
          nextToken?: string | null,
        } | null,
        acceptanceCriteria?: string | null,
        requiresScenario?: boolean | null,
        requiresActionplan?: boolean | null,
        issueOwner?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        issueCreatedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        issueModifiedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        issueVerifiedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        issueSource?:  {
          __typename: "Source",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          organizationID?: string | null,
          groupID?: string | null,
          description?: string | null,
          year?: string | null,
          title?: string | null,
          archived?: boolean | null,
        } | null,
        issueRASCIRoles?:  {
          __typename: "PaginatedRASCIUsersOnItem",
          items:  Array< {
            __typename: "RASCIUserOnItem",
            id?: string | null,
            sort?: string | null,
            userID?: string | null,
            userInfo?:  {
              __typename: "UserInfo",
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
            } | null,
            user?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
            userRASCIRole?: UserRoles | null,
          } >,
          nextToken?: string | null,
        } | null,
        issueApprovers?:  {
          __typename: "PaginatedItemApprover",
          items:  Array< {
            __typename: "ItemApprover",
            id?: string | null,
            sort?: string | null,
            userID?: string | null,
            userInfo?:  {
              __typename: "UserInfo",
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
            } | null,
            user?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
          } >,
          nextToken?: string | null,
        } | null,
      } | null,
      createdAt?: string | null,
      userRASCIRole?: UserRoles | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetIssueChangesInGroupQueryVariables = {
  nextToken?: string | null,
  limit?: number | null,
  id: string,
};

export type GetIssueChangesInGroupQuery = {
  getIssueChangesInGroup?:  {
    __typename: "PaginatedIssueChanges",
    items:  Array< {
      __typename: "IssueChanges",
      id: string,
      sort: string,
      issueID: string,
      currentIssue?:  {
        __typename: "Issue",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        createdAt?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        verifiedBy?: string | null,
        lastModified?: string | null,
        issueID?: string | null,
        title?: string | null,
        description?: string | null,
        assumptions?: string | null,
        notes?: string | null,
        reportedBy?: string | null,
        reportedOn?: string | null,
        nextReview?: string | null,
        owner?: string | null,
        priority?: Priority | null,
        status?: Status | null,
        approver?: string | null,
        dateClosed?: string | null,
        severity?: Severity | null,
        compositeIDYear?: string | null,
        compositeID?: number | null,
        archived?: boolean | null,
        isTemplate?: boolean | null,
        issueSourceId?: string | null,
        groupID?: string | null,
        issueRelatedItems?:  {
          __typename: "PaginatedLinks",
          items:  Array< {
            __typename: "Link",
            id: string,
            sort: string,
            target?: string | null,
            targetType?: LinkTarget | null,
            item?:  {
              __typename: "RelatableItem",
              id?: string | null,
              sort?: string | null,
              groupID?: string | null,
              owner?: string | null,
              uuid?: string | null,
              title?: string | null,
              dateClosed?: string | null,
              dueDate?: string | null,
              status?: Status | null,
              priority?: Priority | null,
              severity?: Severity | null,
              responseStatus?: string | null,
              archived?: boolean | null,
              createdAt?: string | null,
              lastModified?: string | null,
              compositeIDYear?: string | null,
              compositeID?: number | null,
              displayID?: string | null,
              relatedItemsOwner?:  {
                __typename: "User",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                username?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
                lastSeen?: string | null,
                lastLoggedIn?: string | null,
              } | null,
              relatedItemsGroup?:  {
                __typename: "Group",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                organizationID?: string | null,
                title?: string | null,
                shortTitle?: string | null,
                organization?:  {
                  __typename: "Organization",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  name?: string | null,
                  organizationRelationships?: string | null,
                  demographics?: string | null,
                } | null,
                groupEmailFooter?: string | null,
              } | null,
              fileName?: string | null,
              address?: string | null,
            } | null,
          } >,
          nextToken?: string | null,
        } | null,
        acceptanceCriteria?: string | null,
        requiresScenario?: boolean | null,
        requiresActionplan?: boolean | null,
        issueOwner?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        issueCreatedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        issueModifiedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        issueVerifiedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        issueSource?:  {
          __typename: "Source",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          organizationID?: string | null,
          groupID?: string | null,
          description?: string | null,
          year?: string | null,
          title?: string | null,
          archived?: boolean | null,
        } | null,
        issueRASCIRoles?:  {
          __typename: "PaginatedRASCIUsersOnItem",
          items:  Array< {
            __typename: "RASCIUserOnItem",
            id?: string | null,
            sort?: string | null,
            userID?: string | null,
            userInfo?:  {
              __typename: "UserInfo",
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
            } | null,
            user?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
            userRASCIRole?: UserRoles | null,
          } >,
          nextToken?: string | null,
        } | null,
        issueApprovers?:  {
          __typename: "PaginatedItemApprover",
          items:  Array< {
            __typename: "ItemApprover",
            id?: string | null,
            sort?: string | null,
            userID?: string | null,
            userInfo?:  {
              __typename: "UserInfo",
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
            } | null,
            user?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
          } >,
          nextToken?: string | null,
        } | null,
      } | null,
      changeType?: string | null,
      requestingUser?: string | null,
      createdAt?: string | null,
      issueChanges?:  {
        __typename: "Issue",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        createdAt?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        verifiedBy?: string | null,
        lastModified?: string | null,
        issueID?: string | null,
        title?: string | null,
        description?: string | null,
        assumptions?: string | null,
        notes?: string | null,
        reportedBy?: string | null,
        reportedOn?: string | null,
        nextReview?: string | null,
        owner?: string | null,
        priority?: Priority | null,
        status?: Status | null,
        approver?: string | null,
        dateClosed?: string | null,
        severity?: Severity | null,
        compositeIDYear?: string | null,
        compositeID?: number | null,
        archived?: boolean | null,
        isTemplate?: boolean | null,
        issueSourceId?: string | null,
        groupID?: string | null,
        issueRelatedItems?:  {
          __typename: "PaginatedLinks",
          items:  Array< {
            __typename: "Link",
            id: string,
            sort: string,
            target?: string | null,
            targetType?: LinkTarget | null,
            item?:  {
              __typename: "RelatableItem",
              id?: string | null,
              sort?: string | null,
              groupID?: string | null,
              owner?: string | null,
              uuid?: string | null,
              title?: string | null,
              dateClosed?: string | null,
              dueDate?: string | null,
              status?: Status | null,
              priority?: Priority | null,
              severity?: Severity | null,
              responseStatus?: string | null,
              archived?: boolean | null,
              createdAt?: string | null,
              lastModified?: string | null,
              compositeIDYear?: string | null,
              compositeID?: number | null,
              displayID?: string | null,
              relatedItemsOwner?:  {
                __typename: "User",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                username?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
                lastSeen?: string | null,
                lastLoggedIn?: string | null,
              } | null,
              relatedItemsGroup?:  {
                __typename: "Group",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                organizationID?: string | null,
                title?: string | null,
                shortTitle?: string | null,
                organization?:  {
                  __typename: "Organization",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  name?: string | null,
                  organizationRelationships?: string | null,
                  demographics?: string | null,
                } | null,
                groupEmailFooter?: string | null,
              } | null,
              fileName?: string | null,
              address?: string | null,
            } | null,
          } >,
          nextToken?: string | null,
        } | null,
        acceptanceCriteria?: string | null,
        requiresScenario?: boolean | null,
        requiresActionplan?: boolean | null,
        issueOwner?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        issueCreatedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        issueModifiedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        issueVerifiedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        issueSource?:  {
          __typename: "Source",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          organizationID?: string | null,
          groupID?: string | null,
          description?: string | null,
          year?: string | null,
          title?: string | null,
          archived?: boolean | null,
        } | null,
        issueRASCIRoles?:  {
          __typename: "PaginatedRASCIUsersOnItem",
          items:  Array< {
            __typename: "RASCIUserOnItem",
            id?: string | null,
            sort?: string | null,
            userID?: string | null,
            userInfo?:  {
              __typename: "UserInfo",
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
            } | null,
            user?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
            userRASCIRole?: UserRoles | null,
          } >,
          nextToken?: string | null,
        } | null,
        issueApprovers?:  {
          __typename: "PaginatedItemApprover",
          items:  Array< {
            __typename: "ItemApprover",
            id?: string | null,
            sort?: string | null,
            userID?: string | null,
            userInfo?:  {
              __typename: "UserInfo",
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
            } | null,
            user?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
          } >,
          nextToken?: string | null,
        } | null,
      } | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetScenarioChangesInGroupQueryVariables = {
  nextToken?: string | null,
  limit?: number | null,
  id: string,
};

export type GetScenarioChangesInGroupQuery = {
  getScenarioChangesInGroup?:  {
    __typename: "PaginatedScenarioChanges",
    items:  Array< {
      __typename: "ScenarioChanges",
      id: string,
      sort: string,
      scenarioID: string,
      currentScenario?:  {
        __typename: "Scenario",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        createdAt?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        lastModified?: string | null,
        FAIRSummary?: string | null,
        scenarioID?: string | null,
        title?: string | null,
        description?: string | null,
        notes?: string | null,
        assumptions?: string | null,
        creationDate?: string | null,
        lastRevision?: string | null,
        nextRevision?: string | null,
        categories?: string | null,
        owner?: string | null,
        status?: Status | null,
        dateClosed?: string | null,
        indicator?: Indicator | null,
        compositeIDYear?: string | null,
        compositeID?: number | null,
        archived?: boolean | null,
        isTemplate?: boolean | null,
        groupID?: string | null,
        scenarioRelatedItems?:  {
          __typename: "PaginatedLinks",
          items:  Array< {
            __typename: "Link",
            id: string,
            sort: string,
            target?: string | null,
            targetType?: LinkTarget | null,
            item?:  {
              __typename: "RelatableItem",
              id?: string | null,
              sort?: string | null,
              groupID?: string | null,
              owner?: string | null,
              uuid?: string | null,
              title?: string | null,
              dateClosed?: string | null,
              dueDate?: string | null,
              status?: Status | null,
              priority?: Priority | null,
              severity?: Severity | null,
              responseStatus?: string | null,
              archived?: boolean | null,
              createdAt?: string | null,
              lastModified?: string | null,
              compositeIDYear?: string | null,
              compositeID?: number | null,
              displayID?: string | null,
              relatedItemsOwner?:  {
                __typename: "User",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                username?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
                lastSeen?: string | null,
                lastLoggedIn?: string | null,
              } | null,
              relatedItemsGroup?:  {
                __typename: "Group",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                organizationID?: string | null,
                title?: string | null,
                shortTitle?: string | null,
                organization?:  {
                  __typename: "Organization",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  name?: string | null,
                  organizationRelationships?: string | null,
                  demographics?: string | null,
                } | null,
                groupEmailFooter?: string | null,
              } | null,
              fileName?: string | null,
              address?: string | null,
            } | null,
          } >,
          nextToken?: string | null,
        } | null,
        scenarioOwner?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        scenarioCreatedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        scenarioModifiedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        scenarioRASCIRoles?:  {
          __typename: "PaginatedRASCIUsersOnItem",
          items:  Array< {
            __typename: "RASCIUserOnItem",
            id?: string | null,
            sort?: string | null,
            userID?: string | null,
            userInfo?:  {
              __typename: "UserInfo",
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
            } | null,
            user?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
            userRASCIRole?: UserRoles | null,
          } >,
          nextToken?: string | null,
        } | null,
      } | null,
      changeType?: string | null,
      requestingUser?: string | null,
      createdAt?: string | null,
      scenarioChanges?:  {
        __typename: "Scenario",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        createdAt?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        lastModified?: string | null,
        FAIRSummary?: string | null,
        scenarioID?: string | null,
        title?: string | null,
        description?: string | null,
        notes?: string | null,
        assumptions?: string | null,
        creationDate?: string | null,
        lastRevision?: string | null,
        nextRevision?: string | null,
        categories?: string | null,
        owner?: string | null,
        status?: Status | null,
        dateClosed?: string | null,
        indicator?: Indicator | null,
        compositeIDYear?: string | null,
        compositeID?: number | null,
        archived?: boolean | null,
        isTemplate?: boolean | null,
        groupID?: string | null,
        scenarioRelatedItems?:  {
          __typename: "PaginatedLinks",
          items:  Array< {
            __typename: "Link",
            id: string,
            sort: string,
            target?: string | null,
            targetType?: LinkTarget | null,
            item?:  {
              __typename: "RelatableItem",
              id?: string | null,
              sort?: string | null,
              groupID?: string | null,
              owner?: string | null,
              uuid?: string | null,
              title?: string | null,
              dateClosed?: string | null,
              dueDate?: string | null,
              status?: Status | null,
              priority?: Priority | null,
              severity?: Severity | null,
              responseStatus?: string | null,
              archived?: boolean | null,
              createdAt?: string | null,
              lastModified?: string | null,
              compositeIDYear?: string | null,
              compositeID?: number | null,
              displayID?: string | null,
              relatedItemsOwner?:  {
                __typename: "User",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                username?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
                lastSeen?: string | null,
                lastLoggedIn?: string | null,
              } | null,
              relatedItemsGroup?:  {
                __typename: "Group",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                organizationID?: string | null,
                title?: string | null,
                shortTitle?: string | null,
                organization?:  {
                  __typename: "Organization",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  name?: string | null,
                  organizationRelationships?: string | null,
                  demographics?: string | null,
                } | null,
                groupEmailFooter?: string | null,
              } | null,
              fileName?: string | null,
              address?: string | null,
            } | null,
          } >,
          nextToken?: string | null,
        } | null,
        scenarioOwner?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        scenarioCreatedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        scenarioModifiedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        scenarioRASCIRoles?:  {
          __typename: "PaginatedRASCIUsersOnItem",
          items:  Array< {
            __typename: "RASCIUserOnItem",
            id?: string | null,
            sort?: string | null,
            userID?: string | null,
            userInfo?:  {
              __typename: "UserInfo",
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
            } | null,
            user?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
            userRASCIRole?: UserRoles | null,
          } >,
          nextToken?: string | null,
        } | null,
      } | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetActionPlanChangesInGroupQueryVariables = {
  nextToken?: string | null,
  limit?: number | null,
  id: string,
};

export type GetActionPlanChangesInGroupQuery = {
  getActionPlanChangesInGroup?:  {
    __typename: "PaginatedActionPlanChanges",
    items:  Array< {
      __typename: "ActionPlanChanges",
      id: string,
      sort: string,
      actionplanID: string,
      currentActionPlan?:  {
        __typename: "ActionPlan",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        createdAt?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        lastModified?: string | null,
        actionPlanID?: string | null,
        title?: string | null,
        description?: string | null,
        resources?: string | null,
        notes?: string | null,
        owner?: string | null,
        priority?: Priority | null,
        status?: Status | null,
        approver?: string | null,
        dateClosed?: string | null,
        startDate?: string | null,
        dueDate?: string | null,
        reviewDate?: string | null,
        compositeIDYear?: string | null,
        compositeID?: number | null,
        archived?: boolean | null,
        isTemplate?: boolean | null,
        groupID?: string | null,
        actionPlanRelatedItems?:  {
          __typename: "PaginatedLinks",
          items:  Array< {
            __typename: "Link",
            id: string,
            sort: string,
            target?: string | null,
            targetType?: LinkTarget | null,
            item?:  {
              __typename: "RelatableItem",
              id?: string | null,
              sort?: string | null,
              groupID?: string | null,
              owner?: string | null,
              uuid?: string | null,
              title?: string | null,
              dateClosed?: string | null,
              dueDate?: string | null,
              status?: Status | null,
              priority?: Priority | null,
              severity?: Severity | null,
              responseStatus?: string | null,
              archived?: boolean | null,
              createdAt?: string | null,
              lastModified?: string | null,
              compositeIDYear?: string | null,
              compositeID?: number | null,
              displayID?: string | null,
              relatedItemsOwner?:  {
                __typename: "User",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                username?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
                lastSeen?: string | null,
                lastLoggedIn?: string | null,
              } | null,
              relatedItemsGroup?:  {
                __typename: "Group",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                organizationID?: string | null,
                title?: string | null,
                shortTitle?: string | null,
                organization?:  {
                  __typename: "Organization",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  name?: string | null,
                  organizationRelationships?: string | null,
                  demographics?: string | null,
                } | null,
                groupEmailFooter?: string | null,
              } | null,
              fileName?: string | null,
              address?: string | null,
            } | null,
          } >,
          nextToken?: string | null,
        } | null,
        taskChildren?:  {
          __typename: "PaginatedTaskReferences",
          items:  Array< {
            __typename: "TaskReference",
            id: string,
            sort: string,
            itemID: string,
            task?:  {
              __typename: "Task",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              createdAt?: string | null,
              createdBy?: string | null,
              modifiedBy?: string | null,
              lastModified?: string | null,
              parentID?: string | null,
              organizationID?: string | null,
              groupID?: string | null,
              title?: string | null,
              description?: string | null,
              resources?: string | null,
              notes?: string | null,
              owner?: string | null,
              priority?: Priority | null,
              status?: Status | null,
              dateClosed?: string | null,
              dueDate?: string | null,
              archived?: boolean | null,
              taskOwner?:  {
                __typename: "User",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                username?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
                lastSeen?: string | null,
                lastLoggedIn?: string | null,
              } | null,
              taskRASCIRoles?:  {
                __typename: "PaginatedRASCIUsersOnItem",
                items:  Array< {
                  __typename: "RASCIUserOnItem",
                  id?: string | null,
                  sort?: string | null,
                  userID?: string | null,
                  userRASCIRole?: UserRoles | null,
                } >,
                nextToken?: string | null,
              } | null,
            } | null,
            createdAt?: string | null,
            userRASCIRole?: UserRoles | null,
          } >,
          nextToken?: string | null,
        } | null,
        effortAmount?: number | null,
        effortType?: EffortType | null,
        actionPlanOwner?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        actionPlanCreatedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        actionPlanModifiedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        actionPlanRASCIRoles?:  {
          __typename: "PaginatedRASCIUsersOnItem",
          items:  Array< {
            __typename: "RASCIUserOnItem",
            id?: string | null,
            sort?: string | null,
            userID?: string | null,
            userInfo?:  {
              __typename: "UserInfo",
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
            } | null,
            user?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
            userRASCIRole?: UserRoles | null,
          } >,
          nextToken?: string | null,
        } | null,
        actionplanApprovers?:  {
          __typename: "PaginatedItemApprover",
          items:  Array< {
            __typename: "ItemApprover",
            id?: string | null,
            sort?: string | null,
            userID?: string | null,
            userInfo?:  {
              __typename: "UserInfo",
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
            } | null,
            user?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
          } >,
          nextToken?: string | null,
        } | null,
        type?: string | null,
      } | null,
      changeType?: string | null,
      requestingUser?: string | null,
      createdAt?: string | null,
      actionplanChanges?:  {
        __typename: "ActionPlan",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        createdAt?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        lastModified?: string | null,
        actionPlanID?: string | null,
        title?: string | null,
        description?: string | null,
        resources?: string | null,
        notes?: string | null,
        owner?: string | null,
        priority?: Priority | null,
        status?: Status | null,
        approver?: string | null,
        dateClosed?: string | null,
        startDate?: string | null,
        dueDate?: string | null,
        reviewDate?: string | null,
        compositeIDYear?: string | null,
        compositeID?: number | null,
        archived?: boolean | null,
        isTemplate?: boolean | null,
        groupID?: string | null,
        actionPlanRelatedItems?:  {
          __typename: "PaginatedLinks",
          items:  Array< {
            __typename: "Link",
            id: string,
            sort: string,
            target?: string | null,
            targetType?: LinkTarget | null,
            item?:  {
              __typename: "RelatableItem",
              id?: string | null,
              sort?: string | null,
              groupID?: string | null,
              owner?: string | null,
              uuid?: string | null,
              title?: string | null,
              dateClosed?: string | null,
              dueDate?: string | null,
              status?: Status | null,
              priority?: Priority | null,
              severity?: Severity | null,
              responseStatus?: string | null,
              archived?: boolean | null,
              createdAt?: string | null,
              lastModified?: string | null,
              compositeIDYear?: string | null,
              compositeID?: number | null,
              displayID?: string | null,
              relatedItemsOwner?:  {
                __typename: "User",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                username?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
                lastSeen?: string | null,
                lastLoggedIn?: string | null,
              } | null,
              relatedItemsGroup?:  {
                __typename: "Group",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                organizationID?: string | null,
                title?: string | null,
                shortTitle?: string | null,
                organization?:  {
                  __typename: "Organization",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  name?: string | null,
                  organizationRelationships?: string | null,
                  demographics?: string | null,
                } | null,
                groupEmailFooter?: string | null,
              } | null,
              fileName?: string | null,
              address?: string | null,
            } | null,
          } >,
          nextToken?: string | null,
        } | null,
        taskChildren?:  {
          __typename: "PaginatedTaskReferences",
          items:  Array< {
            __typename: "TaskReference",
            id: string,
            sort: string,
            itemID: string,
            task?:  {
              __typename: "Task",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              createdAt?: string | null,
              createdBy?: string | null,
              modifiedBy?: string | null,
              lastModified?: string | null,
              parentID?: string | null,
              organizationID?: string | null,
              groupID?: string | null,
              title?: string | null,
              description?: string | null,
              resources?: string | null,
              notes?: string | null,
              owner?: string | null,
              priority?: Priority | null,
              status?: Status | null,
              dateClosed?: string | null,
              dueDate?: string | null,
              archived?: boolean | null,
              taskOwner?:  {
                __typename: "User",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                username?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
                lastSeen?: string | null,
                lastLoggedIn?: string | null,
              } | null,
              taskRASCIRoles?:  {
                __typename: "PaginatedRASCIUsersOnItem",
                items:  Array< {
                  __typename: "RASCIUserOnItem",
                  id?: string | null,
                  sort?: string | null,
                  userID?: string | null,
                  userRASCIRole?: UserRoles | null,
                } >,
                nextToken?: string | null,
              } | null,
            } | null,
            createdAt?: string | null,
            userRASCIRole?: UserRoles | null,
          } >,
          nextToken?: string | null,
        } | null,
        effortAmount?: number | null,
        effortType?: EffortType | null,
        actionPlanOwner?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        actionPlanCreatedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        actionPlanModifiedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        actionPlanRASCIRoles?:  {
          __typename: "PaginatedRASCIUsersOnItem",
          items:  Array< {
            __typename: "RASCIUserOnItem",
            id?: string | null,
            sort?: string | null,
            userID?: string | null,
            userInfo?:  {
              __typename: "UserInfo",
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
            } | null,
            user?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
            userRASCIRole?: UserRoles | null,
          } >,
          nextToken?: string | null,
        } | null,
        actionplanApprovers?:  {
          __typename: "PaginatedItemApprover",
          items:  Array< {
            __typename: "ItemApprover",
            id?: string | null,
            sort?: string | null,
            userID?: string | null,
            userInfo?:  {
              __typename: "UserInfo",
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
            } | null,
            user?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
          } >,
          nextToken?: string | null,
        } | null,
        type?: string | null,
      } | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetIssuev3QueryVariables = {
  id: string,
};

export type GetIssuev3Query = {
  getIssuev3?:  {
    __typename: "Issue",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    sort?: string | null,
    createdAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    verifiedBy?: string | null,
    lastModified?: string | null,
    issueID?: string | null,
    title?: string | null,
    description?: string | null,
    assumptions?: string | null,
    notes?: string | null,
    reportedBy?: string | null,
    reportedOn?: string | null,
    nextReview?: string | null,
    owner?: string | null,
    priority?: Priority | null,
    status?: Status | null,
    approver?: string | null,
    dateClosed?: string | null,
    severity?: Severity | null,
    compositeIDYear?: string | null,
    compositeID?: number | null,
    archived?: boolean | null,
    isTemplate?: boolean | null,
    issueSourceId?: string | null,
    groupID?: string | null,
    issueRelatedItems?:  {
      __typename: "PaginatedLinks",
      items:  Array< {
        __typename: "Link",
        id: string,
        sort: string,
        target?: string | null,
        targetType?: LinkTarget | null,
        item?:  {
          __typename: "RelatableItem",
          id?: string | null,
          sort?: string | null,
          groupID?: string | null,
          owner?: string | null,
          uuid?: string | null,
          title?: string | null,
          dateClosed?: string | null,
          dueDate?: string | null,
          status?: Status | null,
          priority?: Priority | null,
          severity?: Severity | null,
          responseStatus?: string | null,
          archived?: boolean | null,
          createdAt?: string | null,
          lastModified?: string | null,
          compositeIDYear?: string | null,
          compositeID?: number | null,
          displayID?: string | null,
          relatedItemsOwner?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
          relatedItemsGroup?:  {
            __typename: "Group",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            organizationID?: string | null,
            title?: string | null,
            shortTitle?: string | null,
            riskThreshold?:  {
              __typename: "RiskThreshold",
              financial?:  {
                __typename: "Threshold",
                appetitePercent?: number | null,
                appetiteAbsolute?: number | null,
                capacityPercent?: number | null,
                capacityAbsolute?: number | null,
              } | null,
              privacy?:  {
                __typename: "Threshold",
                appetitePercent?: number | null,
                appetiteAbsolute?: number | null,
                capacityPercent?: number | null,
                capacityAbsolute?: number | null,
              } | null,
            } | null,
            organization?:  {
              __typename: "Organization",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              name?: string | null,
              organizationRelationships?: string | null,
              demographics?: string | null,
            } | null,
            groupEmailFooter?: string | null,
          } | null,
          fileName?: string | null,
          address?: string | null,
        } | null,
      } >,
      nextToken?: string | null,
    } | null,
    acceptanceCriteria?: string | null,
    requiresScenario?: boolean | null,
    requiresActionplan?: boolean | null,
    issueOwner?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    issueCreatedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    issueModifiedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    issueVerifiedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    issueSource?:  {
      __typename: "Source",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      organizationID?: string | null,
      groupID?: string | null,
      description?: string | null,
      year?: string | null,
      title?: string | null,
      archived?: boolean | null,
    } | null,
    issueRASCIRoles?:  {
      __typename: "PaginatedRASCIUsersOnItem",
      items:  Array< {
        __typename: "RASCIUserOnItem",
        id?: string | null,
        sort?: string | null,
        userID?: string | null,
        userInfo?:  {
          __typename: "UserInfo",
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
        } | null,
        user?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        userRASCIRole?: UserRoles | null,
      } >,
      nextToken?: string | null,
    } | null,
    issueApprovers?:  {
      __typename: "PaginatedItemApprover",
      items:  Array< {
        __typename: "ItemApprover",
        id?: string | null,
        sort?: string | null,
        userID?: string | null,
        userInfo?:  {
          __typename: "UserInfo",
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
        } | null,
        user?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
      } >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type GetIssuesInGroupv3QueryVariables = {
  nextToken?: string | null,
  limit?: number | null,
  id: string,
};

export type GetIssuesInGroupv3Query = {
  getIssuesInGroupv3?:  {
    __typename: "PaginatedIssueReferences",
    items:  Array< {
      __typename: "IssueReference",
      id: string,
      sort: string,
      itemID: string,
      issue?:  {
        __typename: "Issue",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        createdAt?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        verifiedBy?: string | null,
        lastModified?: string | null,
        issueID?: string | null,
        title?: string | null,
        description?: string | null,
        assumptions?: string | null,
        notes?: string | null,
        reportedBy?: string | null,
        reportedOn?: string | null,
        nextReview?: string | null,
        owner?: string | null,
        priority?: Priority | null,
        status?: Status | null,
        approver?: string | null,
        dateClosed?: string | null,
        severity?: Severity | null,
        compositeIDYear?: string | null,
        compositeID?: number | null,
        archived?: boolean | null,
        isTemplate?: boolean | null,
        issueSourceId?: string | null,
        groupID?: string | null,
        issueRelatedItems?:  {
          __typename: "PaginatedLinks",
          items:  Array< {
            __typename: "Link",
            id: string,
            sort: string,
            target?: string | null,
            targetType?: LinkTarget | null,
            item?:  {
              __typename: "RelatableItem",
              id?: string | null,
              sort?: string | null,
              groupID?: string | null,
              owner?: string | null,
              uuid?: string | null,
              title?: string | null,
              dateClosed?: string | null,
              dueDate?: string | null,
              status?: Status | null,
              priority?: Priority | null,
              severity?: Severity | null,
              responseStatus?: string | null,
              archived?: boolean | null,
              createdAt?: string | null,
              lastModified?: string | null,
              compositeIDYear?: string | null,
              compositeID?: number | null,
              displayID?: string | null,
              relatedItemsOwner?:  {
                __typename: "User",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                username?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
                lastSeen?: string | null,
                lastLoggedIn?: string | null,
              } | null,
              relatedItemsGroup?:  {
                __typename: "Group",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                organizationID?: string | null,
                title?: string | null,
                shortTitle?: string | null,
                organization?:  {
                  __typename: "Organization",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  name?: string | null,
                  organizationRelationships?: string | null,
                  demographics?: string | null,
                } | null,
                groupEmailFooter?: string | null,
              } | null,
              fileName?: string | null,
              address?: string | null,
            } | null,
          } >,
          nextToken?: string | null,
        } | null,
        acceptanceCriteria?: string | null,
        requiresScenario?: boolean | null,
        requiresActionplan?: boolean | null,
        issueOwner?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        issueCreatedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        issueModifiedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        issueVerifiedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        issueSource?:  {
          __typename: "Source",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          organizationID?: string | null,
          groupID?: string | null,
          description?: string | null,
          year?: string | null,
          title?: string | null,
          archived?: boolean | null,
        } | null,
        issueRASCIRoles?:  {
          __typename: "PaginatedRASCIUsersOnItem",
          items:  Array< {
            __typename: "RASCIUserOnItem",
            id?: string | null,
            sort?: string | null,
            userID?: string | null,
            userInfo?:  {
              __typename: "UserInfo",
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
            } | null,
            user?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
            userRASCIRole?: UserRoles | null,
          } >,
          nextToken?: string | null,
        } | null,
        issueApprovers?:  {
          __typename: "PaginatedItemApprover",
          items:  Array< {
            __typename: "ItemApprover",
            id?: string | null,
            sort?: string | null,
            userID?: string | null,
            userInfo?:  {
              __typename: "UserInfo",
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
            } | null,
            user?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
          } >,
          nextToken?: string | null,
        } | null,
      } | null,
      createdAt?: string | null,
      userRASCIRole?: UserRoles | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetIssuesTriagedPerMonthQueryVariables = {
  nextToken?: string | null,
  limit?: number | null,
  id: string,
};

export type GetIssuesTriagedPerMonthQuery = {
  getIssuesTriagedPerMonth?:  {
    __typename: "PaginatedIssuesTriagedPerMonthInGroup",
    items:  Array< {
      __typename: "IssuesTriagedPerMonthInGroup",
      id: string,
      sort: string,
      total?: number | null,
      year?: number | null,
      month?: string | null,
      firstDate?: string | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetIssuesCreatedPerMonthQueryVariables = {
  nextToken?: string | null,
  limit?: number | null,
  id: string,
};

export type GetIssuesCreatedPerMonthQuery = {
  getIssuesCreatedPerMonth?:  {
    __typename: "PaginatedIssuesCreatedPerMonth",
    items:  Array< {
      __typename: "IssuesCreatedPerMonth",
      id: string,
      sort: string,
      total?: number | null,
      year?: number | null,
      month?: string | null,
      firstDate?: string | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetIssuesCreatedPerMonthInGroupQueryVariables = {
  nextToken?: string | null,
  limit?: number | null,
  id: string,
};

export type GetIssuesCreatedPerMonthInGroupQuery = {
  getIssuesCreatedPerMonthInGroup?:  {
    __typename: "PaginatedIssuesCreatedPerMonthInGroup",
    items:  Array< {
      __typename: "IssuesCreatedPerMonthInGroup",
      id: string,
      sort: string,
      total?: number | null,
      year?: number | null,
      month?: string | null,
      firstDate?: string | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetUserGroupMembershipQueryVariables = {
  nextToken?: string | null,
  limit?: number | null,
  id: string,
};

export type GetUserGroupMembershipQuery = {
  getUserGroupMembership?:  {
    __typename: "PaginatedGroupMembership",
    items:  Array< {
      __typename: "GroupMembership",
      id?: string | null,
      sort?: string | null,
      userID?: string | null,
      groupID?: string | null,
      userRole?: UserRoles | null,
      group?:  {
        __typename: "Group",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        organizationID?: string | null,
        title?: string | null,
        shortTitle?: string | null,
        riskThreshold?:  {
          __typename: "RiskThreshold",
          financial?:  {
            __typename: "Threshold",
            appetitePercent?: number | null,
            appetiteAbsolute?: number | null,
            capacityPercent?: number | null,
            capacityAbsolute?: number | null,
          } | null,
          privacy?:  {
            __typename: "Threshold",
            appetitePercent?: number | null,
            appetiteAbsolute?: number | null,
            capacityPercent?: number | null,
            capacityAbsolute?: number | null,
          } | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          name?: string | null,
          organizationRelationships?: string | null,
          demographics?: string | null,
        } | null,
        groupEmailFooter?: string | null,
      } | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetGroupsInOrganizationQueryVariables = {
  nextToken?: string | null,
  limit?: number | null,
  id: string,
};

export type GetGroupsInOrganizationQuery = {
  getGroupsInOrganization?:  {
    __typename: "PaginatedGroupReferences",
    items:  Array< {
      __typename: "GroupReference",
      id: string,
      sort: string,
      itemID: string,
      group?:  {
        __typename: "Group",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        organizationID?: string | null,
        title?: string | null,
        shortTitle?: string | null,
        riskThreshold?:  {
          __typename: "RiskThreshold",
          financial?:  {
            __typename: "Threshold",
            appetitePercent?: number | null,
            appetiteAbsolute?: number | null,
            capacityPercent?: number | null,
            capacityAbsolute?: number | null,
          } | null,
          privacy?:  {
            __typename: "Threshold",
            appetitePercent?: number | null,
            appetiteAbsolute?: number | null,
            capacityPercent?: number | null,
            capacityAbsolute?: number | null,
          } | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          name?: string | null,
          organizationRelationships?: string | null,
          demographics?: string | null,
        } | null,
        groupEmailFooter?: string | null,
      } | null,
      createdAt?: string | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetAssessmentResponsesQueryVariables = {
  nextToken?: string | null,
  limit?: number | null,
  id: string,
};

export type GetAssessmentResponsesQuery = {
  getAssessmentResponses?:  {
    __typename: "PaginatedAssessmentResponse",
    items:  Array< {
      __typename: "AssessmentResponse",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      parentID?: string | null,
      title?: string | null,
      instructions?: string | null,
      notes?: string | null,
      responseType?: string | null,
      questionText?: string | null,
      response?: string | null,
      responseComments?: string | null,
      responseStatus?: string | null,
      artifactRequired?: boolean | null,
      artifactRequired_yes?: boolean | null,
      artifactRequired_no?: boolean | null,
      owner?: string | null,
      parent?:  {
        __typename: "Assessment",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        parentID?: string | null,
        templateRoot?: boolean | null,
        title?: string | null,
        assessmentStartDate?: string | null,
        assessmentEndDate?: string | null,
        assessor?: string | null,
        scope?: string | null,
        hours?: string | null,
        templateTitle?: string | null,
        name?: string | null,
        hsrID?: string | null,
        description?: string | null,
        questionText?: string | null,
        surveyState?:  {
          __typename: "SurveyTotals",
          unanswered?: number | null,
          noGapIdentified?: number | null,
          deferPendingReassignment?: number | null,
          deferPendingResponse?: number | null,
          clarify?: number | null,
          yesPendingRequestedArtifacts?: number | null,
          yesPendingArtifacts?: number | null,
          yesCompliant?: number | null,
          notApplicable?: number | null,
        } | null,
        size?: number | null,
        parent?:  {
          __typename: "Assessment",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          parentID?: string | null,
          templateRoot?: boolean | null,
          title?: string | null,
          assessmentStartDate?: string | null,
          assessmentEndDate?: string | null,
          assessor?: string | null,
          scope?: string | null,
          hours?: string | null,
          templateTitle?: string | null,
          name?: string | null,
          hsrID?: string | null,
          description?: string | null,
          questionText?: string | null,
          surveyState?:  {
            __typename: "SurveyTotals",
            unanswered?: number | null,
            noGapIdentified?: number | null,
            deferPendingReassignment?: number | null,
            deferPendingResponse?: number | null,
            clarify?: number | null,
            yesPendingRequestedArtifacts?: number | null,
            yesPendingArtifacts?: number | null,
            yesCompliant?: number | null,
            notApplicable?: number | null,
          } | null,
          size?: number | null,
          parent?:  {
            __typename: "Assessment",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            parentID?: string | null,
            templateRoot?: boolean | null,
            title?: string | null,
            assessmentStartDate?: string | null,
            assessmentEndDate?: string | null,
            assessor?: string | null,
            scope?: string | null,
            hours?: string | null,
            templateTitle?: string | null,
            name?: string | null,
            hsrID?: string | null,
            description?: string | null,
            questionText?: string | null,
            surveyState?:  {
              __typename: "SurveyTotals",
              unanswered?: number | null,
              noGapIdentified?: number | null,
              deferPendingReassignment?: number | null,
              deferPendingResponse?: number | null,
              clarify?: number | null,
              yesPendingRequestedArtifacts?: number | null,
              yesPendingArtifacts?: number | null,
              yesCompliant?: number | null,
              notApplicable?: number | null,
            } | null,
            size?: number | null,
            parent?:  {
              __typename: "Assessment",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              parentID?: string | null,
              templateRoot?: boolean | null,
              title?: string | null,
              assessmentStartDate?: string | null,
              assessmentEndDate?: string | null,
              assessor?: string | null,
              scope?: string | null,
              hours?: string | null,
              templateTitle?: string | null,
              name?: string | null,
              hsrID?: string | null,
              description?: string | null,
              questionText?: string | null,
              surveyState?:  {
                __typename: "SurveyTotals",
                unanswered?: number | null,
                noGapIdentified?: number | null,
                deferPendingReassignment?: number | null,
                deferPendingResponse?: number | null,
                clarify?: number | null,
                yesPendingRequestedArtifacts?: number | null,
                yesPendingArtifacts?: number | null,
                yesCompliant?: number | null,
                notApplicable?: number | null,
              } | null,
              size?: number | null,
              parent?:  {
                __typename: "Assessment",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                parentID?: string | null,
                templateRoot?: boolean | null,
                title?: string | null,
                assessmentStartDate?: string | null,
                assessmentEndDate?: string | null,
                assessor?: string | null,
                scope?: string | null,
                hours?: string | null,
                templateTitle?: string | null,
                name?: string | null,
                hsrID?: string | null,
                description?: string | null,
                questionText?: string | null,
                surveyState?:  {
                  __typename: "SurveyTotals",
                  unanswered?: number | null,
                  noGapIdentified?: number | null,
                  deferPendingReassignment?: number | null,
                  deferPendingResponse?: number | null,
                  clarify?: number | null,
                  yesPendingRequestedArtifacts?: number | null,
                  yesPendingArtifacts?: number | null,
                  yesCompliant?: number | null,
                  notApplicable?: number | null,
                } | null,
                size?: number | null,
                parent?:  {
                  __typename: "Assessment",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  parentID?: string | null,
                  templateRoot?: boolean | null,
                  title?: string | null,
                  assessmentStartDate?: string | null,
                  assessmentEndDate?: string | null,
                  assessor?: string | null,
                  scope?: string | null,
                  hours?: string | null,
                  templateTitle?: string | null,
                  name?: string | null,
                  hsrID?: string | null,
                  description?: string | null,
                  questionText?: string | null,
                  size?: number | null,
                  groupID?: string | null,
                  archived?: boolean | null,
                } | null,
                groupID?: string | null,
                archived?: boolean | null,
                assessmentAssessor?:  {
                  __typename: "User",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  email?: string | null,
                  username?: string | null,
                  firstName?: string | null,
                  lastName?: string | null,
                  inactive?: boolean | null,
                  lastSeen?: string | null,
                  lastLoggedIn?: string | null,
                } | null,
              } | null,
              groupID?: string | null,
              archived?: boolean | null,
              assessmentAssessor?:  {
                __typename: "User",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                username?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
                lastSeen?: string | null,
                lastLoggedIn?: string | null,
              } | null,
            } | null,
            groupID?: string | null,
            archived?: boolean | null,
            assessmentAssessor?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
          } | null,
          groupID?: string | null,
          archived?: boolean | null,
          assessmentAssessor?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
        } | null,
        groupID?: string | null,
        archived?: boolean | null,
        assessmentAssessor?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
      } | null,
      groupID?: string | null,
      responseRelatedItems?:  {
        __typename: "PaginatedLinks",
        items:  Array< {
          __typename: "Link",
          id: string,
          sort: string,
          target?: string | null,
          targetType?: LinkTarget | null,
          item?:  {
            __typename: "RelatableItem",
            id?: string | null,
            sort?: string | null,
            groupID?: string | null,
            owner?: string | null,
            uuid?: string | null,
            title?: string | null,
            dateClosed?: string | null,
            dueDate?: string | null,
            status?: Status | null,
            priority?: Priority | null,
            severity?: Severity | null,
            responseStatus?: string | null,
            archived?: boolean | null,
            createdAt?: string | null,
            lastModified?: string | null,
            compositeIDYear?: string | null,
            compositeID?: number | null,
            displayID?: string | null,
            relatedItemsOwner?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
            relatedItemsGroup?:  {
              __typename: "Group",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              organizationID?: string | null,
              title?: string | null,
              shortTitle?: string | null,
              riskThreshold?:  {
                __typename: "RiskThreshold",
                financial?:  {
                  __typename: "Threshold",
                  appetitePercent?: number | null,
                  appetiteAbsolute?: number | null,
                  capacityPercent?: number | null,
                  capacityAbsolute?: number | null,
                } | null,
                privacy?:  {
                  __typename: "Threshold",
                  appetitePercent?: number | null,
                  appetiteAbsolute?: number | null,
                  capacityPercent?: number | null,
                  capacityAbsolute?: number | null,
                } | null,
              } | null,
              organization?:  {
                __typename: "Organization",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                name?: string | null,
                organizationRelationships?: string | null,
                demographics?: string | null,
              } | null,
              groupEmailFooter?: string | null,
            } | null,
            fileName?: string | null,
            address?: string | null,
          } | null,
        } >,
        nextToken?: string | null,
      } | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetNestedAssessmentsQueryVariables = {
  nextToken?: string | null,
  limit?: number | null,
  id: string,
};

export type GetNestedAssessmentsQuery = {
  getNestedAssessments?:  {
    __typename: "PaginatedAssessment",
    items:  Array< {
      __typename: "Assessment",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      parentID?: string | null,
      templateRoot?: boolean | null,
      title?: string | null,
      assessmentStartDate?: string | null,
      assessmentEndDate?: string | null,
      assessor?: string | null,
      scope?: string | null,
      hours?: string | null,
      templateTitle?: string | null,
      name?: string | null,
      hsrID?: string | null,
      description?: string | null,
      questionText?: string | null,
      surveyState?:  {
        __typename: "SurveyTotals",
        unanswered?: number | null,
        noGapIdentified?: number | null,
        deferPendingReassignment?: number | null,
        deferPendingResponse?: number | null,
        clarify?: number | null,
        yesPendingRequestedArtifacts?: number | null,
        yesPendingArtifacts?: number | null,
        yesCompliant?: number | null,
        notApplicable?: number | null,
      } | null,
      size?: number | null,
      parent?:  {
        __typename: "Assessment",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        parentID?: string | null,
        templateRoot?: boolean | null,
        title?: string | null,
        assessmentStartDate?: string | null,
        assessmentEndDate?: string | null,
        assessor?: string | null,
        scope?: string | null,
        hours?: string | null,
        templateTitle?: string | null,
        name?: string | null,
        hsrID?: string | null,
        description?: string | null,
        questionText?: string | null,
        surveyState?:  {
          __typename: "SurveyTotals",
          unanswered?: number | null,
          noGapIdentified?: number | null,
          deferPendingReassignment?: number | null,
          deferPendingResponse?: number | null,
          clarify?: number | null,
          yesPendingRequestedArtifacts?: number | null,
          yesPendingArtifacts?: number | null,
          yesCompliant?: number | null,
          notApplicable?: number | null,
        } | null,
        size?: number | null,
        parent?:  {
          __typename: "Assessment",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          parentID?: string | null,
          templateRoot?: boolean | null,
          title?: string | null,
          assessmentStartDate?: string | null,
          assessmentEndDate?: string | null,
          assessor?: string | null,
          scope?: string | null,
          hours?: string | null,
          templateTitle?: string | null,
          name?: string | null,
          hsrID?: string | null,
          description?: string | null,
          questionText?: string | null,
          surveyState?:  {
            __typename: "SurveyTotals",
            unanswered?: number | null,
            noGapIdentified?: number | null,
            deferPendingReassignment?: number | null,
            deferPendingResponse?: number | null,
            clarify?: number | null,
            yesPendingRequestedArtifacts?: number | null,
            yesPendingArtifacts?: number | null,
            yesCompliant?: number | null,
            notApplicable?: number | null,
          } | null,
          size?: number | null,
          parent?:  {
            __typename: "Assessment",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            parentID?: string | null,
            templateRoot?: boolean | null,
            title?: string | null,
            assessmentStartDate?: string | null,
            assessmentEndDate?: string | null,
            assessor?: string | null,
            scope?: string | null,
            hours?: string | null,
            templateTitle?: string | null,
            name?: string | null,
            hsrID?: string | null,
            description?: string | null,
            questionText?: string | null,
            surveyState?:  {
              __typename: "SurveyTotals",
              unanswered?: number | null,
              noGapIdentified?: number | null,
              deferPendingReassignment?: number | null,
              deferPendingResponse?: number | null,
              clarify?: number | null,
              yesPendingRequestedArtifacts?: number | null,
              yesPendingArtifacts?: number | null,
              yesCompliant?: number | null,
              notApplicable?: number | null,
            } | null,
            size?: number | null,
            parent?:  {
              __typename: "Assessment",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              parentID?: string | null,
              templateRoot?: boolean | null,
              title?: string | null,
              assessmentStartDate?: string | null,
              assessmentEndDate?: string | null,
              assessor?: string | null,
              scope?: string | null,
              hours?: string | null,
              templateTitle?: string | null,
              name?: string | null,
              hsrID?: string | null,
              description?: string | null,
              questionText?: string | null,
              surveyState?:  {
                __typename: "SurveyTotals",
                unanswered?: number | null,
                noGapIdentified?: number | null,
                deferPendingReassignment?: number | null,
                deferPendingResponse?: number | null,
                clarify?: number | null,
                yesPendingRequestedArtifacts?: number | null,
                yesPendingArtifacts?: number | null,
                yesCompliant?: number | null,
                notApplicable?: number | null,
              } | null,
              size?: number | null,
              parent?:  {
                __typename: "Assessment",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                parentID?: string | null,
                templateRoot?: boolean | null,
                title?: string | null,
                assessmentStartDate?: string | null,
                assessmentEndDate?: string | null,
                assessor?: string | null,
                scope?: string | null,
                hours?: string | null,
                templateTitle?: string | null,
                name?: string | null,
                hsrID?: string | null,
                description?: string | null,
                questionText?: string | null,
                surveyState?:  {
                  __typename: "SurveyTotals",
                  unanswered?: number | null,
                  noGapIdentified?: number | null,
                  deferPendingReassignment?: number | null,
                  deferPendingResponse?: number | null,
                  clarify?: number | null,
                  yesPendingRequestedArtifacts?: number | null,
                  yesPendingArtifacts?: number | null,
                  yesCompliant?: number | null,
                  notApplicable?: number | null,
                } | null,
                size?: number | null,
                parent?:  {
                  __typename: "Assessment",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  parentID?: string | null,
                  templateRoot?: boolean | null,
                  title?: string | null,
                  assessmentStartDate?: string | null,
                  assessmentEndDate?: string | null,
                  assessor?: string | null,
                  scope?: string | null,
                  hours?: string | null,
                  templateTitle?: string | null,
                  name?: string | null,
                  hsrID?: string | null,
                  description?: string | null,
                  questionText?: string | null,
                  size?: number | null,
                  groupID?: string | null,
                  archived?: boolean | null,
                } | null,
                groupID?: string | null,
                archived?: boolean | null,
                assessmentAssessor?:  {
                  __typename: "User",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  email?: string | null,
                  username?: string | null,
                  firstName?: string | null,
                  lastName?: string | null,
                  inactive?: boolean | null,
                  lastSeen?: string | null,
                  lastLoggedIn?: string | null,
                } | null,
              } | null,
              groupID?: string | null,
              archived?: boolean | null,
              assessmentAssessor?:  {
                __typename: "User",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                username?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
                lastSeen?: string | null,
                lastLoggedIn?: string | null,
              } | null,
            } | null,
            groupID?: string | null,
            archived?: boolean | null,
            assessmentAssessor?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
          } | null,
          groupID?: string | null,
          archived?: boolean | null,
          assessmentAssessor?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
        } | null,
        groupID?: string | null,
        archived?: boolean | null,
        assessmentAssessor?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
      } | null,
      groupID?: string | null,
      archived?: boolean | null,
      assessmentAssessor?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetTasksInActionPlanv3QueryVariables = {
  nextToken?: string | null,
  limit?: number | null,
  id: string,
};

export type GetTasksInActionPlanv3Query = {
  getTasksInActionPlanv3?:  {
    __typename: "PaginatedTaskReferences",
    items:  Array< {
      __typename: "TaskReference",
      id: string,
      sort: string,
      itemID: string,
      task?:  {
        __typename: "Task",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        createdAt?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        lastModified?: string | null,
        parentID?: string | null,
        organizationID?: string | null,
        groupID?: string | null,
        title?: string | null,
        description?: string | null,
        resources?: string | null,
        notes?: string | null,
        owner?: string | null,
        priority?: Priority | null,
        status?: Status | null,
        dateClosed?: string | null,
        dueDate?: string | null,
        archived?: boolean | null,
        taskOwner?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        taskRASCIRoles?:  {
          __typename: "PaginatedRASCIUsersOnItem",
          items:  Array< {
            __typename: "RASCIUserOnItem",
            id?: string | null,
            sort?: string | null,
            userID?: string | null,
            userInfo?:  {
              __typename: "UserInfo",
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
            } | null,
            user?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
            userRASCIRole?: UserRoles | null,
          } >,
          nextToken?: string | null,
        } | null,
      } | null,
      createdAt?: string | null,
      userRASCIRole?: UserRoles | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetOwnedActionPlansQueryVariables = {
  nextToken?: string | null,
  limit?: number | null,
  id: string,
};

export type GetOwnedActionPlansQuery = {
  getOwnedActionPlans?:  {
    __typename: "PaginatedActionPlanReferences",
    items:  Array< {
      __typename: "ActionPlanReference",
      id: string,
      sort: string,
      itemID: string,
      actionplan?:  {
        __typename: "ActionPlan",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        createdAt?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        lastModified?: string | null,
        actionPlanID?: string | null,
        title?: string | null,
        description?: string | null,
        resources?: string | null,
        notes?: string | null,
        owner?: string | null,
        priority?: Priority | null,
        status?: Status | null,
        approver?: string | null,
        dateClosed?: string | null,
        startDate?: string | null,
        dueDate?: string | null,
        reviewDate?: string | null,
        compositeIDYear?: string | null,
        compositeID?: number | null,
        archived?: boolean | null,
        isTemplate?: boolean | null,
        groupID?: string | null,
        actionPlanRelatedItems?:  {
          __typename: "PaginatedLinks",
          items:  Array< {
            __typename: "Link",
            id: string,
            sort: string,
            target?: string | null,
            targetType?: LinkTarget | null,
            item?:  {
              __typename: "RelatableItem",
              id?: string | null,
              sort?: string | null,
              groupID?: string | null,
              owner?: string | null,
              uuid?: string | null,
              title?: string | null,
              dateClosed?: string | null,
              dueDate?: string | null,
              status?: Status | null,
              priority?: Priority | null,
              severity?: Severity | null,
              responseStatus?: string | null,
              archived?: boolean | null,
              createdAt?: string | null,
              lastModified?: string | null,
              compositeIDYear?: string | null,
              compositeID?: number | null,
              displayID?: string | null,
              relatedItemsOwner?:  {
                __typename: "User",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                username?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
                lastSeen?: string | null,
                lastLoggedIn?: string | null,
              } | null,
              relatedItemsGroup?:  {
                __typename: "Group",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                organizationID?: string | null,
                title?: string | null,
                shortTitle?: string | null,
                organization?:  {
                  __typename: "Organization",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  name?: string | null,
                  organizationRelationships?: string | null,
                  demographics?: string | null,
                } | null,
                groupEmailFooter?: string | null,
              } | null,
              fileName?: string | null,
              address?: string | null,
            } | null,
          } >,
          nextToken?: string | null,
        } | null,
        taskChildren?:  {
          __typename: "PaginatedTaskReferences",
          items:  Array< {
            __typename: "TaskReference",
            id: string,
            sort: string,
            itemID: string,
            task?:  {
              __typename: "Task",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              createdAt?: string | null,
              createdBy?: string | null,
              modifiedBy?: string | null,
              lastModified?: string | null,
              parentID?: string | null,
              organizationID?: string | null,
              groupID?: string | null,
              title?: string | null,
              description?: string | null,
              resources?: string | null,
              notes?: string | null,
              owner?: string | null,
              priority?: Priority | null,
              status?: Status | null,
              dateClosed?: string | null,
              dueDate?: string | null,
              archived?: boolean | null,
              taskOwner?:  {
                __typename: "User",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                username?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
                lastSeen?: string | null,
                lastLoggedIn?: string | null,
              } | null,
              taskRASCIRoles?:  {
                __typename: "PaginatedRASCIUsersOnItem",
                items:  Array< {
                  __typename: "RASCIUserOnItem",
                  id?: string | null,
                  sort?: string | null,
                  userID?: string | null,
                  userRASCIRole?: UserRoles | null,
                } >,
                nextToken?: string | null,
              } | null,
            } | null,
            createdAt?: string | null,
            userRASCIRole?: UserRoles | null,
          } >,
          nextToken?: string | null,
        } | null,
        effortAmount?: number | null,
        effortType?: EffortType | null,
        actionPlanOwner?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        actionPlanCreatedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        actionPlanModifiedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        actionPlanRASCIRoles?:  {
          __typename: "PaginatedRASCIUsersOnItem",
          items:  Array< {
            __typename: "RASCIUserOnItem",
            id?: string | null,
            sort?: string | null,
            userID?: string | null,
            userInfo?:  {
              __typename: "UserInfo",
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
            } | null,
            user?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
            userRASCIRole?: UserRoles | null,
          } >,
          nextToken?: string | null,
        } | null,
        actionplanApprovers?:  {
          __typename: "PaginatedItemApprover",
          items:  Array< {
            __typename: "ItemApprover",
            id?: string | null,
            sort?: string | null,
            userID?: string | null,
            userInfo?:  {
              __typename: "UserInfo",
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
            } | null,
            user?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
          } >,
          nextToken?: string | null,
        } | null,
        type?: string | null,
      } | null,
      createdAt?: string | null,
      userRASCIRole?: UserRoles | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetRASCIOwnedActionPlansQueryVariables = {
  nextToken?: string | null,
  limit?: number | null,
  id: string,
};

export type GetRASCIOwnedActionPlansQuery = {
  getRASCIOwnedActionPlans?:  {
    __typename: "PaginatedActionPlanReferences",
    items:  Array< {
      __typename: "ActionPlanReference",
      id: string,
      sort: string,
      itemID: string,
      actionplan?:  {
        __typename: "ActionPlan",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        createdAt?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        lastModified?: string | null,
        actionPlanID?: string | null,
        title?: string | null,
        description?: string | null,
        resources?: string | null,
        notes?: string | null,
        owner?: string | null,
        priority?: Priority | null,
        status?: Status | null,
        approver?: string | null,
        dateClosed?: string | null,
        startDate?: string | null,
        dueDate?: string | null,
        reviewDate?: string | null,
        compositeIDYear?: string | null,
        compositeID?: number | null,
        archived?: boolean | null,
        isTemplate?: boolean | null,
        groupID?: string | null,
        actionPlanRelatedItems?:  {
          __typename: "PaginatedLinks",
          items:  Array< {
            __typename: "Link",
            id: string,
            sort: string,
            target?: string | null,
            targetType?: LinkTarget | null,
            item?:  {
              __typename: "RelatableItem",
              id?: string | null,
              sort?: string | null,
              groupID?: string | null,
              owner?: string | null,
              uuid?: string | null,
              title?: string | null,
              dateClosed?: string | null,
              dueDate?: string | null,
              status?: Status | null,
              priority?: Priority | null,
              severity?: Severity | null,
              responseStatus?: string | null,
              archived?: boolean | null,
              createdAt?: string | null,
              lastModified?: string | null,
              compositeIDYear?: string | null,
              compositeID?: number | null,
              displayID?: string | null,
              relatedItemsOwner?:  {
                __typename: "User",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                username?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
                lastSeen?: string | null,
                lastLoggedIn?: string | null,
              } | null,
              relatedItemsGroup?:  {
                __typename: "Group",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                organizationID?: string | null,
                title?: string | null,
                shortTitle?: string | null,
                organization?:  {
                  __typename: "Organization",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  name?: string | null,
                  organizationRelationships?: string | null,
                  demographics?: string | null,
                } | null,
                groupEmailFooter?: string | null,
              } | null,
              fileName?: string | null,
              address?: string | null,
            } | null,
          } >,
          nextToken?: string | null,
        } | null,
        taskChildren?:  {
          __typename: "PaginatedTaskReferences",
          items:  Array< {
            __typename: "TaskReference",
            id: string,
            sort: string,
            itemID: string,
            task?:  {
              __typename: "Task",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              createdAt?: string | null,
              createdBy?: string | null,
              modifiedBy?: string | null,
              lastModified?: string | null,
              parentID?: string | null,
              organizationID?: string | null,
              groupID?: string | null,
              title?: string | null,
              description?: string | null,
              resources?: string | null,
              notes?: string | null,
              owner?: string | null,
              priority?: Priority | null,
              status?: Status | null,
              dateClosed?: string | null,
              dueDate?: string | null,
              archived?: boolean | null,
              taskOwner?:  {
                __typename: "User",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                username?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
                lastSeen?: string | null,
                lastLoggedIn?: string | null,
              } | null,
              taskRASCIRoles?:  {
                __typename: "PaginatedRASCIUsersOnItem",
                items:  Array< {
                  __typename: "RASCIUserOnItem",
                  id?: string | null,
                  sort?: string | null,
                  userID?: string | null,
                  userRASCIRole?: UserRoles | null,
                } >,
                nextToken?: string | null,
              } | null,
            } | null,
            createdAt?: string | null,
            userRASCIRole?: UserRoles | null,
          } >,
          nextToken?: string | null,
        } | null,
        effortAmount?: number | null,
        effortType?: EffortType | null,
        actionPlanOwner?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        actionPlanCreatedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        actionPlanModifiedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        actionPlanRASCIRoles?:  {
          __typename: "PaginatedRASCIUsersOnItem",
          items:  Array< {
            __typename: "RASCIUserOnItem",
            id?: string | null,
            sort?: string | null,
            userID?: string | null,
            userInfo?:  {
              __typename: "UserInfo",
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
            } | null,
            user?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
            userRASCIRole?: UserRoles | null,
          } >,
          nextToken?: string | null,
        } | null,
        actionplanApprovers?:  {
          __typename: "PaginatedItemApprover",
          items:  Array< {
            __typename: "ItemApprover",
            id?: string | null,
            sort?: string | null,
            userID?: string | null,
            userInfo?:  {
              __typename: "UserInfo",
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
            } | null,
            user?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
          } >,
          nextToken?: string | null,
        } | null,
        type?: string | null,
      } | null,
      createdAt?: string | null,
      userRASCIRole?: UserRoles | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetActionPlanv3QueryVariables = {
  id: string,
};

export type GetActionPlanv3Query = {
  getActionPlanv3?:  {
    __typename: "ActionPlan",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    sort?: string | null,
    createdAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    lastModified?: string | null,
    actionPlanID?: string | null,
    title?: string | null,
    description?: string | null,
    resources?: string | null,
    notes?: string | null,
    owner?: string | null,
    priority?: Priority | null,
    status?: Status | null,
    approver?: string | null,
    dateClosed?: string | null,
    startDate?: string | null,
    dueDate?: string | null,
    reviewDate?: string | null,
    compositeIDYear?: string | null,
    compositeID?: number | null,
    archived?: boolean | null,
    isTemplate?: boolean | null,
    groupID?: string | null,
    actionPlanRelatedItems?:  {
      __typename: "PaginatedLinks",
      items:  Array< {
        __typename: "Link",
        id: string,
        sort: string,
        target?: string | null,
        targetType?: LinkTarget | null,
        item?:  {
          __typename: "RelatableItem",
          id?: string | null,
          sort?: string | null,
          groupID?: string | null,
          owner?: string | null,
          uuid?: string | null,
          title?: string | null,
          dateClosed?: string | null,
          dueDate?: string | null,
          status?: Status | null,
          priority?: Priority | null,
          severity?: Severity | null,
          responseStatus?: string | null,
          archived?: boolean | null,
          createdAt?: string | null,
          lastModified?: string | null,
          compositeIDYear?: string | null,
          compositeID?: number | null,
          displayID?: string | null,
          relatedItemsOwner?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
          relatedItemsGroup?:  {
            __typename: "Group",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            organizationID?: string | null,
            title?: string | null,
            shortTitle?: string | null,
            riskThreshold?:  {
              __typename: "RiskThreshold",
              financial?:  {
                __typename: "Threshold",
                appetitePercent?: number | null,
                appetiteAbsolute?: number | null,
                capacityPercent?: number | null,
                capacityAbsolute?: number | null,
              } | null,
              privacy?:  {
                __typename: "Threshold",
                appetitePercent?: number | null,
                appetiteAbsolute?: number | null,
                capacityPercent?: number | null,
                capacityAbsolute?: number | null,
              } | null,
            } | null,
            organization?:  {
              __typename: "Organization",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              name?: string | null,
              organizationRelationships?: string | null,
              demographics?: string | null,
            } | null,
            groupEmailFooter?: string | null,
          } | null,
          fileName?: string | null,
          address?: string | null,
        } | null,
      } >,
      nextToken?: string | null,
    } | null,
    taskChildren?:  {
      __typename: "PaginatedTaskReferences",
      items:  Array< {
        __typename: "TaskReference",
        id: string,
        sort: string,
        itemID: string,
        task?:  {
          __typename: "Task",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          createdAt?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          lastModified?: string | null,
          parentID?: string | null,
          organizationID?: string | null,
          groupID?: string | null,
          title?: string | null,
          description?: string | null,
          resources?: string | null,
          notes?: string | null,
          owner?: string | null,
          priority?: Priority | null,
          status?: Status | null,
          dateClosed?: string | null,
          dueDate?: string | null,
          archived?: boolean | null,
          taskOwner?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
          taskRASCIRoles?:  {
            __typename: "PaginatedRASCIUsersOnItem",
            items:  Array< {
              __typename: "RASCIUserOnItem",
              id?: string | null,
              sort?: string | null,
              userID?: string | null,
              userInfo?:  {
                __typename: "UserInfo",
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
              } | null,
              user?:  {
                __typename: "User",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                username?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
                lastSeen?: string | null,
                lastLoggedIn?: string | null,
              } | null,
              userRASCIRole?: UserRoles | null,
            } >,
            nextToken?: string | null,
          } | null,
        } | null,
        createdAt?: string | null,
        userRASCIRole?: UserRoles | null,
      } >,
      nextToken?: string | null,
    } | null,
    effortAmount?: number | null,
    effortType?: EffortType | null,
    actionPlanOwner?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    actionPlanCreatedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    actionPlanModifiedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    actionPlanRASCIRoles?:  {
      __typename: "PaginatedRASCIUsersOnItem",
      items:  Array< {
        __typename: "RASCIUserOnItem",
        id?: string | null,
        sort?: string | null,
        userID?: string | null,
        userInfo?:  {
          __typename: "UserInfo",
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
        } | null,
        user?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        userRASCIRole?: UserRoles | null,
      } >,
      nextToken?: string | null,
    } | null,
    actionplanApprovers?:  {
      __typename: "PaginatedItemApprover",
      items:  Array< {
        __typename: "ItemApprover",
        id?: string | null,
        sort?: string | null,
        userID?: string | null,
        userInfo?:  {
          __typename: "UserInfo",
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
        } | null,
        user?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
      } >,
      nextToken?: string | null,
    } | null,
    type?: string | null,
  } | null,
};

export type GetActionPlansInGroupv3QueryVariables = {
  nextToken?: string | null,
  limit?: number | null,
  id: string,
};

export type GetActionPlansInGroupv3Query = {
  getActionPlansInGroupv3?:  {
    __typename: "PaginatedActionPlanReferences",
    items:  Array< {
      __typename: "ActionPlanReference",
      id: string,
      sort: string,
      itemID: string,
      actionplan?:  {
        __typename: "ActionPlan",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        createdAt?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        lastModified?: string | null,
        actionPlanID?: string | null,
        title?: string | null,
        description?: string | null,
        resources?: string | null,
        notes?: string | null,
        owner?: string | null,
        priority?: Priority | null,
        status?: Status | null,
        approver?: string | null,
        dateClosed?: string | null,
        startDate?: string | null,
        dueDate?: string | null,
        reviewDate?: string | null,
        compositeIDYear?: string | null,
        compositeID?: number | null,
        archived?: boolean | null,
        isTemplate?: boolean | null,
        groupID?: string | null,
        actionPlanRelatedItems?:  {
          __typename: "PaginatedLinks",
          items:  Array< {
            __typename: "Link",
            id: string,
            sort: string,
            target?: string | null,
            targetType?: LinkTarget | null,
            item?:  {
              __typename: "RelatableItem",
              id?: string | null,
              sort?: string | null,
              groupID?: string | null,
              owner?: string | null,
              uuid?: string | null,
              title?: string | null,
              dateClosed?: string | null,
              dueDate?: string | null,
              status?: Status | null,
              priority?: Priority | null,
              severity?: Severity | null,
              responseStatus?: string | null,
              archived?: boolean | null,
              createdAt?: string | null,
              lastModified?: string | null,
              compositeIDYear?: string | null,
              compositeID?: number | null,
              displayID?: string | null,
              relatedItemsOwner?:  {
                __typename: "User",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                username?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
                lastSeen?: string | null,
                lastLoggedIn?: string | null,
              } | null,
              relatedItemsGroup?:  {
                __typename: "Group",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                organizationID?: string | null,
                title?: string | null,
                shortTitle?: string | null,
                organization?:  {
                  __typename: "Organization",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  name?: string | null,
                  organizationRelationships?: string | null,
                  demographics?: string | null,
                } | null,
                groupEmailFooter?: string | null,
              } | null,
              fileName?: string | null,
              address?: string | null,
            } | null,
          } >,
          nextToken?: string | null,
        } | null,
        taskChildren?:  {
          __typename: "PaginatedTaskReferences",
          items:  Array< {
            __typename: "TaskReference",
            id: string,
            sort: string,
            itemID: string,
            task?:  {
              __typename: "Task",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              createdAt?: string | null,
              createdBy?: string | null,
              modifiedBy?: string | null,
              lastModified?: string | null,
              parentID?: string | null,
              organizationID?: string | null,
              groupID?: string | null,
              title?: string | null,
              description?: string | null,
              resources?: string | null,
              notes?: string | null,
              owner?: string | null,
              priority?: Priority | null,
              status?: Status | null,
              dateClosed?: string | null,
              dueDate?: string | null,
              archived?: boolean | null,
              taskOwner?:  {
                __typename: "User",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                username?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
                lastSeen?: string | null,
                lastLoggedIn?: string | null,
              } | null,
              taskRASCIRoles?:  {
                __typename: "PaginatedRASCIUsersOnItem",
                items:  Array< {
                  __typename: "RASCIUserOnItem",
                  id?: string | null,
                  sort?: string | null,
                  userID?: string | null,
                  userRASCIRole?: UserRoles | null,
                } >,
                nextToken?: string | null,
              } | null,
            } | null,
            createdAt?: string | null,
            userRASCIRole?: UserRoles | null,
          } >,
          nextToken?: string | null,
        } | null,
        effortAmount?: number | null,
        effortType?: EffortType | null,
        actionPlanOwner?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        actionPlanCreatedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        actionPlanModifiedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        actionPlanRASCIRoles?:  {
          __typename: "PaginatedRASCIUsersOnItem",
          items:  Array< {
            __typename: "RASCIUserOnItem",
            id?: string | null,
            sort?: string | null,
            userID?: string | null,
            userInfo?:  {
              __typename: "UserInfo",
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
            } | null,
            user?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
            userRASCIRole?: UserRoles | null,
          } >,
          nextToken?: string | null,
        } | null,
        actionplanApprovers?:  {
          __typename: "PaginatedItemApprover",
          items:  Array< {
            __typename: "ItemApprover",
            id?: string | null,
            sort?: string | null,
            userID?: string | null,
            userInfo?:  {
              __typename: "UserInfo",
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
            } | null,
            user?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
          } >,
          nextToken?: string | null,
        } | null,
        type?: string | null,
      } | null,
      createdAt?: string | null,
      userRASCIRole?: UserRoles | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetActionPlansUpdatedPerMonthQueryVariables = {
  nextToken?: string | null,
  limit?: number | null,
  id: string,
};

export type GetActionPlansUpdatedPerMonthQuery = {
  getActionPlansUpdatedPerMonth?:  {
    __typename: "PaginatedActionPlansUpdatedPerMonth",
    items:  Array< {
      __typename: "ActionPlansUpdatedPerMonth",
      id: string,
      sort: string,
      total?: number | null,
      year?: number | null,
      month?: string | null,
      firstDate?: string | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetActionPlansCreatedPerMonthQueryVariables = {
  nextToken?: string | null,
  limit?: number | null,
  id: string,
};

export type GetActionPlansCreatedPerMonthQuery = {
  getActionPlansCreatedPerMonth?:  {
    __typename: "PaginatedActionPlansCreatedPerMonth",
    items:  Array< {
      __typename: "ActionPlansCreatedPerMonth",
      id: string,
      sort: string,
      total?: number | null,
      year?: number | null,
      month?: string | null,
      firstDate?: string | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetOwnedTasksQueryVariables = {
  nextToken?: string | null,
  limit?: number | null,
  id: string,
};

export type GetOwnedTasksQuery = {
  getOwnedTasks?:  {
    __typename: "PaginatedTaskReferences",
    items:  Array< {
      __typename: "TaskReference",
      id: string,
      sort: string,
      itemID: string,
      task?:  {
        __typename: "Task",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        createdAt?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        lastModified?: string | null,
        parentID?: string | null,
        organizationID?: string | null,
        groupID?: string | null,
        title?: string | null,
        description?: string | null,
        resources?: string | null,
        notes?: string | null,
        owner?: string | null,
        priority?: Priority | null,
        status?: Status | null,
        dateClosed?: string | null,
        dueDate?: string | null,
        archived?: boolean | null,
        taskOwner?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        taskRASCIRoles?:  {
          __typename: "PaginatedRASCIUsersOnItem",
          items:  Array< {
            __typename: "RASCIUserOnItem",
            id?: string | null,
            sort?: string | null,
            userID?: string | null,
            userInfo?:  {
              __typename: "UserInfo",
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
            } | null,
            user?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
            userRASCIRole?: UserRoles | null,
          } >,
          nextToken?: string | null,
        } | null,
      } | null,
      createdAt?: string | null,
      userRASCIRole?: UserRoles | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetRASCIOwnedTasksQueryVariables = {
  nextToken?: string | null,
  limit?: number | null,
  id: string,
};

export type GetRASCIOwnedTasksQuery = {
  getRASCIOwnedTasks?:  {
    __typename: "PaginatedTaskReferences",
    items:  Array< {
      __typename: "TaskReference",
      id: string,
      sort: string,
      itemID: string,
      task?:  {
        __typename: "Task",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        createdAt?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        lastModified?: string | null,
        parentID?: string | null,
        organizationID?: string | null,
        groupID?: string | null,
        title?: string | null,
        description?: string | null,
        resources?: string | null,
        notes?: string | null,
        owner?: string | null,
        priority?: Priority | null,
        status?: Status | null,
        dateClosed?: string | null,
        dueDate?: string | null,
        archived?: boolean | null,
        taskOwner?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        taskRASCIRoles?:  {
          __typename: "PaginatedRASCIUsersOnItem",
          items:  Array< {
            __typename: "RASCIUserOnItem",
            id?: string | null,
            sort?: string | null,
            userID?: string | null,
            userInfo?:  {
              __typename: "UserInfo",
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
            } | null,
            user?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
            userRASCIRole?: UserRoles | null,
          } >,
          nextToken?: string | null,
        } | null,
      } | null,
      createdAt?: string | null,
      userRASCIRole?: UserRoles | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetTaskv3QueryVariables = {
  id: string,
};

export type GetTaskv3Query = {
  getTaskv3?:  {
    __typename: "Task",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    sort?: string | null,
    createdAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    lastModified?: string | null,
    parentID?: string | null,
    organizationID?: string | null,
    groupID?: string | null,
    title?: string | null,
    description?: string | null,
    resources?: string | null,
    notes?: string | null,
    owner?: string | null,
    priority?: Priority | null,
    status?: Status | null,
    dateClosed?: string | null,
    dueDate?: string | null,
    archived?: boolean | null,
    taskOwner?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    taskRASCIRoles?:  {
      __typename: "PaginatedRASCIUsersOnItem",
      items:  Array< {
        __typename: "RASCIUserOnItem",
        id?: string | null,
        sort?: string | null,
        userID?: string | null,
        userInfo?:  {
          __typename: "UserInfo",
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
        } | null,
        user?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        userRASCIRole?: UserRoles | null,
      } >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type GetOwnedScenariosQueryVariables = {
  nextToken?: string | null,
  limit?: number | null,
  id: string,
};

export type GetOwnedScenariosQuery = {
  getOwnedScenarios?:  {
    __typename: "PaginatedScenarioReferences",
    items:  Array< {
      __typename: "ScenarioReference",
      id: string,
      sort: string,
      itemID: string,
      scenario?:  {
        __typename: "Scenario",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        createdAt?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        lastModified?: string | null,
        FAIRSummary?: string | null,
        scenarioID?: string | null,
        title?: string | null,
        description?: string | null,
        notes?: string | null,
        assumptions?: string | null,
        creationDate?: string | null,
        lastRevision?: string | null,
        nextRevision?: string | null,
        categories?: string | null,
        owner?: string | null,
        status?: Status | null,
        dateClosed?: string | null,
        indicator?: Indicator | null,
        compositeIDYear?: string | null,
        compositeID?: number | null,
        archived?: boolean | null,
        isTemplate?: boolean | null,
        groupID?: string | null,
        scenarioRelatedItems?:  {
          __typename: "PaginatedLinks",
          items:  Array< {
            __typename: "Link",
            id: string,
            sort: string,
            target?: string | null,
            targetType?: LinkTarget | null,
            item?:  {
              __typename: "RelatableItem",
              id?: string | null,
              sort?: string | null,
              groupID?: string | null,
              owner?: string | null,
              uuid?: string | null,
              title?: string | null,
              dateClosed?: string | null,
              dueDate?: string | null,
              status?: Status | null,
              priority?: Priority | null,
              severity?: Severity | null,
              responseStatus?: string | null,
              archived?: boolean | null,
              createdAt?: string | null,
              lastModified?: string | null,
              compositeIDYear?: string | null,
              compositeID?: number | null,
              displayID?: string | null,
              relatedItemsOwner?:  {
                __typename: "User",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                username?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
                lastSeen?: string | null,
                lastLoggedIn?: string | null,
              } | null,
              relatedItemsGroup?:  {
                __typename: "Group",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                organizationID?: string | null,
                title?: string | null,
                shortTitle?: string | null,
                organization?:  {
                  __typename: "Organization",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  name?: string | null,
                  organizationRelationships?: string | null,
                  demographics?: string | null,
                } | null,
                groupEmailFooter?: string | null,
              } | null,
              fileName?: string | null,
              address?: string | null,
            } | null,
          } >,
          nextToken?: string | null,
        } | null,
        scenarioOwner?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        scenarioCreatedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        scenarioModifiedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        scenarioRASCIRoles?:  {
          __typename: "PaginatedRASCIUsersOnItem",
          items:  Array< {
            __typename: "RASCIUserOnItem",
            id?: string | null,
            sort?: string | null,
            userID?: string | null,
            userInfo?:  {
              __typename: "UserInfo",
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
            } | null,
            user?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
            userRASCIRole?: UserRoles | null,
          } >,
          nextToken?: string | null,
        } | null,
      } | null,
      createdAt?: string | null,
      userRASCIRole?: UserRoles | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetRASCIOwnedScenariosQueryVariables = {
  nextToken?: string | null,
  limit?: number | null,
  id: string,
};

export type GetRASCIOwnedScenariosQuery = {
  getRASCIOwnedScenarios?:  {
    __typename: "PaginatedScenarioReferences",
    items:  Array< {
      __typename: "ScenarioReference",
      id: string,
      sort: string,
      itemID: string,
      scenario?:  {
        __typename: "Scenario",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        createdAt?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        lastModified?: string | null,
        FAIRSummary?: string | null,
        scenarioID?: string | null,
        title?: string | null,
        description?: string | null,
        notes?: string | null,
        assumptions?: string | null,
        creationDate?: string | null,
        lastRevision?: string | null,
        nextRevision?: string | null,
        categories?: string | null,
        owner?: string | null,
        status?: Status | null,
        dateClosed?: string | null,
        indicator?: Indicator | null,
        compositeIDYear?: string | null,
        compositeID?: number | null,
        archived?: boolean | null,
        isTemplate?: boolean | null,
        groupID?: string | null,
        scenarioRelatedItems?:  {
          __typename: "PaginatedLinks",
          items:  Array< {
            __typename: "Link",
            id: string,
            sort: string,
            target?: string | null,
            targetType?: LinkTarget | null,
            item?:  {
              __typename: "RelatableItem",
              id?: string | null,
              sort?: string | null,
              groupID?: string | null,
              owner?: string | null,
              uuid?: string | null,
              title?: string | null,
              dateClosed?: string | null,
              dueDate?: string | null,
              status?: Status | null,
              priority?: Priority | null,
              severity?: Severity | null,
              responseStatus?: string | null,
              archived?: boolean | null,
              createdAt?: string | null,
              lastModified?: string | null,
              compositeIDYear?: string | null,
              compositeID?: number | null,
              displayID?: string | null,
              relatedItemsOwner?:  {
                __typename: "User",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                username?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
                lastSeen?: string | null,
                lastLoggedIn?: string | null,
              } | null,
              relatedItemsGroup?:  {
                __typename: "Group",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                organizationID?: string | null,
                title?: string | null,
                shortTitle?: string | null,
                organization?:  {
                  __typename: "Organization",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  name?: string | null,
                  organizationRelationships?: string | null,
                  demographics?: string | null,
                } | null,
                groupEmailFooter?: string | null,
              } | null,
              fileName?: string | null,
              address?: string | null,
            } | null,
          } >,
          nextToken?: string | null,
        } | null,
        scenarioOwner?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        scenarioCreatedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        scenarioModifiedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        scenarioRASCIRoles?:  {
          __typename: "PaginatedRASCIUsersOnItem",
          items:  Array< {
            __typename: "RASCIUserOnItem",
            id?: string | null,
            sort?: string | null,
            userID?: string | null,
            userInfo?:  {
              __typename: "UserInfo",
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
            } | null,
            user?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
            userRASCIRole?: UserRoles | null,
          } >,
          nextToken?: string | null,
        } | null,
      } | null,
      createdAt?: string | null,
      userRASCIRole?: UserRoles | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetScenariov3QueryVariables = {
  id: string,
};

export type GetScenariov3Query = {
  getScenariov3?:  {
    __typename: "Scenario",
    res?: string | null,
    error?: string | null,
    id?: string | null,
    sort?: string | null,
    createdAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    lastModified?: string | null,
    FAIRSummary?: string | null,
    scenarioID?: string | null,
    title?: string | null,
    description?: string | null,
    notes?: string | null,
    assumptions?: string | null,
    creationDate?: string | null,
    lastRevision?: string | null,
    nextRevision?: string | null,
    categories?: string | null,
    owner?: string | null,
    status?: Status | null,
    dateClosed?: string | null,
    indicator?: Indicator | null,
    compositeIDYear?: string | null,
    compositeID?: number | null,
    archived?: boolean | null,
    isTemplate?: boolean | null,
    groupID?: string | null,
    scenarioRelatedItems?:  {
      __typename: "PaginatedLinks",
      items:  Array< {
        __typename: "Link",
        id: string,
        sort: string,
        target?: string | null,
        targetType?: LinkTarget | null,
        item?:  {
          __typename: "RelatableItem",
          id?: string | null,
          sort?: string | null,
          groupID?: string | null,
          owner?: string | null,
          uuid?: string | null,
          title?: string | null,
          dateClosed?: string | null,
          dueDate?: string | null,
          status?: Status | null,
          priority?: Priority | null,
          severity?: Severity | null,
          responseStatus?: string | null,
          archived?: boolean | null,
          createdAt?: string | null,
          lastModified?: string | null,
          compositeIDYear?: string | null,
          compositeID?: number | null,
          displayID?: string | null,
          relatedItemsOwner?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
          relatedItemsGroup?:  {
            __typename: "Group",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            organizationID?: string | null,
            title?: string | null,
            shortTitle?: string | null,
            riskThreshold?:  {
              __typename: "RiskThreshold",
              financial?:  {
                __typename: "Threshold",
                appetitePercent?: number | null,
                appetiteAbsolute?: number | null,
                capacityPercent?: number | null,
                capacityAbsolute?: number | null,
              } | null,
              privacy?:  {
                __typename: "Threshold",
                appetitePercent?: number | null,
                appetiteAbsolute?: number | null,
                capacityPercent?: number | null,
                capacityAbsolute?: number | null,
              } | null,
            } | null,
            organization?:  {
              __typename: "Organization",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              name?: string | null,
              organizationRelationships?: string | null,
              demographics?: string | null,
            } | null,
            groupEmailFooter?: string | null,
          } | null,
          fileName?: string | null,
          address?: string | null,
        } | null,
      } >,
      nextToken?: string | null,
    } | null,
    scenarioOwner?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    scenarioCreatedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    scenarioModifiedBy?:  {
      __typename: "User",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      email?: string | null,
      username?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      inactive?: boolean | null,
      lastSeen?: string | null,
      lastLoggedIn?: string | null,
    } | null,
    scenarioRASCIRoles?:  {
      __typename: "PaginatedRASCIUsersOnItem",
      items:  Array< {
        __typename: "RASCIUserOnItem",
        id?: string | null,
        sort?: string | null,
        userID?: string | null,
        userInfo?:  {
          __typename: "UserInfo",
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
        } | null,
        user?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        userRASCIRole?: UserRoles | null,
      } >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type GetScenariosInGroupv3QueryVariables = {
  nextToken?: string | null,
  limit?: number | null,
  id: string,
};

export type GetScenariosInGroupv3Query = {
  getScenariosInGroupv3?:  {
    __typename: "PaginatedScenarioReferences",
    items:  Array< {
      __typename: "ScenarioReference",
      id: string,
      sort: string,
      itemID: string,
      scenario?:  {
        __typename: "Scenario",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        createdAt?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        lastModified?: string | null,
        FAIRSummary?: string | null,
        scenarioID?: string | null,
        title?: string | null,
        description?: string | null,
        notes?: string | null,
        assumptions?: string | null,
        creationDate?: string | null,
        lastRevision?: string | null,
        nextRevision?: string | null,
        categories?: string | null,
        owner?: string | null,
        status?: Status | null,
        dateClosed?: string | null,
        indicator?: Indicator | null,
        compositeIDYear?: string | null,
        compositeID?: number | null,
        archived?: boolean | null,
        isTemplate?: boolean | null,
        groupID?: string | null,
        scenarioRelatedItems?:  {
          __typename: "PaginatedLinks",
          items:  Array< {
            __typename: "Link",
            id: string,
            sort: string,
            target?: string | null,
            targetType?: LinkTarget | null,
            item?:  {
              __typename: "RelatableItem",
              id?: string | null,
              sort?: string | null,
              groupID?: string | null,
              owner?: string | null,
              uuid?: string | null,
              title?: string | null,
              dateClosed?: string | null,
              dueDate?: string | null,
              status?: Status | null,
              priority?: Priority | null,
              severity?: Severity | null,
              responseStatus?: string | null,
              archived?: boolean | null,
              createdAt?: string | null,
              lastModified?: string | null,
              compositeIDYear?: string | null,
              compositeID?: number | null,
              displayID?: string | null,
              relatedItemsOwner?:  {
                __typename: "User",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                username?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
                lastSeen?: string | null,
                lastLoggedIn?: string | null,
              } | null,
              relatedItemsGroup?:  {
                __typename: "Group",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                organizationID?: string | null,
                title?: string | null,
                shortTitle?: string | null,
                organization?:  {
                  __typename: "Organization",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  name?: string | null,
                  organizationRelationships?: string | null,
                  demographics?: string | null,
                } | null,
                groupEmailFooter?: string | null,
              } | null,
              fileName?: string | null,
              address?: string | null,
            } | null,
          } >,
          nextToken?: string | null,
        } | null,
        scenarioOwner?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        scenarioCreatedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        scenarioModifiedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        scenarioRASCIRoles?:  {
          __typename: "PaginatedRASCIUsersOnItem",
          items:  Array< {
            __typename: "RASCIUserOnItem",
            id?: string | null,
            sort?: string | null,
            userID?: string | null,
            userInfo?:  {
              __typename: "UserInfo",
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
            } | null,
            user?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
            userRASCIRole?: UserRoles | null,
          } >,
          nextToken?: string | null,
        } | null,
      } | null,
      createdAt?: string | null,
      userRASCIRole?: UserRoles | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetRiskAnalysisCompletedPerMonthQueryVariables = {
  nextToken?: string | null,
  limit?: number | null,
  id: string,
};

export type GetRiskAnalysisCompletedPerMonthQuery = {
  getRiskAnalysisCompletedPerMonth?:  {
    __typename: "PaginatedRiskAnalysisCompletedPerMonthInGroup",
    items:  Array< {
      __typename: "RiskAnalysisCompletedPerMonthInGroup",
      id: string,
      sort: string,
      total?: number | null,
      year?: number | null,
      month?: string | null,
      firstDate?: string | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetMyUserMetaQueryVariables = {
  id: string,
};

export type GetMyUserMetaQuery = {
  getMyUserMeta?:  {
    __typename: "UserMeta",
    id?: string | null,
    sort?: string | null,
    forceResetPassword?: boolean | null,
    emailPreferences?: string | null,
    notificationPreferences?: string | null,
    leftsidePreferences?: string | null,
    snippets?: string | null,
  } | null,
};

export type GetDailyActiveUsersInGroupQueryVariables = {
  nextToken?: string | null,
  limit?: number | null,
  id: string,
  filter?: DailyActiveUsersFilterInput | null,
};

export type GetDailyActiveUsersInGroupQuery = {
  getDailyActiveUsersInGroup?:  {
    __typename: "PaginatedDailyActiveUsers",
    items:  Array< {
      __typename: "DailyActiveUsers",
      id?: string | null,
      sort?: string | null,
      totalActiveUsers?: number | null,
      users?: Array< string | null > | null,
      date?: string | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetDailyActiveUsersInOrganizationQueryVariables = {
  nextToken?: string | null,
  limit?: number | null,
  id: string,
  filter?: DailyActiveUsersFilterInput | null,
};

export type GetDailyActiveUsersInOrganizationQuery = {
  getDailyActiveUsersInOrganization?:  {
    __typename: "PaginatedDailyActiveUsers",
    items:  Array< {
      __typename: "DailyActiveUsers",
      id?: string | null,
      sort?: string | null,
      totalActiveUsers?: number | null,
      users?: Array< string | null > | null,
      date?: string | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetUsersInGroupQueryVariables = {
  nextToken?: string | null,
  limit?: number | null,
  id: string,
};

export type GetUsersInGroupQuery = {
  getUsersInGroup?:  {
    __typename: "PaginatedUsersInGroup",
    items:  Array< {
      __typename: "UsersInGroup",
      id?: string | null,
      sort?: string | null,
      userID?: string | null,
      userInfo?:  {
        __typename: "UserInfo",
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
      } | null,
      groupID?: string | null,
      group?:  {
        __typename: "Group",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        organizationID?: string | null,
        title?: string | null,
        shortTitle?: string | null,
        riskThreshold?:  {
          __typename: "RiskThreshold",
          financial?:  {
            __typename: "Threshold",
            appetitePercent?: number | null,
            appetiteAbsolute?: number | null,
            capacityPercent?: number | null,
            capacityAbsolute?: number | null,
          } | null,
          privacy?:  {
            __typename: "Threshold",
            appetitePercent?: number | null,
            appetiteAbsolute?: number | null,
            capacityPercent?: number | null,
            capacityAbsolute?: number | null,
          } | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          name?: string | null,
          organizationRelationships?: string | null,
          demographics?: string | null,
        } | null,
        groupEmailFooter?: string | null,
      } | null,
      user?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      userRole?: UserRoles | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetUsersInOrganizationQueryVariables = {
  nextToken?: string | null,
  limit?: number | null,
  id: string,
};

export type GetUsersInOrganizationQuery = {
  getUsersInOrganization?:  {
    __typename: "PaginatedUsersInOrganization",
    items:  Array< {
      __typename: "UsersInOrganization",
      id?: string | null,
      sort?: string | null,
      userID?: string | null,
      userInfo?:  {
        __typename: "UserInfo",
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
      } | null,
      organizationID?: string | null,
      organization?:  {
        __typename: "Organization",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        name?: string | null,
        organizationRelationships?: string | null,
        demographics?: string | null,
      } | null,
      user?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      userRole?: UserRoles | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetUserOrganizationMembershipQueryVariables = {
  nextToken?: string | null,
  limit?: number | null,
  id: string,
};

export type GetUserOrganizationMembershipQuery = {
  getUserOrganizationMembership?:  {
    __typename: "PaginatedOrganizationMembership",
    items:  Array< {
      __typename: "OrganizationMembership",
      id?: string | null,
      sort?: string | null,
      userID?: string | null,
      organizationID?: string | null,
      organization?:  {
        __typename: "Organization",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        name?: string | null,
        organizationRelationships?: string | null,
        demographics?: string | null,
      } | null,
      user?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      userRole?: UserRoles | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetOwnedNotificationsQueryVariables = {
  nextToken?: string | null,
  limit?: number | null,
  id: string,
  sortDirection?: FilterSortDirection | null,
};

export type GetOwnedNotificationsQuery = {
  getOwnedNotifications?:  {
    __typename: "PaginatedNotificationReferences",
    items:  Array< {
      __typename: "NotificationReference",
      id: string,
      sort: string,
      itemID: string,
      notification?:  {
        __typename: "Notification",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        createdAt?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        lastModified?: string | null,
        itemID?: string | null,
        moduleName?: string | null,
        item?:  {
          __typename: "RelatableItem",
          id?: string | null,
          sort?: string | null,
          groupID?: string | null,
          owner?: string | null,
          uuid?: string | null,
          title?: string | null,
          dateClosed?: string | null,
          dueDate?: string | null,
          status?: Status | null,
          priority?: Priority | null,
          severity?: Severity | null,
          responseStatus?: string | null,
          archived?: boolean | null,
          createdAt?: string | null,
          lastModified?: string | null,
          compositeIDYear?: string | null,
          compositeID?: number | null,
          displayID?: string | null,
          relatedItemsOwner?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
          relatedItemsGroup?:  {
            __typename: "Group",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            organizationID?: string | null,
            title?: string | null,
            shortTitle?: string | null,
            riskThreshold?:  {
              __typename: "RiskThreshold",
              financial?:  {
                __typename: "Threshold",
                appetitePercent?: number | null,
                appetiteAbsolute?: number | null,
                capacityPercent?: number | null,
                capacityAbsolute?: number | null,
              } | null,
              privacy?:  {
                __typename: "Threshold",
                appetitePercent?: number | null,
                appetiteAbsolute?: number | null,
                capacityPercent?: number | null,
                capacityAbsolute?: number | null,
              } | null,
            } | null,
            organization?:  {
              __typename: "Organization",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              name?: string | null,
              organizationRelationships?: string | null,
              demographics?: string | null,
            } | null,
            groupEmailFooter?: string | null,
          } | null,
          fileName?: string | null,
          address?: string | null,
        } | null,
        notificationType?: string | null,
        data?: string | null,
        isRead?: boolean | null,
      } | null,
      createdAt?: string | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetArtifactsInGroupQueryVariables = {
  nextToken?: string | null,
  limit?: number | null,
  groupID: string,
};

export type GetArtifactsInGroupQuery = {
  getArtifactsInGroup?:  {
    __typename: "PaginatedArtifact",
    items:  Array< {
      __typename: "Artifact",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      groupID?: string | null,
      fileName?: string | null,
      displayFileName?: string | null,
      pointOfContact?: string | null,
      artifactID?: string | null,
      archived?: boolean | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetControlsSurveysQueryVariables = {
  nextToken?: string | null,
  limit?: number | null,
};

export type GetControlsSurveysQuery = {
  GetControlsSurveys?:  {
    __typename: "PaginatedControls",
    items:  Array< {
      __typename: "Controls",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      displayID?: string | null,
      uuid?: string | null,
      parentID?: string | null,
      title?: string | null,
      description?: string | null,
      discussion?: string | null,
      relationships?: string | null,
      controlsList?:  {
        __typename: "PaginatedControls",
        items:  Array< {
          __typename: "Controls",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          displayID?: string | null,
          uuid?: string | null,
          parentID?: string | null,
          title?: string | null,
          description?: string | null,
          discussion?: string | null,
          relationships?: string | null,
          controlsList?:  {
            __typename: "PaginatedControls",
            items:  Array< {
              __typename: "Controls",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              displayID?: string | null,
              uuid?: string | null,
              parentID?: string | null,
              title?: string | null,
              description?: string | null,
              discussion?: string | null,
              relationships?: string | null,
              controlsList?:  {
                __typename: "PaginatedControls",
                items:  Array< {
                  __typename: "Controls",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  displayID?: string | null,
                  uuid?: string | null,
                  parentID?: string | null,
                  title?: string | null,
                  description?: string | null,
                  discussion?: string | null,
                  relationships?: string | null,
                } >,
                nextToken?: string | null,
              } | null,
              controlsRelatedItems?:  {
                __typename: "PaginatedLinks",
                items:  Array< {
                  __typename: "Link",
                  id: string,
                  sort: string,
                  target?: string | null,
                  targetType?: LinkTarget | null,
                } >,
                nextToken?: string | null,
              } | null,
            } >,
            nextToken?: string | null,
          } | null,
          controlsRelatedItems?:  {
            __typename: "PaginatedLinks",
            items:  Array< {
              __typename: "Link",
              id: string,
              sort: string,
              target?: string | null,
              targetType?: LinkTarget | null,
              item?:  {
                __typename: "RelatableItem",
                id?: string | null,
                sort?: string | null,
                groupID?: string | null,
                owner?: string | null,
                uuid?: string | null,
                title?: string | null,
                dateClosed?: string | null,
                dueDate?: string | null,
                status?: Status | null,
                priority?: Priority | null,
                severity?: Severity | null,
                responseStatus?: string | null,
                archived?: boolean | null,
                createdAt?: string | null,
                lastModified?: string | null,
                compositeIDYear?: string | null,
                compositeID?: number | null,
                displayID?: string | null,
                relatedItemsOwner?:  {
                  __typename: "User",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  email?: string | null,
                  username?: string | null,
                  firstName?: string | null,
                  lastName?: string | null,
                  inactive?: boolean | null,
                  lastSeen?: string | null,
                  lastLoggedIn?: string | null,
                } | null,
                relatedItemsGroup?:  {
                  __typename: "Group",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  organizationID?: string | null,
                  title?: string | null,
                  shortTitle?: string | null,
                  groupEmailFooter?: string | null,
                } | null,
                fileName?: string | null,
                address?: string | null,
              } | null,
            } >,
            nextToken?: string | null,
          } | null,
        } >,
        nextToken?: string | null,
      } | null,
      controlsRelatedItems?:  {
        __typename: "PaginatedLinks",
        items:  Array< {
          __typename: "Link",
          id: string,
          sort: string,
          target?: string | null,
          targetType?: LinkTarget | null,
          item?:  {
            __typename: "RelatableItem",
            id?: string | null,
            sort?: string | null,
            groupID?: string | null,
            owner?: string | null,
            uuid?: string | null,
            title?: string | null,
            dateClosed?: string | null,
            dueDate?: string | null,
            status?: Status | null,
            priority?: Priority | null,
            severity?: Severity | null,
            responseStatus?: string | null,
            archived?: boolean | null,
            createdAt?: string | null,
            lastModified?: string | null,
            compositeIDYear?: string | null,
            compositeID?: number | null,
            displayID?: string | null,
            relatedItemsOwner?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
            relatedItemsGroup?:  {
              __typename: "Group",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              organizationID?: string | null,
              title?: string | null,
              shortTitle?: string | null,
              riskThreshold?:  {
                __typename: "RiskThreshold",
                financial?:  {
                  __typename: "Threshold",
                  appetitePercent?: number | null,
                  appetiteAbsolute?: number | null,
                  capacityPercent?: number | null,
                  capacityAbsolute?: number | null,
                } | null,
                privacy?:  {
                  __typename: "Threshold",
                  appetitePercent?: number | null,
                  appetiteAbsolute?: number | null,
                  capacityPercent?: number | null,
                  capacityAbsolute?: number | null,
                } | null,
              } | null,
              organization?:  {
                __typename: "Organization",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                name?: string | null,
                organizationRelationships?: string | null,
                demographics?: string | null,
              } | null,
              groupEmailFooter?: string | null,
            } | null,
            fileName?: string | null,
            address?: string | null,
          } | null,
        } >,
        nextToken?: string | null,
      } | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetRiskPortfolioScenariosQueryVariables = {
  nextToken?: string | null,
  limit?: number | null,
  id: string,
};

export type GetRiskPortfolioScenariosQuery = {
  getRiskPortfolioScenarios?:  {
    __typename: "PaginatedScenarioInPortfolio",
    items:  Array< {
      __typename: "ScenarioInPortfolio",
      id: string,
      sort: string,
      scenarioID?: string | null,
      portfolioID?: string | null,
      scenario?:  {
        __typename: "Scenario",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        createdAt?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        lastModified?: string | null,
        FAIRSummary?: string | null,
        scenarioID?: string | null,
        title?: string | null,
        description?: string | null,
        notes?: string | null,
        assumptions?: string | null,
        creationDate?: string | null,
        lastRevision?: string | null,
        nextRevision?: string | null,
        categories?: string | null,
        owner?: string | null,
        status?: Status | null,
        dateClosed?: string | null,
        indicator?: Indicator | null,
        compositeIDYear?: string | null,
        compositeID?: number | null,
        archived?: boolean | null,
        isTemplate?: boolean | null,
        groupID?: string | null,
        scenarioRelatedItems?:  {
          __typename: "PaginatedLinks",
          items:  Array< {
            __typename: "Link",
            id: string,
            sort: string,
            target?: string | null,
            targetType?: LinkTarget | null,
            item?:  {
              __typename: "RelatableItem",
              id?: string | null,
              sort?: string | null,
              groupID?: string | null,
              owner?: string | null,
              uuid?: string | null,
              title?: string | null,
              dateClosed?: string | null,
              dueDate?: string | null,
              status?: Status | null,
              priority?: Priority | null,
              severity?: Severity | null,
              responseStatus?: string | null,
              archived?: boolean | null,
              createdAt?: string | null,
              lastModified?: string | null,
              compositeIDYear?: string | null,
              compositeID?: number | null,
              displayID?: string | null,
              relatedItemsOwner?:  {
                __typename: "User",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                username?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
                lastSeen?: string | null,
                lastLoggedIn?: string | null,
              } | null,
              relatedItemsGroup?:  {
                __typename: "Group",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                organizationID?: string | null,
                title?: string | null,
                shortTitle?: string | null,
                organization?:  {
                  __typename: "Organization",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  name?: string | null,
                  organizationRelationships?: string | null,
                  demographics?: string | null,
                } | null,
                groupEmailFooter?: string | null,
              } | null,
              fileName?: string | null,
              address?: string | null,
            } | null,
          } >,
          nextToken?: string | null,
        } | null,
        scenarioOwner?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        scenarioCreatedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        scenarioModifiedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        scenarioRASCIRoles?:  {
          __typename: "PaginatedRASCIUsersOnItem",
          items:  Array< {
            __typename: "RASCIUserOnItem",
            id?: string | null,
            sort?: string | null,
            userID?: string | null,
            userInfo?:  {
              __typename: "UserInfo",
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
            } | null,
            user?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
            userRASCIRole?: UserRoles | null,
          } >,
          nextToken?: string | null,
        } | null,
      } | null,
      portfolio?:  {
        __typename: "RiskPortfolio",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        groupID?: string | null,
        title?: string | null,
        description?: string | null,
        notes?: string | null,
        owner?: string | null,
        status?: Status | null,
        assumptions?: string | null,
        archived?: boolean | null,
        modifiedBy?: string | null,
      } | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetAuditPlanningsInGroupv2QueryVariables = {
  nextToken?: string | null,
  limit?: number | null,
  id: string,
};

export type GetAuditPlanningsInGroupv2Query = {
  getAuditPlanningsInGroupv2?:  {
    __typename: "PaginatedAuditPlanningReferences",
    items:  Array< {
      __typename: "AuditPlanningReference",
      id: string,
      sort: string,
      itemID: string,
      auditPlanning?:  {
        __typename: "AuditPlanning",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        title?: string | null,
        groupID?: string | null,
        sort?: string | null,
        createdAt?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        lastModified?: string | null,
        auditPlanID?: string | null,
        riskDomain?: string | null,
        year?: string | null,
        hours?: string | null,
        owner?: string | null,
        days?: string | null,
        status?: Status | null,
        background?: string | null,
        objective?: string | null,
        scope?: string | null,
        methodology?: string | null,
        preliminaryAuditWork?: string | null,
        points?: string | null,
        summary?: string | null,
        auditFindings?: string | null,
        recommendations?: string | null,
        auditStartDate?: string | null,
        auditEndDate?: string | null,
        auditor?: string | null,
        totalHours?: string | null,
        archived?: boolean | null,
        auditPlanningRelatedItems?:  {
          __typename: "PaginatedLinks",
          items:  Array< {
            __typename: "Link",
            id: string,
            sort: string,
            target?: string | null,
            targetType?: LinkTarget | null,
            item?:  {
              __typename: "RelatableItem",
              id?: string | null,
              sort?: string | null,
              groupID?: string | null,
              owner?: string | null,
              uuid?: string | null,
              title?: string | null,
              dateClosed?: string | null,
              dueDate?: string | null,
              status?: Status | null,
              priority?: Priority | null,
              severity?: Severity | null,
              responseStatus?: string | null,
              archived?: boolean | null,
              createdAt?: string | null,
              lastModified?: string | null,
              compositeIDYear?: string | null,
              compositeID?: number | null,
              displayID?: string | null,
              relatedItemsOwner?:  {
                __typename: "User",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                username?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
                lastSeen?: string | null,
                lastLoggedIn?: string | null,
              } | null,
              relatedItemsGroup?:  {
                __typename: "Group",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                organizationID?: string | null,
                title?: string | null,
                shortTitle?: string | null,
                organization?:  {
                  __typename: "Organization",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  name?: string | null,
                  organizationRelationships?: string | null,
                  demographics?: string | null,
                } | null,
                groupEmailFooter?: string | null,
              } | null,
              fileName?: string | null,
              address?: string | null,
            } | null,
          } >,
          nextToken?: string | null,
        } | null,
        isTemplate?: boolean | null,
        auditPlanningOwner?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        auditPlanningCreatedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        auditPlanningModifiedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        riskDomainDetails?:  {
          __typename: "RiskDomain",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          groupID?: string | null,
          sort?: string | null,
          createdAt?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          lastModified?: string | null,
          title?: string | null,
          description?: string | null,
          displayID?: string | null,
          parentID?: string | null,
          parent?:  {
            __typename: "RiskDomain",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            groupID?: string | null,
            sort?: string | null,
            createdAt?: string | null,
            createdBy?: string | null,
            modifiedBy?: string | null,
            lastModified?: string | null,
            title?: string | null,
            description?: string | null,
            displayID?: string | null,
            parentID?: string | null,
            parent?:  {
              __typename: "RiskDomain",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              groupID?: string | null,
              sort?: string | null,
              createdAt?: string | null,
              createdBy?: string | null,
              modifiedBy?: string | null,
              lastModified?: string | null,
              title?: string | null,
              description?: string | null,
              displayID?: string | null,
              parentID?: string | null,
              parent?:  {
                __typename: "RiskDomain",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                groupID?: string | null,
                sort?: string | null,
                createdAt?: string | null,
                createdBy?: string | null,
                modifiedBy?: string | null,
                lastModified?: string | null,
                title?: string | null,
                description?: string | null,
                displayID?: string | null,
                parentID?: string | null,
                parent?:  {
                  __typename: "RiskDomain",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  groupID?: string | null,
                  sort?: string | null,
                  createdAt?: string | null,
                  createdBy?: string | null,
                  modifiedBy?: string | null,
                  lastModified?: string | null,
                  title?: string | null,
                  description?: string | null,
                  displayID?: string | null,
                  parentID?: string | null,
                  archived?: boolean | null,
                } | null,
                archived?: boolean | null,
                riskDomainCreatedBy?:  {
                  __typename: "User",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  email?: string | null,
                  username?: string | null,
                  firstName?: string | null,
                  lastName?: string | null,
                  inactive?: boolean | null,
                  lastSeen?: string | null,
                  lastLoggedIn?: string | null,
                } | null,
                riskDomainModifiedBy?:  {
                  __typename: "User",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  email?: string | null,
                  username?: string | null,
                  firstName?: string | null,
                  lastName?: string | null,
                  inactive?: boolean | null,
                  lastSeen?: string | null,
                  lastLoggedIn?: string | null,
                } | null,
              } | null,
              archived?: boolean | null,
              riskDomainCreatedBy?:  {
                __typename: "User",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                username?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
                lastSeen?: string | null,
                lastLoggedIn?: string | null,
              } | null,
              riskDomainModifiedBy?:  {
                __typename: "User",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                username?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
                lastSeen?: string | null,
                lastLoggedIn?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            riskDomainCreatedBy?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
            riskDomainModifiedBy?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          riskDomainCreatedBy?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
          riskDomainModifiedBy?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
        } | null,
        managementResponse?: string | null,
        viewType?: ViewType | null,
      } | null,
      createdAt?: string | null,
      userRASCIRole?: UserRoles | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetAssetsInGroupv2QueryVariables = {
  nextToken?: string | null,
  limit?: number | null,
  id: string,
};

export type GetAssetsInGroupv2Query = {
  getAssetsInGroupv2?:  {
    __typename: "PaginatedAssetsReferences",
    items:  Array< {
      __typename: "AssetsReference",
      id: string,
      sort: string,
      itemID: string,
      assets?:  {
        __typename: "Assets",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        itemID?: string | null,
        groupID?: string | null,
        sort?: string | null,
        createdAt?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        lastModified?: string | null,
        title?: string | null,
        qty?: number | null,
        assetsGroupID?: string | null,
        notesAboutControls?: string | null,
        notesAboutThreats?: string | null,
        category?: string | null,
        assetCategory?:  {
          __typename: "Category",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          groupID?: string | null,
          sort?: string | null,
          createdAt?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          lastModified?: string | null,
          title?: string | null,
          archived?: boolean | null,
          categoryCreatedBy?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
          categoryModifiedBy?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
        } | null,
        location?: string | null,
        assetLocation?:  {
          __typename: "Location",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          groupID?: string | null,
          sort?: string | null,
          createdAt?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          lastModified?: string | null,
          title?: string | null,
          archived?: boolean | null,
          locationCreatedBy?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
          locationModifiedBy?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
        } | null,
        support?: string | null,
        assetSupport?:  {
          __typename: "Support",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          groupID?: string | null,
          sort?: string | null,
          createdAt?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          lastModified?: string | null,
          title?: string | null,
          pointOfContact?: string | null,
          pocEmail?: string | null,
          pocPhoneNumber?: string | null,
          notes?: string | null,
          archived?: boolean | null,
          supportCreatedBy?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
          supportModifiedBy?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
        } | null,
        owner?: string | null,
        assetsAnalysisInput?: string | null,
        assetsAnalysisOutput?: string | null,
        assetOwner?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        assetCreatedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        assetModifiedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        notes?: string | null,
        displayID?: string | null,
        assetRelatedItems?:  {
          __typename: "PaginatedLinks",
          items:  Array< {
            __typename: "Link",
            id: string,
            sort: string,
            target?: string | null,
            targetType?: LinkTarget | null,
            item?:  {
              __typename: "RelatableItem",
              id?: string | null,
              sort?: string | null,
              groupID?: string | null,
              owner?: string | null,
              uuid?: string | null,
              title?: string | null,
              dateClosed?: string | null,
              dueDate?: string | null,
              status?: Status | null,
              priority?: Priority | null,
              severity?: Severity | null,
              responseStatus?: string | null,
              archived?: boolean | null,
              createdAt?: string | null,
              lastModified?: string | null,
              compositeIDYear?: string | null,
              compositeID?: number | null,
              displayID?: string | null,
              relatedItemsOwner?:  {
                __typename: "User",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                email?: string | null,
                username?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                inactive?: boolean | null,
                lastSeen?: string | null,
                lastLoggedIn?: string | null,
              } | null,
              relatedItemsGroup?:  {
                __typename: "Group",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                organizationID?: string | null,
                title?: string | null,
                shortTitle?: string | null,
                organization?:  {
                  __typename: "Organization",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  name?: string | null,
                  organizationRelationships?: string | null,
                  demographics?: string | null,
                } | null,
                groupEmailFooter?: string | null,
              } | null,
              fileName?: string | null,
              address?: string | null,
            } | null,
          } >,
          nextToken?: string | null,
        } | null,
        archived?: boolean | null,
        threatAssessments?:  {
          __typename: "PaginatedThreatAssessments",
          items:  Array< {
            __typename: "ThreatAssessment",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            createdAt?: string | null,
            createdBy?: string | null,
            modifiedBy?: string | null,
            lastModified?: string | null,
            threatID?: string | null,
            assetID?: string | null,
            min?: number | null,
            mostLikely?: number | null,
            max?: number | null,
            vulnerability?: number | null,
            index?: number | null,
            archived?: boolean | null,
          } >,
          nextToken?: string | null,
        } | null,
        PHIRecords?: string | null,
        assetsGroupDetails?:  {
          __typename: "AssetsGroup",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          groupID?: string | null,
          sort?: string | null,
          createdAt?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          lastModified?: string | null,
          title?: string | null,
          archived?: boolean | null,
          assetsGroupCreatedBy?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
          assetsGroupModifiedBy?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
        } | null,
        assetID?: string | null,
        assessmentID?: string | null,
      } | null,
      createdAt?: string | null,
      userRASCIRole?: UserRoles | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetAssessmentAssetsQueryVariables = {
  nextToken?: string | null,
  limit?: number | null,
  id: string,
};

export type GetAssessmentAssetsQuery = {
  getAssessmentAssets?:  {
    __typename: "PaginatedAssets",
    items:  Array< {
      __typename: "Assets",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      itemID?: string | null,
      groupID?: string | null,
      sort?: string | null,
      createdAt?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      lastModified?: string | null,
      title?: string | null,
      qty?: number | null,
      assetsGroupID?: string | null,
      notesAboutControls?: string | null,
      notesAboutThreats?: string | null,
      category?: string | null,
      assetCategory?:  {
        __typename: "Category",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        groupID?: string | null,
        sort?: string | null,
        createdAt?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        lastModified?: string | null,
        title?: string | null,
        archived?: boolean | null,
        categoryCreatedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        categoryModifiedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
      } | null,
      location?: string | null,
      assetLocation?:  {
        __typename: "Location",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        groupID?: string | null,
        sort?: string | null,
        createdAt?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        lastModified?: string | null,
        title?: string | null,
        archived?: boolean | null,
        locationCreatedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        locationModifiedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
      } | null,
      support?: string | null,
      assetSupport?:  {
        __typename: "Support",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        groupID?: string | null,
        sort?: string | null,
        createdAt?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        lastModified?: string | null,
        title?: string | null,
        pointOfContact?: string | null,
        pocEmail?: string | null,
        pocPhoneNumber?: string | null,
        notes?: string | null,
        archived?: boolean | null,
        supportCreatedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        supportModifiedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
      } | null,
      owner?: string | null,
      assetsAnalysisInput?: string | null,
      assetsAnalysisOutput?: string | null,
      assetOwner?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      assetCreatedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      assetModifiedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      notes?: string | null,
      displayID?: string | null,
      assetRelatedItems?:  {
        __typename: "PaginatedLinks",
        items:  Array< {
          __typename: "Link",
          id: string,
          sort: string,
          target?: string | null,
          targetType?: LinkTarget | null,
          item?:  {
            __typename: "RelatableItem",
            id?: string | null,
            sort?: string | null,
            groupID?: string | null,
            owner?: string | null,
            uuid?: string | null,
            title?: string | null,
            dateClosed?: string | null,
            dueDate?: string | null,
            status?: Status | null,
            priority?: Priority | null,
            severity?: Severity | null,
            responseStatus?: string | null,
            archived?: boolean | null,
            createdAt?: string | null,
            lastModified?: string | null,
            compositeIDYear?: string | null,
            compositeID?: number | null,
            displayID?: string | null,
            relatedItemsOwner?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
            relatedItemsGroup?:  {
              __typename: "Group",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              organizationID?: string | null,
              title?: string | null,
              shortTitle?: string | null,
              riskThreshold?:  {
                __typename: "RiskThreshold",
                financial?:  {
                  __typename: "Threshold",
                  appetitePercent?: number | null,
                  appetiteAbsolute?: number | null,
                  capacityPercent?: number | null,
                  capacityAbsolute?: number | null,
                } | null,
                privacy?:  {
                  __typename: "Threshold",
                  appetitePercent?: number | null,
                  appetiteAbsolute?: number | null,
                  capacityPercent?: number | null,
                  capacityAbsolute?: number | null,
                } | null,
              } | null,
              organization?:  {
                __typename: "Organization",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                name?: string | null,
                organizationRelationships?: string | null,
                demographics?: string | null,
              } | null,
              groupEmailFooter?: string | null,
            } | null,
            fileName?: string | null,
            address?: string | null,
          } | null,
        } >,
        nextToken?: string | null,
      } | null,
      archived?: boolean | null,
      threatAssessments?:  {
        __typename: "PaginatedThreatAssessments",
        items:  Array< {
          __typename: "ThreatAssessment",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          createdAt?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          lastModified?: string | null,
          threatID?: string | null,
          assetID?: string | null,
          min?: number | null,
          mostLikely?: number | null,
          max?: number | null,
          vulnerability?: number | null,
          index?: number | null,
          archived?: boolean | null,
        } >,
        nextToken?: string | null,
      } | null,
      PHIRecords?: string | null,
      assetsGroupDetails?:  {
        __typename: "AssetsGroup",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        groupID?: string | null,
        sort?: string | null,
        createdAt?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        lastModified?: string | null,
        title?: string | null,
        archived?: boolean | null,
        assetsGroupCreatedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        assetsGroupModifiedBy?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
      } | null,
      assetID?: string | null,
      assessmentID?: string | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetSystemSurveysQueryVariables = {
  nextToken?: string | null,
  limit?: number | null,
  id: string,
};

export type GetSystemSurveysQuery = {
  getSystemSurveys?:  {
    __typename: "PaginatedSurveyTemplate",
    items:  Array< {
      __typename: "SurveyTemplate",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      parentID?: string | null,
      templateRoot?: boolean | null,
      title?: string | null,
      locked?: number | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetTemplateChildrenQueryVariables = {
  nextToken?: string | null,
  limit?: number | null,
  id: string,
};

export type GetTemplateChildrenQuery = {
  getTemplateChildren?:  {
    __typename: "PaginatedTemplateChildren",
    items:  Array< {
      __typename: "TemplateChildren",
      id: string,
      sort: string,
      title?: string | null,
      type?: string | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetIssueGroupSummaryQueryVariables = {
  id: string,
};

export type GetIssueGroupSummaryQuery = {
  getIssueGroupSummary:  {
    __typename: "PaginatedIssueGroupSummary",
    items:  Array< {
      __typename: "IssueGroupSummary",
      groupID: string,
      status?: Status | null,
      priority?: Priority | null,
      severity?: Severity | null,
      count?: number | null,
    } >,
  },
};

export type GetIssueTimeSeriesGroupSummaryQueryVariables = {
  id: string,
  dates: Array< string | null >,
};

export type GetIssueTimeSeriesGroupSummaryQuery = {
  getIssueTimeSeriesGroupSummary:  Array< {
    __typename: "PaginatedIssueGroupSummary",
    items:  Array< {
      __typename: "IssueGroupSummary",
      groupID: string,
      status?: Status | null,
      priority?: Priority | null,
      severity?: Severity | null,
      count?: number | null,
    } >,
  } >,
};

export type GetSQLIssuesQueryVariables = {
  id: string,
  limit?: number | null,
  nextToken?: string | null,
  filter?: IssueFilterInput | null,
  sort?: IssueSortInput | null,
};

export type GetSQLIssuesQuery = {
  getSQLIssues:  {
    __typename: "PaginatedIssue",
    items:  Array< {
      __typename: "Issue",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      createdAt?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      verifiedBy?: string | null,
      lastModified?: string | null,
      issueID?: string | null,
      title?: string | null,
      description?: string | null,
      assumptions?: string | null,
      notes?: string | null,
      reportedBy?: string | null,
      reportedOn?: string | null,
      nextReview?: string | null,
      owner?: string | null,
      priority?: Priority | null,
      status?: Status | null,
      approver?: string | null,
      dateClosed?: string | null,
      severity?: Severity | null,
      compositeIDYear?: string | null,
      compositeID?: number | null,
      archived?: boolean | null,
      isTemplate?: boolean | null,
      issueSourceId?: string | null,
      groupID?: string | null,
      issueRelatedItems?:  {
        __typename: "PaginatedLinks",
        items:  Array< {
          __typename: "Link",
          id: string,
          sort: string,
          target?: string | null,
          targetType?: LinkTarget | null,
          item?:  {
            __typename: "RelatableItem",
            id?: string | null,
            sort?: string | null,
            groupID?: string | null,
            owner?: string | null,
            uuid?: string | null,
            title?: string | null,
            dateClosed?: string | null,
            dueDate?: string | null,
            status?: Status | null,
            priority?: Priority | null,
            severity?: Severity | null,
            responseStatus?: string | null,
            archived?: boolean | null,
            createdAt?: string | null,
            lastModified?: string | null,
            compositeIDYear?: string | null,
            compositeID?: number | null,
            displayID?: string | null,
            relatedItemsOwner?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
            relatedItemsGroup?:  {
              __typename: "Group",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              organizationID?: string | null,
              title?: string | null,
              shortTitle?: string | null,
              riskThreshold?:  {
                __typename: "RiskThreshold",
                financial?:  {
                  __typename: "Threshold",
                  appetitePercent?: number | null,
                  appetiteAbsolute?: number | null,
                  capacityPercent?: number | null,
                  capacityAbsolute?: number | null,
                } | null,
                privacy?:  {
                  __typename: "Threshold",
                  appetitePercent?: number | null,
                  appetiteAbsolute?: number | null,
                  capacityPercent?: number | null,
                  capacityAbsolute?: number | null,
                } | null,
              } | null,
              organization?:  {
                __typename: "Organization",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                name?: string | null,
                organizationRelationships?: string | null,
                demographics?: string | null,
              } | null,
              groupEmailFooter?: string | null,
            } | null,
            fileName?: string | null,
            address?: string | null,
          } | null,
        } >,
        nextToken?: string | null,
      } | null,
      acceptanceCriteria?: string | null,
      requiresScenario?: boolean | null,
      requiresActionplan?: boolean | null,
      issueOwner?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      issueCreatedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      issueModifiedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      issueVerifiedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      issueSource?:  {
        __typename: "Source",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        organizationID?: string | null,
        groupID?: string | null,
        description?: string | null,
        year?: string | null,
        title?: string | null,
        archived?: boolean | null,
      } | null,
      issueRASCIRoles?:  {
        __typename: "PaginatedRASCIUsersOnItem",
        items:  Array< {
          __typename: "RASCIUserOnItem",
          id?: string | null,
          sort?: string | null,
          userID?: string | null,
          userInfo?:  {
            __typename: "UserInfo",
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
          } | null,
          user?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
          userRASCIRole?: UserRoles | null,
        } >,
        nextToken?: string | null,
      } | null,
      issueApprovers?:  {
        __typename: "PaginatedItemApprover",
        items:  Array< {
          __typename: "ItemApprover",
          id?: string | null,
          sort?: string | null,
          userID?: string | null,
          userInfo?:  {
            __typename: "UserInfo",
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
          } | null,
          user?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
        } >,
        nextToken?: string | null,
      } | null,
    } >,
    nextToken?: string | null,
  },
};

export type GetIssueOrganizationSummaryQueryVariables = {
  id: string,
};

export type GetIssueOrganizationSummaryQuery = {
  getIssueOrganizationSummary:  {
    __typename: "PaginatedIssueOrganizationSummary",
    items:  Array< {
      __typename: "IssueOrganizationSummary",
      organizationID: string,
      status?: Status | null,
      priority?: Priority | null,
      severity?: Severity | null,
      count?: number | null,
    } >,
  },
};

export type GetSQLActionPlanQueryVariables = {
  id: string,
  limit?: number | null,
  nextToken?: string | null,
  filter?: ActionPlanFilterInput | null,
  sort?: ActionPlanSortInput | null,
};

export type GetSQLActionPlanQuery = {
  getSQLActionPlan:  {
    __typename: "PaginatedActionPlan",
    items:  Array< {
      __typename: "ActionPlan",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      createdAt?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      lastModified?: string | null,
      actionPlanID?: string | null,
      title?: string | null,
      description?: string | null,
      resources?: string | null,
      notes?: string | null,
      owner?: string | null,
      priority?: Priority | null,
      status?: Status | null,
      approver?: string | null,
      dateClosed?: string | null,
      startDate?: string | null,
      dueDate?: string | null,
      reviewDate?: string | null,
      compositeIDYear?: string | null,
      compositeID?: number | null,
      archived?: boolean | null,
      isTemplate?: boolean | null,
      groupID?: string | null,
      actionPlanRelatedItems?:  {
        __typename: "PaginatedLinks",
        items:  Array< {
          __typename: "Link",
          id: string,
          sort: string,
          target?: string | null,
          targetType?: LinkTarget | null,
          item?:  {
            __typename: "RelatableItem",
            id?: string | null,
            sort?: string | null,
            groupID?: string | null,
            owner?: string | null,
            uuid?: string | null,
            title?: string | null,
            dateClosed?: string | null,
            dueDate?: string | null,
            status?: Status | null,
            priority?: Priority | null,
            severity?: Severity | null,
            responseStatus?: string | null,
            archived?: boolean | null,
            createdAt?: string | null,
            lastModified?: string | null,
            compositeIDYear?: string | null,
            compositeID?: number | null,
            displayID?: string | null,
            relatedItemsOwner?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
            relatedItemsGroup?:  {
              __typename: "Group",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              organizationID?: string | null,
              title?: string | null,
              shortTitle?: string | null,
              riskThreshold?:  {
                __typename: "RiskThreshold",
                financial?:  {
                  __typename: "Threshold",
                  appetitePercent?: number | null,
                  appetiteAbsolute?: number | null,
                  capacityPercent?: number | null,
                  capacityAbsolute?: number | null,
                } | null,
                privacy?:  {
                  __typename: "Threshold",
                  appetitePercent?: number | null,
                  appetiteAbsolute?: number | null,
                  capacityPercent?: number | null,
                  capacityAbsolute?: number | null,
                } | null,
              } | null,
              organization?:  {
                __typename: "Organization",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                name?: string | null,
                organizationRelationships?: string | null,
                demographics?: string | null,
              } | null,
              groupEmailFooter?: string | null,
            } | null,
            fileName?: string | null,
            address?: string | null,
          } | null,
        } >,
        nextToken?: string | null,
      } | null,
      taskChildren?:  {
        __typename: "PaginatedTaskReferences",
        items:  Array< {
          __typename: "TaskReference",
          id: string,
          sort: string,
          itemID: string,
          task?:  {
            __typename: "Task",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            createdAt?: string | null,
            createdBy?: string | null,
            modifiedBy?: string | null,
            lastModified?: string | null,
            parentID?: string | null,
            organizationID?: string | null,
            groupID?: string | null,
            title?: string | null,
            description?: string | null,
            resources?: string | null,
            notes?: string | null,
            owner?: string | null,
            priority?: Priority | null,
            status?: Status | null,
            dateClosed?: string | null,
            dueDate?: string | null,
            archived?: boolean | null,
            taskOwner?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
            taskRASCIRoles?:  {
              __typename: "PaginatedRASCIUsersOnItem",
              items:  Array< {
                __typename: "RASCIUserOnItem",
                id?: string | null,
                sort?: string | null,
                userID?: string | null,
                userInfo?:  {
                  __typename: "UserInfo",
                  id?: string | null,
                  sort?: string | null,
                  email?: string | null,
                  firstName?: string | null,
                  lastName?: string | null,
                  inactive?: boolean | null,
                } | null,
                user?:  {
                  __typename: "User",
                  res?: string | null,
                  error?: string | null,
                  id?: string | null,
                  sort?: string | null,
                  email?: string | null,
                  username?: string | null,
                  firstName?: string | null,
                  lastName?: string | null,
                  inactive?: boolean | null,
                  lastSeen?: string | null,
                  lastLoggedIn?: string | null,
                } | null,
                userRASCIRole?: UserRoles | null,
              } >,
              nextToken?: string | null,
            } | null,
          } | null,
          createdAt?: string | null,
          userRASCIRole?: UserRoles | null,
        } >,
        nextToken?: string | null,
      } | null,
      effortAmount?: number | null,
      effortType?: EffortType | null,
      actionPlanOwner?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      actionPlanCreatedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      actionPlanModifiedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      actionPlanRASCIRoles?:  {
        __typename: "PaginatedRASCIUsersOnItem",
        items:  Array< {
          __typename: "RASCIUserOnItem",
          id?: string | null,
          sort?: string | null,
          userID?: string | null,
          userInfo?:  {
            __typename: "UserInfo",
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
          } | null,
          user?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
          userRASCIRole?: UserRoles | null,
        } >,
        nextToken?: string | null,
      } | null,
      actionplanApprovers?:  {
        __typename: "PaginatedItemApprover",
        items:  Array< {
          __typename: "ItemApprover",
          id?: string | null,
          sort?: string | null,
          userID?: string | null,
          userInfo?:  {
            __typename: "UserInfo",
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
          } | null,
          user?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
        } >,
        nextToken?: string | null,
      } | null,
      type?: string | null,
    } >,
    nextToken?: string | null,
  },
};

export type GetActionPlanGroupSummaryQueryVariables = {
  id: string,
};

export type GetActionPlanGroupSummaryQuery = {
  getActionPlanGroupSummary:  {
    __typename: "PaginatedActionPlanGroupSummary",
    items:  Array< {
      __typename: "ActionPlanGroupSummary",
      groupID: string,
      status?: Status | null,
      priority?: Priority | null,
      duedate?: ActionPlanDueDates | null,
      count?: number | null,
    } >,
  },
};

export type GetActionPlanTimeSeriesGroupSummaryQueryVariables = {
  id: string,
  dates: Array< string | null >,
};

export type GetActionPlanTimeSeriesGroupSummaryQuery = {
  getActionPlanTimeSeriesGroupSummary:  Array< {
    __typename: "PaginatedActionPlanGroupSummary",
    items:  Array< {
      __typename: "ActionPlanGroupSummary",
      groupID: string,
      status?: Status | null,
      priority?: Priority | null,
      duedate?: ActionPlanDueDates | null,
      count?: number | null,
    } >,
  } >,
};

export type GetSQLScenariosQueryVariables = {
  id: string,
  limit?: number | null,
  nextToken?: string | null,
  filter?: ScenarioFilterInput | null,
  sort?: ScenarioSortInput | null,
};

export type GetSQLScenariosQuery = {
  getSQLScenarios:  {
    __typename: "PaginatedScenario",
    items:  Array< {
      __typename: "Scenario",
      res?: string | null,
      error?: string | null,
      id?: string | null,
      sort?: string | null,
      createdAt?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      lastModified?: string | null,
      FAIRSummary?: string | null,
      scenarioID?: string | null,
      title?: string | null,
      description?: string | null,
      notes?: string | null,
      assumptions?: string | null,
      creationDate?: string | null,
      lastRevision?: string | null,
      nextRevision?: string | null,
      categories?: string | null,
      owner?: string | null,
      status?: Status | null,
      dateClosed?: string | null,
      indicator?: Indicator | null,
      compositeIDYear?: string | null,
      compositeID?: number | null,
      archived?: boolean | null,
      isTemplate?: boolean | null,
      groupID?: string | null,
      scenarioRelatedItems?:  {
        __typename: "PaginatedLinks",
        items:  Array< {
          __typename: "Link",
          id: string,
          sort: string,
          target?: string | null,
          targetType?: LinkTarget | null,
          item?:  {
            __typename: "RelatableItem",
            id?: string | null,
            sort?: string | null,
            groupID?: string | null,
            owner?: string | null,
            uuid?: string | null,
            title?: string | null,
            dateClosed?: string | null,
            dueDate?: string | null,
            status?: Status | null,
            priority?: Priority | null,
            severity?: Severity | null,
            responseStatus?: string | null,
            archived?: boolean | null,
            createdAt?: string | null,
            lastModified?: string | null,
            compositeIDYear?: string | null,
            compositeID?: number | null,
            displayID?: string | null,
            relatedItemsOwner?:  {
              __typename: "User",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              email?: string | null,
              username?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              inactive?: boolean | null,
              lastSeen?: string | null,
              lastLoggedIn?: string | null,
            } | null,
            relatedItemsGroup?:  {
              __typename: "Group",
              res?: string | null,
              error?: string | null,
              id?: string | null,
              sort?: string | null,
              organizationID?: string | null,
              title?: string | null,
              shortTitle?: string | null,
              riskThreshold?:  {
                __typename: "RiskThreshold",
                financial?:  {
                  __typename: "Threshold",
                  appetitePercent?: number | null,
                  appetiteAbsolute?: number | null,
                  capacityPercent?: number | null,
                  capacityAbsolute?: number | null,
                } | null,
                privacy?:  {
                  __typename: "Threshold",
                  appetitePercent?: number | null,
                  appetiteAbsolute?: number | null,
                  capacityPercent?: number | null,
                  capacityAbsolute?: number | null,
                } | null,
              } | null,
              organization?:  {
                __typename: "Organization",
                res?: string | null,
                error?: string | null,
                id?: string | null,
                sort?: string | null,
                name?: string | null,
                organizationRelationships?: string | null,
                demographics?: string | null,
              } | null,
              groupEmailFooter?: string | null,
            } | null,
            fileName?: string | null,
            address?: string | null,
          } | null,
        } >,
        nextToken?: string | null,
      } | null,
      scenarioOwner?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      scenarioCreatedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      scenarioModifiedBy?:  {
        __typename: "User",
        res?: string | null,
        error?: string | null,
        id?: string | null,
        sort?: string | null,
        email?: string | null,
        username?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        inactive?: boolean | null,
        lastSeen?: string | null,
        lastLoggedIn?: string | null,
      } | null,
      scenarioRASCIRoles?:  {
        __typename: "PaginatedRASCIUsersOnItem",
        items:  Array< {
          __typename: "RASCIUserOnItem",
          id?: string | null,
          sort?: string | null,
          userID?: string | null,
          userInfo?:  {
            __typename: "UserInfo",
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
          } | null,
          user?:  {
            __typename: "User",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            email?: string | null,
            username?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            inactive?: boolean | null,
            lastSeen?: string | null,
            lastLoggedIn?: string | null,
          } | null,
          userRASCIRole?: UserRoles | null,
        } >,
        nextToken?: string | null,
      } | null,
    } >,
    nextToken?: string | null,
  },
};

export type GetAnalysisQueryVariables = {
  id: string,
};

export type GetAnalysisQuery = {
  getAnalysis?:  {
    __typename: "Analysis",
    id: string,
    sort: string,
    name?: string | null,
    owner?: string | null,
    summary?:  {
      __typename: "SummaryStats",
      id?: string | null,
      results?: string | null,
      ALE25?: number | null,
      ALE50?: number | null,
      ALE75?: number | null,
      ALE95?: number | null,
      ALEs?: Array< number | null > | null,
      max?: number | null,
      mean?: number | null,
      median?: number | null,
      min?: number | null,
      mode?: number | null,
    } | null,
    frequency?:  {
      __typename: "FrequencyData",
      CEF?:  {
        __typename: "PertInfo",
        min?: number | null,
        max?: number | null,
        mode?: number | null,
        shape?: number | null,
      } | null,
      CEFAssumptions?: string | null,
      LEF?:  {
        __typename: "PertInfo",
        min?: number | null,
        max?: number | null,
        mode?: number | null,
        shape?: number | null,
      } | null,
      LEFAssumptions?: string | null,
      TEF?:  {
        __typename: "PertInfo",
        min?: number | null,
        max?: number | null,
        mode?: number | null,
        shape?: number | null,
      } | null,
      TEFAssumptions?: string | null,
      POA?: number | null,
      POAAssumptions?: string | null,
      vulnerability?: number | null,
      vulnerabilityAssumptions?: string | null,
      currentLayer?: string | null,
      seed?: number | null,
    } | null,
    magnitudes?:  {
      __typename: "PaginatedMagnitudeData",
      items:  Array< {
        __typename: "MagnitudeData",
        magnitudeType?: string | null,
        assumptions?: string | null,
        primaryMagnitude?:  {
          __typename: "PertInfo",
          min?: number | null,
          max?: number | null,
          mode?: number | null,
          shape?: number | null,
        } | null,
        secondaryFrequency?: number | null,
        secondaryMagnitude?:  {
          __typename: "PertInfo",
          min?: number | null,
          max?: number | null,
          mode?: number | null,
          shape?: number | null,
        } | null,
        secondaryAssumptions?: string | null,
      } >,
      nextToken?: string | null,
    } | null,
    lastRun?:  {
      __typename: "AnalysisRun",
      settings?: string | null,
      results?: string | null,
      frequencyResults?: string | null,
      seed?: number | null,
    } | null,
  } | null,
};

export type GetCommentsByFeedQueryVariables = {
  id: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetCommentsByFeedQuery = {
  getCommentsByFeed?:  {
    __typename: "PaginatedComments",
    items:  Array< {
      __typename: "Comment",
      id: string,
      sort: string,
      owner?: string | null,
      message: string,
      change?: boolean | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetLinksByItemQueryVariables = {
  id: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetLinksByItemQuery = {
  getLinksByItem?:  {
    __typename: "PaginatedLinks",
    items:  Array< {
      __typename: "Link",
      id: string,
      sort: string,
      target?: string | null,
      targetType?: LinkTarget | null,
      item?:  {
        __typename: "RelatableItem",
        id?: string | null,
        sort?: string | null,
        groupID?: string | null,
        owner?: string | null,
        uuid?: string | null,
        title?: string | null,
        dateClosed?: string | null,
        dueDate?: string | null,
        status?: Status | null,
        priority?: Priority | null,
        severity?: Severity | null,
        responseStatus?: string | null,
        archived?: boolean | null,
        createdAt?: string | null,
        lastModified?: string | null,
        compositeIDYear?: string | null,
        compositeID?: number | null,
        displayID?: string | null,
        relatedItemsOwner?:  {
          __typename: "User",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          email?: string | null,
          username?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          inactive?: boolean | null,
          lastSeen?: string | null,
          lastLoggedIn?: string | null,
        } | null,
        relatedItemsGroup?:  {
          __typename: "Group",
          res?: string | null,
          error?: string | null,
          id?: string | null,
          sort?: string | null,
          organizationID?: string | null,
          title?: string | null,
          shortTitle?: string | null,
          riskThreshold?:  {
            __typename: "RiskThreshold",
            financial?:  {
              __typename: "Threshold",
              appetitePercent?: number | null,
              appetiteAbsolute?: number | null,
              capacityPercent?: number | null,
              capacityAbsolute?: number | null,
            } | null,
            privacy?:  {
              __typename: "Threshold",
              appetitePercent?: number | null,
              appetiteAbsolute?: number | null,
              capacityPercent?: number | null,
              capacityAbsolute?: number | null,
            } | null,
          } | null,
          organization?:  {
            __typename: "Organization",
            res?: string | null,
            error?: string | null,
            id?: string | null,
            sort?: string | null,
            name?: string | null,
            organizationRelationships?: string | null,
            demographics?: string | null,
          } | null,
          groupEmailFooter?: string | null,
        } | null,
        fileName?: string | null,
        address?: string | null,
      } | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetAssessmentCSVQueryVariables = {
  id: string,
};

export type GetAssessmentCSVQuery = {
  getAssessmentCSV?: string | null,
};
